<template>
  <el-card>
    <el-button type="primary" size="small" v-if="canInput" @click="toAdd()">
      <i class="fa fa-fw fa-plus-circle"></i> 新收款</el-button>
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.org_no" clearable placeholder="单据编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.q" clearable placeholder="关键字" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search" v-if="!isInputRole">
        <el-select v-model="queryForm.company_id" clearable placeholder="企业筛选">
          <el-option v-for="item in companies" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status" clearable placeholder="状态">
          <el-option v-for="(label, key) in statusesMap" :key="key" :value="key" :label="label"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th class="text-center">原始单据编号</th>
          <th class="text-center">日期</th>
          <th class="text-center">金额</th>
          <th class="text-center">类型</th>
          <th class="text-center">状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item) in list" :key="item.id">
          <td class="text-center"><a href="#" @click.prevent="showDetail(item.id)">{{ item.org_no }}</a></td>
          <td class="text-center">{{ item.occ_at }}</td>
          <td class="text-center">{{ item.amount }}</td>
          <td class="text-center">{{ item.type_text }}</td>
          <td :class="{'text-center': true,'text-danger': item.status == '4', 'text-info': item.status == '3' }">
            {{statusesMap[item.status] ? statusesMap[item.status] : ''}}</td>
          <td class="text-center">
            <div class="text-nowrap">
              <el-button v-if="(item.status == '1' || item.status == '4') && canInput" icon="fa fa-pencil"
                style="margin-left: 3px;" type="info" title="修改信息" @click="toEdit(item)"></el-button>
              <el-button v-if="(item.status == '1' || item.status == '4') && canInput" icon="fa fa-upload"
                style="margin-left: 3px;" type="warning" title="提交审核" @click="toVerify(item)"></el-button>
              <el-button v-if="item.status == '2' && canInput" icon="fa fa-undo"
                style="margin-left: 3px;" type="warning" title="撤回" @click="backVerify(item)"></el-button>
              <el-button v-if="item.status == '2' && canArch" icon="fa fa-paint-brush"
                style="margin-left: 3px;" type="warning" title="审核" @click="showDetail(item.id)"></el-button>
              <el-button v-if="['3', '4'].indexOf(item.status) !== -1" icon="fa fa-search"
                style="margin-left: 3px;" type="info" title="详情" @click="showDetail(item.id)"></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="showDialog" title="收入记录详情" :close-on-click-modal="false"
      :close="closeDetailDialog" width="90%">
      <el-form label-width="130px" class="compact-mb5">
        <el-row>
          <el-col :span="12">
            <el-form-item label="原始单据编号：">
              {{showModel.org_no}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期：">
              {{showModel.occ_at}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="收款公司：">
              {{showModel.company ? showModel.company.name : '-'}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="业务来源：">
              {{showModel.from_type == '1' ? '集团公司' : '其他业务' }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付方：">
              {{showModel.from}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="支付类型：">
              {{showModel.type_text}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="金额(USD)：">
              {{showModel.amount}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="状态：">
              {{showModel.status ? statusesMap[showModel.status] : ''}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="事项说明：">
          {{showModel.desc}}
        </el-form-item>
        <el-form-item label="其他说明：">
          {{showModel.remark}}
        </el-form-item>
        <el-form-item label="审批信息：" v-if="['1', '2', '4'].indexOf(showModel.status) !== -1">
          <el-input type="textarea" v-if="showModel.status === '2' && canArch" v-model="showModel.verify_response"></el-input>
          <span v-else>{{showModel.verify_response}}</span>
        </el-form-item>
        <el-form-item label="明细:">
          <table class="table bordered" v-if="showModel.items && showModel.items.length > 0">
            <thead>
              <tr>
                <th>运单号</th>
                <th>款号名称</th>
                <th>数量</th>
                <th>总金额</th>
                <th>支付金额</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in showModel.items" :key="index">
                <td>{{item.waybill_no}}</td>
                <td>{{item.production}}</td>
                <td>{{item.num}}</td>
                <td>{{item.amount}}</td>
                <td>{{item.pay}}</td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
        <el-form-item label="附件：" label-width="100px" v-if="showModel.attachments">
          <div style="padding-right: 50px;">
            <div v-for="(item, idx) in showModel.attachments" :key="idx" class="download-img-box">
              <i class="fa fa-download download-rt-ico" title="下载"
                @click="downloadImg(item, `${showModel.org_no}_${idx + 1}`)"></i>
              <img :src="item.url" alt="" style="max-width: 100%;">
            </div>
          </div>
          <a ref="downloadLink" :href="downloadUrl" :download="downloadFileName" v-show="false"></a>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="showModel.id && showModel.status=='1' && canInput"
          title="删除" type="danger" @click="del(showModel.id)"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button v-if="showModel.status == '2' && canArch" icon="fa fa-check"
          style="margin-left: 5px;" @click="verifyCheck(showModel.id)" type="primary">
            审核通过</el-button>
        <el-button v-if="showModel.status == '2' && canArch" icon="fa fa-reply"
          style="margin-left: 5px;" :disabled="!showModel.verify_response" @click="reject(showModel.id)" type="warning">
            驳 回</el-button>
        <el-button @click="showDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" width="80%" :title="editModel.id ? '收款记录编辑' : '新增收款记录'"
      :close-on-click-modal="false" :close="closeEditDialog">
      <el-form :model="editModel" ref="editForm" label-width="110px" :rules="rules">
        <el-form-item label="原始单据编号" prop="org_no">
          <el-input v-model="editModel.org_no" placeholder="如果没有新增时自动生成"></el-input>
        </el-form-item>
        <el-form-item label="企业" prop="company_id" v-if="!isInputRole">
          <el-select v-model="editModel.company_id" :disabled="editModel.id ? true : false">
            <el-option v-for="item in companies" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期" prop="occ_at">
          <el-date-picker v-model="editModel.occ_at" :readonly="editModel.org_no|isAuto"
            style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="支付方" prop="from_type">
          <el-select v-model="editModel.from_type" @change="selectFormType">
            <el-option value="1" label="国泰国华相关企业"></el-option>
            <el-option value="2" label="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付方名称" prop="from">
          <el-input v-model="editModel.from"></el-input>
        </el-form-item>
        <el-form-item label="支付类型" prop="type">
          <el-select v-model="editModel.type" clearable placeholder="请选择" style="width: 100%;">
            <el-option v-for="(text, value) in types" :key="value" :value="value" :label="text"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额(USD)" prop="amount">
          <el-input type="number" v-model="editModel.amount"></el-input>
        </el-form-item>
        <el-form-item label="事项说明" prop="desc">
          <el-input type="textarea" v-model="editModel.desc"></el-input>
        </el-form-item>
        <el-form-item label="其他说明" prop="remark">
          <el-input type="textarea" v-model="editModel.remark"></el-input>
        </el-form-item>
        <el-form-item v-if="editModel.status == '4'" label="驳回信息">
          {{editModel.verify_response}}
        </el-form-item>
        <el-form-item label="明细">
          <table class="bordered table" style="width: 100%;">
            <thead>
              <tr>
                <th>运单号</th>
                <th>款号名称</th>
                <th>数量</th>
                <th>总金额</th>
                <th>支付金额</th>
                <th width="50">操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in editModel.items" :key="index" :title="item.remark">
                <!-- <td><el-input v-model="item.ydh"></el-input></td>
                <td><el-input v-model="item.name"></el-input></td>
                <td><el-input type="number" v-model="item.num"></el-input></td>
                <td><el-input type="number" v-model="item.amount"></el-input></td>
                <td><el-button @click="delItem(index)" type="danger" title="删除" icon="el-icon-delete"></el-button></td> -->
                <td>{{item.waybill_no}}</td>
                <td>{{item.production}}</td>
                <td>{{item.num}}</td>
                <td>{{item.amount}}</td>
                <td width="200">
                  <el-input @change="calcSum(item)" style="width: 100%;" v-model="item.pay" type="number"></el-input></td>
                <td><el-button @click="delItem(index)" type="danger" title="删除" icon="el-icon-delete"></el-button></td>
              </tr>
              <tr>
                <td class="text-right" colspan="5">{{itemAmountSum}}</td>
                <td>合计</td>
              </tr>
              <tr>
                <td colspan="6">
                  <!-- <el-button class="pull-right" type="primary" @click="calcTotal">合计金额</el-button> -->
                  <!-- <el-button type="primary" @click="addItem">新增项目</el-button> -->
                  <!-- <el-select v-model="riskSource" remote filterable allow-create
                    :remote-method="queryRiskSource" @change="chooseRiskSource" value-key="id">
                      <el-option v-for="(item, idx) in riskSourceOptions" :key="idx"
                        :value="item" :label="item.riskSourceName"></el-option>
                  </el-select> -->
                  <el-select style="width: 80%" v-model="invoiceSel" value-key="id" remote filterable
                    :remote-method="queryInvoice" @change="changeInvoice">
                    <el-option v-for="item in invoices" :key="item.id" :value="item" :label="itemLabel(item)"></el-option>
                  </el-select>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
        <el-form-item label="附件">
          <div>
              <div v-if="editModel.attachments && editModel.attachments.length !== 0">
                <div class="text-semibold">已上传的附件</div>
                <div class="file-list" v-for="(file, index) in editModel.attachments" :key="index">
                  <span class="pull-right cur-pointer del-btn" @click="delFileOne(index)" style="width: 25px;" title="删除附件">
                  <i class="fa fa-times" aria-hidden="true"></i></span>
                  <span class="text-overflow" :title="file.name" style="margin-right: 25px;">
                    {{ file.orgName }}</span>
                </div>
              </div>
              <div v-if="files.length !== 0">
                <div class="text-semibold">新添的附件</div>
                <div class="file-list" v-for="(item, index) in files" :key="index">
                  <span class="pull-right cur-pointer del-btn" @click="delUfile(index)" style="width: 25px;" title="删除附件">
                    <i class="fa fa-times" aria-hidden="true"></i></span>
                  <span class="text-overflow" :title="item.name" style="margin-right: 25px;">{{ item.name }}</span>
                </div>
              </div>
            <!-- <div v-for="(item, index) in files" :key="index">
              <span>{{item.name}}</span>
              <span style="cursor: pointer;" @click="delufile(index)"><i class="fa fa-fw fa-trash text-danger"></i></span>
            </div> -->
          </div>
          <file-uploader ref="addFile"
            accept="."
            @change="changeFile"
            :showPreview="false"
            :showFileHint="false">
          </file-uploader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="showModel.id && showModel.status=='1' && canInput"
          title="删除" type="danger" @click="del(editModel.id)"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="beforeSave">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>
    <!-- <img style="max-height: 300px; max-width: 500px;" :src="imgSrc" alt=""> -->
  </el-card>
</template>

<script>
import * as _findIndex from 'lodash/findIndex';
// import * as _find from 'lodash/find';
import { mapState } from 'vuex';
import FileUploader from '@/components/FileUpload';

export default {
  components: {
    FileUploader,
  },
  filters: {
    isAuto(value) {
      let result = false;
      if (value && /^(GH|W)\d{13}$/.test(value)) {
        result = true;
      }
      return result;
    },
  },
  data() {
    return {
      // imgSrc: '',
      loading: true,
      waiting: false,
      statusesMap: {
        1: '待提交',
        2: '待审核',
        3: '已审核',
        4: '已驳回',
      },
      types: {
        cash: '现金',
        cheque: '支票',
        transfer: '转账',
        unpaid: '未支付',
      },
      queryForm: {
        org_no: '',
        q: '',
        type: '',
      },
      queryParams: {},
      list: [],
      showModel: {},
      showDialog: false,
      editDialog: false,
      editModel: { items: [] },
      itemAmountSum: 0,
      rules: {
        occ_at: [{ required: true, message: '请选择日期', trigger: ['change', 'blur'] }],
        type: [{ required: true, message: '请选择类型', trigger: ['change', 'blur'] }],
        amount: [{ required: true, message: '请输入金额', trigger: ['change', 'blur'] }],
        desc: [{ required: true, message: '请输入事项说明', trigger: ['change', 'blur'] }],
        from_type: [{ required: true, message: '请选择支付方', trigger: ['change', 'blur'] }],
      },
      files: [], // 待上传的文件
      downloadFileName: '',
      downloadUrl: '',
      // 所有缅甸企业
      companies: [],
      // page
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      // 选择用的
      invoiceSel: '',
      invoices: [],
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    canArch() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('mmr_verify') !== -1) {
        result = true;
      }
      return result;
    },
    canInput() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('mmr_revenue_input') !== -1) {
        result = true;
      }
      return result;
    },
    isInputRole() {
      let result = false;
      if (this.user.id) {
        const companyId = this.user.meta ? this.user.meta.mmr_company_id : false;
        if (_findIndex(this.user.roles, { code: 'mmr_input' }) !== -1 && companyId) {
          result = true;
        }
      }
      return result;
    },
  },
  created() {
    this.init();
    this.getList();
  },
  methods: {
    init() {
      // 获取公司
      this.$http.get('mmrcompany', { params: { with_list: 1 } }).then((resp) => {
        this.companies = resp.data;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    getList() {
      this.loading = true;
      const params = { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize };
      this.$http.get('revenue', { params }).then((resp) => {
        this.list = resp.data.data;
        this.loading = false;
        this.totalItem = resp.data.total;
      }, (err) => {
        this.loading = false;
        this.$utils.resp(err);
      });
    },
    closeEditDialog() {
      // this.$refs.editForm.clearValidate();
    },
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toAdd() {
      this.files = [];
      const model = {
        occ_at: this.$utils.FormatDate(),
        org_no: '',
        desc: '',
        remark: '',
        amount: '',
        type: '',
        from_type: '1',
        from: '国泰国华',
        company_id: '',
        items: [],
      };
      if (this.isInputRole) {
        model.company_id = this.user.meta.mmr_company_id;
      }
      this.editModel = model;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
      this.editDialog = true;
      this.$nextTick(() => {
        this.queryInvoice('');
      });
    },
    toEdit(item) {
      this.files = [];
      this.axios.get(`revenue/${item.id}`).then((resp) => {
        const result = resp.data;
        result.items = [];
        result.invoices.forEach((item) => {
          item.get -= item.pivot ? item.pivot.pay : 0;
          item.pay = item.pivot ? item.pivot.pay : 0;
          result.items.push(item);
        });
        this.editModel = result;
        this.$nextTick(() => {
          if (this.$refs.editForm) {
            this.$refs.editForm.clearValidate();
          }
          this.calcSum();
        });
        this.editDialog = true;
        this.$nextTick(() => {
          this.queryInvoice('');
        });
      });
    },
    getImg(fileName) {
      const params = {
        hint: 'mmrrevenue',
        name: fileName,
      };
      return this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        return objurl;
      }, (err) => {
        this.$utils.resp(err);
        // todo
        return '404png';
      });
    },
    downloadImg(item, name = false) {
      const tmp = item.orgName.split('.');
      const ext = tmp[tmp.length - 1];
      this.downloadFileName = name ? name + '.' + ext : item.orgName;
      this.downloadUrl = item.url;
      this.$nextTick(() => {
        this.$refs.downloadLink.click();
      });
    },
    // 详情
    showDetail(id) {
      this.$http.get(`revenue/${id}`).then((resp) => {
        const result = resp.data;
        result.items = [];
        result.invoices.forEach((item) => {
          item.pay = item.pivot ? item.pivot.pay : '0';
          result.items.push(item);
        });
        this.showModel = result;
        this.showModel.attachments.forEach((item) => {
          this.getImg(item.name).then((url) => {
            this.$set(item, 'url', url);
          });
        });
        this.showDialog = true;
      });
    },
    closeDetailDialog() {
      // 清图片缓存;
      this.showModel.attachments.forEach((item) => {
        URL.revokeObjectURL(item.url);
      });
    },
    // 提交审核
    toVerify(revenue) {
      this.$confirm('确认提交审核？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = { status: '2' };
        this.$http.put(`revenue/${revenue.id}/status`, params).then(() => {
          this.$message.success('提交成功');
          this.getList();
        }, (err) => { this.$utils.resp(err); });
      }, () => {});
    },
    // 撤回提交审核
    backVerify(revenue) {
      const params = { status: '1' };
      this.$http.put(`revenue/${revenue.id}/status`, params).then(() => {
        this.$message.success('已撤回');
        this.getList();
      }, (err) => { this.$utils.resp(err); });
    },
    // 审批通过
    verifyCheck(id, isOk = true) {
      let message = '驳回';
      const params = {
        status: '4',
        cost_type: this.showModel.cost_type,
        verify_response: this.showModel.verify_response,
      };
      if (isOk) {
        message = '审核通过';
        params.status = '3';
      }
      this.$confirm(`确认${message}？`, '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.put(`revenue/${id}/status`, params).then(() => {
          this.$message.success('操作成功');
          this.showDialog = false;
          this.getList();
        }, (err) => { this.$utils.resp(err); });
      }, () => {});
    },
    reject(id) {
      this.verifyCheck(id, false);
    },
    // archiveIt() {
    //   this.$confirm('确认信息并归档？注意归档后不可修改', '确认信息', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning',
    //   }).then(() => {
    //     const obj = { cost_type: this.checkType };
    //     this.$http.put(`arch-revenue/${this.showModel.id}`, obj).then(() => {
    //       this.showDialog = false;
    //       this.getList();
    //       this.$utils.msg(`记录"${this.showModel.org_no}"已审核`, 'success', 5000);
    //     });
    //   }, () => {});
    // },
    del(id) {
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.axios.delete(`revenue/${id}`).then(() => {
          this.showDialog = false;
          this.editDialog = false;
          this.getList();
        }, (err) => {
          this.$utils.resp(err);
        });
      }, () => {});
    },
    beforeSave() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          if (this.editModel.amount * 1 !== 1 * this.itemAmountSum) {
            this.$message.warning('总金额不匹配');
            return;
          }
          if (this.$refs.addFile.identifyBorwser() && this.files.length > 0) {
            const formData = new window.FormData(this.$refs.editForm.$el);
            formData.append('hint', 'mmrrevenue');
            this.files.forEach((ele) => {
              formData.append('file[]', ele);
            });
            this.waiting = true;
            this.$http.post('transfile', formData).then((resp) => {
              this.editModel.addFiles = resp.data;
              this.saveSubmit();
            }, (err) => {
              this.editModel.addFiles = [];
              this.waiting = false;
              this.$utils.resp(err);
            });
          } else {
            this.saveSubmit();
          }
        }
      });
    },
    saveSubmit() {
      // 更新
      if (this.editModel.id) {
        this.axios.put(`revenue/${this.editModel.id}`, this.editModel).then((resp) => {
          const index = _findIndex(this.list, { id: this.editModel.id });
          this.list.splice(index, 1, resp.data);
          this.editDialog = false;
          this.waiting = false;
          this.$utils.msg('已更新');
        }, (err) => {
          this.$utils.resp(err);
          this.waiting = false;
        });
      } else {
      // 新增
        this.axios.post('revenue', this.editModel).then(() => {
          this.editDialog = false;
          this.getList();
          this.waiting = false;
          this.$utils.msg('保存成功');
        }, (err) => {
          this.$utils.resp(err);
          this.waiting = false;
        });
      }
    },
    // 选中上传文件时
    changeFile(files) {
      if (this.$refs.addFile.sizeWarning) {
        this.$utils.msg('文件过大', 'warning');
        return;
      }
      if (files.length > 0) {
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
      }
    },
    // 删除原图片
    delFileOne(index) {
      this.editModel.attachments.splice(index, 1);
    },
    // 删除未上传的文件
    delUfile(index) {
      this.files.splice(index, 1);
    },
    queryInvoice(q) {
      this.$http.get('invoice-options', { params: { q } }).then((resp) => {
        this.invoices = resp.data.data;
      });
    },
    changeInvoice(value) {
      if (_findIndex(this.editModel.items, { id: value.id }) !== -1) {
        this.invoiceSel = null;
        this.$message.warning('已经选过这条信息');
        return;
      }
      const obj = {
        ...value,
        pay: value.amount - value.get,
      };
      this.editModel.items.push(obj);
      this.calcSum();
      this.invoiceSel = null;
    },
    // 明细删除和添加
    delItem(index) {
      this.editModel.items.splice(index, 1);
    },
    // addItem() {
    //   this.editModel.items.push({
    //     waybill_no: '',
    //     name: '',
    //     num: '',
    //     amount: '',
    //   });
    // },
    // 选择支付方
    selectFormType(value) {
      if (value === '1' && !this.editModel.from) {
        this.editModel.from = '国泰国华';
      }
    },
    // calcTotal() {
    //   let sum = 0;
    //   this.editModel.items.forEach((item) => {
    //     if (item.amount) {
    //       sum += 1 * item.amount;
    //     }
    //   });
    //   this.editModel.amount = Math.round(sum * 100) / 100;
    // },
    // 合计
    calcSum(item = null) {
      if (item && item.amount < item.pay * 1 + item.get * 1) {
        item.pay = item.amount - item.get;
      }
      let sum = 0;
      this.editModel.items.forEach((item) => {
        if (item.pay) {
          sum += 1 * item.pay;
        }
      });
      this.itemAmountSum = sum;
    },
    // 选择开票信息时显示内容
    itemLabel(invoice) {
      return `【${invoice.waybill_no}】 ${invoice.title}
      (${invoice.production}， ${invoice.num}x${invoice.unit_price}，${invoice.get} / ${invoice.amount})`;
    },
  },
};
</script>
