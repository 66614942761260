<template>
  <el-row>
    <el-col :span="8">
      <el-card>
        <table class="table">
          <thead>
            <tr>
              <th>模块名</th>
              <th>编号</th>
              <th>id</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in setList" :key="index"
              @dblclick="getFlowCourse(item)">
              <td>{{item.name}}</td>
              <td>{{item.num}}</td>
              <td>{{item.id}}</td>
            </tr>
          </tbody>
        </table>
      </el-card>
    </el-col>
    <el-col :span="16">
      <el-card class="components">
        <div class="mar-btm-10 pull-right">
          <el-button :disabled="!selectedCourse" size="small" @click="addNewItem">
            <i class="fa fa-fw fa-plus"></i> 新增下级审批
          </el-button>
        </div>
        {{curFlowSet.name}}

        <table class="table" v-loading="loading">
          <thead>
            <tr>
              <th width="25">#</th>
              <th>名称</th>
              <th>状态</th>
              <th>id</th>
              <th width="1%">操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="item.id" @click="selectItem(item)"
              :class="{cur: item.selected}">
              <td>{{ index + 1 }}</td>
              <td>
                <span v-for="(val, idx) in item.indent" :key="idx">-&nbsp;</span>
                {{ item.name }}
              </td>
              <td></td>
              <td>{{item.id}}</td>
              <td>
                <div class="text-nowrap">
                  <el-button v-if="index > 0" size="small" title="修改信息" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="clearfix mar-top">
          <el-pagination class="pull-right"
            @current-change="changePage"
            layout="total, prev, pager, next"
            :total="totalItem"
            :current-page="currentPage"
            :page-size="pageSize">
          </el-pagination>
        </div>

        <!-- 编辑dialog -->
        <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'" :close-on-click-modal="false">
          <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
            <el-form-item label="名称" prop="name">
              <el-input v-model="editModel.name"></el-input>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="类型" prop="check_type">
                  <el-select v-model="editModel.check_type" @change="checkTypeChange">
                    <el-option v-for="(label, key) in checkTypes" :key="key" :value="key" :label="label"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item v-if="editModel.check_type==='user'" label="指定人员" prop="check_id">
                  <dept-user v-model="editModel.check_id" @selected="selectUser"></dept-user>
                </el-form-item>
                <el-form-item v-if="editModel.check_type==='field'" label="主表字段" prop="check_id">
                  <el-input v-model="editModel.check_id"></el-input>
                </el-form-item>
                <el-form-item v-if="editModel.check_type==='method'" label="自定义方法" prop="check_id">
                  <el-input v-model="editModel.check_id"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="需审核人数" prop="check_num">
              <el-input v-model="editModel.check_num" type="number" style="width: 200px"></el-input>
              0表示要全部审核人都同意
            </el-form-item>
            <el-form-item label="操作" prop="action">
              <el-input v-model="editModel.action" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="editModel.remark"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="editDialog = false">
              <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
            <el-button type="primary" @click="save"
              icon="fa fa-fw fa-floppy-o" :disabled="waiting"> 确 定</el-button>
            <el-button v-if="editModel.id" type="danger" @click="delIt(editModel.id)"
              icon="fa fa-fw fa-trash" class="pull-left" disabled> 删 除</el-button>

          </div>
        </el-dialog>

      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { mapState } from 'vuex';
import DeptUser from '@/components/DeptUser';

function setChildren(item, all) {
  const list = all.filter(a => a.pid === item.id);
  list.forEach((i) => {
    setChildren(i, all);
  });
  item.children = list.length > 0 ? list : null;
  return list;
}

function platChildren(obj) {
  const list = [];
  if (obj.children) {
    obj.children.forEach((item) => {
      item.indent = [];
      list.push(item);
      const subs = platChildren(item);
      subs.forEach((subItem) => {
        list.push(subItem);
      });
    });
  }
  list.forEach(item => item.indent.push('1'));
  return list;
}

// 请在赋值给this前调用，除非你不需要indent和selected属性
function treeDeepPlat(tree) {
  const list = [];
  tree.forEach((item) => {
    item.indent = [];
    list.push(item);
    const arr = platChildren(item);
    arr.forEach((item) => { list.push(item); });
  });
  list.forEach((item) => { item.selected = false; });
  return list;
}

export default {
  components: {
    DeptUser,
  },
  data() {
    return {
      waiting: false,
      loading: false,
      setList: [],
      // 查询条件form
      queryForm: {
        q: '',
      },
      curFlowSet: {},
      tree: [],
      list: [], // 管理的列表对象
      checkTypes: {
        dept: '部门负责人',
        user: '指定人员',
        role: '角色',
        // group: '审核人组',
        field: '主表字段',
        method: '自定义方法',
      },
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
        check_type: [{ required: true, message: '请选择审核类型', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    coursesMap() {
      const res = {};
      this.courses.forEach((item) => {
        res[item.id] = item;
      });
      return res;
    },
    selectedCourse() {
      let result = null;
      const arr = this.list.filter(item => item.selected);
      if (arr.length === 1) {
        result = arr[0];
      }
      return result;
    },
  },
  created() {
    this.getFlowSets();
    // this.onSubmitQuery();
  },
  methods: {
    getFlowSets() {
      this.axios.get('flowset', { params: { all: 1 } })
        .then((response) => {
          const list = response.data;
          this.setList = list;
        }, (response) => {
          this.$utils.resp(response);
        });
    },
    // 查询
    getFlowCourse(setObj) {
      this.curFlowSet = { ...setObj };
      this.getList();
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$http.get(`flowset/${this.curFlowSet.id}/course`).then((resp) => {
        const courses = resp.data;
        resp.data.forEach((item) => {
          item.children = null;
          item.indent = [];
        });
        const root = [{
          id: 0,
          pid: null,
          name: '提交',
        }];
        root[0].children = courses.filter(item => item.pid === 0);
        root.forEach((item) => {
          setChildren(item, courses);
        });
        console.log(root);
        const list = treeDeepPlat(root, courses);
        console.log(list);
        // this.tree = root;
        // this.courses = resp.data;
        this.list = list;
        this.loading = false;
      }, (err) => {
        this.loading = false;
        this.$utils.resp(err);
      });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.editModel = {
        name: '',
        code: '',
        remark: '',
      };
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 打开编辑
    toEdit(editObj) {
      this.editModel = { ...editObj };
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    selectUser(list) {
      this.editModel.check_name = list.map(item => item.name).join(',');
    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.editModel };
          this.waiting = true;
          let method = 'post';
          let url = 'flowcourse';
          if (obj.id) {
            method = 'put';
            url = `flowcourse/${obj.id}`;
          }
          this.axios({
            method,
            url,
            data: obj,
          }).then(() => {
            this.editDialog = false;
            this.waiting = false;
            this.getList();
            this.$message({
              type: 'success',
              message: '保存成功',
              duration: 2000,
            });
          }, (err) => {
            this.waiting = false;
            this.$utils.resp(err);
          });
        }
      });
    },
    selectItem(obj) {
      this.list.forEach((item) => {
        item.selected = false;
      });
      obj.selected = true;
    },
    addNewItem() {
      this.editModel = {
        set_id: this.curFlowSet.id,
        pid: this.selectedCourse.id,
        name: '',
        code: '',
        check_type: null,
        check_id: '',
        check_name: '',
        remark: '',
        action: '通过|success,驳回|warning',
        check_num: 1,
        max_min: 0, // 自动审批时间分钟
        over_action: '1', // 自动审批动作
      };
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    checkTypeChange(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="scss" scoped>
  .table tbody tr.cur td {
    background: #b3d3ee;
  }
</style>
