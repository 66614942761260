<template>
  <el-card class="components">
    <el-button type="primary" v-if="isDeliveryManager" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 采购入库</el-button>
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="输入关键项目号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <!-- <div class="box-inline standard-search">
        <el-input v-model="queryForm.receipt_num" clearable placeholder="入库单号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div> -->
      <!-- <div class="box-inline standard-search" v-if="!isParty">
        <el-select style="width: 100%" v-model="queryForm.company_id" v-if="!isParty" placeholder="选择加工单位" :loading="queryPartiesLoading"
          clearable filterable remote :remote-method="queryParties">
          <el-option v-for="item in parties" :key="item.party_id"
            :value="item.party_id" :label="item.party_name"></el-option>
        </el-select>
      </div> -->
      <div class="box-inline standard-search" v-if="!isParty">
        <el-select style="width: 100%" v-model="queryForm.status" placeholder="状态选择" clearable>
          <el-option v-for="(val, key) in stockStatusMap" :key="key"
            :value="key" :label="val"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>部门</th>
          <th>编号</th>
          <!-- <th>入库单号</th> -->
          <th>入库日期</th>
          <th>申请人</th>
          <th>状态</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.department }}</td>
          <td><a href="#" @click="toDetail(item)">{{ item.code }}</a></td>
          <!-- <td>{{ item.receipt_num }}</td> -->
          <td>{{ item.transaction_date }}</td>
          <td>{{ item.creator }}</td>
          <td>{{ item.status_text }}</td>
          <td>
            <el-button type="info" icon="el-icon-edit" :disabled="waiting" @click="toEdit(item.id)" v-if="item.status==='PROCESSING'"></el-button>
            <el-button type="info" :disabled="waiting" @click="apply(item)" v-if="item.status==='ENTERED'">提交</el-button>
            <el-button type="info" :disabled="waiting" @click="approve(item)"
              v-if="item.status==='PROCESSING' && !item.dv_order_id">审核通过</el-button>
            <el-button type="info" :disabled="waiting" @click="toDvOrder(item)"
              v-if="item.status==='PROCESSING' && item.dv_order_id">完结审核</el-button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'"
      :close-on-click-modal="false" width="90%" top="2%">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属项目" prop="project_id">
              <el-input v-if="editModel.id" :value="editModel.project_number" readonly></el-input>
              <el-select v-else v-model="editModel.project_id" style="width:100%;" filterable
                remote :remote-method="getProjects" @change="chooseProject">
                <el-option label="" value=""></el-option>
                <el-option v-for="item in projects" :key="item.id"
                  :value="item.project_id" :label="item.project_number">{{`【${item.project_number}】 ${item.description ? item.description : ''}`}}</el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="editModel.project_id">
            <el-form-item v-if="editModel.id" label="编号">
              <el-input :value="editModel.code" readonly></el-input>
            </el-form-item>
            <el-form-item v-else label="采购合同" prop="po_header_id">
              <el-select v-model="editModel.po_header_id" style="width:100%;" clearable
                filterable @change="choosePo">
                <el-option label="" value=""></el-option>
                <el-option v-for="item in pos" :key="item.po_header_id"
                  :value="item.po_header_id" :label="`【${item.po_number}】 ${item.business_type_name ? item.business_type_name : ''}`"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="业务部门">
              <el-input v-if="editModel.id" readonly :value="editModel.department"></el-input>
              <el-select v-else :value="userDeptId" style="width: 100%">
                <el-option v-for="item in deptMap" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="!editModel.id">
            <el-form-item label="仓库组织" prop="organization_id">
              <el-select v-model="editModel.organization_id" style="width: 100%;" @change="getInventories">
                <el-option value="" label=""></el-option>
                <el-option v-for="(item) in organizations" :key="item.organization_id"
                  :value="item.organization_id" :label="item.organization_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货单位" prop="from_id">
              <el-input v-if="editModel.id" :value="editModel.from" readonly></el-input>
              <el-select v-else style="width: 100%" v-model="editModel.from_id" filterable
                @change="chooseFrom" clearable remote :remote-method="queryFromCompany">
                <el-option v-for="item in fromCompanies" :key="item.party_id"
                  :value="item.party_id" :label="item.party_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="收货单位1" prop="company_id">
              <el-input v-if="editModel.id" :value="editModel.company_name" readonly></el-input>
              <el-select v-else style="width: 100%" v-model="editModel.company_id" placeholder="收货单位" :loading="queryPartiesLoading"
                clearable filterable remote :remote-method="queryParties2" @change="getInventories">
                <el-option v-for="item in parties2" :key="item.party_id"
                  :value="item.party_id" :label="item.party_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库" prop="secondary_inventory_name">
              <el-input readonly v-if="editModel.id" :value="editModel.stocks ? editModel.stocks[0].subinventory_desc : ''"></el-input>
              <el-select v-else v-model="editModel.secondary_inventory_name" style="width: 100%;">
                <el-option v-for="(item, idx) in inventories" :key="idx"
                  :value="item.secondary_inventory_name" :label="item.description"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="接收人" prop="receipt_by">
              <el-input v-model="editModel.receipt_by"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="送货单号" prop="shipment_num">
              <el-input v-model="editModel.shipment_num"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="申请日期" prop="receipt_date">
              <el-date-picker v-model="editModel.receipt_date"
                style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="入库日期" prop="transaction_date">
              <el-date-picker v-model="editModel.transaction_date"
                style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注说明" prop="remark">
          <el-input type="textarea" v-model="editModel.remark"></el-input>
        </el-form-item>
        <el-row v-if="!editModel.id">
          <el-col :span="12">
            <el-form-item label="创建发货" prop="needDelivery">
              <el-switch
                v-model="editModel.needDelivery"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
          </el-col>
        </el-row>

        <div v-show="editModel.needDelivery">
          <el-row>
            <el-col :span="12">
              <el-form-item label="发货方" prop="from_id">
                <el-select style="width: 100%" v-model="editModel.from_id">
                  <el-option v-for="item in fromCompanies" :key="item.party_id"
                    :value="item.party_id" :label="item.party_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收货方">
                <el-select style="width: 100%" :value="editModel.company_id" placeholder="收货单位"
                  @change="getInventories">
                  <el-option v-for="item in parties2" :key="item.party_id"
                    :value="item.party_id" :label="item.party_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发货联系人" prop="from_contact">
                <el-select v-model="editModel.from_contact" filterable allow-create clearable @change="changeFromContact">
                  <el-option v-for="item in fromContacts" :key="item.id" :label="item.name" :value="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收货联系人" prop="to_contact">
                <el-select v-model="editModel.to_contact" filterable allow-create clearable @change="changeToContact">
                  <el-option v-for="item in toContacts" :key="item.id" :label="item.name" :value="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="发货人电话" prop="from_tel">
                <el-input v-model="editModel.from_tel"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收货人电话" prop="to_tel">
                <el-input v-model="editModel.to_tel"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="发货地址" prop="from_addr">
                <el-input v-model="editModel.from_addr"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收获地址" prop="to_addr">
                <el-input v-model="editModel.to_addr"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-form-item>
          <template slot="label">
            明细
            <el-tooltip content="数量前面的参数相同会归为相同产品" placement="top" effect="light">
              <i class="el-icon-warning text-warning"></i>
            </el-tooltip>
          </template>
          <div style="width">
            <table class="items bordered table" style="width: 100%;">
              <thead>
                <tr>
                  <!-- <th class="text-center">货号</th> -->
                  <th class="text-center">品名</th>
                  <th class="text-center">款号</th>
                  <th class="text-center">规格</th>
                  <th class="text-center">英文款号</th>
                  <th class="text-center">英文规格</th>
                  <th class="text-center">颜色/其他</th>
                  <th class="text-center" width="110">数量</th>
                  <th class="text-center" width="70">单位</th>
                  <!-- <th class="text-center">单价</th> -->
                  <!-- <th class="text-center">金额</th> -->
                  <th width="100">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in editModel.items" :key="index" :title="item.remark">
                  <!-- <td><el-input v-model="item.sn"></el-input></td> -->
                  <td><el-input :readonly="item.item_id" v-model="item.item_name"></el-input></td>
                  <td><el-input :readonly="item.item_id" v-model="item.attr1"></el-input></td>
                  <td><el-input :readonly="item.item_id" v-model="item.attr2"></el-input></td>
                  <td><el-input :readonly="item.item_id" v-model="item.attr6"></el-input></td>
                  <td><el-input :readonly="item.item_id" v-model="item.attr7"></el-input></td>
                  <td><el-input v-model="item.color"></el-input></td>
                  <td :title="item.totalNum ? `${item.stockNum}/${item.totalNum}` : ''">
                    <el-input :class="{'text-right': true, 'warn-input': calcQuantity(item)}" v-model="item.quantity" type="number"></el-input>
                  </td>
                  <td>
                    <el-select style="width: 100px;" v-model="item.uom" filterable :disabled="Boolean(item.item_id)">
                      <el-option v-for="(value, key, index) in uoms" :value="key" :key="index" :label="value"></el-option>
                    </el-select>
                  </td>
                  <!-- <td><el-input v-model="item.unit_price" @change="calcItem(item)" type="number"></el-input></td>
                  <td><el-input v-model="item.sum" type="number"></el-input></td> -->
                  <td>
                    <el-button @click="delItem(index)" :disabled="Boolean(editModel.id)"
                      type="danger" title="删除" icon="el-icon-delete"></el-button>
                  </td>
                </tr>
                <tr v-if="!editModel.id">
                  <td colspan="9" class="text-right">
                    <el-button @click="addItem" type="primary" icon="el-icon-plus">添加</el-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteRecord(editModel.id)" class="pull-left"
          v-if="editModel.id && ['ENTERED', 'PROCESSING'].indexOf(editModel.status) !== -1" type="danger" icon="el-icon-delete">删 除</el-button>
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="save(false)" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 保 存</el-button>
      </div>
    </el-dialog>

    <!-- detail -->
    <el-dialog :visible.sync="detailDialog" title="详情"
      :close-on-click-modal="false" width="90%" top="2%">
      <el-form>
        <el-row>
          <el-col :span="12">
            <el-form-item label="记录编号："> {{showModel.code}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态：">{{showModel.status_text}}</el-form-item>
            <!-- <el-form-item label="入库单号：">{{showModel.receipt_num}}</el-form-item> -->
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="部门："> {{showModel.department}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="创建人：">{{showModel.creator}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="申请人："> {{showModel.requested_by}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请日期：">{{showModel.receipt_date}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="审核人："> {{showModel.approved_by}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核日期：">{{showModel.approved_date}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="仓库："> {{showModel.stocks ? showModel.stocks[0].company_name: ''}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="入库日期："> {{showModel.transaction_date}}</el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="12">
            <el-form-item label="状态：">{{showModel.status_text}}</el-form-item>
          </el-col>
        </el-row> -->
        <el-form-item label="入库商品：">
          <table class="items bordered table" style="width: 100%;">
            <thead>
              <tr>
                <th class="text-center">库存编号</th>
                <th class="text-center">品名</th>
                <th class="text-center">款号</th>
                <th class="text-center">规格</th>
                <th class="text-center">英文款号</th>
                <th class="text-center">英文规格</th>
                <th class="text-center">颜色/其他</th>
                <th class="text-center" width="110">数量</th>
                <th class="text-center" width="70">单位</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in showModel.stocks" :key="index" :title="item.remark">
                <td> {{item.code}} </td>
                <td> {{item.item_name}} </td>
                <td> {{item.attr1}} </td>
                <td> {{item.attr2}} </td>
                <td> {{item.attr6}} </td>
                <td> {{item.attr7}} </td>
                <td> {{item.color}} </td>
                <td class="text-right"> {{item.quantity}} </td>
                <td> {{item.uom}} </td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteRecord(showModel.id)" class="pull-left"
          v-if="['ENTERED', 'PROCESSING'].indexOf(showModel.status) !== -1" type="danger" icon="el-icon-delete">删除</el-button>
        <el-button @click="detailDialog = false" icon="fa fa-fw fa-times"> 关 闭 </el-button>
        <el-button :disabled="waiting" type="info" @click="apply(showModel)" v-if="showModel.status==='ENTERED'">提交</el-button>
        <el-button :disabled="waiting" type="info" @click="approve(showModel)"
          v-if="showModel.status==='PROCESSING' && !showModel.dv_order_id">审核通过</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'stockin',
  data() {
    return {
      waiting: false,
      loading: false,
      selectFromLoading: false,
      fromCompanies: [],
      fromContacts: [],
      toContacts: [],
      // 查询条件form
      queryForm: {
        party_id: '',
        company_id: '',
        q: '',
        code: '',
        receipt_num: '',
      },
      queryParams: {}, // 分页查询参数
      parties: [], // select party options array
      parties2: [], // adit时候的选项
      queryPartiesLoading: false, // query-remote parties loading flag
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      projects: [], // 新增下拉选择的options
      pos: [],
      inventories: [], // 仓库编码
      showModel: {},
      detailDialog: false,
      rules: {
        project_id: [{ required: true, message: '选择项目', trigger: ['change', 'blur'] }],
        company_id: [{ required: true, message: '选择公司', trigger: ['change', 'blur'] }],
        // secondary_inventory_name: [{ required: true, message: '请选择仓库', trigger: ['change', 'blur'] }],
      },
      // 修改页面
      modifyDialog: false,
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  beforeCreate() {
    if (this.$store.state.common.depts.length === 0) {
      this.$store.dispatch('GET_DEPTS');
    }
    if (this.$store.state.common.organizations.length === 0) {
      this.$store.dispatch('GET_ORGANIZATION');
    }
  },
  created() {
    this.onSubmitQuery();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      curCompanyType: state => state.auth.curCompanyType,
      userMeta: state => state.auth.userMeta,
      organizations: state => state.common.organizations,
      stockStatusMap: state => state.common.stockStatusMap,
      uoms: state => state.common.uoms,
    }),
    ...mapGetters([
      'deptMap',
      'userDeptId',
    ]),
    // 工厂库
    isParty() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('dv_party') !== -1) {
        result = true;
      }
      return result;
    },
    // 公司部门用户
    isDeliveryManager() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('delivery_order_manager') !== -1) {
        result = true;
      }
      return result;
    },
    isAdmin() {
      return this.user.type === 'admin';
    },
  },
  watch: {
    user() {
      this.onSubmitQuery();
    },
    curCompanyType() {
      this.onSubmitQuery();
    },
  },
  methods: {
    // 查询企业
    queryParties(q) {
      const params = {
        limit: 20,
        q: {
          status_code: 'APPROVED',
        },
      };
      if (typeof q === 'string' && q.trim() !== '') {
        const name = q.trim();
        params.q.party_name = { $like: `%${name}%` };
      } else {
        return;
      }
      this.queryPartiesLoading = true;
      this.$http.get('erp-parties/', { params }).then((resp) => {
        const list = resp.data.items;
        this.queryPartiesLoading = false;
        this.parties = list;
      }, (err) => {
        this.queryPartiesLoading = false;
        this.$utils.resp(err);
      });
    },
    queryParties2(q) {
      const params = {
        limit: 20,
        q: {
          status_code: 'APPROVED',
        },
      };
      if (typeof q === 'string' && q.trim() !== '') {
        const name = q.trim();
        params.q.party_name = { $like: `%${name}%` };
      } else {
        return;
      }
      this.queryPartiesLoading = true;
      this.$http.get('erp-parties/', { params }).then((resp) => {
        const list = resp.data.items;
        this.queryPartiesLoading = false;
        this.parties2 = list;
      }, (err) => {
        this.queryPartiesLoading = false;
        this.$utils.resp(err);
      });
    },
    // 查询仓库
    getInventories(id) {
      if (!this.editModel.company_id) {
        return;
      }
      if (id === this.editModel.company_id) {
        this.chooseTo(id);
      }
      const params = {
        party_id: this.editModel.company_id,
        organization_id: this.editModel.organization_id,
      };
      this.inventories = [{
        secondary_inventory_name: 'GH',
        description: '国华仓库',
      }];
      this.$http.get('inventory', { params }).then((resp) => {
        const list = resp.data;
        list.push({
          secondary_inventory_name: 'GH',
          description: '国华仓库',
        });
        this.inventories = list;
        if (this.inventories.length > 0) {
          this.editModel.secondary_inventory_name = this.inventories[0].secondary_inventory_name;
        } else {
          this.editModel.secondary_inventory_name = '';
        }
      });
    },
    // 查询
    onSubmitQuery() {
      if (!this.user.id) {
        return;
      }
      const params = {};
      if (this.queryForm.dept_id) {
        params.dept_id = this.queryForm.dept_id;
      }
      if (this.queryForm.status) {
        params.status = this.queryForm.status;
      }
      if (this.queryForm.code.trim()) {
        params.code = this.queryForm.code.trim();
      }
      if (this.queryForm.receipt_num.trim()) {
        params.receipt_num = this.queryForm.receipt_num.trim();
      }
      if (this.queryForm.q.trim()) {
        params.q = this.queryForm.q.trim();
      }
      this.queryParams = params;
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      const params = this.queryParams;
      params.pageSize = this.pageSize;
      params.page = this.currentPage;
      this.loading = true;
      this.$http.get('postock', { params }).then((resp) => {
        this.loading = false;
        const list = resp.data.data;
        this.list = list;
        this.totalItem = resp.data.total;
      }, (err) => {
        this.loading = false;
        this.$utils.resp(err);
      });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 新增入库
    toAdd() {
      if (this.projects.length === 0) {
        this.getProjects('');
      }
      this.editModel = {
        // 货运相关
        needDelivery: true,
        from_id: '',
        to_id: '',
        from_company: '',
        to_company: '',
        from_contact: '',
        to_contact: '',
        from_tel: '',
        to_tel: '',
        from_addr: '',
        to_addr: '',
        // not dv
        type: 'input',
        organization_id: '',
        secondary_inventory_name: '',
        project_id: '',
        receipt_by: '',
        shipment_num: '',
        receipt_date: this.$utils.FormatDate(),
        transaction_date: this.$utils.FormatDate(),
        po_header_id: '',
        po_number: '',
        department_id: this.userDeptId,
        company_id: '',
        company_name: '',
        remark: '',
        count: '',
        items: [],
        // left_quantity: '',
      };
      if (this.deptMap && this.userDeptId) {
        const depts = this.deptMap[this.userDeptId].name.split('.');
        if (depts.length > 1) {
          const name = depts[1].substr(0, 2);
          const org = this.organizations.filter(item => item.organization_name.indexOf(name) > -1);
          if (org.length > 0) {
            this.editModel.organization_id = org[0].organization_id;
          }
        }
      }
      this.editDialog = true;
      if (this.$refs.editForm) {
        this.$nextTick(() => {
          this.$refs.editForm.clearValidate();
        });
      }
    },
    toEdit(id) {
      this.$http.get(`postock/${id}`).then((resp) => {
        const stockin = resp.data;
        let lines = [];
        let poPromise = null;
        if (stockin.stocks.length > 0 && stockin.stocks[0].po_header_id) {
          const pohid = stockin.stocks[0].po_header_id;
          poPromise = this.$http.get(`po-num/${pohid}`).then((resp) => {
            lines = resp.data;
          });
        } else {
          poPromise = Promise.resolve();
        }
        poPromise.then(() => {
          const list = [];
          stockin.stocks.forEach((item) => {
            let leftNum = null;
            let poLine = {};
            if (lines.length > 0) {
              poLine = lines.find(a => a.po_line_id === item.po_line_id);
              // 加上自己的数量
              poLine.stockNum = this.$calc.Sub(poLine.stockNum, item.quantity * 1);
              leftNum = this.$calc.Sub(poLine.pricing_quantity, poLine.stockNum);
            }
            let org = null;
            if (item.item_id) {
              org = {
                po_line_id: item.po_line_id,
                po_header_id: item.po_header_id,
              };
            }
            const obj = {
              org,
              ...item,
              obj_id: item.po_line_id,
              quantity: item.quantity * 1,
              leftNum,
              stockNum: poLine.stockNum,
              totalNum: poLine.pricing_quantity,
            };
            // let arr = [];
            // if (item.attr1) {
            //   arr.push(item.attr1);
            // }
            // if (item.attr6) {
            //   arr.push(item.attr6);
            // }
            // obj.attr_1 = arr.join('/');
            // arr = [];
            // if (item.attr2) {
            //   arr.push(item.attr2);
            // }
            // if (item.attr7) {
            //   arr.push(item.attr7);
            // }
            // obj.attr_2 = arr.join('/');
            list.push(obj);
          });
          stockin.items = list;
          this.editModel = stockin;
          this.editDialog = true;
        });
      }, (err) => { this.$utils.resp(err); });
    },
    // 项目查询方法
    getProjects(qStr) {
      const params = {
        limit: 20,
        q: {
          // status_code: 'APPROVED',
          // project_number: { $like: `%${qStr}%` },
        },
      };
      if (typeof qStr === 'number') {
        params.q.project_id = qStr;
      } else if (qStr && qStr.trim() !== '') {
        params.q.project_number = { $like: `%${qStr}%` };
      } else {
        const dateStr = this.$utils.FormatDate(new Date(new Date().getTime() - 100 * 86400000), 'yyyy-MM-ddT00:00:00Z');
        params.q.creation_date = { $gt: { $date: dateStr } };
      }
      return this.$http.get('erp-project', { params }).then((resp) => {
        const list = resp.data.items;
        list.forEach((item) => {
          item.cur = false;
        });
        this.projects = list;
        return list;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // project choose
    chooseProject(id, isInit = false) {
      const projects = this.projects.filter(item => item.project_id === id);
      if (projects.length === 1) {
        const project = projects[0];
        const projectNum = project.project_number;
        // const params = {
        //   q: {
        //     // status_code: 'APPROVED',
        //     project_number: '20GH05300152',
        //     // project_number: projectNum,
        //     // group_id: this.groupId,
        //     // po_number: '20GH05300152P01',
        //   },
        // };
        const params = {
          project_number: projectNum,
        };
        if (!isInit) {
          this.editModel.po_header_id = '';
        }
        this.$http.get('erp-po', { params }).then((resp) => {
          const list = resp.data.items;
          this.pos = list;
        });
      }
    },
    // 选中采购合同
    choosePo(id) {
      if (!id) {
        this.editModel.items = [];
        return;
      }
      const arr = this.pos.filter(item => item.po_header_id === id);
      if (arr.length === 1) {
        const poHeader = arr[0];
        this.editModel.po_number = poHeader.po_number;
        const formCompanyId = poHeader.vendor_id;
        this.queryFromCompany(formCompanyId);
        // 查询合同明细
        const params = {
          po_number: poHeader.po_number,
          q: {
            po_header_id: id,
          },
        };
        this.$http.get(`erp-po/${id}`, { params }).then((resp) => {
          const lines = resp.data;
          this.addPoItems(lines, poHeader);
        });
      }
    },
    // 查询发货企业
    queryFromCompany(q) {
      if (typeof q === 'number') {
        this.$http.get('erp-party/' + q).then((resp) => {
          const partyObj = resp.data;
          this.selectFromLoading = false;
          this.fromCompanies = [partyObj];
          this.chooseFrom(partyObj);
        }, (err) => {
          this.selectFromLoading = false;
          this.$utils.resp(err);
        });
      } else {
        const params = {
          limit: 20,
          q: {
            status_code: 'APPROVED',
          },
        };
        if (typeof q === 'string' && q.trim() !== '') {
          const name = q.trim();
          params.q.party_name = { $like: `%${name}%` };
        }
        this.selectFromLoading = true;
        this.$http.get('erp-parties/', { params }).then((resp) => {
          const list = resp.data.items;
          this.selectFromLoading = false;
          this.fromCompanies = list;
        }, (err) => {
          this.selectFromLoading = false;
          this.$utils.resp(err);
        });
      }
    },
    // 发货企业改变obj或者id
    chooseFrom(obj) {
      let from = obj;
      if (typeof obj !== 'object') {
        const arr = this.fromCompanies.filter(item => item.party_id === obj);
        if (arr.length > 0) {
          from = arr[0];
        }
      }
      this.editModel.from_id = from.party_id;
      this.editModel.from_company = from.party_name;
      this.editModel.from_addr = from.register_addr_tele;
      this.getContracts(from.party_id).then((contacts) => {
        if (contacts.length === 0) {
          this.editModel.from_contact = '';
          this.editModel.from_tel = '';
        }
        contacts.forEach((item) => {
          if (item.default) {
            this.editModel.from_contact = item.name;
            this.editModel.from_tel = item.phone;
          }
        });
        this.fromContacts = contacts;
      });
    },
    chooseTo(obj) {
      let to = obj;
      if (typeof obj !== 'object') {
        const arr = this.parties2.filter(item => item.party_id === obj);
        if (arr.length > 0) {
          to = arr[0];
        }
      }
      this.editModel.to_id = to.party_id;
      this.editModel.to_company = to.party_name;
      this.editModel.to_addr = to.register_addr_tele;
      this.getContracts(to.party_id).then((contacts) => {
        if (contacts.length === 0) {
          this.editModel.to_contact = '';
          this.editModel.to_tel = '';
        }
        contacts.forEach((item) => {
          if (item.default) {
            this.editModel.to_contact = item.name;
            this.editModel.to_tel = item.phone;
          }
        });
        this.toContacts = contacts;
      });
    },
    getContracts(id) {
      return this.$http.get(`erp-party/${id}/contracts`).then(resp => resp.data);
    },
    // 联系人
    changeFromContact(name) {
      const checked = this.fromContacts.find(item => item.name === name);
      if (checked) {
        this.editModel.from_contact = checked.name;
        this.editModel.from_tel = checked.phone;
      }
    },
    changeToContact(name) {
      const checked = this.toContacts.find(item => item.name === name);
      if (checked) {
        this.editModel.to_contact = checked.name;
        this.editModel.to_tel = checked.phone;
      }
    },
    // 添加采购合同的商品明细
    addPoItems(lines, po) {
      this.editModel.items = [];
      lines.forEach((item) => {
        const leftNum = this.$calc.Sub(item.pricing_quantity, item.stockNum);
        const obj = {
          org: item,
          po_header_id: item.po_header_id,
          obj_id: item.po_line_id,
          po_number: po.po_number,
          item_id: item.item_id,
          item_name: item.item_name,
          attr1: item.item_attribute1,
          attr2: item.item_attribute2,
          attr6: item.item_attribute6,
          attr7: item.item_attribute7,
          unit_price: item.unit_price,
          unit_vat_price: item.unit_vat_price,
          uom: item.pricing_quantity_uom,
          leftNum,
          stockNum: item.stockNum,
          totalNum: item.pricing_quantity,
          quantity: leftNum,
          color: '',
          real_quantity: 0,
          sum: 0,
        };
        let arr = [];
        if (item.item_attribute1) {
          arr.push(item.item_attribute1);
        }
        if (item.item_attribute6) {
          arr.push(item.item_attribute6);
        }
        obj.attr_1 = arr.join('/');
        arr = [];
        if (item.item_attribute2) {
          arr.push(item.item_attribute2);
        }
        if (item.item_attribute7) {
          arr.push(item.item_attribute7);
        }
        obj.attr_2 = arr.join('/');
        this.editModel.items.push(obj);
      });
    },
    // 添加自定义商品明细
    addItem() {
      const item = {
        item_name: '',
        attr1: '',
        attr2: '',
        attr6: '',
        attr7: '',
        unit_price: null,
        unit_vat_price: null,
        uom: '',
        quantity: 0,
        color: '',
      };
      this.editModel.items.push(item);
    },
    calcQuantity(item) {
      return item.quantity > item.leftNum;
    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.editModel };
          if (obj.items.length <= 0) {
            this.$message({
              type: 'warning',
              message: '至少需要有一条数据',
              showClose: true,
              duration: 2000,
            });
            return;
          }
          let flag = true;
          obj.items.forEach((item) => {
            if (item.item_name.trim() === '' || item.quantity <= 0) {
              flag = false;
            }
          });
          if (!flag) {
            this.$message({
              type: 'warning',
              message: '产品名称不能为空，数量也不能为0',
              showClose: true,
              duration: 2000,
            });
            return;
          }
          // if (obj.po_header_id) {
          //   this.$http.get(`po-num/${obj.po_header_id}`).then((resp) => {
          //     const poList = resp.data;
          //     const poMap = {};
          //     poList.forEach((item) => {
          //       poMap[item.po_header_id] = item;
          //     });
          //     for (let i = 0; i < obj.items.length; i++) {
          //       const t = obj.item[i];
          //       const poItem = poMap[t.po_header_id];
          //       const max = poItem.pricing_quantity - poItem.stockNum;
          //       if (t.quantity > max) {
          //         cons
          //       }
          //     }
          //   });
          // }
          if (obj.id) {
          // 修改
            this.waiting = true;
            this.$http.put(`postock/${obj.id}`, obj).then(() => {
              this.getList();
              this.waiting = false;
              this.editDialog = false;
            }, (err) => {
              this.$utils.resp(err);
              this.waiting = false;
            });
          } else {
          // 新增
            if (obj.needDelivery) {
              if (obj.from_tel.trim().length === 0 || obj.to_tel.trim().length === 0) {
                let msg = false;
                if (obj.from_tel.trim().length === 0) {
                  msg = '强填写 发货方 电话';
                } else {
                  msg = '强填写 收货方 电话';
                }
                this.$message({
                  type: 'warning',
                  message: msg,
                  showClose: true,
                  duration: 2000,
                });
                return;
              }
            }
            // 仓库名称
            let storeObj = this.inventories.filter(item => item.secondary_inventory_name === obj.secondary_inventory_name);
            storeObj = storeObj.length > 0 ? storeObj[0] : false;
            obj.secondary_inventory_description = storeObj.description;
            this.waiting = true;
            const projects = this.projects.filter(item => item.project_id === obj.project_id);
            if (projects.length === 1) {
              const project = projects[0];
              const projectNum = project.project_number;
              obj.project_number = projectNum;
            } else {
              this.$message({
                type: 'warning',
                message: '没有对应项目',
                showClose: true,
                duration: 2000,
              });
              return;
            }
            const company = this.parties2.filter(item => item.party_id === obj.company_id);
            if (company.length === 1) {
              obj.company_name = company[0].party_name;
            } else {
              this.$message({
                type: 'warning',
                message: '没有对应企业',
                showClose: true,
                duration: 2000,
              });
              return;
            }
            this.$http.post('postock', obj).then(() => {
              this.editDialog = false;
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
              this.changePage(1);
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
    delItem(index) {
      this.editModel.items.splice(index, 1);
    },
    toDetail(item) {
      this.$http.get(`postock/${item.id}`).then((resp) => {
        this.detailDialog = true;
        this.showModel = resp.data;
      });
    },
    apply(item) {
      this.$confirm('确认提交？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.put(`postock/${item.id}/submit`, {}).then(() => {
          this.getList();
          this.waiting = false;
          this.detailDialog = false;
          this.$message({
            type: 'success',
            message: '提交成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    approve(item) {
      this.$confirm('确认审批？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.put(`postock/${item.id}/approve`, {}).then(() => {
          this.getList();
          this.waiting = false;
          this.detailDialog = false;
          this.$message({
            type: 'success',
            message: '审批成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    toDvOrder(item) {
      this.$router.push({ name: 'DvOrder', query: { code: item.code } });
    },
    deleteRecord(id) {
      this.$http.delete(`postock/${id}`).then(() => {
        this.editDialog = false;
        this.detailDialog = false;
        this.getList();
      }, (err) => {
        this.waiting = false;
        this.$utils.resp(err);
      });
    },
  },
};
</script>

<style scoped>
.warn-input {
  border: 1px solid #f00;
}
</style>
