<template>
  <el-card class="components">
    <el-button type="primary" size="small" v-if="isDeliveryManager"
      @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search" style="margin-right: 5px;">
        <el-input v-model="queryForm.project" clearable placeholder="所属项目编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search" style="margin-right: 5px;">
        <el-input v-model="queryForm.q" clearable placeholder="输入关键字检索" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search" style="margin-right: 5px;" v-if="isDeliveryInput">
        <el-select v-model="queryForm.relaType" clearable placeholder="选择关系" @change="onSubmitQuery">
          <el-option value="" label="所有相关"></el-option>
          <el-option value="from" label="我发货的"></el-option>
          <el-option value="to" label="我接收的"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status" clearable placeholder="选择" @change="onSubmitQuery">
          <el-option label="全部" value=""></el-option>
          <el-option label="当前订单" :value="`-${statuses.finished}`"></el-option>
          <el-option v-for="(val, key) in statusList" :label="val" :key="key" :value="key"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>标题</th>
          <th>编号</th>
          <th>开始日期</th>
          <th>发货方</th>
          <th>收货方</th>
          <th width="200">部门</th>
          <th>状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>
            {{ index + 1 }}
          </td>
          <td>{{ item.title }}</td>
          <td><a href="#" @click.prevent="toDetail(item)">{{ item.code }}</a></td>
          <td>{{ item.send_date }}</td>
          <td>{{ item.from_company }}</td>
          <td>{{ item.to_company }}</td>
          <td :title="deptStr(item.detail_dept_id)">
            <span style="width: 200px;" class="text-overflow">{{ deptsMap[item.dept_id]?deptsMap[item.dept_id].name:''}}</span>
          </td>
          <td>
            <span :class="[`status-color-${item.status}`]">
              {{ item.status_text }}</span>
          </td>
          <td>
            <div class="text-nowrap">
              <el-button v-if="canModify(item)" type="default" style="margin-left: 3px;"
                icon="fa fa-fw fa-pencil" title="修改信息" @click="toEdit(item)" :disabled="waiting"></el-button>
              <el-button v-if="needOperate(item)" title="订单确认" @click="toDetail(item)"
                icon="fa fa-fw fa-check" :disabled="waiting" type="warning" style="margin-left: 3px;"></el-button>
              <el-button v-if="canViewCheck(item)" title="订单审核" @click="toDetail(item)"
                icon="fa fa-fw fa-check" :disabled="waiting" type="warning" style="margin-left: 3px;"></el-button>
              <el-button v-if="isDeliveryManager" type="warning" style="margin-left: 3px;"
                icon="fa fa-fw fa-copy" title="拷贝" @click="copyIt(item)" :disabled="waiting"></el-button>
              <!-- <el-button v-if="!needOperate(item)" size="small" type="primary"
                title="详情" @click="toDetail(item)" style="margin-left: 3px;"
                icon="fa fa-fw fa-search" :disabled="waiting"></el-button> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'"
      :close-on-click-modal="false" width="90%" top="2%">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="所属项目" prop="project_id">
          <el-select v-model="editModel.project_id" style="width:100%;" filterable>
            <el-option label="" value=""></el-option>
            <el-option v-for="item in projects" :key="item.id"
              :value="item.id" :label="`【${item.code}】 ${item.title}`"></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="标题" prop="title">
              <el-input v-model="editModel.title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务部门">
              <el-input readonly :value="userMeta.branch.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="部门联系人" prop="contact_man">
              <el-input v-model="editModel.contact_man"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人电话" prop="contact_phone">
              <el-input v-model="editModel.contact_phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="订单编号" prop="code">
              <el-input v-model="editModel.code" placeholder="不填自动生成"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开始日期" prop="send_date">
              <el-date-picker v-model="editModel.send_date"
                style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货方" prop="from">
              <el-select style="width: 100%" v-model="editModel.from" value-key="id" :loading="selectFromLoading"
                filterable remote :remote-method="queryFromCompany" @change="chooseFrom" @focus="focusFromCompany"
                :disabled="isEditModelProgress">
                <el-option v-for="item in fromCompanies" :key="item.id"
                  :value="item" :label="item.company_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货方" prop="to">
              <el-select style="width: 100%" v-model="editModel.to" value-key="id" :loading="selectToLoading"
                filterable remote :remote-method="queryToCompany" @change="chooseTo" @focus="focusToCompany"
                :disabled="isEditModelProgress">
                <el-option v-for="item in toCompanies" :key="item.id"
                  :value="item" :label="item.company_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货联系人" prop="from_contact">
              <el-select v-model="editModel.from_contact" filterable allow-create clearable @change="changeFromContact">
                <el-option v-for="item in fromContacts" :key="item.id" :label="item.name" :value="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货联系人" prop="to_contact">
              <el-select v-model="editModel.to_contact" filterable allow-create clearable @change="changeToContact">
                <el-option v-for="item in toContacts" :key="item.id" :label="item.name" :value="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货人电话" prop="from_tel">
              <el-input v-model="editModel.from_tel"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货人电话" prop="to_tel">
              <el-input v-model="editModel.to_tel"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货地址" prop="from_addr">
              <el-input v-model="editModel.from_addr"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收获地址" prop="to_addr">
              <el-input v-model="editModel.to_addr"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注说明" prop="remark">
          <el-input type="textarea" v-model="editModel.remark"></el-input>
        </el-form-item>
        <el-form-item label="明细">
          <table class="items bordered table" style="width: 100%;">
            <thead>
              <tr>
                <!-- <th class="text-center">货号</th> -->
                <th class="text-center">品名规格</th>
                <th class="text-center">款号</th>
                <th class="text-center">花型/颜色</th>
                <th class="text-center">数量</th>
                <th class="text-center">单位</th>
                <!-- <th class="text-center">单价</th> -->
                <!-- <th class="text-center">金额</th> -->
                <th v-if="!editModel.id || editModel.status === '1'">操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in editModel.items" :key="index" :title="item.remark">
                <!-- <td><el-input v-model="item.sn"></el-input></td> -->
                <td>
                  <el-input v-model="item.name"></el-input>
                </td>
                <td><el-input v-model="item.construction"></el-input></td>
                <td>
                  <el-input v-model="item.color"></el-input>
                </td>
                <td>
                  <el-input class="text-right" v-model="item.amount" @change="calcItem(item)" type="number"></el-input>
                </td>
                <td><el-input v-model="item.unit"></el-input></td>
                <!-- <td><el-input v-model="item.unit_price" @change="calcItem(item)" type="number"></el-input></td>
                <td><el-input v-model="item.sum" type="number"></el-input></td> -->
                <td>
                  <el-button :disabled="item.index && isEditModelProgress" @click="delItem(index)"
                    type="danger" title="删除" icon="el-icon-delete"></el-button>
                </td>
              </tr>
              <tr>
                <td colspan="5" class="text-right">
                </td>
                <!-- <td class="text-right text-info">{{total}}</td> -->
                <td>
                  <el-button @click="addItem" type="primary" title="新增" icon="el-icon-plus"></el-button>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="save(false)" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 保 存</el-button>
        <el-button type="primary" v-if="editModel.status !== statuses.ongoing"
          @click="save(true)" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 保存并提交</el-button>
        <el-button v-if="editModel.id"
          type="danger" @click="delIt" class="pull-left" :disabled="waiting" icon="fa fa-fw fa-trash"> 删 除</el-button>

      </div>
    </el-dialog>

    <!-- 详情dialog -->
    <el-dialog :visible.sync="showDialog" :title="'详情'"
      :close-on-click-modal="false" width="90%" top="2%">
      <el-form :model="editModel" label-width="100px">
        <el-form-item label="所属项目：" prop="project_id" v-if="editModel.project">
          【{{editModel.project.code}}】{{editModel.project.title}}
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="标题：" prop="title">
              {{editModel.title}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务部门：">
              {{editModel.branch ? editModel.branch.name : ''}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="部门联系人：" prop="contact_man">
              {{editModel.contact_man}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人电话：" prop="contact_phone">
              {{editModel.contact_phone}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="订单编号：" prop="code">
              {{editModel.code}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开始日期：" prop="send_date">
              {{editModel.send_date}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货方：" prop="from">
              {{editModel.from_company}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货方：" prop="to">
              {{editModel.to_company}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货联系人：" prop="from_contact">
              {{editModel.from_contact}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货联系人：" prop="to_contact">
              {{editModel.to_contact}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货人电话：" prop="from_tel">
              {{editModel.from_tel}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货人电话：" prop="to_tel">
              {{editModel.to_tel}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货地址：" prop="from_addr">
              {{editModel.from_addr}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货地址：" prop="to_addr">
              {{editModel.to_addr}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="当前状态：">
              <span :class="[`status-color-${editModel.status}`]">{{editModel.status_text}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="二维码：">
              <span style="cursor:pointer" @click="toQrPage(editModel.id)">显示二维码</span>
              <el-button @click="downloadQr(editModel)" type="primary" icon="fa fa-download"></el-button>
              <vue-qr  ref="qrImg" v-show="false" logoSrc="/asset/favicon.ico" logoBackgroundColor="#fff" :logoMargin="5"
                :correctLevel="3" :text="editModel.id|orderQr" :margin="6" :dotScale="1"></vue-qr>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注说明：" prop="remark">
          {{editModel.remark}}
        </el-form-item>
        <div v-if="editModel.logs && editModel.logs.length > 0" class="text-right" style="padding: 5px 0;">
          <el-button type="warning" @click="showLogs(editModel.logs)">显示调整记录</el-button>
        </div>
        <div class="text-right" style="padding: 10px 0;">
          <el-checkbox v-model="allChecked" @change="checkAll" style="margin-right: 10px;">全选</el-checkbox>
          <el-button type="primary" :disabled="checkedIds.length === 0" @click="getPdf">导出pdf</el-button>
        </div>
        <table class="items bordered table" style="width: 100%;">
          <thead>
            <tr>
              <!-- <th class="text-center">货号</th> -->
              <th class="text-center">品名规格</th>
              <th class="text-center">款号</th>
              <th class="text-center">花型/颜色</th>
              <th class="text-center">单位</th>
              <!-- <th class="text-center">单价</th> -->
              <th class="text-center">通知发货量</th>
              <th class="text-center">实际发货量</th>
              <th colspan="2" class="text-center" :title="item.code" v-for="item in records" :key="item.id">
                {{item.delivery_date}}
                <el-checkbox v-model="item.checked" @change="changeCheck"></el-checkbox>
              </th>
              <!-- <th class="text-center">计划金额</th>
              <th class="text-center">实发金额</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in editModel.items" :key="index" :title="item.remark">
              <!-- <td class="text-center">{{item.sn}}</td> -->
              <td class="text-center">{{item.name}}</td>
              <td class="text-center">{{item.construction}}</td>
              <td class="text-center">{{item.color}}</td>
              <td class="text-right">{{item.unit}}</td>
              <!-- <td class="text-right">{{item.unit_price}}</td> -->
              <td class="text-right">{{item.amount}}</td>
              <td class="text-right">
                <span class="text-primary">{{item.real_amount}}</span>
              </td>
              <!-- <td class="text-center" v-for="subItem in records" :title="'金额 ' + (subItem.detailMap[item.index]
                ? parseFloat(subItem.detailMap[item.index].sum ? subItem.detailMap[item.index].sum : 0) : 0)"
                :key="subItem.id">
                {{subItem.detailMap[item.index] ? parseFloat(subItem.detailMap[item.index].amount) : 0}}
              </td> -->
              <template v-for="(subItem, idx) in records">
                <td style="width: 100px" class="text-center" :key="idx">
                  {{subItem.detailMap[item.index] ? parseFloat(subItem.detailMap[item.index].amount) : 0}}</td>
                <td style="width: 100px" class="text-center" :key="idx + 's'">
                  {{subItem.detailMap[item.index] ? subItem.detailMap[item.index].count : ''}}</td>
              </template>
              <!-- <td class="text-right">{{item.sum}}</td>
              <td class="text-right">{{sumMap[item.index] ? sumMap[item.index] : 0}}</td> -->
            </tr>
            <tr>
              <td colspan="6" class="text-right">附件</td>
              <td colspan="2" class="text-center" v-for="subItem in records" :key="subItem.id">
                <el-button v-if="subItem.attachments && subItem.attachments.length > 0"
                  @click="downloadFile(subItem.attachments[0])"
                  icon="fa fa-download" :title="subItem.attachments[0].orgName"></el-button>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="text-right">状态</td>
              <td colspan="2" class="text-center" v-for="subItem in records" :key="subItem.id">
                <el-button v-if="subItem.status == 1 && editModel.creator_id == user.id"
                  type="warning" @click="checkDelivery(subItem)">
                  确认可发
                </el-button>
                <el-button v-else-if="subItem.status == 2 && subItem.creator_id == user.id"
                  @click="toDelivery(subItem)" type="warning">发货</el-button>
                <span v-else-if="(subItem.status == 3 || subItem.status == 9)
                  && editModel.to_id == user.meta.shipping_company_id">
                  <el-button type="primary" style="margin-right: 5px;"
                    @click="finishDelivery(subItem)">确认收货</el-button>
                  <el-button v-if="subItem.status == 3" type="warning" style="margin-right: 5px;"
                    @click="ycDelivery(subItem)">延迟收货</el-button>
                  <span v-if="subItem.status == 9" class="text-warning">({{subItem.status_text}})</span>
                </span>
                <span v-else>{{subItem.status_text}}</span>
              </td>
              <!-- <td class="text-right">{{total}}</td> -->
              <!-- <td class="text-right">{{realTotal2}}</td> -->
            </tr>
            <!-- <tr>
              <td colspan="8" class="text-right">最终金额</td>
              <td class="text-center" v-for="subItem in records" :key="subItem.id">
                {{subItem.amount}}<span v-if="subItem.offset">{{subItem.offset}}</span>
              </td>
              <td class="text-right">最终金额总计</td>
              <td class="text-right">{{realAmount}}</td>
            </tr> -->
          </tbody>
        </table>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="toPending(editModel)" v-if="canPending(editModel)"
          :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 提 交</el-button>
        <el-button type="primary" @click="finishIt(editModel)" v-if="canFinish(editModel)"
          :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 完 结</el-button>
        <el-button type="primary" @click="viewCheck(editModel)" v-if="canViewCheck(editModel)"
          :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 审核通过</el-button>
        <el-button type="warning" @click="viewCheck(editModel, false)" v-if="canViewCheck(editModel)"
          :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 驳回</el-button>
        <el-button type="primary" @click="checkIt(editModel)" v-if="needOperate(editModel)"
          :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 确认订单</el-button>
        <el-button type="warning" @click="checkIt(editModel, false)" v-if="needOperate(editModel)"
          :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 不同意</el-button>

        <el-button type="danger" @click="delIt" v-if="editModel.status==1 && user.id === editModel.creator_id"
          class="pull-left" :disabled="waiting" icon="fa fa-fw fa-trash-o"> 删 除</el-button>
        <a ref="newPageLink" target="_blank" :href="downloadUrl" :download="downloadFileName" v-show="false">icon</a>
      </div>
    </el-dialog>

    <el-dialog  :visible.sync="logsDialog" :title="'历史记录'"
      :close-on-click-modal="true" width="80%">
      <div v-for="(log, idx) in logs" :key="idx">
        <div v-if="idx > 0" style="margin-top: 10px;padding: 5px;">修改时间：{{log.created_at}}</div>
        <table class="items bordered table" style="width: 100%;">
          <thead>
            <tr>
              <!-- <th class="text-center">货号</th> -->
              <th class="text-center">品名规格</th>
              <!-- <th class="text-center">规格</th> -->
              <th class="text-center">花型/颜色</th>
              <th class="text-center">单位</th>
              <!-- <th class="text-center">单价</th> -->
              <th class="text-center">通知发货量</th>
              <!-- <th class="text-center">计划金额</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in log.items" :key="index" :title="item.remark">
              <!-- <td class="text-center">{{item.sn}}</td> -->
              <td class="text-center">{{item.name}}</td>
              <!-- <td class="text-center">{{item.construction}}</td> -->
              <td class="text-center">{{item.color}}</td>
              <td class="text-right">{{item.unit}}</td>
              <!-- <td class="text-right">{{item.unit_price}}</td> -->
              <td class="text-right">{{item.amount}}</td>
              <!-- <td class="text-right">{{item.sum}}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </el-dialog>
    <a ref="downloadLink" :href="downloadUrl" :download="downloadFileName" v-show="false"></a>
  </el-card>
</template>

<style scoped lang="scss">
  .items.table td {
    padding: 5px;
  }
  .status-color-1 {
    color: #409eff;
  }
  .status-color-2 {
    color: #e6a23c;
  }
  .status-color-r2 {
    color: #f56c6c;
  }
  .status-color-3 {
    color: #e6a23c;
  }
  .status-color-r3 {
    color: #f56c6c;
  }
  .status-color-4 {
    color: #67c23a;
  }
  .status-color-5 {
    color: #909399;
  }
</style>

<script>
import { mapState } from 'vuex';
import VueQr from 'vue-qr';

export default {
  name: 'wb-order',
  components: {
    VueQr,
  },
  data() {
    return {
      loading: false,
      waiting: false,
      selectFromLoading: false,
      selectToLoading: false,
      // 查询条件form
      queryForm: {
        q: '',
        status: '-5',
        relaType: '',
        project: '',
      },
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      // 联系人选择
      fromContacts: [],
      toContacts: [],
      editDialog: false, // dialog显示标志 true 显示
      showDialog: false, // dialog显示标志 true 显示
      logsDialog: false, // logs
      logs: [], // 调整记录
      rules: {
        project_id: [{ required: true, message: '必须选择一个所属项目', trigger: ['change', 'blur'] }],
        title: [{ required: true, message: '填写标题', trigger: ['change', 'blur'] }],
        from: [{ required: true, message: '填写标题', trigger: ['change', 'blur'] }],
        to: [{ required: true, message: '填写标题', trigger: ['change', 'blur'] }],
      },
      // 发货记录
      records: [],
      allChecked: false, // 打印选择是否全选
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      statusList: { 1: '草稿', 3: '待确认', r3: '确认驳回', 4: '进行中', 5: '已完成' },
      // v2
      // statusList: { 1: '草稿', 2: '待审核', r2: '已驳回', 3: '待确认', r3: '确认驳回', 4: '进行中', 5: '已完成' },
      fromCompanies: [],
      toCompanies: [],
      projects: [],
      total: 0,
      sumMap: {},
      // 发货记录附件
      downloadFileName: '',
      downloadUrl: '',
      // realTotal: 0,
      realTotal2: 0,
      realAmount: 0,
      statuses: {
        draft: '1', // 草稿
        pending: '2', // 待审核
        checked: '3', // 审核通过，待确认
        reject: 'r2', // 审核不通过
        reject2: 'r3', // 发货方不同意
        ongoing: '4', // 发货方确认可执行
        finished: '5', // 订单完结
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      userMeta: state => state.auth.userMeta,
    }),
    // 是否为公司业务员
    isDeliveryManager() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('delivery_order_manager') !== -1) {
        result = true;
      }
      return result;
    },
    // 是否为发货相关企业用户
    isDeliveryInput() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('delivery_input') !== -1) {
        result = true;
      }
      return result;
    },
    // 是否为进行中调整
    isEditModelProgress() {
      let result = false;
      if (this.editModel.id && this.editModel.status === this.statuses.ongoing) {
        result = true;
      }
      return result;
    },
    // 消息调整的消息id
    notification() {
      let result = '';
      if (this.$route.query.notification) {
        result = this.$route.query.notification;
      }
      return result;
    },
    checkedIds() {
      return this.records.filter(item => item.checked).map(item => item.id);
    },
  },
  created() {
    this.getDepts().then(() => {
      this.onSubmitQuery();
    });
  },
  activated() {
    this.getProjects();
    if (this.$route.query.code) {
      this.queryForm.project = this.$route.query.code;
      this.queryForm.status = '';
      this.queryForm.q = '';
      this.queryForm.relaType = '';
      this.onSubmitQuery();
    }
    this.queryNotif();
  },
  filters: {
    orderQr(id) {
      return `https://www.guotaigh.com.cn/wb-app/#/query?id=${id}`;
    },
  },
  watch: {
    notification() {
      this.queryNotif();
    },
  },
  methods: {
    // 部门列表
    getDepts() {
      return this.$http.get('depts').then((resp) => {
        const depts = resp.data;
        const deptsMap = {};
        depts.forEach((item) => {
          deptsMap[item.id] = item;
        });
        this.deptsMap = deptsMap;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // 获取部门名称
    deptStr(deptId, withoutHead = 1) {
      const depts = [];
      let id = deptId;
      while (id) {
        const curDept = this.deptsMap[id];
        depts.unshift(curDept);
        id = curDept.parent_id;
      }
      const strArr = depts.map(item => item.name);
      let len = withoutHead;
      while (len > 0) {
        strArr.shift();
        len--;
      }
      return strArr.join('/');
    },
    // 对应消息的数据详情
    queryNotif() {
      if (this.$route.query.notification) {
        this.queryForm.project = '';
        this.queryForm.q = this.$route.query.sn;
        this.queryForm.status = '';
        this.queryForm.relaType = '';
        this.onSubmitQuery();
        this.toDetail({ id: this.$route.query.detail });
        this.$store.dispatch('READ_NOTIFYICATION', this.$route.query.notification);
      }
    },
    getProjects() {
      if (this.isDeliveryManager) {
        this.$http.get('unfinished-wbprojects').then((resp) => {
          this.projects = resp.data;
        }, (err) => {
          this.$utils.resp(err);
        });
      }
    },
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$http.get('dely-order', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.loading = false;
          const list = response.data.data;
          this.list = list;
          this.totalItem = response.data.total;
        }, (err) => {
          this.loading = false;
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.getProjects();
      this.editModel = {
        title: '',
        code: '',
        contact_man: this.user.name,
        contact_phone: this.user.mobile,
        send_date: this.$utils.FormatDate(),
        from: null,
        to: null,
        from_id: '',
        to_id: '',
        from_contact: '',
        from_tel: '',
        to_contact: '',
        to_tel: '',
        remark: '',
        status: '1',
        items: [],
      };
      this.total = 0;
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    copyIt(item) {
      this.getProjects();
      const copyModel = {
        // from_id: item.from_id,
        // from_company: item.from_company,
        // to_id: item.to_id,
        // to_company: item.to_company,
        // from_contact: item.from_contact,
        // from_tel: item.from_tel,
        // to_contact: item.from_contact,
        // to_tel: item.to_tel,
        // remark: item.remark,
        ...item,
        title: item.title + '-副本',
        code: '',
        contact_man: this.user.name,
        contact_phone: this.user.mobile,
        send_date: this.$utils.FormatDate(),
        status: '1',
        items: [],
      };
      delete copyModel.id;
      copyModel.from = {
        id: copyModel.from_id,
        company_name: copyModel.from_company,
      };
      this.fromCompanies = [{
        id: copyModel.from_id,
        company_name: copyModel.from_company,
      }];
      copyModel.to = {
        id: copyModel.to_id,
        company_name: copyModel.to_company,
      };
      this.toCompanies = [{
        id: copyModel.to_id,
        company_name: copyModel.to_company,
      }];
      // contacts
      this.$http.get(`shippingcompany/${copyModel.from_id}`).then((resp) => {
        this.fromContacts = resp.data.contacts || [];
      });
      this.$http.get(`shippingcompany/${copyModel.to_id}`).then((resp) => {
        this.toContacts = resp.data.contacts || [];
      });
      item.items.forEach((obj) => {
        copyModel.items.push({
          sn: obj.sn,
          name: obj.name,
          construction: obj.construction,
          color: obj.color,
          amount: obj.amount,
          unit_price: obj.unit_price,
          real_amount: 0,
          sum: 0,
        });
      });
      this.editModel = copyModel;
      this.editDialog = true;
      this.calcTotal();
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 打开编辑
    toEdit(editObj) {
      this.editModel = { ...editObj };
      this.editModel.from = {
        id: this.editModel.from_id,
        company_name: this.editModel.from_company,
      };
      this.fromCompanies = [{
        id: this.editModel.from_id,
        company_name: this.editModel.from_company,
      }];
      this.editModel.to = {
        id: this.editModel.to_id,
        company_name: this.editModel.to_company,
      };
      this.toCompanies = [{
        id: this.editModel.to_id,
        company_name: this.editModel.to_company,
      }];
      // contacts
      this.$http.get(`shippingcompany/${this.editModel.from_id}`).then((resp) => {
        this.fromContacts = resp.data.contacts || [];
      });
      this.$http.get(`shippingcompany/${this.editModel.to_id}`).then((resp) => {
        this.toContacts = resp.data.contacts || [];
      });
      this.editDialog = true;
      this.calcTotal();
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 是否有权提交
    canPending(item) {
      let result = false;
      if (this.statuses.draft === item.status && this.isDeliveryManager && item.creator_id === this.user.id) {
        result = true;
      }
      return result;
    },
    // 判断该记录是否可修改操作
    canModify(item) {
      let result = false;
      // 驳回或者草稿或者进行中可修改
      const allowStatus = [
        this.statuses.draft,
        this.statuses.reject,
        this.statuses.reject2,
        this.statuses.ongoing,
      ];
      if (allowStatus.indexOf(item.status) !== -1 && this.isDeliveryManager && item.creator_id === this.user.id) {
        result = true;
      }
      return result;
    },
    // 判断是否领导需要审批
    canViewCheck(item) {
      let result = false;
      if (this.user.meta) {
        const deptIds = this.user.meta.wb_depts ? this.user.meta.wb_depts : [];
        if (deptIds.indexOf(item.detail_dept_id) !== -1 && item.status === this.statuses.pending) {
          result = true;
        }
      }
      return result;
    },
    // 判断该记录是否有待确认操作
    needOperate(item) {
      let result = false;
      if (item.status === this.statuses.checked && this.user.meta && this.user.meta.shipping_company_id === item.from_id) {
        result = true;
      }
      return result;
    },
    // 判断是否可操作完结
    canFinish(item) {
      let result = false;
      if (this.isDeliveryManager && item.status === this.statuses.ongoing && item.creator_id === this.user.id) {
        result = true;
      }
      return result;
    },
    toDetail(obj) {
      this.waiting = true;
      this.$http.get(`dely-order/${obj.id}`).then((resp) => {
        this.waiting = false;
        this.showDialog = true;
        const records = resp.data.deliveries;
        const sumMap = {};
        let realTotal = 0;
        let offset = 0;
        records.forEach((item) => {
          this.$set(item, 'checked', false);
          realTotal += (item.amount ? parseFloat(item.amount) : 0);
          offset += (item.offset ? parseFloat(item.offset) : 0);
          item.detailMap = {};
          item.details.forEach((detail) => {
            // realTotal += detail.sum ? parseFloat(detail.sum) : 0;
            item.detailMap[detail.index] = detail;
            sumMap[detail.index] = this.$calc.Add(
              (sumMap[detail.index] ? sumMap[detail.index] : 0),
              (detail.sum ? parseFloat(detail.sum) : 0)
            );
          });
        });
        this.realTotal2 = Math.round(realTotal * 100) / 100;
        this.realAmount = Math.round((realTotal + offset) * 100) / 100;
        this.records = records;
        this.allChecked = false;
        this.editModel = resp.data;
        this.calcTotal();
        this.sumMap = sumMap; // 实发金额小计 { index : real_sum }
      }, (err) => {
        this.waiting = false;
        this.$utils.resp(err);
      });
    },
    showLogs(logs) {
      this.logs = logs;
      this.logsDialog = true;
    },
    // 修改新增的保存
    save(withSubmit = false) {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.editModel };
          // 可提交的状态
          const allowSumitStas = [
            this.statuses.draft,
            this.statuses.reject,
            this.statuses.reject2,
          ];
          if (withSubmit && allowSumitStas.indexOf(obj.status) !== -1) {
            obj.status = this.statuses.checked;
            // v2
            // obj.status = this.statuses.pending;
          }
          this.waiting = true;
          if (obj.id) {
            this.$http.put(`dely-order/${obj.id}`, obj).then(() => {
              this.editDialog = false;
              this.getList();
              this.$message({
                type: 'success',
                message: '更新成功',
                showClose: true,
                duration: 2000,
              });
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          } else {
            this.$http.post('dely-order', obj).then(() => {
              this.editDialog = false;
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
              this.getList();
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
    toPending(item) {
      const id = item ? item.id : this.editModel.id;
      this.$confirm('是否提交？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        // v2
        // this.$http.put(`dely-order/${id}/change`, { status: this.statuses.pending }).then(() => {
        this.$http.put(`dely-order/${id}/change`, { status: this.statuses.checked }).then(() => {
          this.getList();
          this.showDialog = false;
          this.waiting = false;
          this.$message.success('订单已提交');
        }, (err) => {
          this.waiting = false;
          this.$utils.resp(err);
        });
      }, () => {});
    },
    // 完成订单
    finishIt(item) {
      const id = item ? item.id : this.editModel.id;
      this.$confirm('是否确认完成？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.put(`dely-order/${id}/change`, { status: this.statuses.finished }).then(() => {
          this.getList();
          this.showDialog = false;
          this.waiting = false;
          this.$message.success('订单已完结');
        }, (err) => {
          this.waiting = false;
          this.$utils.resp(err);
        });
      }, () => {});
    },
    viewCheck(item, pass = true) {
      const id = item ? item.id : this.editModel.id;
      if (pass) {
        this.waiting = true;
        this.$http.put(`dely-order/${id}/change`, { status: this.statuses.checked }).then(() => {
          this.getList();
          this.waiting = false;
          this.showDialog = false;
          this.$message.success('审核通过');
        }, (err) => {
          this.waiting = false;
          this.$utils.resp(err);
        });
      } else {
        this.waiting = true;
        this.$http.put(`dely-order/${id}/change`, { status: this.statuses.reject }).then(() => {
          this.getList();
          this.waiting = false;
          this.showDialog = false;
          this.$message.success('已驳回');
        }, (err) => {
          this.waiting = false;
          this.$utils.resp(err);
        });
      }
    },
    // 发货方核对确认订单
    checkIt(item, pass = true) {
      let message = '是否确认发货订单内容？';
      if (!pass) {
        message = '是否驳回该订单？';
      }
      const id = item ? item.id : this.editModel.id;
      this.$confirm(message, '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        if (pass) {
          this.waiting = true;
          this.$http.put(`dely-order/${id}/change`, { status: this.statuses.ongoing }).then(() => {
            this.getList();
            this.waiting = false;
            this.showDialog = false;
            this.$message.success('已确认');
          }, (err) => {
            this.waiting = false;
            this.$utils.resp(err);
          });
        } else {
          this.waiting = true;
          this.$http.put(`dely-order/${id}/change`, { status: this.statuses.reject2 }).then(() => {
            this.getList();
            this.waiting = false;
            this.showDialog = false;
            this.$message.success('已退回请求');
          }, (err) => {
            this.waiting = false;
            this.$utils.resp(err);
          });
        }
      }, () => {});
    },
    delIt() {
      this.$confirm('删除后无法恢复，是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.delete(`dely-order/${this.editModel.id}`).then(() => {
          this.getList();
          this.editDialog = false;
          this.waiting = false;
          this.$message({
            type: 'success',
            message: '删除成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    // select from
    // 没有数据查询相关企业作为发货企业选择
    focusFromCompany() {
      if (this.fromCompanies.length === 0) {
        this.queryFromCompany(' ');
      }
    },
    queryFromCompany(q) {
      if (typeof q === 'number') {
        this.$http.get('shippingcompany/' + q).then((resp) => {
          this.fromCompanies = [resp.data];
        });
      } else if (typeof q === 'string' && q !== '') {
        this.selectFromLoading = true;
        this.$http.get('query-shippingcompany', { params: { q, pageSize: 20 } }).then((resp) => {
          this.fromCompanies = resp.data.data;
          this.selectFromLoading = false;
        }, (err) => {
          this.selectFromLoading = false;
          this.$utils.resp(err);
        });
      } else {
        this.options = [];
      }
    },
    chooseFrom(from) {
      this.editModel.from_id = from.id;
      this.editModel.from_company = from.company_name;
      // this.editModel.from_contact = from.contact_man;
      // this.editModel.from_tel = from.contact_phone;
      this.editModel.from_addr = from.addr;
      this.$http.get(`shippingcompany/${from.id}`).then((resp) => {
        const contacts = resp.data.contacts || [];
        contacts.forEach((item) => {
          if (item.default) {
            this.editModel.from_contact = item.name;
            this.editModel.from_tel = item.phone;
          }
        });
        this.fromContacts = contacts;
        if (contacts.length === 0) {
          this.editModel.from_contact = from.contact_man;
          this.editModel.from_tel = from.contact_phone;
        }
      });
    },
    changeFromContact(name) {
      const checked = this.fromContacts.find(item => item.name === name);
      if (checked) {
        this.editModel.from_contact = checked.name;
        this.editModel.from_tel = checked.phone;
      }
    },
    // select to
    focusToCompany() {
      if (this.toCompanies.length === 0) {
        this.queryToCompany(' ');
      }
    },
    queryToCompany(q) {
      if (typeof q === 'number') {
        this.$http.get('shippingcompany/' + q).then((resp) => {
          this.toCompanies = [resp.data];
        });
      } else if (typeof q === 'string' && q !== '') {
        this.selectToLoading = true;
        this.$http.get('query-shippingcompany', { params: { q } }).then((resp) => {
          this.toCompanies = resp.data.data;
          this.selectToLoading = false;
        }, (err) => {
          this.selectToLoading = false;
          this.$utils.resp(err);
        });
      } else {
        this.options = [];
      }
    },
    chooseTo(company) {
      this.editModel.to_id = company.id;
      this.editModel.to_company = company.company_name;
      this.editModel.to_addr = company.addr;
      this.$http.get(`shippingcompany/${company.id}`).then((resp) => {
        const contacts = resp.data.contacts || [];
        contacts.forEach((item) => {
          if (item.default) {
            this.editModel.to_contact = item.name;
            this.editModel.to_tel = item.phone;
          }
        });
        this.toContacts = contacts;
        if (contacts.length === 0) {
          this.editModel.to_contact = company.contact_man;
          this.editModel.to_tel = company.contact_phone;
        }
      });
    },
    changeToContact(name) {
      const checked = this.toContacts.find(item => item.name === name);
      if (checked) {
        this.editModel.to_contact = checked.name;
        this.editModel.to_tel = checked.phone;
      }
    },
    // 新增明细
    addItem() {
      const obj = {
        sn: '',
        name: '',
        construction: '',
        color: '',
        amount: 0,
        real_amount: 0,
        unit_price: 0,
        sum: 0,
      };
      this.editModel.items.push(obj);
    },
    // 删除明细
    delItem(index) {
      this.editModel.items.splice(index, 1);
    },
    // 单条记录计算总价sum
    calcItem(item) {
      item.sum = this.$calc.Mul(item.amount ? item.amount : 0, item.unit_price ? item.unit_price : 0);
      this.calcTotal();
    },
    calcTotal() {
      let total = 0;
      let realTotal = 0;
      this.editModel.items.forEach((item) => {
        total += item.sum ? item.sum : 0;
        realTotal += (item.real_sum ? item.real_sum : 0);
      });
      this.total = Math.round(total * 100) / 100;
      this.realTotal = Math.round(realTotal * 100) / 100;
    },
    // 公司确认发货信息是否批准
    checkDelivery(record) {
      this.$http.put(`dely-record/${record.id}/change`, { status: '2' }).then(() => {
        record.status = '2';
        record.status_text = '待发货';
      });
    },
    // 发货方发货
    toDelivery(record) {
      this.$http.put(`dely-record/${record.id}/change`, { status: '3' }).then(() => {
        record.status = '3';
        record.status_text = '已发货';
      });
    },
    // 收货方确认收货
    finishDelivery(record) {
      this.$confirm('是否确认收货？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.put(`dely-record/${record.id}/change`, { status: '4' }).then(() => {
          record.status = '4';
          record.status_text = '已收货';
        });
      }, () => {});
    },
    // 收货方延迟收货
    ycDelivery(record) {
      this.$confirm('是否延迟收货？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.put(`dely-record/${record.id}/change`, { status: '9' }).then(() => {
          record.status = '9';
          record.status_text = '有争议';
        });
      }, () => {});
    },
    // 文件下载
    getfile(fileName) {
      const params = {
        hint: 'delivery',
        name: fileName,
      };
      return this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        return objurl;
      }, (err) => {
        this.$utils.resp(err);
        // todo
        return '404png';
      });
    },
    downloadFile(item) {
      const storeName = item.name;
      const params = {
        hint: 'delivery',
        name: storeName,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.downloadFileName = item.orgName;
        this.downloadUrl = objurl;
        this.$nextTick(() => {
          this.$refs.downloadLink.click();
        });
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    downloadQr(item) {
      this.downloadFileName = item.code;
      this.downloadUrl = this.$refs.qrImg.$el.src;
      this.$nextTick(() => {
        this.$refs.downloadLink.click();
      });
    },
    toQrPage(id) {
      const route = this.$router.resolve({ name: 'OrderQr', query: { id } });
      window.open(route.href, '_blank');
    },
    // 打印pdf相关
    changeCheck() {
      this.setAllCheck();
    },
    setAllCheck() {
      const list = this.records.filter(item => item.checked);
      if (this.records.length > 0 && list.length === this.records.length) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    },
    checkAll(val) {
      this.records.forEach((item) => {
        item.checked = val;
      });
    },
    getPdf() {
      const list = this.records.filter(item => item.checked);
      const ids = list.map(item => item.id);
      const params = { ids };
      this.$http.get(`create-pdf/wb-order/${this.editModel.id}`, { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/pdf',
        });
        const objurl = URL.createObjectURL(blob);
        this.downloadFileName = this.editModel.code + '.pdf';
        this.downloadUrl = objurl;
        this.$nextTick(() => {
          this.$refs.newPageLink.click();
        });
      }, (err) => {
        this.$utils.resp(err);
      });
      // this.$http.get(`create-pdf/wb-order/${this.editModel.id}`, { ids }).then((resp) => {
      //   console.log(resp.data);
      //   this.downloadFileName = this.editModel.code + '.pdf';
      //   this.downloadUrl = 'temp-file?name=resp.data';
      //   this.$nextTick(() => {
      //     this.$refs.downloadLink.click();
      //   });
      // });
    },
  },
};
</script>
