<template>
  <div>
    <div class="mar-btm-10 text-right">
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.kh" clearable filterable placeholder="款号">
          <el-option v-for="item in khs" :key="item" :value="item" :label="item"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.rela_style" clearable filterable placeholder="关联款号">
          <el-option v-for="item in relaStyles" :key="item" :value="item" :label="item"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.color" clearable placeholder="颜色选择" filterable>
          <el-option v-for="(label, key) in colors" :key="key" :value="key" :label="`[${key}]${label}`"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.size" clearable placeholder="尺码" filterable>
          <el-option v-for="(label, key) in sizes" :key="key" :value="key" :label="`${label}`"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.outType" clearable placeholder="返库类型">
          <el-option v-for="(label, key) in backTypes" :key="key" :value="key" :label="`${label}`"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-date-picker value-format="yyyy-MM-dd"
          v-model="queryForm.start_date"
          type="date" placeholder="开始日期">
        </el-date-picker>
      </div>
      <div class="box-inline standard-search">
        <el-date-picker value-format="yyyy-MM-dd"
          v-model="queryForm.end_date"
          type="date" placeholder="截至日期">
        </el-date-picker>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
      <el-button size="small" @click="excel">
        <i class="fa fa-fw fa-download"></i>
      </el-button>
      <a v-show="false" ref="downloadHref" :href="excelUrl" download="out.xlsx"></a>
    </div>
    <el-tabs v-model="subActionName" @tab-click="handleClick">
      <el-tab-pane name="sub_first" label="记录">
        <table class="table bordered">
          <thead>
            <tr>
              <th>款号</th>
              <th>类别</th>
              <th>颜色</th>
              <th>尺码</th>
              <th>SKU</th>
              <th>关联款号</th>
              <th>出库类型</th>
              <th>操作人</th>
              <th>操作时间</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="index">
              <td>{{item.code.substr(2, 5)}}</td>
              <td>{{categories[item.code.substr(3, 2)]}}</td>
              <td>{{colors[item.code.substr(7, 2)]}}</td>
              <td>{{sizes[item.code.substr(9, 1)]}}</td>
              <td>{{item.code}}</td>
              <td>{{item.clothes_stock.rela_style}}</td>
              <td>{{backTypes[item.sub_type]}}</td>
              <td>{{item.creator}}</td>
              <td>{{item.occur_date}}</td>
            </tr>
          </tbody>
        </table>
        <div class="clearfix mar-top">
          <el-pagination class="pull-right"
            @current-change="changePage"
            layout="total, prev, pager, next"
            :total="totalItem"
            :current-page="currentPage"
            :page-size="pageSize">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane name="sub_second" label="统计">
        <div :style="{height: boxHeight+'px', overflow: 'auto'}">
          <table class="table bordered">
            <thead>
              <tr>
                <th>款号</th>
                <th>关联款号</th>
                <th>类别</th>
                <th>颜色</th>
                <th>图片</th>
                <th>XS</th>
                <th>S</th>
                <th>M</th>
                <th>L</th>
                <th>XL</th>
                <th class="text-right" width="60">合计出库</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(totalList, code) in totalData">
                <tr v-for="(item, index) in totalList" :key="`${code}_${index}`">
                  <td :rowspan="totalList.length" v-if="index == 0">{{String(item.code).substr(0, 5)}}
                    [{{countMap[String(item.code).substr(0, 5)]}}]</td>
                  <td :rowspan="totalList.length" v-if="index == 0">{{item.rela_style}}</td>
                  <td :rowspan="totalList.length" v-if="index == 0">{{categories[String(item.code).substr(1, 2)]}}</td>
                  <td>{{item.color}}</td>
                  <td>
                    <el-popover v-if="item.imgs"
                      placement="right"
                      trigger="hover">
                      <img :src="showImgUrl">
                      <img slot="reference" @mouseover="showItemImg(item, false)" @click="showItemImg(item, true)"
                        :src="'data:image/jpg/png/gif;base64,' + item.imgs.simg" alt="">
                    </el-popover>
                  </td>
                  <td class="text-info">{{item.size1 > 0 ? item.size1 : '-'}}</td>
                  <td class="text-info">{{item.size2 > 0 ? item.size2 : '-'}}</td>
                  <td class="text-info">{{item.size3 > 0 ? item.size3 : '-'}}</td>
                  <td class="text-info">{{item.size4 > 0 ? item.size4 : '-'}}</td>
                  <td class="text-info">{{item.size5 > 0 ? item.size5 : '-'}}</td>
                  <td class="text-info">{{item.total > 0 ? item.total : '-'}}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      subActionName: 'sub_first',
      queryForm: {
        kh: '',
        rela_style: '',
        color: '',
        size: '',
        start_date: '',
        end_date: '',
        out_type: '',
      },
      list: [],
      queryParams: {},
      totalData: [],
      countMap: {},
      showImgUrl: '',
      excelUrl: '',
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      backTypes: {
        // 8: '退货返库',
        // 7: '取样返库',
        // 9: '分销返库',
        // 10: '其他返库',
        '-1': '淘宝返库',
        '-4': '天猫返库',
        '-5': '抖音2返库',
        '-51': '抖音2返库',
        '-52': '视频号返库',
        '-3': '分销返库',
        '-2': '取样返库',
        '-0': '其他返库',
      },
    };
  },
  props: {
    boxHeight: {
      type: [Number, String],
    },
    colors: {
      type: [Array, Object],
    },
    sizes: {
      type: [Array, Object],
    },
    seasons: {
      type: [Array, Object],
    },
    categories: {
      type: [Array, Object],
    },
    outTypes: {
      type: [Array, Object],
    },
    relaStyles: {
      type: [Array, Object],
    },
    khs: {
      type: [Array, Object],
    },
  },
  created() {
    this.init();
  },
  methods: {
    handleClick() {
      if (this.activeName === 'second') {
        // this.$refs.log.showTab();
      }
    },
    init() {
      // this.boxHeight = document.documentElement.clientHeight - 580;
      // if (this.$store.state.common.clothesDicts.sizes) {
      //   this.sizes = this.$store.state.common.clothesDicts.sizes;
      //   this.seasons = this.$store.state.common.clothesDicts.seasons;
      //   this.categories = this.$store.state.common.clothesDicts.categories;
      //   this.colors = this.$store.state.common.clothesDicts.colors;
      // } else {
      //   this.$store.dispatch('GET_COLTHES_DICTS').then((resp) => {
      //     this.sizes = resp.data.sizes;
      //     this.seasons = resp.data.seasons;
      //     this.categories = resp.data.categories;
      //     this.colors = resp.data.colors;
      //   });
      // }
    },
    onSubmitQuery() {
      this.queryParams = {};
      if (this.queryForm.rela_style && this.queryForm.rela_style.trim()) {
        this.queryParams.rela_style = this.queryForm.rela_style;
      }
      if (this.queryForm.kh && this.queryForm.kh.trim()) {
        this.queryParams.kh = this.queryForm.kh;
      }
      if (this.queryForm.color && this.queryForm.color.trim()) {
        this.queryParams.color = this.queryForm.color;
      }
      if (this.queryForm.size !== null) {
        this.queryParams.size = this.queryForm.size;
      }
      if (this.queryForm.start_date && this.queryForm.start_date.trim()) {
        this.queryParams.start_date = this.queryForm.start_date;
      }
      if (this.queryForm.end_date && this.queryForm.end_date.trim()) {
        this.queryParams.end_date = this.queryForm.end_date;
      }
      if (this.queryForm.outType) {
        this.queryParams.sub_type = this.queryForm.outType;
      }
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
      this.getTotal();
    },
    getList() {
      this.axios.get('clothes-log', { params: { ...this.queryParams, m_type: 2, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          this.list = list;
          this.totalItem = response.data.total;
        }, (err) => {
          this.$utils.resp(err);
        });
    },
    getTotal() {
      this.axios.get('clothes-log-tj', { params: { ...this.queryParams, m_type: 2 } }).then((resp) => {
        const temps = resp.data;
        const countMap = {};
        const keys = Object.keys(temps);
        keys.forEach((key) => {
          const arr = temps[key];
          let keyTotal = 0;
          arr.forEach((item) => {
            keyTotal += item.total ? item.total : 0;
          });
          countMap[key] = keyTotal;
        });
        this.countMap = countMap;
        this.totalData = resp.data;
      });
    },
    excel() {
      this.$http.get('clothes-log-excel', { params: { ...this.queryParams, m_type: 2 }, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        this.excelUrl = URL.createObjectURL(blob);
        this.$nextTick(() => {
          this.$refs.downloadHref.click();
        });
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 显示图片
    showItemImg(item, isFullScrean = false) {
      this.showImg(item.imgs, isFullScrean);
    },
    showImg(file, isFullScrean) {
      const params = {
        hint: 'clothes',
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.showImgUrl = objurl;
        if (isFullScrean) {
          this.$store.commit('SET_BIG_IMG_URL', objurl);
          this.$store.commit('PICKER_SHOW_SHOW_BIG_IMG');
        }
      }, (err) => {
        this.$utils.resp(err);
      });
    },
  },
};
</script>

<style>

</style>
