<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="款号编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.category" clearable placeholder="分类选择" filterable>
          <el-option v-for="(label, key) in categories" :key="key" :value="key" :label="`[${key}]${label}`"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.color_num" clearable placeholder="颜色分类" filterable>
          <el-option v-for="(item) in colors" :key="item.code" :value="item.code" :label="`[${item.code}]${item.name}`"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.size" clearable placeholder="尺码" filterable>
          <el-option v-for="(item) in sizes" :key="item" :value="item" :label="item"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.order_by" clearable placeholder="库存排序" filterable>
          <el-option value="stock_num|asc" label="库存升序"></el-option>
          <el-option value="stock_num|desc" label="库存降序"></el-option>
          <el-option value="lack_num|asc" label="库存缺少降序"></el-option>
          <el-option value="lack_num|desc" label="库存缺少升序"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status" clearable>
          <el-option value="1" label="在售"></el-option>
          <el-option value="5" label="下架"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>款号</th>
          <th>编号</th>
          <th>类别</th>
          <th>细类</th>
          <th>图片</th>
          <th>颜色</th>
          <th>尺码</th>
          <th>价格</th>
          <th>预警库存</th>
          <th>库存</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.code.substr(0, 5) }}</td>
          <td>{{ item.code }}</td>
          <td>{{ item.category }}{{item.category_text}}</td>
          <td>{{ item.sub_category}}</td>
          <td>
            <el-popover v-if="item.imgs.length === 1"
              placement="right"
              trigger="hover">
              <img :src="showImgUrl">
              <img slot="reference" @mouseover="showItemImg(item, false)" @click="showItemImg(item, true)"
                :src="'data:image/jpg/png/gif;base64,' + item.imgs[0].simg" alt="">
            </el-popover>
          </td>
          <td>[{{item.color_num}}{{colorMap[item.color_num]}}] {{item.color}}</td>
          <td>{{item.size}}{{ item.size_sub }}</td>
          <td>{{item.price}}</td>
          <td>{{item.warn_num}}</td>
          <td>{{item.stock_num ? item.stock_num : 0}}</td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" title="修改信息" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button>
              <el-button type="warning" size="small" title="打印" @click="toPrint(item)" icon="fa fa-print"></el-button>
              <el-button type="info" size="small" title="复制" @click="toCopy(item)" icon="fa fa-copy"></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'" :close-on-click-modal="false">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="编号" prop="code">
          <el-input v-model="editModel.code" @blur="codeChange"></el-input>
        </el-form-item>
        <el-form-item label="年分" prop="year">
          <el-input v-model="editModel.year" @blur="changeCat"></el-input>
        </el-form-item>
        <el-form-item label="主分类" prop="category">
          <el-select v-model="editModel.category" @change="changeCat" clearable placeholder="分类选择" filterable>
            <el-option v-for="(label, key) in categories" :key="key" :value="key" :label="`[${key}]${label}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品类" prop="sub_category">
          <el-select v-model="editModel.sub_category" clearable placeholder="分类选择" filterable>
            <el-option v-for="(label, index) in subCategories" :key="index" :value="label" :label="label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="颜色分类" prop="color_num">
          <el-select v-model="editModel.color_num" @change="changeCat" clearable placeholder="颜色分类" filterable>
            <el-option v-for="(item) in colors" :key="item.code" :value="item.code" :label="`[${item.code}]${item.name}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="尺码" prop="size">
          <el-select v-model="editModel.size" @change="changeCat" clearable placeholder="尺码" filterable>
            <el-option v-for="(item) in sizes" :key="item" :value="item" :label="item"></el-option>
          </el-select>
          <el-checkbox-group v-if="!editModel.id" v-model="eidtSizes">
            <el-checkbox v-for="(item) in sizes" :disabled="eEidtSizes.indexOf(item) !== -1" :key="item" :label="item"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="尺码备注" prop="size_sub">
          <el-input v-model="editModel.size_sub"></el-input>
        </el-form-item>
        <el-form-item label="执行标准" prop="axbz">
          <el-select v-model="editModel.zxbz" allow-create filterable>
            <el-option v-for="item in bzs" :key="item.id" :value="item.value" :label="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安全技术等级" prop="aqjsdj">
          <el-select v-model="editModel.aqjsdj" allow-create filterable>
            <el-option v-for="item in djs" :key="item.id" :value="item.value" :label="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="具体颜色" prop="color">
          <el-input v-model="editModel.color"></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input type="number" v-model="editModel.price"></el-input>
        </el-form-item>
        <el-form-item label="预警库存" prop="warn_num">
          <el-input type="number" v-model="editModel.warn_num"></el-input>
        </el-form-item>
        <el-form-item label="特别关注" prop="ismark">
          <el-switch
            v-model="editModel.ismark" active-value="1" :inactive-value="null"
            active-color="#13ce66"
            inactive-color="#ddd">
          </el-switch>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="editModel.status">
            <el-option value="1" label="在售"></el-option>
            <el-option value="5" label="下架"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model.number="editModel.remark" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="附件">
          <span v-if="files.length > 0">{{files[0].name}}</span>
          <span v-else>
            <span v-if="editModel.imgs">
              <a href="#" v-for="(item) in editModel.imgs" :key="item.name" @click.prevent="download(item)">{{item.orgName}}</a>
              <!-- {{editModel.imgs.length > 0 ? editModel.imgs[0].orgName : '' }} -->
            </span>
          </span>
          <file-uploader ref="addFile"
            accept="."
            @change="changeFile"
            :showPreview="false"
            :showFileHint="false">
          </file-uploader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="save">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true" :disabled="waiting"></i> 确 定</el-button>
        <el-button v-if="editModel.id" type="danger" @click="delIt(editModel.id)" class="pull-left" disabled>
          <i class="fa fa-fw fa-trash" aria-hidden="true"></i> 删 除</el-button>

      </div>
    </el-dialog>
    <!-- 加入打印  -->
    <el-dialog :visible.sync="toPrintDialog" title="打印数量设置">
      <el-form :model="toAddObj" label-width="100px">
        <el-form-item label="编号：">
          {{toAddObj.code}}
        </el-form-item>
        <el-form-item label="打印数量：" prop="count">
          <el-input v-model="toAddObj.count" type="number"></el-input>
        </el-form-item>
        <el-form-item label="工厂：" prop="supplier">
          <el-select v-model="toAddObj.supplier" clearable>
            <el-option v-for="(item) in suppliers" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="toPrintDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="printList(toAddObj)">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>
    <!-- 文件上传临时用的 -->
    <el-form ref="tempForm" v-show="false"></el-form>
    <a ref="downloadLink" :href="downloadUrl" :download="downloadFileName" v-show="false"></a>
  </el-card>
</template>

<script>
import { mapState } from 'vuex';
import FileUploader from '@/components/FileUpload';

export default {
  components: {
    FileUploader,
  },
  data() {
    return {
      toPrintDialog: false,
      ws: null,
      msgCode: null,
      printSuccessNum: 0, // 已打印份数
      isWsOpen: false, // 打印机websocket是否连接成功
      printerUrl: 'ws://localhost:8989',
      toAddObj: {}, // 标签打印对象
      waiting: false,
      loading: false,
      colors: [],
      sizes: [],
      categories: {},
      subCategories: [],
      // 查询条件form
      queryForm: { status: '1' },
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      eidtSizes: [],
      eEidtSizes: [], // 已经有的尺寸
      suppliers: [],
      editDialog: false, // dialog显示标志 true 显示
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
        code: [{ required: true, message: '填写编号', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      // imgfile
      downloadFileName: '',
      downloadUrl: '',
      files: [],
      showImgUrl: '',
      bzs: [],
      djs: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    colorMap() {
      const map = {};
      this.colors.forEach((item) => {
        map[item.code] = item.name;
      });
      return map;
    },
  },
  created() {
    this.init();
    this.onSubmitQuery();
  },
  methods: {
    init() {
      if (this.$store.state.common.clothesDicts.sizes) {
        this.sizes = this.$store.state.common.clothesDicts.sizes;
        this.categories = this.$store.state.common.clothesDicts.categories;
        this.subCategories = this.$store.state.common.clothesDicts.sub_categories;
        this.colors = this.$store.state.common.clothesDicts.colors;
      } else {
        this.$store.dispatch('GET_COLTHES_DICTS').then((resp) => {
          this.sizes = resp.data.sizes;
          this.categories = resp.data.categories;
          this.subCategories = resp.data.sub_categories;
          this.colors = resp.data.colors;
        });
      }
      this.getSuppliers();
      this.getDicts();
    },
    getDicts() {
      this.axios.get('dicts').then((r) => {
        this.bzs = r.data.zxbz;
        this.djs = r.data.aqjsdj;
      });
    },
    // 加工厂信息
    getSuppliers() {
      this.axios.get('clothes-supplier', { params: { all: 1 } }).then((r) => {
        this.suppliers = r.data;
      });
    },
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$http.get('e-clothes', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          this.loading = false;
          this.list = list;
          this.list = response.data.data;
          this.totalItem = response.data.total;
        }, (err) => {
          this.loading = false;
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.editModel = {
        code: '',
        year: String(new Date().getFullYear()),
        color_num: '',
        category: '',
        color: '',
        size: '',
        price: null,
        warn_num: null,
        ismark: '0',
        remark: '',
      };
      this.eidtSizes = [];
      this.eEidtSizes = [];
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 复制
    toCopy(item) {
      this.editModel = {
        ...item, id: null,
      };
      this.eidtSizes = [];
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
          this.checkCode(item.code);
        }
      });
    },
    // 打开编辑
    toEdit(editObj) {
      this.editModel = { ...editObj };
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 选中上传文件时
    changeFile(files) {
      if (this.$refs.addFile.sizeWarning) {
        this.$utils.msg('文件过大', 'warning');
        return;
      }
      if (files.length > 0) {
        this.files = [];
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
      }
    },
    // 下载图片
    download(file) {
      const params = {
        hint: 'e_clothes',
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.downloadFileName = file.orgName;
        this.downloadUrl = objurl;
        this.$nextTick(() => {
          this.$refs.downloadLink.click();
        });
        this.getList();
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          let obj = { ...this.editModel };
          // 上传文件
          let formData = null;
          if (this.$refs.addFile.identifyBorwser() && this.files.length > 0) {
            formData = new window.FormData(this.$refs.tempForm.$el);
            formData.append('hint', 'clothes');
            this.files.forEach((ele) => {
              formData.append('file[]', ele);
            });
          }
          if (formData) {
            this.$utils.formDataAdd(formData, obj);
            obj = formData;
          }
          this.waiting = true;
          if (this.editModel.id) {
            // 文件需要post上传，所以修改时也用post
            this.$http.post(`e-clothes-update/${this.editModel.id}`, obj).then(() => {
              this.editDialog = false;
              this.getList();
              this.$message({
                type: 'success',
                message: '更新成功',
                showClose: true,
                duration: 2000,
              });
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          } else {
            obj.sizes = this.eidtSizes.join(',');
            this.$http.post('e-clothes', obj).then(() => {
              this.editDialog = false;
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
              this.getList();
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
    // 修改编号联动
    codeChange() {
      const { code } = this.editModel;
      if (code.length > 8 && code.length < 11) {
        this.checkCode();
        // category
        const ccode = code.substr(0, 1);
        const catcodes = Object.keys(this.categories);
        if (catcodes.indexOf(ccode) !== -1) {
          this.editModel.category = ccode;
        }
        // year
        this.editModel.year = '20' + code.substr(1, 2);
        // color_num
        const colorNum = code.substr(5, 3);
        const colorCodes = this.colors.map((item) => item.code);
        if (colorCodes.indexOf(colorNum) !== -1) {
          this.editModel.color_num = colorNum;
        }
        // size
        const size = code.substr(8);
        if (this.sizes.indexOf(size) !== -1) {
          this.editModel.size = size;
        }
      }
    },
    changeCat() {
      const tcode = this.editModel.code;
      if (tcode.length > 8 && tcode.length < 11) {
        let code = this.editModel.category;
        let y = this.editModel.year;
        y = y.substr(y.length - 2);
        code += y;
        code += tcode.substr(3, 2);
        code += this.editModel.color_num;
        code += this.editModel.size;
        this.editModel.code = code;
        this.checkCode();
      }
    },
    // 显示图片
    showItemImg(item, isFullScrean = false) {
      this.showImg(item.imgs[0], isFullScrean);
    },
    showImg(file, isFullScrean) {
      const params = {
        hint: 'e_clothes',
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.showImgUrl = objurl;
        if (isFullScrean) {
          this.$store.commit('SET_BIG_IMG_URL', objurl);
          this.$store.commit('PICKER_SHOW_SHOW_BIG_IMG');
        }
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // 打印初始化
    toPrint(item) {
      this.toAddObj = { ...item, count: 1, supplier: '' };
      this.toPrintDialog = true;
    },
    initPrint() {
      return new Promise((resolve, reject) => {
        if ('WebSocket' in window) {
          this.ws = new window.WebSocket(this.printerUrl);
        } else if ('MozWebSocket' in window) {
          this.ws = new window.MozWebSocket(this.printerUrl);
        } else {
          this.$notify.error({
            title: '错误',
            message: '当前浏览器不支持html5的WebSocket，因此无法连接打印机',
          });
        }
        this.ws.onopen = () => {
          resolve('connected');
        };
        this.ws.onerror = () => {
          this.ws = null;
          this.$notify.error({
            title: '错误',
            message: '打印服务链接异常',
          });
          reject(new Error('无法链接打印机'));
        };
        this.ws.onmessage = (res) => {
          if (res.data && res.data.length > 10) {
            this.printSuccessNum++;
          } else {
            this.msgCode = res.data;
          }
        };
      });
    },
    // 开始打印
    printList(item) {
      this.printSuccessNum = 0;
      if (!this.isWsOpen) {
        this.initPrint().then(() => {
          this.isWsOpen = true;
          setTimeout(() => {
            this.print(item);
          }, 1000);
        });
      } else {
        this.print(item);
      }
    },
    print(item) {
      if (item.count === 0) {
        return;
      }
      const { ws } = this;
      const supplier = item.supplier ? item.supplier : '';
      const sn = item.code.substr(0, 5);
      const lineCode = `${item.code}${supplier}`;
      let cm = item.size;
      if (item.size_sub) {
        cm = `${item.size} ${item.size_sub}`;
      } else if (sn === 'T2204' || sn === 'T2102') {
        const map = {
          S: '（160/84A）',
          M: '（165/88A）',
          L: '（170/92A）',
        };
        const chima = map[cm] ? map[cm] : '';
        cm = `${item.size}${chima}`;
      }
      // 执行标准
      let bz = item.zxbz;
      if (!bz) {
        bz = 'GB/T 22853-2019';
      }
      // 安全技术等级
      let dj = item.aqjsdj;
      if (!dj) {
        dj = 'GB18401-2010 B类';
      }
      ws.send(`${item.sub_category}|${sn}|${cm}|${item.color}|${lineCode}|${item.price}||${bz}|${dj}`);
      setTimeout(() => {
        item.count--;
        this.print(item);
      }, 10);
    },
    checkCode(code) {
      let c = code;
      if (!code) {
        c = this.editModel.code;
      }
      const ecode = encodeURIComponent(c);
      this.$http.get(`e-check-code/${ecode}`).then((resp) => {
        const list = resp.data;
        const sizes = [];
        list.forEach((item) => {
          sizes.push(item.size);
          if (item.imgs && item.imgs.length > 0) {
            this.editModel.imgs = item.imgs;
          }
        });
        this.eEidtSizes = sizes;
      });
    },
  },
};
</script>
