<template>
  <el-card class="components">
    <el-button type="primary" size="small" v-if="canAdd"
      @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-date-picker v-model="queryForm.begin_date" placeholder="下单开始日期"
          style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
      </div>
      -
      <div class="box-inline standard-search">
        <el-date-picker v-model="queryForm.end_date" placeholder="下单结束日期"
          style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>下单日期</th>
          <th>部门</th>
          <th>客户</th>
          <th class="text-right">金额(万)</th>
          <th>币种</th>
          <th>交货日期</th>
          <th>标题</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.order_at }}</td>
          <td>{{ item.dept }}                                                           </td>
          <td>{{ item.customer }}                                                           </td>
          <td class="text-right">{{ item.amount }}</td>
          <td>{{ item.currency_code }}</td>
          <td>{{ item.delivery_date }}</td>
          <td>{{ item.title }}</td>
          <td>
            <div class="text-nowrap">
              <!-- <el-button type="danger" :disabled="waiting" @click="delIt(item)">删除</el-button> -->
              <el-button type="warning" :disabled="waiting" @click="toEdit(item)">修改</el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>
    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" width="90%" :title="editModel.id ? '编辑' : '新增'"
      :close-on-click-modal="false">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item v-if="userDept && userDept._rgt-userDept._lft === 1" label="部门" prop="dept">
          <el-input readonly :value="userDept.name"></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="editModel.title"></el-input>
        </el-form-item>
        <el-form-item label="下单日期" prop="order_at">
          <el-date-picker v-model="editModel.order_at"
            style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="交货日期" prop="delivery_date">
          <el-date-picker v-model="editModel.delivery_date"
            style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="客户" prop="customer">
          <el-select v-model="editModel.customer" style="width: 100%"
            filterable allow-create clearable remote :remote-method="queryCustomer">
            <el-option v-for="item in customerList" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="16">
            <el-form-item label="金额（万）" prop="amount">
              <el-input type="number" v-model="editModel.amount"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="币种" prop="currency_code">
              <el-select v-model="editModel.currency_code">
                <el-option v-for="item in currencyList" :key="item.code"
                  :label="item.code" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="save">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true" :disabled="waiting"></i> 确 定</el-button>
        <el-button v-if="editModel.id" type="danger" @click="delIt(editModel.id)" class="pull-left" :disabled="waiting">
          <i class="fa fa-fw fa-trash" aria-hidden="true"></i> 删 除</el-button>

      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      waiting: false,
      // 查询
      queryForm: {
        begin_date: null,
        end_date: null,
      },
      queryParams: {},
      list: [],
      // 编辑
      editDialog: false,
      editModel: {
        order_at: null,
        currency_code: null,
        amount: null,
        customer: null,
        delivery_date: null,
      },
      rules: {
        order_at: [{ required: true, message: '选择日期', trigger: ['change', 'blur'] }],
        delivery_date: [{ required: true, message: '选择交货项目', trigger: ['change', 'blur'] }],
        customer: [{ required: true, message: '选择客户', trigger: ['change'] }],
        amount: [{ required: true, message: '请填写金额', trigger: ['change', 'blur'] }],
        currency_code: [{ required: true, message: '请选择币种', trigger: ['change', 'blur'] }],
      },
      customerList: [], // 客户
      // 分页
      currentCode: null,
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      userMeta: state => state.auth.userMeta,
      currencyList: state => state.common.currencyList,
      erpTree: state => state.common.erpTree,
    }),
    ...mapGetters([
      'deptMap',
    ]),
    canAdd() {
      let result = false;
      if (this.user.allPerms && this.user.allPerms.indexOf('statistic_order') > -1) {
        result = true;
      }
      return result;
    },
    // 当前用户部门
    userDept() {
      let result = {};
      if (this.user && this.user.department_id) {
        result = this.deptMap[this.user.department_id];
      }
      return result;
    },
    // 当前用户的部门id数组
    deptIds() {
      const result = [];
      if (this.canAdd && this.userDept.id && this.deptMap[this.userDept.id]) {
        let dept = this.deptMap[this.userDept.id];
        result.unshift(dept.id);
        while (dept.parent_id) {
          result.unshift(dept.id);
          dept = this.deptMap[dept.parent_id];
        }
      }
      return result;
    },
  },
  beforeCreate() {
    if (this.$store.state.common.depts.length === 0) {
      this.$store.dispatch('GET_DEPTS');
    }
  },
  created() {
    this.onSubmitQuery();
  },
  methods: {
    onSubmitQuery() {
      if (!this.user.id) {
        return;
      }
      const params = {};
      if (this.queryForm.begin_date) {
        params.begin_date = this.queryForm.begin_date;
      }
      if (this.queryForm.end_date) {
        params.end_date = this.queryForm.end_date;
      }
      this.queryParams = params;
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      const params = this.queryParams;
      params.pageSize = this.pageSize;
      params.page = this.currentPage;
      this.loading = true;
      this.$http.get('statistic/order', { params }).then((resp) => {
        this.loading = false;
        const list = resp.data.data;
        this.list = list;
        this.totalItem = resp.data.total;
      }, (err) => {
        this.loading = false;
        this.$utils.resp(err);
      });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toAdd() {
      this.editModel = {
        order_at: this.$utils.dateFormat(),
        amount: null,
        currency_code: 'USD',
        customer: '',
        delivery_date: null,
      };
      this.editDialog = true;
      if (this.$refs.editForm) {
        this.$nextTick(() => {
          this.$refs.editForm.clearValidate();
        });
      }
    },
    queryCustomer(q) {
      const params = {};
      if (q && q.trim()) {
        params.q = q;
      }
      this.$http.get('customer', { params }).then((res) => {
        this.customerList = res.data.data;
      });
    },
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = this.editModel;
          obj.dept = this.userDept.name;
          obj.department_ids = this.deptIds.join(',');
          if (obj.id) {
            this.waiting = true;
            this.$http.put(`statistic/order/${obj.id}`, obj).then(() => {
              this.getList();
              this.editDialog = false;
              this.waiting = false;
            }, (err) => {
              this.waiting = false;
              this.$utils.resp(err);
            });
          } else {
            this.waiting = true;
            this.$http.post('statistic/order', obj).then(() => {
              this.changePage(1);
              this.editDialog = false;
              this.waiting = false;
            }, (err) => {
              this.waiting = false;
              this.$utils.resp(err);
            });
          }
        }
      });
    },
    toEdit(item) {
      const obj = { ...item };
      this.editModel = obj;
      this.editDialog = true;
      if (this.$refs.editForm) {
        this.$nextTick(() => {
          this.$refs.editForm.clearValidate();
        });
      }
    },
    delIt(obj) {
      let delId = obj;
      if (typeof obj === 'object') {
        delId = obj.id;
      }
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.delete(`statistic/order/${delId}`).then(() => {
          this.getList();
          this.waiting = false;
          this.editDialog = false;
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    chunk(arr, n) {
      const marr = [];
      for (let i = 2; i < arr.length; i++) {
        const index = Math.floor((i - 2) / n);
        if (typeof marr[index] === 'object') {
          marr[index].push(arr[i]);
        } else {
          marr[index] = [arr[i]];
        }
      }
      return marr;
    },
    chunk2(arr, n) {
      const marr = [];
      let arr1 = [];
      for (let i = 2; i < arr.length; i++) {
        const cur = arr[i];
        if (arr1.length < n) {
          arr1.push(cur);
        } else {
          marr.push(arr1);
          arr1 = [cur];
        }
        if (i === arr.length - 1) {
          marr.push(arr1);
        }
      }
      return marr;
    },
  },
};
</script>
