<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>
    <a ref="downloadLink" :href="downloadUrl" :download="downloadFileName" v-show="false">隐藏的下载文件链接</a>
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="打样编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status">
          <el-option v-for="(val, key) in searchStatuses" :key="key" :value="key" :label="val"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>编号</th>
          <th>款号</th>
          <th>衣服类型</th>
          <th>样品类别</th>
          <th>部门</th>
          <th>打样数量</th>
          <th>排样衣日期</th>
          <th>预计完成日期</th>
          <th>状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody v-loading="loading">
        <tr v-for="(item, index) in sampleList" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td><a href="#" @click.prevent="toDetail(item)">{{ item.code }}</a></td>
          <!-- <td>{{ item.name }}</td> -->
          <td>{{ item.style }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.category }}</td>
          <td>{{ item.dept }}</td>
          <td>{{ item.num }}</td>
          <td>{{ item.plan_date }}</td>
          <td>{{ item.finished_date }}</td>
          <td>{{ searchStatuses[item.status] }}</td>
          <td>
            <div class="text-nowrap">
              <a href="#" @click.prevent="toQrPage(item)">二维码</a>
              <el-button size="small" title="修改信息" @click="toEdit(item)" icon="fa fa-fw fa-pencil"
                v-if="item.status != '6'"></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="sampleForm.id ? '编辑打样' : '新增打样'" top="20px"
      :close-on-click-modal="false">
      <el-form :model="sampleForm" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="打样编号" prop="code">
          <el-input v-model="sampleForm.code"></el-input>
        </el-form-item>
        <!-- <el-form-item label="部门" prop="dept">
          <el-input v-model="sampleForm.dept"></el-input>
        </el-form-item> -->
        <el-form-item label="部门" prop="dept">
          <el-cascader style="width: 100%;" :show-all-levels="false"
            expand-trigger="hover"
            :options="tree"
            :props="{label: 'name', value: 'id'}"
            v-model="selectedDept"
            @change="chooseDept"></el-cascader>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="款号" prop="style">
              <el-input v-model="sampleForm.style"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量" prop="num">
              <el-input v-model="sampleForm.num"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="样品类别" prop="category">
              <el-select v-model="sampleForm.category" clearable style="width: 100%" allow-create filterable>
                <el-option v-for="item in categoryList" :value="item" :key="item" :label="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="衣服类型" prop="type">
              <el-input v-model="sampleForm.type"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="排样日期" prop="plan_date">
              <el-date-picker v-model="sampleForm.plan_date" style="width: 100%" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预计完成日期" prop="finished_date">
              <el-date-picker v-model="sampleForm.finished_date" style="width: 100%" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="业务员" prop="contact">
              <el-input v-model="sampleForm.contact"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="contact_phone">
              <el-input v-model="sampleForm.contact_phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="sampleForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="附件">
          <div>
            <div v-if="sampleForm.attachments && sampleForm.attachments.length !== 0">
              <div class="text-semibold">原文件</div>
              <div class="file-list" v-for="(item, index) in sampleForm.attachments" :key="item.name">
                <span class="pull-right cur-pointer del-btn" @click="delFileOne(index)" style="width: 25px;" title="删除附件">
                <i class="fa fa-times" aria-hidden="true"></i></span>
                <span class="text-overflow" :title="item.name" style="margin-right: 25px;">
                  <a href="#" @click.prevent="download(item)">{{item.orgName}}</a></span>
              </div>
            </div>
            <div v-if="files.length !== 0">
              <div class="text-semibold">待上传的文件</div>
              <div class="file-list" v-for="(item, index) in files" :key="index">
                <span class="pull-right cur-pointer del-btn" @click="delUfile(index)" style="width: 25px;" title="删除附件">
                  <i class="fa fa-times" aria-hidden="true"></i></span>
                <span class="text-overflow" :title="item.name" style="margin-right: 25px;">{{ item.name }}</span>
              </div>
            </div>
          </div>
          <!-- <span v-if="files.length > 0">{{files[0].name}}</span>
          <span v-else>
          </span> -->
          <file-uploader ref="addFile"
            accept="."
            :fileSize="8"
            @change="changeFile"
            :showPreview="false"
            :showFileHint="false">
          </file-uploader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="sampleForm.id && sampleForm.creator_id == user.id && sampleForm.status != '6'"
          :disabled="waiting" title="删除" type="danger" @click="delsample(sampleForm.id)"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button @click="editDialog = false" :disabled="waiting">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="savesample" :disabled="waiting">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="detailDialog" title="打样详情" width="80%">
      <el-form :model="detail" label-width="120px" class="detail-form">
        <el-row>
          <el-col :span="12">
            <el-form-item label="二维码：">
              <a href="#" @click.prevent="toQrPage(detail)">显示</a>
              <el-button icon="fa fa-download fa-fw" @click="downloadQr(detail)"></el-button>
              <vue-qr ref="qrImg" v-show="false" :logoSrc="logoUrl" logoBackgroundColor="#fff" :logoMargin="5"
                :correctLevel="3" :text="detail.id|qrUrl" :margin="6" :dotScale="1"></vue-qr>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="当前状态：">{{searchStatuses[detail.status]}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="打样编号：">{{detail.code}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门：">{{detail.dept}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="款号：">{{detail.style}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量：">{{detail.num}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="样品类型：">{{detail.category}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="衣服类型：">{{detail.type}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人：">{{detail.contact}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话：">{{detail.contact_phone}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排样日期：">{{detail.plan_date}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预计完成日期：">{{detail.finished_date}}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="附件：">
              <div v-if="detail.attachments">
                <div v-for="(item) in detail.attachments" :key="item.name" >
                  <a href="#" @click.prevent="download(item)">{{item.orgName}}</a>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注说明：">{{detail.remark}}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="打样进度：">
              <table v-if="detailItems.length > 0" class="table">
                <thead>
                  <tr>
                    <th>操作</th>
                    <th>操作人</th>
                    <th>联系电话</th>
                    <th>备注</th>
                    <th>处理时间</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in detailItems" :key="item.id">
                    <td>{{item.step}}</td>
                    <td>{{item.user}}</td>
                    <td>{{item.phone}}</td>
                    <td>{{item.remark}}</td>
                    <td>{{item.status == '1' ? item.created_at : item.updated_at}}</td>
                  </tr>
                </tbody>
              </table>
              <span v-else></span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="detail.creator_id == user.id && detail.status != '6'"
          :disabled="waiting" title="删除" type="danger" @click="delsample(detail.id)"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button @click="detailDialog = false" :disabled="waiting">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button v-if="canFinish(detail)"
          :disabled="waiting" title="完 结" type="warning" @click="finish">完 结</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
/* eslint-disable import/extensions */
import VueQr from 'vue-qr';
import { mapState, mapGetters } from 'vuex';
// eslint-disable-next-line import/no-unresolved
import FileUploader from '@/components/FileUpload';

export default {
  components: {
    VueQr,
    FileUploader,
  },
  beforeCreate() {
    if (this.$store.state.common.depts.length === 0) {
      this.$store.dispatch('GET_DEPTS');
    }
  },
  data() {
    return {
      logoUrl: window.baseUrl + 'favicon.ico',
      loading: false,
      waiting: false,
      // 查询条件form
      queryForm: {
        code: '',
        status: '',
      },
      searchStatuses: {
        1: '未确认',
        2: '已确认',
        6: '已结束',
      },
      selectedDept: [],
      queryParams: {}, // 分页查询参数
      sampleList: [], // 查询展示的列表对象
      sampleForm: {}, // 管理的操作时对象
      editDialog: false, // message dialog显示标志 true 显示
      detailDialog: false,
      detail: { items: [] },
      files: [],
      downloadFileName: '',
      downloadUrl: '',
      // 编辑form的条件
      rules: {
        code: [{ required: true, message: '填写打样编号', trigger: ['change', 'blur'] }],
      },
      categoryList: [], // 样品类别
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapState({
      depts: state => state.common.depts,
      erpTree: state => state.common.erpTree,
    }),
    ...mapGetters([
      'deptMap',
    ]),
    detailItems() {
      let result = [];
      if (this.detail && Array.isArray(this.detail.items)) {
        result = this.detail.items;
      }
      return result;
    },
    user() {
      return this.$store.state.auth.user;
    },
    tree() {
      let result = [];
      if (this.erpTree && this.erpTree.length > 0) {
        const companylist = this.erpTree[0].children;
        if (companylist.length > 0) {
          result = companylist.filter(item => item.id === 19211);
        }
      }
      return result;
    },
  },
  created() {
    this.getDict();
    this.onSubmitQuery();
  },

  methods: {
    chooseDept(ids) {
      this.sampleForm.department_id = ids.length > 0 ? ids[ids.length - 1] : '';
      this.sampleForm.dept = this.deptMap[this.sampleForm.department_id].name;
    },
    getDict() {
      this.axios.get('sfdy-dict').then((resp) => {
        this.categoryList = resp.data.category;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    onSubmitQuery() {
      this.queryParams = {};
      if (this.queryForm.code && this.queryForm.code.trim()) {
        this.queryParams.code = this.queryForm.code;
      }
      if (this.queryForm.status) {
        this.queryParams.status = this.queryForm.status;
      }
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.axios.get('sfdy', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.loading = false;
          this.sampleList = response.data.data;
          this.totalItem = response.data.total;
        }, (response) => {
          this.loading = false;
          this.$utils.resp(response);
        });
    },
    toEdit(editObj) {
      this.axios.get(`sfdy/${editObj.id}`)
        .then((response) => {
          this.sampleForm = response.data;
          this.files = [];
          const selectedDept = [];
          if (editObj.department_id) {
            const id = editObj.department_id;
            let dept = this.deptMap[id];
            while (dept) {
              selectedDept.unshift(dept.id);
              dept = this.deptMap[dept.parent_id];
              if (dept.id === 19177) {
                dept = null;
              }
            }
          }
          this.selectedDept = selectedDept;
          if (this.$refs.addFile) {
            this.$refs.addFile.reset();
          }
          this.editDialog = true;
        }, (err) => {
          this.$utils.resp(err);
        });
      // 初始化部门选择框
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toDetail(sample) {
      this.axios.get(`sfdy/${sample.id}`)
        .then((response) => {
          this.detail = response.data;
          this.detailDialog = true;
        }, (response) => {
          this.$utils.resp(response);
        });
    },
    toAdd() {
      // 清空数据
      this.selectedDept = [];
      this.files = [];
      const sample = {
        code: '',
        dept: '',
        department_id: null,
        name: '',
        style: '',
        num: '',
        type: '',
        category: '',
        contact: '',
        contact_phone: '',
        plan_date: null,
        finished_date: null,
        remark: '',
      };
      if (this.$refs.addFile) {
        this.$refs.addFile.reset();
      }
      this.sampleForm = sample;
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    // 修改新增的保存
    savesample() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          if (this.$refs.addFile.identifyBorwser() && this.files.length > 0) {
            const formData = new window.FormData(this.$refs.editForm.$el);
            formData.append('hint', 'dy');
            this.files.forEach((ele) => {
              formData.append('file[]', ele);
            });
            this.saveSubmit(formData);
            // this.waiting = true;
            // this.$http.post('transfile', formData).then((resp) => {
            //   this.editModel.addFiles = resp.data;
            //   this.saveSubmit();
            // }, (err) => {
            //   this.editModel.addFiles = [];
            //   this.waiting = false;
            //   this.$utils.resp(err);
            // });
          } else {
            this.saveSubmit();
          }
        }
      });
    },
    saveSubmit(formData = null) {
      const model = { ...this.sampleForm };
      let tempForm = model;
      if (formData) {
        this.$utils.formDataAdd(formData, tempForm);
        tempForm = formData;
      }
      this.waiting = true;
      if (this.sampleForm.id) {
        this.waiting = true;
        this.$http.post(`sfdy/${this.sampleForm.id}`, tempForm).then(() => {
          this.editDialog = false;
          this.waiting = false;
          this.getList();
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      } else {
        this.waiting = true;
        this.$http.post('sfdy', tempForm).then(() => {
          this.editDialog = false;
          this.waiting = false;
          this.changePage(1);
        }, (resp) => {
          this.$utils.resp(resp);
          this.waiting = false;
        });
      }
    },
    delsample(id) {
      let sampleId = id;
      if (!sampleId) {
        sampleId = this.sampleForm.id;
      }
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.delete(`sfdy/${sampleId}`).then(() => {
          this.getList();
          this.waiting = false;
          this.editDialog = false;
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    canFinish(obj) {
      return Boolean(obj.creator_id === this.user.id && obj.status !== '6' && obj.items.length > 0);
    },
    finish(obj) {
      this.$confirm('是否完结归档？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.put(`sfdy/${this.detail.id}`, { ...obj, status: '6' }).then(() => {
          this.getList();
          this.$message.success('已完结 ' + obj.code);
          this.waiting = false;
          this.detailDialog = false;
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    // 删除原附件
    delFileOne(index) {
      this.sampleForm.attachments.splice(index, 1);
    },
    // 删除未上传的文件
    delUfile(index) {
      this.files.splice(index, 1);
    },
    // 选中上传文件时
    changeFile(files) {
      if (this.$refs.addFile.sizeWarning) {
        this.$utils.msg('文件过大，请一次上传小于8M的文件', 'warning');
        return;
      }
      if (files.length > 0) {
        // this.files = []; // 附件只能有一个时加上这一行
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
      }
    },
    download(file) {
      const params = {
        hint: 'dy',
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.downloadFileName = file.orgName;
        this.downloadUrl = objurl;
        this.$nextTick(() => {
          this.$refs.downloadLink.click();
        });
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    toQrPage(sample) {
      const route = this.$router.resolve({
        name: 'DySampleQr',
        query: {
          id: sample.id,
          code: sample.code,
        },
      });
      window.open(route.href, '_blank');
    },
    downloadQr(sample) {
      this.downloadFileName = sample.code;
      this.downloadUrl = this.$refs.qrImg.$el.src;
      this.$nextTick(() => {
        this.$refs.downloadLink.click();
      });
    },
  },
  filters: {
    // 二维码
    qrUrl(code) {
      return `https://www.guotaigh.com.cn/wb-app/#/dysample?id=${code}`;
    },
  },
};
</script>

<style lang="scss" scope>
.detail-form ::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 10px;
}
</style>
