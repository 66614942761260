<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新角色</el-button>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.q" clearable placeholder="输入关键字检索" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>名称</th>
          <th>编号</th>
          <th>描述</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.code }}</td>
          <td><div class="text-overflow">{{ item.remark }}</div></td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" title="修改信息" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'" :close-on-click-modal="false">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="名称" prop="name">
          <el-input v-model="editModel.name"></el-input>
        </el-form-item>
        <el-form-item label="编号" prop="code">
          <el-input v-model="editModel.code"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="editModel.remark"></el-input>
        </el-form-item>
        <div style="font-size: 14px;padding: 10px;">角色权限选择：</div>
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" v-for="(item) in allPerms" :key="item.id">
            <el-checkbox v-model="selectedBox[item.id]" :label="item.name"></el-checkbox>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="save">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true" :disabled="waiting"></i> 确 定</el-button>
        <el-button v-if="editModel.id" type="danger" @click="delIt" class="pull-left" :disabled="waiting">
          <i class="fa fa-fw fa-trash" aria-hidden="true"></i> 删 除</el-button>

      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import * as _forOwn from 'lodash/forOwn';

export default {
  data() {
    return {
      waiting: false,
      allPerms: [],
      selectedBox: {},
      // 查询条件form
      queryForm: {
        q: '',
      },
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
        code: [{ required: true, message: '填写编号', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
  },
  created() {
    this.onSubmitQuery();
    this.getPerms();
  },
  methods: {
    getPerms() {
      this.axios.get('allperms').then((resp) => {
        this.allPerms = resp.data;
      });
    },
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.$http.get('role', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.list = response.data.data;
          this.totalItem = response.data.total;
        }, (err) => {
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.editModel = {
        name: '',
        code: '',
        remark: '',
      };
      this.editDialog = true;
      this.initSelectedBox();
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 打开编辑
    toEdit(editObj) {
      this.axios.get(`role/${editObj.id}`).then((resp) => {
        const hasPromiseIds = [];
        resp.data.promises.forEach((item) => {
          hasPromiseIds.push(item.id);
        });
        this.initSelectedBox(hasPromiseIds);
        this.editModel = resp.data;
        this.editDialog = true;
      });
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 初始化权限选择框
    initSelectedBox(selectedArr) {
      const obj = {};
      this.allPerms.forEach((item) => {
        if (selectedArr && selectedArr.indexOf(item.id) !== -1) {
          obj[item.id] = true;
        } else {
          obj[item.id] = false;
        }
      });
      this.selectedBox = obj;
    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = {
            id: this.editModel.id,
            name: this.editModel.name,
            code: this.editModel.code,
            remark: this.editModel.remark,
          };
          obj.proIds = [];
          _forOwn(this.selectedBox, (val, key) => {
            if (val) {
              obj.proIds.push(key);
            }
          });

          this.waiting = true;
          if (obj.id) {
            this.$http.put(`role/${obj.id}`, obj).then(() => {
              this.editDialog = false;
              this.getList();
              this.$message({
                type: 'success',
                message: '更新成功',
                showClose: true,
                duration: 2000,
              });
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          } else {
            this.$http.post('role', obj).then(() => {
              this.editDialog = false;
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
              this.getList();
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
    delIt() {
      this.$confirm('删除后无法恢复，是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`role/${this.editModel.id}`).then(() => {
          this.getList();
          this.editDialog = false;
          this.$message({
            type: 'success',
            message: '删除成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.$utils.resp(resp);
        });
      }, () => {});
    },
  },
};
</script>
