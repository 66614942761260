<template>
  <el-dialog :visible="visible" :close-on-click-modal="false" @update:visible="close">
    <div slot="title">{{title}}</div>
    <div>
      <div>
        <el-form :inline="true">
          <el-form-item>
            <el-select v-model="category" clearable>
              <el-option v-for="(i, v) in categories" :key="v" :value="v" :label="v"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="q" clearable placeholder="输入名称检索"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="overflow: auto;height: 400px;">
        <table class="table">
          <body>
            <tr v-for="item in list" :key="item.id">
              <td>
                <el-checkbox v-model="item.selected">
                  {{item.name}}{{item.name1}} [{{item.sn}}] <span style="font-size: 12px;color: #bbb">{{item.spec}}</span>
                </el-checkbox>
              </td>
            </tr>
          </body>
        </table>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
      <el-button type="primary" @click="selected">
        <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      goods: [],
      category: '',
      q: '',
    };
  },
  computed: {
    // isSpecialUser() {
    //   let r = false;
    //   if (Array.isArray(this.$store.state.auth.user.allPerms) && this.$store.state.auth.user.allPerms.indexOf('goods_manager') !== -1) {
    //     r = true;
    //   }
    //   return r;
    // },
    categories() {
      const r = {};
      this.goods.forEach((item) => {
        if (item.category) {
          r[item.category] = 1;
        }
      });
      return r;
    },
    list() {
      let r = this.goods;
      if (this.category) {
        r = r.filter(item => item.category === this.category);
      }
      if (this.q.trim()) {
        const k = this.q.trim();
        r = r.filter((item) => {
          let has = false;
          if (item.name.indexOf(k) !== -1 || item.name1.indexOf(k) !== -1) {
            has = true;
          }
          if (item.selected) {
            has = true;
          }
          return has;
        });
      }
      return r;
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.init();
      }
    },
  },
  props: {
    title: {
      type: String,
      default: '物品',
    },
    factory_id: {
      type: Number,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init() {
      this.goods = [];
      this.q = '';
      const params = {
        all: 1,
      };
      if (this.factory_id) {
        params.own_factory_id = this.factory_id;
      }
      this.category = null;
      this.axios.get('owfstock', { params }).then((r) => {
        const goods = r.data;
        goods.forEach((item) => {
          item.selected = false;
        });
        this.goods = goods;
      });
    },
    close() {
      this.$emit('update:visible', false);
    },
    selected() {
      const l = this.goods.filter(item => item.selected);
      const selectedGoods = [];
      l.forEach((i) => {
        selectedGoods.push({ ...i });
      });
      this.$emit('selected', selectedGoods);
      this.close();
    },
  },
};
</script>

<style>

</style>
