<template>
  <div>
    <el-input v-model="theName">
      <el-button slot="append" icon="el-icon-search" @click="toSelect"></el-button>
    </el-input>
    <el-dialog :visible.sync="dialogShow" :title="title"
      :modal-append-to-body='false' :append-to-body="true">
      <el-card>
        <el-row :gutter="5">
          <el-col :span="9">
            <div class="title">部门</div>
            <div class="box">
              <el-tree :data="deptTree" :props="defaultProps"
                :expand-on-click-node="false"
                @node-click="handleNodeClick"></el-tree>
            </div>
          </el-col>
          <el-col :span="7">
            <div class="title">选择人员</div>
            <div class="box">
              <div>
                <div v-if="leftDept.length > 0" class="l-item" @click="addToRightItem(leftDept[0], 1)">
                  [{{leftDept[0].type}}.{{leftDept[0].id}}]{{leftDept[0].name}}
                </div>
                <div class="l-item" @click="addToRightItem(user)"
                  v-for="user in leftUsers" :key="user.id" :value="user">
                  {{user.name}}
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="1">
            <div>
              <el-button @click="rightList=[]">清空</el-button>
            </div>
            <div>
              <el-button @click="allLeftToRight">全选</el-button>
            </div>
          </el-col>
          <el-col :span="7">
            <div class="title">已选择</div>
            <div class="box">
              <div class="item cur-pointer" v-for="(item, index) in rightList" :key="item.id"
                @click="delRightItem(index)">
                {{item.showName}}
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <div class="pull-left">
          <el-input v-model="nameQ" @keydown.enter.native="filterUsers" placeholder="用户名检索">
            <el-button @click="filterUsers" slot="append" type="primary">查询</el-button>
          </el-input>
        </div>
        <el-button @click="dialogShow = false" icon="fa fa-fw fa-times">关 闭</el-button>
        <el-button @click="getDeptUsers" icon="icon-check">刷新数据</el-button>
        <el-button @click="selected" icon="icon-check">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */

function getChildren(obj) {
  const arr = [];
  if (obj.children && Array.isArray(obj.children)) {
    for (let i = 0; i < obj.children.length; i++) {
      const item = obj.children[i];
      arr.push({ id: item.id, name: item.name });
      const list = getChildren(item);
      list.forEach((i) => {
        arr.push(i);
      });
    }
  }
  return arr;
}
function flatTree(tree) {
  let obj = tree;
  if (Array.isArray(tree)) {
    obj = {
      children: tree,
    };
  }
  return getChildren(obj);
}
export default {
  data() {
    return {
      dialogShow: false,
      deptTree: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      leftDept: [],
      users: [],
      leftUsers: [],
      nameQ: '',
      theValue: '',
      theName: '',
      emptyValue: '',
      rightList: [],
    };
  },
  props: {
    title: {
      type: String,
      default: '选择人员',
    },
    value: {
      required: true,
    },
  },
  computed: {
    rightIds() {
      return this.rightList.map(item => item.id);
    },
    deptMap() {
      const map = {};
      const arr = flatTree(this.deptTree);
      arr.forEach((item) => {
        map[item.id] = item;
      });
      return map;
    },
    userMap() {
      const map = {};
      this.users.forEach((item) => {
        map[item.id] = item;
      });
      return map;
    },
  },
  created() {
    if (this.$store.state.common.users) {
      this.deptTree = this.$store.state.common.deptTree;
      this.users = this.$store.state.common.deptUsers;
      this.initData();
    } else {
      this.getDeptUsers().then(() => {
        this.initData();
      });
    }
  },
  watch: {
  // el-select 的 v-model 值
    theValue(val) {
      if (this.emptyValue !== '' && val === '') {
        this.$emit('input', this.emptyValue);
        this.$emit('change', this.emptyValue);
      } else {
        this.$emit('input', val);
        this.$emit('change', val);
      }
    },
    value(val) {
      if (this.emptyValue !== val) {
        this.theValue = val;
      } else {
        this.theValue = '';
      }
    },
  },
  methods: {
    initData() {
      if (this.value) {
        const ids = this.value.split(',');
        const arr = [];
        ids.forEach((item) => {
          if (item.slice(0, 1) === 'd') {
            const dept = this.deptMap[item.substr(1)];
            arr.push({
              id: item,
              name: dept.name,
              showName: `[部门.${dept.id}]${dept.name}`,
            });
          }
          if (item.slice(0, 1) === 'u') {
            const user = this.userMap[item.substr(1)];
            arr.push({
              id: item,
              name: user.name,
              showName: user.name,
            });
          }
        });
        this.rightList = arr;
        this.selected();
      }
    },
    getDeptUsers() {
      return this.$store.dispatch('GET_DEPT_USERS').then(() => {
        this.users = this.$store.state.common.deptUsers;
        this.deptTree = this.$store.state.common.deptTree;
      });
    },
    toSelect() {
      this.initData();
      this.dialogShow = true;
    },
    // 点击部门树的部门
    handleNodeClick(node) {
      this.leftDept = [{ ...node, type: '部门' }];
      this.leftUsers = this.getUserByDept(node);
    },
    filterUsers() {
      this.leftDept = [];
      this.leftUsers = this.users.filter((item) => {
        let r = false;
        if (item.name.indexOf(this.nameQ) !== -1) {
          r = true;
        }
        return r;
      });
    },
    getUserByDept(node) {
      // const $list = this.users.filter((item) => {
      //   return item.dept_lft === 310;
      // });
      // return $list;
      return this.users.filter((item) => {
        let result = false;
        if (item.dept_lft >= node._lft && item.dept_lft <= node._rgt) {
          result = true;
        }
        return result;
      });
    },
    addToRightItem(item, type = 2) {
      if (type === 1) {
        if (this.rightIds.indexOf('d' + item.id) === -1) {
          this.rightList.push({
            id: 'd' + item.id,
            showName: `[部门.${item.id}]${item.name}`,
            name: item.name,
          });
        }
      }
      if (type === 2) {
        if (this.rightIds.indexOf('u' + item.id) === -1) {
          this.rightList.push({
            id: 'u' + item.id,
            name: item.name,
            showName: item.name,
          });
        }
      }
    },
    delRightItem(index) {
      this.rightList.splice(index, 1);
    },
    allLeftToRight() {
      this.addToRightItem(this.leftDept[0], 1);
      this.leftUsers.forEach((item) => {
        this.addToRightItem(item);
      });
    },
    // 选中内容提交
    selected() {
      this.theName = this.rightList.map(item => item.name).join(',');
      this.theValue = this.rightList.map(item => item.id).join(',');
      this.$emit('selected', this.rightList);
      this.dialogShow = false;
    },
    getNames() {
      return this.theName;
    },
  },
};
</script>

<style lang="scss" scoped>
  .title {
    color: #007ae1;
    padding-left: 20px;
  }
  .box {
    overflow: auto;
    padding: 10px 20px 10px 10px;
    height: 350px;
    border: 1px solid #ddd;
  }
  .l-item {
    border-bottom: 1px solid #ddd;
    padding: 5px;
    margin: 0 0 4px;
    cursor: pointer;
  }
</style>
