const printMix = {
  data() {
    return {
      ws: null,
      printerUrl: 'ws://localhost:2012',
      printErrMsg: 'abc',
    };
  },
  methods: {
    connectPrinter() {
      if ('WebSocket' in window) {
        this.ws = new window.WebSocket(this.printerUrl);
      } else if ('MozWebSocket' in window) {
        this.ws = new window.MozWebSocket(this.printerUrl);
      } else {
        this.$notify.error({
          title: '错误',
          message: '当前浏览器不支持html5的WebSocket，因此无法连接打印机',
        });
      }
      this.ws.onopen = () => {};
      this.ws.onclose = () => {
      };
      this.ws.onerror = (err) => {
        console.log(err);
        this.ws = null;
        this.$notify.error({
          title: '错误',
          message: '无法连接到打印机',
        });
      };
      this.ws.onmessage = (receiveMsg) => {
        if (receiveMsg.data.split('|')[0] === 'B_GetPrinterStatus') {
          const ret = receiveMsg.data.split('|')[1];
          switch (ret) {
            case '1':
              this.printErrMsg = '1:打印命令错误！';
              break;
            case '2':
              this.printErrMsg = '2:USB端口开启失败';
              break;
            case '3':
              this.printErrMsg = '3:条码格式错误！';
              break;
            case '4':
              this.printErrMsg = '4:内存溢出！';
              break;
            case '5':
              this.printErrMsg = '5:碳带用完或安装错误！';
              break;
            case '6':
              this.printErrMsg = '6:串口通信异常！';
              break;
            case '7':
              this.printErrMsg = '7:纸张/碳带用完！';
              break;
            case '9':
              this.printErrMsg = '9:未取得返回值';
              break;
            case '12':
              this.printErrMsg = '12:打印机暂停！';
              break;
            case '0':
              this.printErrMsg = '0:等待列印!';
              break;
            case '50':
              this.printErrMsg = '50:打印机忙碌！';
              break;
            default:
              this.printErrMsg = '未定义打印消息';
          }
        }
      };
    },
    printTag(asset) {
      const temp = {
        id: 1,
        name: '1',
        spec: '2',
        sn: '3',
      };
      if (!asset) {
        asset = temp;
      }
      const { ws } = this;
      ws.send('B_EnumUSB');
      ws.send('B_CreateUSBPort|1');
      // ws.send('B_Draw_Box|23|10|5|700|410');
      ws.send(`B_Bar2d_QR|30|18|4|8|M|A|0|0|0|https://www.guotaigh.com.cn/asset-show/info?id=${asset.id}`);
      if (asset.name.length > 7) {
        const s1 = asset.name.substr(0, 7);
        const s2 = asset.name.substr(7);
        ws.send(`B_Prn_Text_TrueType|340|30|45|宋体|1|500|0|0|0|NA|${s1}`);
        ws.send(`B_Prn_Text_TrueType|340|100|45|宋体|1|500|0|0|0|NA2|${s2}`);
      } else {
        ws.send(`B_Prn_Text_TrueType|340|30|45|宋体|1|500|0|0|0|NA|${asset.name}`);
      }
      ws.send(`B_Prn_Text_TrueType|340|180|45|宋体|1|500|0|0|0|SP|${asset.spec}`);
      ws.send(`B_Prn_Text_TrueType|50|310|60|Arial|1|500|0|0|0|CC|${asset.sn}`);
      // ws.send(`B_Prn_Text_TrueType|50|300|30|30|宋体|1|500|0|0|0|CC|${asset.sn}`);
      // ws.send('B_Bar2d_QR|25|12|4|8|M|A|0|0|0|https://www.guotaigh.com.cn/asset-show/info?id=1233231');
      // ws.send('B_Prn_Text_TrueType|340|30|50|宋体|1|500|0|0|0|CC|笔记本');
      // ws.send('B_Prn_Text_TrueType|340|180|40|宋体|1|500|0|0|0|CC|e480');
      // ws.send('B_Prn_Text_TrueType|50|300|60|宋体|1|500|0|0|0|CC|001212312312');
      // ws.send('B_Draw_Line|E|450|120|4|380');
      // ws.send('B_Bar2d_QR|20|200|2|6|M|A|0|0|0|test1234566test1234566test1234566');
      // ws.send('B_Prn_Barcode|50|210|0|1E|2|6|13|B|Data246u6j');
      // 演示从网路服务器下载图片并且动态打印的功能
      // ws.send('DownloadFile|ftp://www.argox.net/download/CSA.bmp|aaa.bmp');
      // ws.send('B_Get_Graphic_ColorBMP|600|10|aaa.bmp');
      ws.send('B_Print_Out|1');
      ws.send('B_ClosePrn');
    },
    getPrinterStatus() {
      const { ws } = this;
      ws.send('B_EnumUSB');
      ws.send('B_CreateUSBPort|1');
      ws.send('B_GetPrinterStatus');
      ws.send('B_ClosePrn');
    },
  },
};
const DUMMY = '';

export {
  printMix,
  DUMMY,
};
