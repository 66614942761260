<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.q" clearable placeholder="输入关键字检索" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status" clearable placeholder="选择" @change="onSubmitQuery">
          <el-option label="全部" value=""></el-option>
          <el-option v-for="(val, key) in statusList" :label="val" :key="key" :value="key"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>项目标题</th>
          <th>项目号</th>
          <th>项目开始日期</th>
          <th width="200">说明</th>
          <th>状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.title }}</td>
          <td><a href="#" @click.prevent="toOrders(item.code)">{{ item.code }}</a></td>
          <td>{{ item.create_date }}</td>
          <td :title="item.remark"><span style="width: 200px;" class="text-overflow">{{ item.remark }}</span></td>
          <td><span :class="[item.status === '1' ? 'text-primary' : '']">{{ item.status_text }}</span></td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" type="primary" title="修改信息" @click="toEdit(item)">
                <i class="fa fa-fw fa-pencil"></i></el-button>
              <el-button style="margin-left: 3px;" size="small" v-if="item.status == 1" type="warning" title="完结项目"
                @click="finish(item)">
                <i class="fa fa-fw fa-check"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'" :close-on-click-modal="false">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="标题" prop="title">
          <el-input v-model="editModel.title"></el-input>
        </el-form-item>
        <el-form-item label="项目编号" prop="code">
          <el-input v-model="editModel.code" placeholder="不填编号自动生成"></el-input>
        </el-form-item>
        <el-form-item label="开始日期" prop="create_date">
          <el-date-picker v-model="editModel.create_date"
            style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="客户" prop="customer">
          <el-input v-model="editModel.customer"></el-input>
        </el-form-item>
        <el-form-item label="客户国家" prop="country">
          <el-input v-model="editModel.country"></el-input>
        </el-form-item>
        <el-form-item label="项目金额" prop="amount">
          <el-input v-model="editModel.amount"></el-input>
        </el-form-item>
        <el-form-item label="预计损耗率" prop="expect_rate">
          <el-input v-model="editModel.expect_rate"></el-input>
        </el-form-item>
        <el-form-item label="结余" prop="rest">
          <el-input v-model="editModel.rest"></el-input>
        </el-form-item>
        <el-form-item label="实际损耗率" prop="wastage_rate">
          <el-input v-model="editModel.wastage_rate"></el-input>
        </el-form-item>
        <!-- <el-form-item label="预计结余" prop="expect_rest">
          <el-input v-model="editModel.expect_rest"></el-input>
        </el-form-item> -->
        <el-form-item label="备注说明" prop="remark">
          <el-input type="textarea" v-model="editModel.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="save" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 确 定</el-button>
        <el-button v-if="editModel.id" type="danger" @click="delIt" class="pull-left" :disabled="waiting">
          <i class="fa fa-fw fa-trash" aria-hidden="true"></i> 删 除</el-button>

      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'wb-project',
  data() {
    return {
      loading: false,
      waiting: false,
      // 查询条件form
      queryForm: {
        q: '',
        status: '1',
      },
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      rules: {
        title: [{ required: true, message: '填写标题', trigger: ['change', 'blur'] }],
        code: [{ required: true, message: '填写订单号', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      // branches: [],
      statusList: { 1: '进行中', 2: '已完成' },
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    isDeliveryManager() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('delivery_manager') !== -1) {
        result = true;
      }
      return result;
    },
  },
  created() {
    // this.$http.get('branches').then((resp) => {
    //   this.branches = resp.data;
    // }, (err) => {
    //   this.$utils.resp(err);
    // });
    this.onSubmitQuery();
  },
  methods: {
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$http.get('dely-project', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.loading = false;
          this.list = response.data.data;
          this.totalItem = response.data.total;
        }, (err) => {
          this.loading = false;
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.editModel = {
        title: '',
        code: '',
        create_date: this.$utils.FormatDate(),
        customer: '',
        country: '',
        amount: '',
        remark: '',
        status: 1,
      };
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 打开编辑
    toEdit(editObj) {
      const wastageObj = editObj.wastages ? editObj.wastages : {};
      const tempObj = { ...editObj, ...wastageObj };
      this.editModel = tempObj;
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    toOrders(code) {
      this.$router.push({ name: 'DelyOrder', query: { code } });
    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.editModel };
          this.waiting = true;
          if (obj.id) {
            this.$http.put(`dely-project/${obj.id}`, obj).then(() => {
              this.editDialog = false;
              this.getList();
              this.$message({
                type: 'success',
                message: '更新成功',
                showClose: true,
                duration: 2000,
              });
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          } else {
            this.$http.post('dely-project', obj).then(() => {
              this.editDialog = false;
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
              this.getList();
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
    finish(item) {
      this.$confirm('是否确认完成？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.put(`dely-project/${item.id}/change`, { status: '2' }).then(() => {
          this.getList();
          this.$message.success('已完成订单');
        }, (err) => {
          this.$utils.resp(err);
        });
      }, () => {});
    },
    delIt() {
      this.$confirm('删除后无法恢复，是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.delete(`dely-project/${this.editModel.id}`).then(() => {
          this.getList();
          this.editDialog = false;
          this.waiting = false;
          this.$message({
            type: 'success',
            message: '删除成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
  },
};
</script>
