<template>
<el-row class="header">
  <div class="logo-container">
    <!-- <img class="logo-img" src="../../assets/logo.png" alt=""> -->
    &nbsp;<span class="logo-name">国华管理平台</span>
  </div>
  <!-- <el-breadcrumb separator="/" v-if="authorized">
    <el-breadcrumb-item :to="{ name: 'Index' }"><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
    <el-breadcrumb-item v-for="item in breadcrumbList" v-if="item.title" :key="item.title"
      :to="item.location ? item.location : null">{{item.title}}</el-breadcrumb-item>
  </el-breadcrumb> -->
    <!-- <div class="pull-right" v-loading.fullscreen.lock="themeLoading">
      <label>主题切换</label>
      <el-select @change="changeTheme" v-model="themeKey">
        <el-option v-for="(theme, index) in themes"
          :key="index" :value="theme.key" :label="theme.label"></el-option>
      </el-select>
    </div> -->
  <div v-if="companyType" class="pull-left" style="margin-left: 10px;">
    <label>当前公司：</label>
    <el-select v-model="companyType">
      <el-option v-for="(label, val) in companies"
        :key="val" :value="val" :label="label"></el-option>
    </el-select>
  </div>
  <el-popover v-if="authorized" ref="profile" placement="bottom-end" width="220"
    :visible-arrow="false" popper-class="popover-format" v-model="profileVisible">
    <div @click="profileVisible = false">
      <div class="list-group-item text-center pad-all bord-btm" style="padding-top: 10px;">
        设置
      </div>
      <div class="list-group-item pad-all cur-pointer bord-btm" @click="editPwd">
        <i class="fa fa-fw fa-key mar-rgt"></i>修改密码</div>
      <div v-if="user.can_init" @click="initUser"
        class="list-group-item pad-all cur-pointer bord-btm">
        <i class="fa fa-fw fa-user-o mar-rgt"></i>初始化用户</div>
      <!-- <div class="list-group-item pad-all cur-pointer bord-btm"><i class="fa fa-fw fa-user-o mar-rgt"></i>用户设置</div> -->
      <div class="list-group-item pad-all clearfix">
        <el-button size="normal" style="width: 100%" type="primary" @click="$store.commit('AUTH_LOGOUT')"> 退 出 </el-button>
      </div>
    </div>
  </el-popover>
  <div v-if="authorized" class="clicker profile" v-popover:profile>
    <i class="fa fa-lg fa-user-o" aria-hidden="true"></i>
    <span class="profile-name">{{user.name}}</span>
  </div>

  <el-popover ref="smgs" placement="bottom-end" width="400" :visible-arrow="false" popper-class="popover-format"
    v-model="smgsVisible">
    <div @click="smgsVisible = false">
      <div class="list-group-item text-center pad-all bord-btm" style="padding-top: 10px;">
        {{hasMsgs ? '' : '没有新的'}}消息
      </div>
      <div v-for="item in msgList" :key="item.id" :title="$utils.curTimeDistance(item.created_at)" @click="toMsgTarget(item)"
        class="list-group-item pad-all cur-pointer bord-btm text-overflow">
        {{item.data.message}}
      </div>
    </div>
  </el-popover>
  <div class="pull-right cur-pointer top-icon-pop" v-popover:smgs>
    <el-badge  class="badge-danger badge-bg"
      :hidden="!hasMsgs" :value="msgList.length" :max="10">
      <span :class="{'text-primary': hasMsgs}" title="消息">
        <i class="fa fa-fw fa-bell"></i>
      </span>
    </el-badge>
  </div>
</el-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      profileVisible: false, // 右上角下拉内容显示标志
      smgsVisible: false, // bell下拉内容显示标志
      themes: [
        {
          key: 'default',
          label: '默认主题',
        }, {
          key: 'black',
          label: '主题1',
        },
      ],
      themeLoading: false,
      // 实际环境通过store 获取
      company: null,
      themeKey: 'black',
      chunked: false,
      companies: {
        1: '国泰国华',
        2: '上海豪鼎',
      },
    };
  },
  props: {
    authorized: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    // this.changeTheme('default');
    // 更新通知
    // setInterval(() => {
    //   this.$store.dispatch('AUTH_GET_NOTIFICATIONS');
    // }, 60000);
  },
  computed: {
    ...mapState({
      // breadcrumbList: state => state.auth.breadcrumbList,
      user: state => state.auth.user,
      msgList: state => state.auth.msgList,
      curCompanyType: state => state.auth.curCompanyType,
    }),
    hasMsgs() {
      return this.msgList.length > 0;
    },
    companyType: {
      get() {
        return this.$store.state.auth.curCompanyType;
      },
      set(value) {
        this.$store.dispatch('UPDATE_COMPANY_TYPE', value);
      },
    },
  },
  methods: {
    changeTheme(theme) {
      if (theme) {
        this.themeKey = theme;
      }
      this.themeLoading = true;
      const { document } = window;
      const { head } = document;
      const links = document.getElementsByClassName('csslink');
      // 保留最后一个其他的删除掉
      for (let i = 0; i < links.length; i++) {
        if (i === links.length - 1) {
          setTimeout((j => (() => {
            head.removeChild(links[j]);
            this.themeLoading = false;
          }))(i), 1000);
        } else {
          head.removeChild(links[i]);
        }
      }
      if (links.length === 0) {
        this.themeLoading = false;
      }
      // 添加新的样式文件link
      let hrefStr = `/static/css/theme-${this.themeKey}.css?`;
      // let hrefStr = `/static/themes/theme-${this.themeKey}.css?`;
      hrefStr += new Date().getTime();
      // this.$http.put('theme', {theme: this.themeKey});
      const cssRef = document.createElement('link');
      cssRef.setAttribute('rel', 'stylesheet');
      cssRef.setAttribute('type', 'text/css');
      cssRef.setAttribute('class', 'csslink');
      cssRef.setAttribute('href', hrefStr);
      head.appendChild(cssRef);
    },
    toMsgTarget(notification) {
      const typeClass = notification.type;
      if (typeClass.indexOf('OrderMessage') > 0) {
        this.$router.push({
          name: 'DelyOrder',
          query: {
            detail: notification.data.id,
            sn: notification.data.code,
            notification: notification.id,
          },
        });
      } else if (typeClass.indexOf('RecordMessage')) {
        this.$router.push({
          name: 'DelyRecord',
          query: {
            detail: notification.data.id,
            sn: notification.data.code,
            notification2: notification.id,
          },
        });
      }
    },
    editPwd() {
      this.$store.commit('SET_SHOW_EDITSELF', true);
    },
    initUser() {
      this.$store.commit('SET_SHOW_INIT_USER', true);
    },
  },
};
</script>
