<template>
  <el-card class="component">
    <el-row>
      <el-col :span="12">
        <el-form label-width="100px" @submit.native.prevent>
          <el-form-item  label="手动添加">
            <el-input v-model="custCode" @keydown.enter.native="addCustCode(custCode)"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12" class="text-right">
        <el-button type="warning" @click="clearAll" :disabled="addList.length===0">清空</el-button>
        <el-button type="warning" @click="sort" :disabled="addList.length===0">排序</el-button>
        <el-button v-if="addedList.length > 0" type="warning" @click="addList = addedList" :disabled="addList.length>0">刚才数据</el-button>
        <el-button type="primary" @click="inStore" :disabled="addList.length===0">入库</el-button>
        <!-- <el-button type="warning" v-if="typeNum === 1" :disabled="addList.length<2" @click="updateStore">更新</el-button> -->
      </el-col>
    </el-row>
    <table class="table border">
      <thead>
        <tr>
          <th>#</th>
          <th>操作</th>
          <th>次品</th>
          <th>条码</th>
          <th>款号</th>
          <th>款式</th>
          <th>颜色</th>
          <th>尺码</th>
          <th>关联款号</th>
          <th>库存数量</th>
          <th>退货单号</th>
          <th>备注</th>
        </tr>
      </thead>
      <tbody>
        <tr :title="itemStatus(item)" :class="{'is-mul': item.isMul, 'is-store': item.stores > 0, 'is-export': item.export > 0 }"
          v-for="(item, idx) in addList" :key="idx">
          <td>{{idx + 1}}</td>
          <td><el-button type="danger" @click="delItem(idx)" icon="fa fa-trash"></el-button></td>
          <td><el-checkbox v-model="item.iscp"></el-checkbox></td>
          <td>{{item.code}}</td>
          <td>{{item.season}}{{item.category}}{{item.style_num}}</td>
          <td>{{item.category_text}}</td>
          <td>{{item.color_text}}</td>
          <td>{{item.size_text}}</td>
          <td>{{item.rela_style}}</td>
          <td class="text-danger">{{item.stores}}</td>
          <td class="text-warning"><el-input v-model="item.order_num" v-if="item.isBack"></el-input></td>
          <td><el-input v-model="item.check_remark"  v-if="item.isBack"></el-input></td>
        </tr>
      </tbody>
    </table>
    <el-dialog :visible.sync="showDialog" title="详情"
      :close-on-click-modal="false" :close="closeShowDialog">
      <el-row :gutter="10">
        <el-col :span="12">
          <div class="grid-content">
            <label>款号：</label>
            {{showObj.season}}{{showObj.category}}{{showObj.style_num}}【{{showObj.rela_style}}】
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <label>款式：</label>
            {{showObj.category_text}}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <label>颜色：</label>
            {{showObj.color_text}}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <label>尺码：</label>
            {{showObj.size_text}}
          </div>
        </el-col>
      </el-row>
      <div>
        <span class="text-primary" style="font-size: 20px;">{{showObj.code}}</span>
      </div>
      <div>
        <img :src="'data:image/jpg/png/gif;base64,'+showObj.img" alt="">
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      custCode: '',
      code: '',
      scanCode: '',
      lastKeyTime: null,
      addList: [],
      addedList: [],
      showDialog: false,
      showObj: {},
    };
  },
  created() {
    window.document.addEventListener('keydown', this.keydown);
  },
  computed: {
    typeNum() {
      let count = 0;
      const map = {};
      this.addList.forEach((item) => {
        const mcode = item.code.substr(0, 10);
        if (!map[mcode]) {
          map[mcode] = 1;
          count++;
        }
      });
      return count;
    },
  },
  methods: {
    keydown(e) {
      const curTime = new Date().getTime();
      const keyCode = e.which;
      if (!this.lastKeyTime) {
        this.lastKeyTime = curTime;
      }
      if ((curTime - this.lastKeyTime) < 40) {
        if (keyCode === 13) {
          this.scanCode = this.code;
          this.addCode(this.code);
        } else {
          this.code += String.fromCharCode(keyCode);
        }
      } else {
        this.code = String.fromCharCode(keyCode);
      }
      this.lastKeyTime = curTime;
    },
    addCustCode(code) {
      this.addCode(code);
    },
    addCode(code) {
      if (code.length === 13) {
        const ecode = encodeURIComponent(code);
        this.$http.get(`clothesbycode/${ecode}`).then((resp) => {
        // this.$http.get('clothesbycode/2131112117').then((resp) => {
          let item = { code };
          if (resp.data.clothes) {
            item = { ...resp.data.clothes, code, isBack: false, order_num: '', check_remark: '' };
          } else {
            this.$message({
              type: 'warning',
              message: '未找到对应款式',
            });
          }
          item.iscp = false; // 次品标志
          if (resp.data.store.length > 0) {
            const list = resp.data.store;
            const stores = list.filter(x => x.status === '2');
            const outs = list.filter(x => x.status === '5');
            item.stores = stores.length ? stores.length : '';
            item.export = outs.length ? outs.length : '';
            item.outType = resp.data.out_type;
            if (['1', '4', '5'].indexOf(item.outType) !== -1) {
              item.isBack = true;
            }
          }
          this.showDialog = true;
          this.showObj = item;
          this.addList.push(item);
          this.showMultiple();
        });
      } else {
        this.$message({
          type: 'error',
          message: `[${code}] 不是正确的13位条码编号`,
        });
      }
    },
    clearAll() {
      if (this.addList.length === 0) {
        return;
      }
      this.$confirm('是否确认清空？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.addList = [];
      }, () => {});
    },
    delItem(index) {
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.addList.splice(index, 1);
        this.showMultiple();
      }, () => {});
    },
    sort() {
      this.showMultiple();
      this.addList = this.addList.sort((a, b) => {
        let result = 0;
        if (a.code > b.code) {
          result = 1;
        } else {
          result = -1;
        }
        return result;
      });
    },
    showMultiple() {
      this.addList.forEach((item) => {
        if (this.addList.filter(x => x.code === item.code).length > 1) {
          this.$set(item, 'isMul', true);
        } else {
          this.$set(item, 'isMul', false);
        }
      });
    },
    inStore() {
      // const list = this.addList.map(item => item.code);
      // 有重复的或者库存已经存在的
      const temp = this.addList.filter((item) => {
        let result = false;
        if (item.isMul) {
          result = true;
        }
        if (item.stores > 0 && !item.iscp) {
          result = true;
        }
        return result;
      });
      if (temp.length > 0) {
        this.$message({
          type: 'warning',
          message: '编号重复无法入库',
          duration: 3000,
          showClose: true,
        });
        return;
      }
      // 退货未填写订单号
      const lackOrderNumList = this.addList.filter((item) => {
        let result = false;
        const orderNum = item.order_num.trim();
        if (item.isBack && !orderNum) {
          result = true;
        }
        return result;
      });
      if (lackOrderNumList.length > 0) {
        this.$message({
          type: 'warning',
          message: '请填写退货单号',
          duration: 3000,
          showClose: true,
        });
        return;
      }
      this.addList.forEach((item) => {
        item.substandard = item.iscp ? '1' : '0';
      });
      this.$http.post('clothes-ins', { list: this.addList }).then((resp) => {
        let duration = 3000;
        let errMessage = '。';
        const result = resp.data;
        let clearList = true;
        if (resp.data.err.length > 0) {
          clearList = false;
          duration = 0;
          errMessage = `；未成功记录： ${result.err} `;
        }
        this.$message({
          type: duration > 0 ? 'success' : 'warning',
          message: `成功入库 ${result.success} 条${errMessage} `,
          duration,
          showClose: true,
        });
        if (clearList) {
          this.addedList = this.addList;
          this.addList = [];
        }
      });
    },
    updateStore() {
      const list = this.addList.map(item => item.code);
      this.$http.put('clothes-check', { list }).then((resp) => {
        const result = resp.data;
        this.$message({
          type: 'success',
          message: `更新入库 ${result.update} 条; 新增入库 ${result.new} 条`,
        });
      });
    },
    itemStatus(item) {
      let result = '';
      if (item.isMul) {
        result = '重复的记录';
      }
      if (item.export > 0) {
        result = '已出库产品，将作返库处理';
      }
      if (item.stores > 0) {
        result = '已经有库存';
      }
      return result;
    },
    closeShowDialog() {

    },
  },
  destroyed() {
    window.document.removeEventListener('keydown', this.keydown);
  },
};
</script>

<style scoped>
.table tr.is-mul, .table tr.is-mul.is-export {
  background: #ffce54;
}
.table tr.is-store {
  background: rgb(235, 111, 80);
}
.table tr.is-export {
  background: rgb(227, 247, 118);
}
</style>
