<template>
  <el-card class="components">
    <el-button type="warning" size="small" :disabled="waiting" v-loading="waiting" @click="updateData()">获取erp用户</el-button>
    <el-button type="warning" size="small" :disabled="waiting" v-loading="waiting" @click="addUsers()">添加所有用户</el-button>

    <div class="box-inline standard-search">
      <el-cascader style="width: 300px;" :options="departments" clearable
          :show-all-levels="false"
          expand-trigger="hover"
          :props="{label: 'name', value: 'id'}"
          change-on-select
          v-model="departmentArr"
          @change="changeDepartment">
        </el-cascader>
    </div>
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.name" clearable placeholder="姓名" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>部门</th>
          <th>登录名</th>
          <th>用户</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in firmList" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{deptMap[item.department_id] ? deptMap[item.department_id].name : ''}}</td>
          <td>{{ item.login_name }}</td>
          <td>{{ item.name }}</td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" title="添加用户" @click="addUser(item)"><i class="fa fa-fw fa-plus"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

  </el-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      waiting: false,
      // 查询条件form
      queryForm: {
        name: '',
        department_id: '',
      },
      departmentArr: [],
      queryParams: {}, // 分页查询参数
      firmList: [], // 管理的列表对象
      firmForm: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      // 编辑form的条件
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
        asset_sn: [{ required: true, message: '填写资产编号', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  beforeCreate() {
    if (this.$store.state.common.depts.length === 0) {
      this.$store.dispatch('GET_DEPTS');
    }
  },
  created() {
    this.onSubmitQuery();
  },
  computed: {
    ...mapState({
      departments: state => state.common.erpTree,
    }),
    ...mapGetters([
      'deptMap', // 分公司级别
    ]),
  },
  methods: {
    onSubmitQuery() {
      this.queryParams = {};
      if (this.queryForm.name && this.queryForm.name.trim()) {
        this.queryParams.name = this.queryForm.name;
      }
      if (this.queryForm.department_id) {
        this.queryParams.department_id = this.queryForm.department_id;
      }
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    // 查询列表数据
    getList() {
      this.axios.get('erpuser', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.firmList = response.data.data;
          this.totalItem = response.data.total;
        }, (response) => {
          this.$utils.resp(response);
        });
    },
    addUsers() {
      this.waiting = true;
      this.axios.post('erpusers').then(() => {
        this.waiting = false;
      }, (err) => {
        this.waiting = false;
        this.$utils.resp(err);
      });
    },
    updateData() {
      this.waiting = true;
      this.axios.put('erpuser-update').then(() => {
        this.$message({
          type: 'success',
          message: '更新完成',
          showClose: true,
          duration: 2000,
        });
        this.changePage(1);
        this.waiting = false;
      }, (err) => {
        this.waiting = false;
        this.$utils.resp(err);
      });
    },
    changeDepartment(arr) {
      if (arr.length > 0) {
        this.queryForm.department_id = arr[arr.length - 1];
      } else {
        this.queryForm.department_id = '';
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    addUser(item) {
      this.axios.post(`erpuser/${item.user_id}`).then(() => {
        this.$message({
          type: 'success',
          message: '添加用户成功',
          showClose: true,
          duration: 2000,
        });
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    closeEditDialog() {
      // this.canChooseBranch = true;
    },
  },
  // filters: {
  //   substr4(value) {
  //     return value.substr(0, 4);
  //   },
  // },
};
</script>
