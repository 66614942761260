<template>
  <div>
    <el-row>
      <el-col :span="15">
        <el-card>
          <div style="padding: 0 20px 20px;">
            <el-button class="pull-right" type="primary" :disabled="waiting" @click="save">保存</el-button>
            <el-button  style="margin-right: 5px;" class="pull-right" type="warning"
              :disabled="waiting" @click="getDepts">重置</el-button>
            <span>部门管理</span>
          </div>
          <div class="dept-tree" v-loading="loading">
            <el-tree ref="tree" :data="depts" node-key="id"
              @node-click="clickNode"
              :expand-on-click-node="false"
              :default-expanded-keys="defaultExpands"
              highlight-current
              draggable :props="treeProps" :render-content="renderContent"></el-tree>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8" :offset="1">
        <el-form>
          <el-form-item label="id：">
            {{curDept.id}}
          </el-form-item>
          <el-form-item label="名称：">
            {{curDept.name}}
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row style="margin-top: 10px;">
      <el-col :span="12">
        <el-card>
          <div style="padding: 0 20px 20px;">
            <span style="margin-right: 10px;">国华部门</span>
            <el-button :disabled="udpateWaiting" type="primary" @click="updateErp">更新erp部门</el-button>
          </div>
          <el-tree :expand-on-click-node="false"
            class="dept-tree" ref="tree" :data="erpTree" node-key="id" :props="treeProps"
            :render-content="renderContent2"></el-tree>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div style="padding: 0 20px 20px;">
            <span style="margin-right: 10px;">豪鼎部门</span>
          </div>
          <el-tree :expand-on-click-node="false"
            class="dept-tree" ref="tree" :data="hdErpTree" node-key="id" :props="treeProps"
            :render-content="renderContent3"></el-tree>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="editDialog" :title="isAdd ? '新增' : '编辑'" :rules="rules">
      <el-form :model="editModel" label-width="100px" @submit.native.prevent>
        <el-form-item label="部门名称" prop="name">
          <el-input v-model="editModel.name" @keyup.native.enter="saveAddNode" placeholder="新部门名称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="saveAddNode">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
        <!-- <el-button v-else type="primary" @click="editNode">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button> -->
      </div>
    </el-dialog>

    <el-dialog :visible.sync="addUserDialog" title="新增用户">
      <el-form :model="addUser" label-width="100px" :rules="uRules">
        <el-form-item label="登录名" prop="login_name">
          <el-input v-model="addUser.login_name" @keyup.native.enter="saveAddNode" placeholder="新部门名称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addUserDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="saveUser">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      waiting: false,
      udpateWaiting: false,
      depts: [],
      curDept: {},
      defaultExpands: [1],
      treeProps: {
        label: 'name',
        children: 'children',
      },
      isAdd: false,
      editDialog: false,
      editModel: {},
      rules: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      },
      addUserDialog: false,
      addUser: {},
      uRules: {
        login_name: [{ required: true, message: '登录名不能为空', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapState({
      // depts: state => state.common.depts,
      erpTree: state => state.common.erpTree,
      hdErpTree: state => state.common.hdErpTree,
    }),
    // erpDept() {
    //   return this.$store.state.common.erpTree;
    // },
  },
  created() {
    this.getDepts();
    this.$store.dispatch('GET_DEPTS');
  },
  methods: {
    getDepts() {
      this.loading = true;
      this.axios.get('deptTree').then((resp) => {
        this.loading = false;
        this.depts = resp.data;
        this.defaultExpands = [this.depts[0].id];
      }, (err) => {
        this.loading = false;
        this.$utils.resp(err);
      });
    },
    updateErp() {
      this.udpateWaiting = true;
      this.$http.get('erp-dept').then(() => {
        this.udpateWaiting = false;
        this.$store.dispatch('GET_DEPTS');
        this.$success('部门已经更新');
      }, () => {
        this.udpateWaiting = false;
      });
    },
    toEdit(data) {
      this.isAdd = false;
      this.editModel = { ...data, org: data };
      this.editDialog = true;
    },
    editNode() {
      this.editModel.org.name = this.editModel.name;
      this.editDialog = false;
    },
    toAppend(data) {
      this.isAdd = true;
      this.editModel = {
        name: '',
        parent: data,
      };
      this.editDialog = true;
    },
    toAppend2(data) {
      this.addUser = {
        login_name: '',
        department_id: data.id,
        name: data.name,
      };
      this.addUserDialog = true;
    },
    toAppend3(data) {
      this.addUser = {
        login_name: '',
        department_id: data.id,
        name: data.name,
      };
      this.addUserDialog = true;
    },
    saveUser() {
      const params = {
        ...this.addUser,
        role: 'order_statistic_manager',
      };
      this.$http.post('dept-user', params).then(() => {
        this.$utils.msg('保存成功', 'success');
        this.addUserDialog = false;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    appendNode() {
      const { parent } = this.editModel;
      const addObj = {
        name: this.editModel.name,
      };
      if (!parent.children) {
        this.$set(parent, 'children', [addObj]);
      } else {
        parent.children.push(addObj);
      }
      this.editDialog = false;
    },
    saveAddNode() {
      if (this.isAdd) {
        this.appendNode();
      } else {
        this.editNode();
      }
    },
    clickNode(data) {
      this.curDept = data;
    },
    save() {
      this.$confirm('是否保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.warning = true;
        this.axios.put('depts/rebuild', { depts: this.depts }).then(() => {
          this.getDepts();
          this.$utils.msg('保存成功', 'success');
          this.waiting = false;
        }, (err) => {
          this.waiting = false;
          this.$utils.resp(err);
        });
      }, () => {});
    },
    renderContent(h, { node, data }) {
      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
          <span class="tree-oprt">
            <el-button size="mini" title="新增子部门" type="text" on-click={ () => this.toAppend(data) } icon="fa fa-plus"></el-button>
            <el-button size="mini" title="编辑" type="text" on-click={ () => this.toEdit(data) } icon="fa fa-pencil"></el-button>
          </span>
        </span>);
    },
    renderContent2(h, { node, data }) {
      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
          <span class="tree-oprt">
            <el-button size="mini" title="新增在手订单统计用户" type="text"
              on-click={ () => this.toAppend2(data) } icon="fa fa-plus"></el-button>
          </span>
        </span>);
    },
    renderContent3(h, { node }) {
      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
        </span>);
    },
  },
};
</script>

<style lang="scss">
  .dept-tree ::v-deep {
    .el-tree-node__content {
      .tree-oprt {
        display: inline-block;
        padding: 0 10px;
        margin-left: 5px;
        visibility: hidden;
        .el-button {
          margin-left: 5px;
        }
      }
      &:hover {
        .tree-oprt {
          visibility: visible;
        }
      }
    }
  }
</style>
