import Vue from 'vue';

const state = {
  showEditSelf: false,
  showInitUser: false,
  imgSrc: null,
  showBigImgModel: false,
  // branch: null,
  costTypes: [],
  depts: [],
  erpTree: [],
  deptTree: [],
  deptUsers: null,
  hdErpTree: [],
  organizations: [],
  stockStatusMap: {
    ENTERED: '已输入',
    PROCESSING: '待审批',
    APPROVED: '已批准',
    REJECTED: '已驳回',
    RECEIPTED: '已接收',
    PREPARE: '准备退货',
    RETURNED: '已退货',
  },
  dvStatuses: {
    ENTERED: '已输入',
    PROCESSING: '待审批',
    APPROVED: '已批准',
    REJECTED: '已驳回',
    RECEIPTED: '已接收',
    PREPARE: '准备退货',
    RETURNED: '已退货',
  },
  receiptStatuses: null,
  receiptContacts: null,
  receiptTypes: null,
  // 调拨类型
  txnTypes: [
    { typeCode: 'INTRAPRO', typeName: '项目内调拨' },
    { typeCode: 'INTERPRO', typeName: '项目间调拨' },
    { typeCode: 'INTERORA', typeName: '部门间调拨' },
  ],
  outTypes: [],
  uoms: {
    M3: '立方米',
    MBF: '千板尺',
    L: '升',
    EA: '个',
    THG: '万粒',
    SRI: '付',
    LI: '令',
    PCS: '件',
    FEN: '份',
    Y: '元',
    FU: '副',
    BAO: '包',
    PI: '匹',
    BOU: '十亿',
    QG: '千个',
    QJ: '千件',
    QZ: '千只',
    THP: '千张',
    KPC: '千支',
    KP: '千片',
    SIN: '单',
    JU: '卷',
    PAI: '双',
    ZHI: '只',
    UNI: '台',
    KUA: '块',
    SET: '套',
    DU: '对',
    kWh: '度',
    SHI: '式',
    PIE: '张',
    DOZ: '打',
    BAT: '批',
    EA1: '把',
    KUN: '捆',
    BR: '支',
    BK: '本',
    DUO: '朵',
    TIA: '条',
    MEI: '枚',
    GEN: '根',
    BAR: '桶',
    TAN: '樘',
    CI: '次',
    K: '款',
    'PC.': '片',
    BTL: '瓶',
    HEA: '百个',
    HS: '百套',
    BT: '百条',
    HUN: '百粒',
    HE: '盒',
    DIS: '盘',
    PIA: '票',
    BI: '笔',
    CON: '筒',
    GRS: '箩',
    CTN: '箱',
    GRA: '粒',
    ZU: '组',
    GAN: '缸',
    LUO: '罗',
    SOU: '艘',
    COL: '色',
    BAG: '袋',
    ZHO: '轴',
    LIA: '辆',
    X: '项',
    KG: '千克',
    G: '克',
    kg: '公斤',
    MT: '吨',
    LBS: '磅',
    M: '米',
    KM: '千米',
    HM: '百米',
    'YD.': '码',
    M2: '平方米',
    PFC: '平方尺',
    MM2: '平方毫米',
    QFT: '平方英尺',
  },
  currencyList: [
    { code: 'USD', name: '美元' },
    { code: 'CNY', name: '人民币' },
    { code: 'EUR', name: '欧元' },
    { code: 'JPY', name: '日元' },
    { code: 'RUB', name: '卢布' },
    { code: 'ARS', name: '' },
    { code: 'AUD', name: '' },
    { code: 'BUK', name: '' },
    { code: 'CAD', name: '' },
    { code: 'CHF', name: '' },
    { code: 'DKK', name: '' },
    { code: 'EGP', name: '' },
    { code: 'GBP', name: '' },
    { code: 'HKD', name: '' },
    { code: 'MXN', name: '' },
    { code: 'SGD', name: '' },
    { code: 'VND', name: '' },
  ],
  clothesDicts: {},
};

const getters = {
  costTypesMap: (state) => {
    const objMap = {};
    state.costTypes.forEach((item) => {
      objMap[item.id] = item.name;
    });
    return objMap;
  },
  deptMap: (state) => {
    const objMap = {};
    state.depts.forEach((item) => {
      objMap[item.id] = item;
    });
    return objMap;
  },
  organizationsMap: (state) => {
    const objMap = {};
    state.organizations.forEach((item) => {
      objMap[item.organization_id] = item.organization_name;
    });
    return objMap;
  },
};

const actions = {
  GET_COLTHES_DICTS({ commit }) {
    return Vue.axios.get('e-clothes-dicts').then((resp) => {
      commit('SET_COLTHES_DICTS', resp.data);
      return resp;
    }, () => {});
  },
  GET_COST_TYPE({ commit }) {
    return Vue.axios.get('expense_type', { params: { with_list: 1 } }).then((resp) => {
      commit('SET_COST_TYPE', resp.data);
    }, () => {
    });
  },
  GET_DEPTS({ commit }) {
    Vue.axios.get('depts').then((resp) => {
      commit('SET_DEPTS_LIST', resp.data);
    }, (err) => {
      Vue.utils.resp(err);
    });
    Vue.axios.get('deptTree', { params: { id: 437134 } }).then((resp) => {
      const depts = resp.data;
      Vue.utils.treeEach(depts, (item) => {
        if (item.children) {
          if (item.children.length === 0) {
            delete item.children;
          }
        }
      });
      commit('SET_HD_DER_DEPTS', depts);
      // this.viewDepts = viewDepts;
    }, (err) => {
      Vue.utils.resp(err);
    });
    return Vue.axios.get('deptTree', { params: { id: 19177 } }).then((resp) => {
      const depts = resp.data;
      Vue.utils.treeEach(depts, (item) => {
        if (item.children) {
          if (item.children.length === 0) {
            delete item.children;
          }
        }
      });
      // Vue.utils.treeTailFun(depts, this.everyFun);
      // // const viewDepts = _cloneDeep(depts);
      commit('SET_DER_DEPTS', depts);
      // this.viewDepts = viewDepts;
    }, (err) => {
      Vue.utils.resp(err);
    });
  },
  GET_DEPT_USERS({ commit }) {
    return Vue.axios.get('deptanduser').then((resp) => {
      const result = resp.data;
      commit('SET_DEPT_USERS', result.users);
      commit('SET_DEPT_Tree', result.dept);
      return resp;
    });
  },
  GET_OUT_TYPES({ commit }) {
    Vue.axios.get('outtypes').then((resp) => {
      commit('SET_OUT_TYPES', resp.data);
    });
  },
  GET_ORGANIZATION({ commit }) {
    return Vue.axios.get('organizations').then((resp) => {
      commit('SET_ORGANIZATION', resp.data);
      return resp.data;
    }, () => {
    });
  },
  GET_RECEIPT_STATUSES({ commit }) {
    return Vue.axios.get('receipt-status').then((resp) => {
      commit('SET_RECEIPT_STATUSES', resp.data);
      return resp.data;
    }, () => {
    });
  },
  GET_RECEIPT_TYPES({ commit }) {
    return Vue.axios.get('receipt-types').then((resp) => {
      commit('SET_RECEIPT_TYPES', resp.data);
      return resp.data;
    }, () => {
    });
  },
  GET_RECEIPT_CONTACTS({ commit }) {
    return Vue.axios.get('receipt-contacts').then((resp) => {
      commit('SET_RECEIPT_CONTACTS', resp.data);
      return resp.data;
    }, () => {
    });
  },
};
/* eslint-disable no-shadow, no-param-reassign */
const mutations = {
  SET_SHOW_EDITSELF(state, show) {
    state.showEditSelf = show;
  },
  SET_SHOW_INIT_USER(state, show) {
    state.showInitUser = show;
  },
  SET_COST_TYPE(state, list) {
    state.costTypes = list;
  },
  SET_OUT_TYPES(state, payload) {
    state.outTypes = payload;
  },
  SET_ORGANIZATION(state, list) {
    state.organizations = list;
  },
  SET_DEPTS_LIST(state, list) {
    state.depts = list;
  },
  SET_DER_DEPTS(state, tree) {
    state.erpTree = tree;
  },
  SET_DEPT_USERS(state, list) {
    state.deptUsers = list;
  },
  SET_DEPT_Tree(state, list) {
    state.deptTree = list;
  },
  SET_HD_DER_DEPTS(state, tree) {
    state.hdErpTree = tree;
  },
  SET_RECEIPT_STATUSES(state, obj) {
    state.receiptStatuses = obj;
  },
  SET_RECEIPT_TYPES(state, obj) {
    state.receiptTypes = obj;
  },
  SET_RECEIPT_CONTACTS(state, obj) {
    state.receiptContacts = obj;
  },
  SET_BIG_IMG_URL(state, obj) {
    state.imgSrc = obj;
  },
  PICKER_SHOW_SHOW_BIG_IMG(state) {
    state.showBigImgModel = true;
  },
  PICKER_HIDE_SHOW_BIG_IMG(state) {
    state.showBigImgModel = false;
  },
  SET_COLTHES_DICTS(state, obj) {
    state.clothesDicts = obj;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
