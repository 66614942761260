<template>
  <el-card class="component">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="first" label="实际出库">
        <real-log :boxHeight="boxHeight"
          :colors="colors"
          :sizes="sizes"
          :categories="categories"
          :outTypes="outTypes"
          :relaStyles="relaStyles"
          :khs="khs"
          :seasons="seasons"></real-log>
      </el-tab-pane>
      <el-tab-pane name="second" label="生产入库">
        <div class="mar-btm-10 text-right">
          <div class="box-inline standard-search">
            <el-input v-model="queryForm.kh" clearable placeholder="款号" @keydown.enter.native="onSubmitQuery"></el-input>
          </div>
          <div class="box-inline standard-search">
            <el-input v-model="queryForm.rela_style" clearable placeholder="关联款号" @keydown.enter.native="onSubmitQuery"></el-input>
          </div>
          <div class="box-inline standard-search">
            <el-select v-model="queryForm.color" clearable placeholder="颜色选择" filterable>
              <el-option v-for="(label, key) in colors" :key="key" :value="key" :label="`[${key}]${label}`"></el-option>
            </el-select>
          </div>
          <div class="box-inline standard-search">
            <el-select v-model="queryForm.size" clearable placeholder="尺码" filterable>
              <el-option v-for="(label, key) in sizes" :key="key" :value="key" :label="`${label}`"></el-option>
            </el-select>
          </div>
          <div class="box-inline standard-search">
            <el-date-picker value-format="yyyy-MM-dd"
              v-model="queryForm.start_date"
              type="date" placeholder="开始日期">
            </el-date-picker>
          </div>
          <div class="box-inline standard-search">
            <el-date-picker value-format="yyyy-MM-dd"
              v-model="queryForm.end_date"
              type="date" placeholder="截至日期">
            </el-date-picker>
          </div>
          <el-button size="small" @click="onSubmitQuery">
            <i class="fa fa-fw fa-search"></i>
          </el-button>
          <el-button size="small" @click="excel">
            <i class="fa fa-fw fa-download"></i>
          </el-button>
          <a v-show="false" ref="downloadHref" :href="excelUrl" download="out.xlsx"></a>
        </div>
        <el-tabs v-model="subActionName" @tab-click="handleClick">
          <el-tab-pane name="sub_first" label="记录">
            <table class="table bordered">
              <thead>
                <tr>
                  <th>款号</th>
                  <th>类别</th>
                  <th>颜色</th>
                  <th>尺码</th>
                  <th>SKU</th>
                  <th>关联款号</th>
                  <th>操作人</th>
                  <th>操作时间</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in list" :key="index">
                  <td>{{item.code.substr(2, 5)}}</td>
                  <td>{{categories[item.code.substr(3, 2)]}}</td>
                  <td>{{colors[item.color]}}</td>
                  <td>{{sizes[item.size]}}</td>
                  <td>{{item.code}}</td>
                  <td>{{item.rela_style}}</td>
                  <td>{{item.in_creator}}</td>
                  <td>{{item.in_at}}</td>
                </tr>
              </tbody>
            </table>
            <div class="clearfix mar-top">
              <el-pagination class="pull-right"
                @current-change="changePage"
                layout="total, prev, pager, next"
                :total="totalItem"
                :current-page="currentPage"
                :page-size="pageSize">
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane name="sub_second" label="统计">
            <div :style="{height: boxHeight+'px', overflow: 'auto'}">
              <table class="table bordered">
                <thead>
                  <tr>
                    <th>款号</th>
                    <th>关联款号</th>
                    <th>类别</th>
                    <th>颜色</th>
                    <th>图片</th>
                    <th>XS</th>
                    <th>S</th>
                    <th>M</th>
                    <th>L</th>
                    <th>XL</th>
                    <th class="text-right" width="60">合计生产入库</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(totalList, code) in totalData">
                    <tr v-for="(item, index) in totalList" :key="`${code}_${index}`">
                      <td :rowspan="totalList.length" v-if="index == 0">{{String(item.code).substr(0, 5)}}
                        [{{countMap[String(item.code).substr(0, 5)]}}]</td>
                      <td :rowspan="totalList.length" v-if="index == 0">{{item.rela_style}}</td>
                      <td :rowspan="totalList.length" v-if="index == 0">{{categories[String(item.code).substr(1, 2)]}}</td>
                      <td>{{item.color}}</td>
                      <td>
                        <el-popover v-if="item.imgs"
                          placement="right"
                          trigger="hover">
                          <img :src="showImgUrl">
                          <img slot="reference" @mouseover="showItemImg(item, false)" @click="showItemImg(item, true)"
                            :src="'data:image/jpg/png/gif;base64,' + item.imgs.simg" alt="">
                        </el-popover>
                      </td>
                      <td class="text-info">{{item.size1 > 0 ? item.size1 : '-'}}</td>
                      <td class="text-info">{{item.size2 > 0 ? item.size2 : '-'}}</td>
                      <td class="text-info">{{item.size3 > 0 ? item.size3 : '-'}}</td>
                      <td class="text-info">{{item.size4 > 0 ? item.size4 : '-'}}</td>
                      <td class="text-info">{{item.size5 > 0 ? item.size5 : '-'}}</td>
                      <td class="text-info">{{item.total > 0 ? item.total : '-'}}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane name="third" label="出库">
        <out-log :boxHeight="boxHeight"
          :colors="colors"
          :sizes="sizes"
          :categories="categories"
          :outTypes="outTypes"
          :relaStyles="relaStyles"
          :khs="khs"
          :seasons="seasons"></out-log>
      </el-tab-pane>
      <el-tab-pane name="fourth" label="返库">
        <reback-log :boxHeight="boxHeight"
          :colors="colors"
          :sizes="sizes"
          :categories="categories"
          :outTypes="outTypes"
          :relaStyles="relaStyles"
          :khs="khs"
          :seasons="seasons"></reback-log>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import OutLog from './part/OutLog';
import RebackLog from './part/RebackLog';
import RealLog from './part/RealLog';

export default {
  components: {
    OutLog,
    RebackLog,
    RealLog,
  },
  data() {
    return {
      activeName: 'first',
      subActionName: 'sub_first',
      queryForm: {
        kh: '',
        rela_style: '',
        color: '',
        size: '',
        start_date: '',
        end_date: '',
      },
      list: [],
      queryParams: {},
      colors: [],
      sizes: [],
      seasons: [],
      outTypes: [],
      categories: [],
      relaStyles: [],
      khs: [],
      boxHeight: 100,
      totalData: [],
      countMap: {},
      showImgUrl: '',
      excelUrl: '',
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  created() {
    this.init();
  },
  methods: {
    handleClick() {
      if (this.activeName === 'second') {
        // this.$refs.log.showTab();
      }
    },
    init() {
      this.boxHeight = document.documentElement.clientHeight - 300;
      if (this.$store.state.common.clothesDicts.sizes) {
        this.sizes = this.$store.state.common.clothesDicts.sizes;
        this.seasons = this.$store.state.common.clothesDicts.seasons;
        this.categories = this.$store.state.common.clothesDicts.orgCategories;
        this.colors = this.$store.state.common.clothesDicts.orgColors;
        this.outTypes = this.$store.state.common.clothesDicts.sub_types;
        this.relaStyles = this.$store.state.common.clothesDicts.relaStyles;
        this.khs = this.$store.state.common.clothesDicts.khs;
      } else {
        this.$store.dispatch('GET_COLTHES_DICTS').then((resp) => {
          this.sizes = resp.data.sizes;
          this.seasons = resp.data.seasons;
          this.categories = resp.data.orgCategories;
          this.colors = resp.data.orgColors;
          this.outTypes = this.$store.state.common.clothesDicts.sub_types;
          this.relaStyles = this.$store.state.common.clothesDicts.relaStyles;
          this.khs = this.$store.state.common.clothesDicts.khs;
        });
      }
      // this.$http.get('clothes-log').then((resp) => {
      //   console.log(resp.data);
      // });
    },
    onSubmitQuery() {
      this.queryParams = {};
      if (this.queryForm.rela_style && this.queryForm.rela_style.trim()) {
        this.queryParams.rela_style = this.queryForm.rela_style;
      }
      if (this.queryForm.kh && this.queryForm.kh.trim()) {
        this.queryParams.kh = this.queryForm.kh;
      }
      if (this.queryForm.color && this.queryForm.color.trim()) {
        this.queryParams.color = this.queryForm.color;
      }
      if (this.queryForm.size !== null) {
        this.queryParams.size = this.queryForm.size;
      }
      if (this.queryForm.start_date && this.queryForm.start_date.trim()) {
        this.queryParams.start_date = this.queryForm.start_date;
      }
      if (this.queryForm.end_date && this.queryForm.end_date.trim()) {
        this.queryParams.end_date = this.queryForm.end_date;
      }
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
      this.getTotal();
    },
    getList() {
      this.axios.get('clothes-in-log', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          this.list = list;
          this.totalItem = response.data.total;
        }, (err) => {
          this.$utils.resp(err);
        });
    },
    getTotal() {
      this.axios.get('clothes-in-log-tj', { params: this.queryForm }).then((resp) => {
        const temps = resp.data;
        const countMap = {};
        const keys = Object.keys(temps);
        keys.forEach((key) => {
          const arr = temps[key];
          let keyTotal = 0;
          arr.forEach((item) => {
            keyTotal += item.total ? item.total : 0;
          });
          countMap[key] = keyTotal;
        });
        this.countMap = countMap;
        this.totalData = resp.data;
      });
    },
    excel() {
      this.$http.get('clothes-in-excel', { params: this.queryForm, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        this.excelUrl = URL.createObjectURL(blob);
        this.$nextTick(() => {
          this.$refs.downloadHref.click();
        });
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 显示图片
    showItemImg(item, isFullScrean = false) {
      this.showImg(item.imgs, isFullScrean);
    },
    showImg(file, isFullScrean) {
      const params = {
        hint: 'clothes',
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.showImgUrl = objurl;
        if (isFullScrean) {
          this.$store.commit('SET_BIG_IMG_URL', objurl);
          this.$store.commit('PICKER_SHOW_SHOW_BIG_IMG');
        }
      }, (err) => {
        this.$utils.resp(err);
      });
    },
  },
};
</script>

<style>

</style>
