<template>
  <div class="main-show" style="padding: 20px; background: #eee;">
    <div>
      <el-input size="" v-model="orderNo" @keydown.enter.native="submit()" style="width: 100%">
        <el-button slot="append" icon="el-icon-search" @click="submit()"></el-button></el-input>
    </div>
    <div v-if="order.Id" style="background: #fff; margin-top: 10px;">
      <el-form :model="order">
        <el-form-item label="编号：">
          {{order.OrderNo}}
        </el-form-item>
        <el-form-item label="状态：">
          {{statuses[order.OrderStatus]}}
        </el-form-item>
        <el-form-item label="创建时间：">
          {{order.date}}
        </el-form-item>
      </el-form>
    </div>

    <div>
      <el-row :gutter="10">
        <div class="img-item" v-for="img in rootPics" :key="img.Id" clearfix>
          <el-col :span="12">
            <img :src="`/gh-pic/${img.PicName}`" @click="showBigImg(img)" v-err-src="`${baseUrl}404.png`" alt="">
          </el-col>
        </div>
      </el-row>
    </div>

    <el-dialog fullscreen :visible.sync="showBigImgVisible" custom-class="show-big-img-dialog">
      <div class="show-big-img-h">
        <div class="to-show-pic">
          <div class="show-img pos-rel">
            <img :src="bigImgSrc" v-err-src="`${baseUrl}404.png`"/>
            <div class="point" v-for="(item) in subPics"
              @click="showSubImg(item)"
              :key="item.Id" :style="{left: item.PXaxis + '%', top: item.PYaxis + '%'}">
              <div class="dot"></div>
            </div>
            <!-- <i class="el-dialog__close el-icon el-icon-close pos-abs" style="top: 0; right: 0; color:#007ae;cursor:pointer;"
              @click="showBigImgVisible=false"></i> -->
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="subImgShow" width="100%" heigth="100%">
      <div class="text-center" style="width: 100%; overflow: auto;backgound: #eee;">
        <img style="width: 100%" :src="`/gh-pic/${subPic.PicName}`" v-err-src="`${baseUrl}404.png`"/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      order: {},
      orderNo: '',
      allPics: [],
      curRootPic: {}, // 主图
      curSubPic: {}, // 子图
      bigImgSrc: '', // 主图src
      showBigImgVisible: false, // 主图显示
      subImgShow: false,
      subPic: {}, // 子图
      statuses: { A: '进行中', B: '已完成' },
      baseUrl: window.baseUrl,
    };
  },
  created() {
  },
  mounted() {
    const orderNo = this.$route.query.code;
    if (orderNo) {
      this.$http.get('Home/GetOrderInfo', { params: { orderNo }, baseURL: '/dimg-api' }).then((resp) => {
        const order = resp.data.result;
        order.date = this.$utils.formatDate(new Date(order.CreateDate.replace(/\D*/g, '') * 1));
        this.order = order;
        this.getPics(this.order.Id);
      });
    }
  },
  computed: {
    rootPics() {
      let result = [];
      if (this.allPics && this.allPics.length > 0) {
        result = this.allPics.filter(item => !item.FId);
      }
      return result;
    },
    subPics() {
      let result = [];
      if (this.allPics.length > 0 && this.curRootPic.Id) {
        result = this.allPics.filter(item => item.FId === this.curRootPic.Id);
      }
      return result;
    },
    // 子图src
    curSubImgSrc() {
      let src = '';
      if (this.subPic.PicName) {
        src = `/gh-pic/${this.subPic.PicName}`;
      }
      return src;
    },
  },
  methods: {
    submit() {
      const orderNo = this.orderNo.trim();
      this.$http.get('Home/GetOrderInfo', { params: { orderNo }, baseURL: '/dimg-api' }).then((resp) => {
        const order = resp.data.result;
        order.date = this.$utils.formatDate(new Date(order.CreateDate.replace(/\D*/g, '') * 1));
        this.order = order;
        this.getPics(this.order.Id);
      });
    },
    getPics(OrderId) {
      const params = { OrderId };
      this.$http.get('Home/GetPicInfoList', { params, baseURL: '/dimg-api' }).then((res) => {
        const list = res.data.result;
        list.forEach((item) => {
          if (item.FId) {
            item.PXaxis /= 100;
            item.PYaxis /= 100;
          }
        });
        this.allPics = list;
      });
    },
    // 打开
    showBigImg(item) {
      this.curRootPic = item;
      this.bigImgSrc = `/gh-pic/${item.PicName}`;
      this.showBigImgVisible = true;
    },
    showSubImg(subPic) {
      this.subPic = subPic;
      this.subImgShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  .img-item {
    margin-top: 5px;
    img {
      width: 100%;
    }
  }
  .el-dialog.show-big-img-dialog {
    background-color: rgba(0,0,0,0.9);
  }
  .el-dialog.show-big-img-dialog .el-dialog__header {
    padding: 0 10px;
    height: 0;
  }
  .el-dialog.show-big-img-dialog .el-dialog__body {
    padding: 0;
    height: 100%;
  }
  .el-dialog.show-big-img-dialog .el-dialog__body .show-big-img-h {
    height: 100%;
    background: rgba($color: #888, $alpha: .3);
    // background-image: url(~assets/imgs/loadingbg.gif);
    // background-position: center center;
    // background-repeat: no-repeat;
  }
  .el-dialog.show-big-img-dialog .el-dialog__body .show-big-img-h .to-show-pic {
    height: 100%;
    width: 100%;
    overflow: auto;
    margin: 0 auto;
    padding: 42px 10px 0;
    text-align: center;
  }
  .el-dialog.show-big-img-dialog .el-dialog__body .show-big-img-h .to-show-pic .show-img {
    display: table;
    padding: 0;
    margin: 0;
    position: relative;
    vertical-align: middle;
    img {
      display: block;
    }
  }
</style>
