<template>
  <el-card class="home">
    <p>
      欢迎用户：{{user.name}}
    </p>
    <p v-if="company">所属缅甸公司：{{company.code}}</p>
  </el-card >
</template>

<script>
import * as _findIndex from 'lodash/findIndex';
// import * as _find from 'lodash/find';
import { mapState } from 'vuex';

export default {
  name: 'home',
  data() {
    return {
      company: false,
    };
  },
  components: {
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    // company() {
    //   let result = false;
    //   if (this.user.id) {
    //     const companyId = this.user.meta ? this.user.meta.mmr_company_id : false;
    //     if (_findIndex(this.user.roles, { code: 'mmr_input' }) !== -1 && companyId) {
    //       result = true;
    //     }
    //   }
    //   return result;
    // },
    isInputRole() {
      let result = false;
      if (this.user.id) {
        const companyId = this.user.meta ? this.user.meta.mmr_company_id : false;
        if (_findIndex(this.user.roles, { code: 'mmr_input' }) !== -1 && companyId) {
          result = true;
        }
      }
      return result;
    },
  },
  methods: {
    init() {
      if (this.isInputRole) {
        this.getCompany(this.user.meta.mmr_company_id);
      }
    },
    getCompany(id) {
      this.$http.get(`mmrcompany/${id}`).then((resp) => {
        this.company = resp.data;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
  },
};
</script>
