<template>
  <div v-loading="loading">
    <el-card>
      <h2 class="text-center">工厂评估表</h2>
      <table class="table bordered">
        <tbody>
          <tr>
            <td>工厂抬头：</td>
            <td>{{factory.name}}</td>
            <td>工厂地址：</td>
            <td><el-input v-model="factory.addr"></el-input></td>
          </tr>
          <tr>
            <td>联系人：</td>
            <td><el-input v-model="factory.contact_man"></el-input></td>
            <td>联系方式：</td>
            <td><el-input v-model="factory.contact_phone"></el-input></td>
          </tr>
          <tr>
            <td>厂房总面积：</td>
            <td><el-input v-model="factory.area"></el-input></td>
            <td>工厂总人数：</td>
            <td><el-input v-model="factory.number"></el-input></td>
          </tr>
        </tbody>
      </table>
      <table class="table bordered">
        <template v-for="(arr, category) in categoryMap">
          <tr :key="category">
            <td colspan="10">{{category}}</td>
          </tr>
          <template v-for="(item, idx) in arr">
            <tr v-for="(line, index) in item.list" :key="idx + category + index">
              <template v-if="line[0]">
                <td> {{line[0].item_name}} </td>
                <template v-if="index === 0">
                  <td class="text-right"><span v-if="line[0].is_person_number">人数：</span></td>
                  <td><el-input @blur="calcNumber(line[0])" v-if="line[0].is_person_number" v-model="line[0].person_number"></el-input></td>
                  <td class="text-right"><span v-if="line[0].is_area">面积：</span></td>
                  <td><el-input @blur="calcArea" v-if="line[0].is_area" v-model="line[0].area"></el-input></td>
                </template>
                <template v-else>
                  <td>
                    <el-input @blur="calcNumber(line[0])" v-if="line[0].type === 'number'" v-model="line[0].person_number"></el-input>
                    <el-switch v-if="line[0].type==='boolean'" v-model="line[0].person_number"
                      active-text="是" inactive-text="否"
                      :active-value="1" :inactive-value="0"></el-switch>
                  </td>
                  <td colspan="3">
                    <el-radio-group v-if="line[0].is_score" v-model="line[0].score">
                      <el-radio label="A">A</el-radio>
                      <el-radio label="B">B</el-radio>
                      <el-radio label="C">C</el-radio>
                    </el-radio-group>
                  </td>
                </template>
              </template>
              <template v-else>
                <td colspan="5"></td>
              </template>
              <template v-if="line[1]">
                <td> {{line[1].item_name}} </td>
                <template v-if="index === 0">
                  <td class="text-right"><span v-if="line[1].is_person_number">人数：</span></td>
                  <td><el-input @blur="calcNumber(line[1])" v-if="line[1].is_person_number" v-model="line[1].person_number"></el-input></td>
                  <td class="text-right"><span v-if="line[1].is_area">面积：</span></td>
                  <td><el-input @blur="calcArea" v-if="line[1].is_area" v-model="line[1].area"></el-input></td>
                </template>
                <template v-else>
                  <td>
                    <el-input @blur="calcNumber(line[1])" v-if="line[1].type === 'number'" v-model="line[1].person_number"></el-input>
                    <el-switch v-if="line[1].type==='boolean'" v-model="line[1].person_number"
                      active-text="是" inactive-text="否"
                      :active-value="1" :inactive-value="0"></el-switch>
                  </td>
                  <td colspan="3">
                    <el-radio-group v-if="line[1].is_score" v-model="line[1].score">
                      <el-radio label="A">A</el-radio>
                      <el-radio label="B">B</el-radio>
                      <el-radio label="C">C</el-radio>
                    </el-radio-group>
                  </td>
                </template>
              </template>
              <template v-else>
                <td colspan="5"></td>
              </template>
            </tr>
          </template>
        </template>
      </table>
      <table class="table bordered">
        <template v-for="(item, index) in textList">
          <tr :key="index">
            <td>{{item.item_name}}</td>
            <td width="50">
              <el-button @click="addText(item)" v-if="item.type==='array'" type="primary" icon="fa fa-plus"></el-button>
            </td>
          </tr>
          <tr v-for="(t, idx) in item.items" :key="`${index}_sub_${idx}`">
            <td>
              <el-input v-model="t.text" :type="item.type==='text' ? 'textarea' : 'input'"></el-input>
            </td>
            <td><el-button v-if="idx > 0" type="danger" icon="fa fa-trash" @click="delText(item, idx)"></el-button></td>
          </tr>
        </template>
      </table>
      <div class="text-center" v-if="factory.status != 2">
        <el-button @click="save(1)" type="warning" style="width: 100px; margin-top: 20px;" size="larger">保存</el-button>
        <el-button @click="save(2)" type="primary" style="width: 100px; margin-top: 20px;" size="larger">提交</el-button>
      </div>
      <div v-if="report_id">
        <el-row>
          <el-col :span="8">
            <div v-for="(num, title) in scoreMap" :key="title">
              {{title}}: {{num}}
            </div>
          </el-col>
          <el-col :span="8">
            <div v-for="(rate) in scoreR" :key="rate.key">
              {{rate.key}}: <el-input style="width:100px;" v-model="rate.value"></el-input>
            </div>
          </el-col>
          <el-col :span="8">评分 {{score}}</el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
// import { mapState } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      factory_id: null,
      template_id: null,
      report_id: null,
      reportList: [],
      factory: {},
      categories: [],
      categoryMap: {},
      scoreR: [
        { key: 'A', value: '100' },
        { key: 'B', value: '70' },
        { key: 'C', value: '0' },
      ],
    };
  },
  created() {
    this.init();
    // this.$store.dispatch('AUTH_GET_USER');
  },
  computed: {
    // ...mapState({
    //   user: state => state.auth.user,
    // }),
    textList() {
      const list = [];
      this.reportList.forEach((item) => {
        if (item.type === 'text' || item.type === 'array') {
          list.push(item);
        }
      });
      return list;
    },
    scoreMap() {
      if (!this.report_id) {
        return {
          A: 0,
          B: 0,
          C: 0,
        };
      }
      const map = {};
      this.reportList.forEach((item) => {
        if (item.score) {
          if (map[item.score]) {
            map[item.score]++;
          } else {
            map[item.score] = 1;
          }
        }
        if (item.subs) {
          item.subs.forEach((sub) => {
            if (sub.score) {
              if (map[sub.score]) {
                map[sub.score]++;
              } else {
                map[sub.score] = 1;
              }
            }
          });
        }
      });
      return map;
    },
    score() {
      let r = 0;
      let num = 0;
      let total = 0;
      const keys = Object.keys(this.scoreMap);
      keys.forEach((key) => {
        num += this.scoreMap[key];
        const curR = this.scoreR.find(i => i.key === key);
        let keyScore = 0;
        if (curR) {
          keyScore = curR.value;
        }
        const sum = this.$calc.Mul(this.scoreMap[key], keyScore);
        total = this.$calc.Add(total, sum);
      });
      r = this.$calc.Div(total, num);
      return r;
    },
  },
  methods: {
    init() {
      this.loading = true;
      this.axios.get('report-dict').then((r) => {
        this.categories = r.data.categories;
        if (this.$route.query.report_id) {
          this.getReport();
        } else {
          this.getFactory();
          this.getItems();
        }
      });
    },
    getReport() {
      const rId = this.$route.query.report_id;
      this.report_id = rId;
      this.axios.get(`factoryreport/${rId}`).then((resp) => {
        this.loading = false;
        const list = resp.data.details;
        list.forEach((item) => {
          item.is_area = item.is_area === '1';
          item.is_person_number = item.is_person_number === '1';
          item.is_score = item.is_score === '1';
        });
        const reportList = list.filter(item => !item.pid);
        const categoryObj = {};
        reportList.forEach((item) => {
          item.subs = list.filter(i => i.pid === item.item_id);
          if (this.categories.indexOf(item.category) !== -1) {
            if (categoryObj[item.category]) {
              categoryObj[item.category].push(item);
            } else {
              categoryObj[item.category] = [item];
            }
          }
        });
        this.factory = { ...resp.data, name: '' };
        this.resortCategory(categoryObj);
        this.reportList = reportList;
        this.axios.get(`factory/${this.factory.factory_id}`).then((r) => {
          this.factory.name = r.data.name;
        }, (e) => {
          this.utils.resp(e);
        });
        this.factory_id = resp.data.factory_id;
        this.template_id = resp.data.template_id;
      }, (e) => {
        this.utils.resp(e);
      });
    },
    getFactory() {
      const fId = this.$route.query.factory;
      this.factory_id = fId;
      this.axios.get(`factory/${fId}`).then((resp) => {
        this.factory = { ...resp.data, number: null, area: null };
      }, (e) => {
        this.utils.resp(e);
      });
    },
    getItems() {
      const pid = this.$route.query.template;
      this.template_id = pid;
      const params = { pid };
      this.axios.get('reportitem', { params }).then((resp) => {
        this.loading = false;
        const list = resp.data;
        const reportList = [];
        const categoryObj = {};
        list.forEach((item) => {
          const record = {
            item_name: item.title,
            item_id: item.id,
            type: item.type,
            category: item.category,
            is_area: item.is_area === '1',
            is_person_number: item.is_person_number === '1',
            is_score: item.is_score === '1',
            score: null,
            person_number: null,
            area: null,
            subs: [],
            items: (item.type === 'text' || item.type === 'array') ? [{ text: '' }] : null,
            remark: null,
          };
          reportList.push(record);
          if (item.subs && item.subs.length > 0) {
            item.subs.forEach((sub) => {
              const rsub = {
                pid: record.item_id,
                item_name: sub.title,
                item_id: sub.id,
                type: sub.type,
                is_area: sub.is_area === '1',
                is_person_number: sub.is_person_number === '1',
                is_score: sub.is_score === '1',
                score: null,
                person_number: null,
                area: null,
                items: null,
                remark: null,
              };
              record.subs.push(rsub);
            });
          }
          if (this.categories.indexOf(item.category) !== -1) {
            if (categoryObj[item.category]) {
              categoryObj[item.category].push(record);
            } else {
              categoryObj[item.category] = [record];
            }
          }
        });
        this.resortCategory(categoryObj);
        this.reportList = reportList;
      });
    },
    resortCategory(map) {
      const typeObj = {};
      this.categories.forEach((category) => {
        if (map[category]) {
          const list = map[category];
          const resultList = [];
          for (let i = 0; i < list.length; i++) {
            const index = Math.floor(i / 2);
            if (resultList[index]) {
              resultList[index].right = list[i];
            } else {
              resultList[index] = {
                left: list[i],
                right: null,
                list: [],
              };
            }
          }
          resultList.forEach((item) => {
            item.list.push([item.left, item.right]);
            const leftList = item.left.subs;
            const rightList = item.right ? item.right.subs : [];
            const leftLen = leftList.length;
            const rightLen = rightList.length;
            const len = leftLen > rightLen ? leftLen : rightLen;
            for (let j = 0; j < len; j++) {
              const l = j < leftLen ? leftList[j] : null;
              const r = j < rightLen ? rightList[j] : null;
              item.list.push([l, r]);
            }
          });
          typeObj[category] = resultList;
        }
      });
      this.categoryMap = typeObj;
    },
    // status 1保存，2提交
    save(status = 1) {
      const obj = {
        ...this.factory,
        details: null,
        status,
        factory_id: this.factory_id,
        template_id: this.template_id,
        records: this.reportList,
        score: this.score,
      };
      if (this.report_id) {
        this.loading = true;
        this.axios.put(`factoryreport/${this.report_id}`, obj).then(() => {
          this.getReport();
        });
      } else {
        this.loading = true;
        this.axios.post('factoryreport', obj).then((r) => {
          this.$router.push({
            name: 'ReportPage',
            query: { report_id: r.data.id },
          });
          this.getReport();
        });
      }
    },
    calcArea() {
      let num = 0;
      this.reportList.forEach((item) => {
        if (item.is_area && item.area) {
          num = this.$calc.Add(num, item.area);
        }
      });
      this.factory.area = num;
    },
    calcNumber(obj) {
      if (obj.is_person_number) {
        if (obj.pid) {
          this.reportList.forEach((item) => {
            let num = 0;
            if (item.subs) {
              item.subs.forEach((sub) => {
                if (sub.is_person_number && sub.person_number) {
                  num = this.$calc.Add(num, sub.person_number);
                }
              });
            }
            if (item.is_person_number) {
              item.person_number = num;
            }
          });
        }
        let sum = 0;
        this.reportList.forEach((item) => {
          if (item.is_person_number && item.person_number) {
            sum = this.$calc.Add(sum, item.person_number);
          }
        });
        this.factory.number = sum;
      }
    },
    addText(item) {
      item.items.push({ text: '' });
    },
    delText(item, index) {
      item.items.splice(index, 1);
    },
  },
};
</script>

<style>

</style>
