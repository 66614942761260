<template>
  <div>
    <table class="items bordered table" style="width: 100%;">
      <thead>
        <tr>
          <th><el-checkbox v-model="all" @change="selAll"></el-checkbox></th>
          <th>商品名称</th>
          <th>成本批次号</th>
          <th>款号</th>
          <th>规格</th>
          <th>颜色</th>
          <th>数量</th>
          <th>单位</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(val, idx) in list" :key="idx" @click.stop="trClick(val)">
          <td><el-checkbox v-model="val.sel" @change="checkAll"></el-checkbox></td>
          <td>{{val.item_name}}</td>
          <td>{{val.code.replace(/_S\d+$/, '')}}</td>
          <td class="text-center">{{[val.attr1, val.attr6].join(' ')}}</td>
          <td class="text-center">{{[val.attr2, val.attr7].join(' ')}}</td>
          <td>{{val.color}}</td>
          <td>{{val.left_quantity}}</td>
          <td>{{val.uom}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      all: false,
    };
  },
  methods: {
    trClick(item) {
      item.sel = !item.sel;
      this.checkAll();
    },
    checkAll() {
      this.$nextTick(() => {
        let f = false;
        this.list.forEach((item) => {
          if (!item.sel) {
            f = true;
          }
        });
        this.all = !f;
      });
    },
    selAll(val) {
      this.list.forEach((item) => {
        item.sel = val;
      });
    },
  },
};
</script>

<style>

</style>
