import Vue from 'vue';

const state = {
  invoiceTypes: [
    { id: 'G', label: '国华公司业务（应收）' },
    { id: 'W', label: '对外服务业务（应收）' },
    // { id: 'Z', label: '部分外发业务' },
  ],
  // wallbill
  deliveryStatuses: { unloaded: '' },
  companyTypes: { unloaded: '' },
  receiveTypes: { unloaded: '' },
  orderStatus: {
    pending: '1', // 待审核
    checked: '2', // 审核通过，待确认
    reject: '-1', // 审核不通过
    reject2: '-2', // 发货方不同意
    ongoing: '3', // 发货方确认可执行
    finished: '5', // 订单完结
  },
};

const getters = {
  // deliveryStatusesMap: (state) => {
  //   const objMap = {};
  //   state.delyOrderStatusList.forEach((item) => {
  //     objMap[item.id] = item.label;
  //   });
  //   return objMap;
  // },
  invoiceTypesMap: (state) => {
    const objMap = {};
    state.invoiceTypes.forEach((item) => {
      objMap[item.id] = item.label;
    });
    return objMap;
  },
  // companyTypesMap: (state) => {
  //   const objMap = {};
  //   state.companyTypes.forEach((item) => {
  //     objMap[item.id] = item.label;
  //   });
  //   return objMap;
  // },
};

const actions = {
  GET_DELIVERY_STATUSES({ commit }) {
    return Vue.axios.get('dely-statuses').then((resp) => {
      commit('SET_DELIVERY_STATUSES', resp.data);
    }, (err) => {
      Vue.utils.resp(err);
    });
  },
  GET_COMPANY_TYPES({ commit }) {
    return Vue.axios.get('company-types').then((resp) => {
      commit('SET_COMPANY_TYPES', resp.data);
    }, (err) => {
      Vue.utils.resp(err);
    });
  },
  GET_RECEIVE_TYPES({ commit }) {
    return Vue.axios.get('dely-types').then((resp) => {
      commit('SET_RECEIVE_TYPES', resp.data);
    }, (err) => {
      Vue.utils.resp(err);
    });
  },
};
/* eslint-disable no-shadow, no-param-reassign */
const mutations = {
  SET_COMPANY_TYPES(state, map) {
    state.companyTypes = map;
  },
  SET_RECEIVE_TYPES(state, map) {
    state.receiveTypes = map;
  },
  SET_DELIVERY_STATUSES(state, map) {
    state.deliveryStatuses = map;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
