<template>
  <el-card class="components">
    <el-button v-if="isPartyRole" type="primary" size="small" @click="toAdd()"
      icon="fa fa-fw fa-plus-circle"> 新增</el-button>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search" style="margin-right: 5px;">
        <el-input v-model="queryForm.q" clearable placeholder="输入关键字检索" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search" style="margin-right: 5px;" v-if="isPartyRole">
        <el-select v-model="queryForm.relaType" clearable placeholder="选择关系" @change="onSubmitQuery">
          <el-option value="" label="所有相关"></el-option>
          <el-option value="from" label="我发货的"></el-option>
          <el-option value="to" label="我接收的"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status" placeholder="选择状态" clearable>
          <el-option value="" label=""></el-option>
          <el-option v-for="(label, key) in statuses" :key="key" :value="key" :label="label"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25"># <el-checkbox v-if="false" v-model="allCheck" @change="checkAll"></el-checkbox></th>
          <th>记录单号</th>
          <th>国华公司</th>
          <th>发货方</th>
          <th>收货方</th>
          <th>发货日期</th>
          <th>状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }} <el-checkbox v-if="false" v-model="item.checked" @change="(val) => {checkItem(item, val)}"></el-checkbox> </td>
          <td><a href="#" @click.prevent="toDetail(item)">{{ item.code }}</a></td>
          <td>{{ item.dept_name }}</td>
          <td>{{ item.from_company }}</td>
          <td>{{ item.to_company }}</td>
          <td>{{ item.delivery_date }}</td>
          <td><span :class="[`status-color-${item.status}`]">{{ item.status_text }}</span></td>
          <td>
            <div class="text-nowrap">
              <el-button style="margin-right: 3px;" v-if="canEdit(item)" icon="fa fa-fw fa-pencil"
                title="调整信息" @click="toEdit(item)"></el-button>
              <!-- <el-button style="margin-right: 3px;" v-if="needOperate(item) === ''" type="primary"
                title="详情" @click="toDetail(item)" icon="fa fa-fw fa-search"></el-button> -->
              <el-button style="margin-right: 3px;" v-if="operateText(item) !== ''" type="warning"
                :title="operateText(item)" @click="toDetail(item)"><i class="fa fa-fw fa-check"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'"
      :close-on-click-modal="false" width="90%" top="20px">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="所属订单" prop="order">
          <el-select :disabled="editModel.id ? true : false"  v-if="!editModel.id || editModel.status == 1" style="width:100%;"
            v-model="editModel.order_id" @change="selectedOrder" filterable>
            <el-option v-for="item in orderOptions" :key="item.id"
              :value="item.id" :label="`【${item.code}】 ${item.title}`"></el-option>
          </el-select>
          <span v-else>{{editModel.order ? `【${editModel.order.code}】 ${editModel.order.title}`: ''}}</span>
        </el-form-item>
      <div v-if="editModel.order && editModel.order.id">
        <el-row>
          <el-col :span="12">
            <el-form-item label="业务部门">
              <el-input readonly v-model="editModel.dept_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货方" prop="from_company">
              <el-input readonly v-model="editModel.from_company"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货方" prop="to_company">
              <el-input readonly v-model="editModel.to_company"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货地址" prop="from_addr">
              <el-input v-model="editModel.from_addr"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货地址" prop="to_addr">
              <el-input v-model="editModel.to_addr"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货联系人" prop="from_contact">
              <el-input v-model="editModel.from_contact"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货联系人" prop="to_contact">
              <el-input v-model="editModel.to_contact"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货人电话" prop="from_tel">
              <el-input v-model="editModel.from_tel"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货人电话" prop="to_tel">
              <el-input v-model="editModel.to_tel"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货日期" prop="delivery_date">
              <el-date-picker v-model="editModel.delivery_date"
                style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发货时间" prop="delivery_at">
              <el-input v-model="editModel.delivery_at"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="12">
          <el-form-item label="物流公司" prop="transport_company">
            <el-input v-model="editModel.transport_company"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="物流单号" prop="trans_sn">
            <el-input v-model="editModel.trans_sn"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="货物总价" prop="amount">
            <el-input readonly type="number" v-model="editModel.amount"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="总价调整" prop="offset">
            <el-input type="number" v-model="editModel.offset"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="物流费用" prop="price">
            <el-input v-model="editModel.price" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发货类型" prop="receive_type">
            <el-select v-model="editModel.receive_type">
              <el-option v-for="(label, type) in receiveTypes" :key="type" :value="type" :label="label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="收货日期" prop="receive_date">
              <el-date-picker v-model="editModel.receive_date"
                style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货时间" prop="receive_at">
              <el-input v-model="editModel.receive_at"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div> -->
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="editModel.remark"></el-input>
        </el-form-item>
        <table class="items bordered table" style="width: 100%;">
          <thead>
            <tr>
              <!-- <th class="text-center">货号</th> -->
              <th class="text-center">品名规格</th>
              <th class="text-center">款号</th>
              <th class="text-center">花型/颜色</th>
              <th class="text-center">单位</th>
              <!-- <th class="text-center">单价</th> -->
              <th class="text-center">通知发货量</th>
              <th class="text-center">已发货数量1</th>
              <th class="text-center">发货数量</th>
              <th class="text-center">其他计数(卷)</th>
              <!-- <th class="text-center">单价</th> -->
              <!-- <th class="text-center">金额</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in editModel.items" :key="index" :title="item.remark">
              <!-- <td class="text-center">{{item.sn}}</td> -->
              <td class="text-center">{{item.item_name}}</td>
              <td class="text-center">{{item.attr1}}</td>
              <td class="text-center">{{item.attr2}}</td>
              <td class="text-right">{{item.uom}}</td>
              <td class="text-right">{{item.quantity}}</td>
              <td class="text-right" :title="item.totalNum ? `合同数量：${item.itemNum}/${item.totalNum}` : ''">
                <span class="text-primary">{{item.real_amount}}</span>
              </td>
              <td>
                <el-input :class="[checkNum(item)]" v-model="item.num" @change="calcSum(item)" type="number"></el-input>
              </td>
              <td>
                <el-input v-model="item.count"></el-input>
              </td>
              <!-- <td class="text-right">{{item.unit_price}}</td> -->
              <!-- <td>
                <el-input  v-model.number="item.sum" @change="calcTotal(true)" type="number"></el-input>
              </td> -->
            </tr>
            <tr>
              <td colspan="7" class="text-right">附件</td>
              <td>
                <el-button title="原文件下载" v-if="editModel.attachments && editModel.attachments.length > 0" icon="fa fa-download"
                  @click="downloadFile(editModel.attachments[0])"></el-button>
                <file-uploader v-if="editModel.order" ref="addFile"
                  accept="."
                  :emptyText="emptyText"
                  :multiple="false"
                  @change="changeFile"
                  :showPreview="false"
                  :showFileHint="true">
                </file-uploader>
              </td>
            </tr>
            <!-- <tr>
              <td colspan="9" class="text-right">合计</td>
              <td class="text-right">{{total}}</td>
            </tr> -->
          </tbody>
        </table>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="save" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 保 存</el-button>
        <el-button v-if="editModel.status == '1' && editModel.creator_id == user.id"
          type="danger" @click="delIt" class="pull-left" :disabled="waiting">
          <i class="fa fa-fw fa-trash" aria-hidden="true"></i> 删 除</el-button>
      </div>
    </el-dialog>

    <!-- detail dialog -->
    <el-dialog :visible.sync="showDialog" title="详情"
      :close-on-click-modal="false" width="90%" top="20px">
      <el-form :model="editModel" ref="detailForm" label-width="100px" :rules="rules">
        <el-form-item label="所属订单" prop="order">
          <span>{{editModel.order ? `【${editModel.order.code}】 ${editModel.order.title}`: ''}}</span>
        </el-form-item>
      <div v-if="editModel.order && editModel.order.id">
        <el-row>
          <el-col :span="12">
            <el-form-item label="业务部门">
              <el-input readonly v-model="editModel.dept_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="当前进度">
              <el-input readonly v-model="editModel.status_text"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货方" prop="from_company">
              <el-input readonly v-model="editModel.from_company"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货方" prop="to_company">
              <el-input readonly v-model="editModel.to_company"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货联系人" prop="from_contact">
              <el-input readonly v-model="editModel.from_contact"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货联系人" prop="to_contact">
              <el-input readonly v-model="editModel.to_contact"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货人电话" prop="from_tel">
              <el-input readonly v-model="editModel.from_tel"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货人电话" prop="to_tel">
              <el-input readonly v-model="editModel.to_tel"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货地址" prop="from_addr">
              <el-input readonly v-model="editModel.from_addr"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货地址" prop="to_addr">
              <el-input readonly v-model="editModel.to_addr"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货日期" prop="delivery_date">
              <el-date-picker readonly v-model="editModel.delivery_date"
                style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发货时间" prop="delivery_at">
              <el-input readonly v-model="editModel.delivery_at"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="12">
          <el-form-item label="物流公司" prop="transport_company">
            <el-input readonly v-model="editModel.transport_company"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="物流单号" prop="trans_sn">
            <el-input readonly v-model="editModel.trans_sn"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="货物总价" prop="amount">
            <el-input :readonly="!isDeliveryManager || editModel.order && editModel.order.status == 3"
              type="number" v-model="editModel.amount"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="总价调整" prop="offset">
            <el-input :readonly="!isDeliveryManager || editModel.order && editModel.order.status == 3"
              type="number" v-model="editModel.offset"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="物流费用" prop="price">
            <el-input readonly v-model="editModel.price" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发货类型" prop="receive_type">
            <el-select readonly v-model="editModel.receive_type">
              <el-option v-for="(label, type) in receiveTypes" :key="type" :value="type" :label="label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="收货日期" prop="receive_date">
              <el-date-picker :readonly="!canGot(editModel)" v-model="editModel.receive_date"
                style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货时间" prop="receive_at">
              <el-input readonly v-model="editModel.receive_at"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" readonly v-model="editModel.remark"></el-input>
        </el-form-item>
        <el-form-item label="说明" prop="m_remark">
          <el-input type="textarea" :readonly="!isDeliveryManager || editModel.order && editModel.order.status == 3"
            v-model="editModel.m_remark"></el-input>
        </el-form-item>
        <el-form-item v-if="isDeliveryManager && editModel.order && editModel.order.status != 3">
          <el-button type="primary" @click="updateInfo">更新说明</el-button>
        </el-form-item>
        <table class="items bordered table" style="width: 100%;">
          <thead>
            <tr>
              <!-- <th class="text-center">货号</th> -->
              <th class="text-center">品名</th>
              <th class="text-center">款号</th>
              <th class="text-center">规格</th>
              <th class="text-center">花型/颜色</th>
              <th class="text-center">单位</th>
              <!-- <th class="text-center">单价</th> -->
              <th class="text-center">通知发货量</th>
              <th class="text-center">已发货数量</th>
              <th class="text-center">发货数量</th>
              <th class="text-center">其他计数(卷)</th>
              <!-- <th class="text-center">单价</th> -->
              <!-- <th class="text-center">金额</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in editModel.items" :key="index" :title="item.remark">
              <!-- <td class="text-center">{{item.sn}}</td> -->
              <td class="text-center">{{item.item_name}}</td>
              <td class="text-center">{{item.attr1}}</td>
              <td class="text-center">{{item.attr2}}</td>
              <td class="text-center">{{item.color}}</td>
              <td class="text-right">{{item.uom}}</td>
              <td class="text-right">{{item.quantity}}</td>
              <td class="text-right">
                <span class="text-primary">{{item.real_amount}}</span>
              </td>
              <td class="text-right">{{1*item.num}}</td>
              <td class="text-right">{{item.count}}</td>
              <!-- <td class="text-right">{{item.unit_price}}</td> -->
              <!-- <td class="text-right">{{item.sum}}</td> -->
            </tr>
            <tr>
              <td colspan="8" class="text-right">附件</td>
              <td class="text-right">
                <!-- <a title="下载" href="#" v-if="editModel.attachments && editModel.attachments.length > 0"
                  @click.prevent="downloadFile(editModel.attachments[0])">{{editModel.attachments[0].orgName}}</a> -->
                <el-button title="原文件下载" v-if="editModel.attachments && editModel.attachments.length > 0" icon="fa fa-download"
                  @click="downloadFile(editModel.attachments[0])"></el-button>
                <file-uploader v-if="editModel.order" ref="detailFile"
                  accept="."
                  :emptyText="emptyText"
                  :multiple="false"
                  @change="changeDetailFile"
                  :showPreview="false"
                  :showFileHint="true">
                </file-uploader>
                <el-button type="primary" v-if="files.length > 0" @click="updateFile">上传附件</el-button>
              </td>
            </tr>
          </tbody>
        </table>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false" icon="fa fa-fw fa-times"> 关 闭</el-button>
        <!-- <el-button type="primary" @click="save" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 确 定</el-button> -->
        <el-button v-if="canCheck(editModel)" type="warning" icon="fa fa-fw fa-check"
          @click="checkDelivery(editModel)" :disabled="waiting"> 确认信息</el-button>
        <el-button v-if="canDel(editModel)" type="danger" icon="fa fa-fw fa-trash"
          @click="delIt(editModel)" class="pull-left" :disabled="waiting"> 删 除</el-button>
        <el-button v-if="canSend(editModel)" icon="fa fa-fw fa-check" type="warning"
          @click="toDelivery(editModel)" :disabled="waiting"> 发 货</el-button>
        <el-button v-if="canGot(editModel)" type="warning" icon="fa fa-fw fa-check"
          @click="finishDelivery(editModel)" :disabled="waiting"> 确认收货</el-button>
        <!-- <el-button v-if="canGot(editModel) && editModel.status !== statusM.delay" type="danger" icon="fa fa-fw fa-warning"
          @click="ycDelivery(editModel)" :disabled="waiting"> 延迟收货</el-button> -->
      </div>
    </el-dialog>

    <a ref="downloadLink" :href="downloadUrl" :download="downloadFileName" v-show="false"></a>

  </el-card>
</template>

<script>
import { mapState } from 'vuex';
import FileUploader from '../../components/FileUpload';
// import FileUploader from '@/components/FileUpload';

// 判断是否存在id
function hasParty(meta, id) {
  if (!meta) {
    return false;
  }
  let partyId = meta.party_id;
  if (!partyId) {
    partyId = [];
  }
  partyId = Array.isArray(partyId) ? partyId : [partyId];
  return partyId.indexOf(id) !== -1;
}

export default {
  components: {
    FileUploader,
  },
  data() {
    return {
      waiting: false,
      loading: false,
      orderOptions: [], // 订单选择
      companies: [], // 选择的企业
      // 查询条件form
      queryForm: {
        q: '',
        status: '-4',
        relaType: '',
      },
      queryPartiesLoading: false,
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      allCheck: false, // 全选
      checkedIds: [], // 已选ids
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      showDialog: false, // dialog显示标志 true 显示
      rules: {
        title: [{ required: true, message: '填写标题', trigger: ['change', 'blur'] }],
        order_id: [{ required: true, message: '请选择订单', trigger: 'change' }],
        send_date: [{ required: true, message: '发货日期必须填写', trigger: 'change' }],
        receive_company_id: [{ required: true, message: '请选择收货方', trigger: 'change' }],
      },
      fiels: [], // 文件上传选择的结果
      downloadFileName: '',
      downloadUrl: '',
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      total: 0, // 合计费用
      statusM: {
        pending: '1', // 待审核 v2
        tosend: '2', // 等待发货
        sended: '3', // 已发货
        got: '4', // 已收货
        delay: '9', // 延迟收货
      },
      files: [], // 上传文件暂存
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      statuses: state => state.dict.deliveryStatuses,
      receiveTypes: state => state.dict.receiveTypes,
    }),
    // 是否为交易方
    isPartyRole() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('dv_party') !== -1) {
        result = true;
      }
      return result;
    },
    isDeliveryManager() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('delivery_order_manager') !== -1) {
        result = true;
      }
      return result;
    },
    emptyText() {
      let result = '未选择文件';
      if (this.editModel && this.editModel.attachments && this.editModel.attachments.length > 0) {
        result = this.editModel.attachments[0].orgName;
      }
      // if (this.editModel && this.editModel.id) {
      //   result = 'ke';
      // }
      return result;
    },
    notification() {
      let result = '';
      if (this.$route.query.notification2) {
        result = this.$route.query.notification2;
      }
      return result;
    },
  },
  created() {
    this.init();
    if (this.notification) {
      this.queryNotif();
    } else {
      this.onSubmitQuery();
    }
  },
  watch: {
    notification() {
      this.queryNotif();
    },
  },
  methods: {
    init() {
      if (!this.statuses.unloaded) {
        this.$store.dispatch('GET_DELIVERY_STATUSES');
      }
      if (!this.receiveTypes.unloaded) {
        this.$store.dispatch('GET_RECEIVE_TYPES');
      }
      this.$http.get('dv-working-orders').then((resp) => {
        this.orderOptions = resp.data;
      });
      // this.$http.get('dely-order', { params: { with_list: 1, status: 1 } }).then((resp) => {
      //   this.orderOptions = resp.data;
      // }, (err) => {
      //   this.$utils.resp(err);
      // });
    },
    // 对应消息的数据详情
    queryNotif() {
      if (this.$route.query.notification2) {
        this.queryForm.q = this.$route.query.sn;
        this.queryForm.status = '';
        this.queryForm.relaType = '';
        this.onSubmitQuery();
        this.toDetail({ id: this.$route.query.detail });
        this.$store.dispatch('READ_NOTIFYICATION', this.notification);
      }
    },
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$http.get('dv-delivery', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          let isAll = true;
          list.forEach((item) => {
            if (this.checkedIds.indexOf(item.id) !== -1) {
              item.checked = true;
            } else {
              isAll = false;
              item.checked = false;
            }
          });
          this.allCheck = isAll;
          this.list = list;
          this.totalItem = response.data.total;
          this.loading = false;
        }, (err) => {
          this.loading = false;
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.files = [];
      this.editModel = {
        project_id: '',
        order_id: '',
        from_id: '',
        from_company: '',
        from_addr: '',
        from_contact: '',
        from_tel: '',
        to_id: '',
        to_company: '',
        to_addr: '',
        to_contact: '',
        to_tel: '',
        dept_id: '',
        detail_dept_id: '',
        dept_name: '',
        delivery_date: this.$utils.FormatDate(),
        delivery_at: this.$utils.FormatDate(new Date(), 'cah时'),
        receive_date: '',
        receive_at: '',
        transport_company: '',
        trans_sn: '',
        price: '',
        amount: '',
        offset: '',
        remark: '',
        m_remark: '',
        attachments: '',
        items: [],
        addFiles: [],
      };
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 可编辑
    canEdit(item) {
      let result = false;
      const meta = this.user.meta ? this.user.meta : {};
      const allowStatus = [
        this.statusM.pending,
        this.statusM.tosend,
        this.statusM.delay,
      ];
      if (hasParty(meta, item.from_id) && allowStatus.indexOf(item.status) !== -1) {
        result = true;
      }
      return result;
    },
    // 是否可删除
    canDel(item = null) {
      const obj = item || this.editModel;
      let result = false;
      if (obj.status !== this.statusM.got && obj.creator_id === this.user.id) {
        result = true;
      }
      return result;
    },
    // 可发货
    canSend(item = null) {
      const obj = item || this.editModel;
      let result = false;
      if (obj.status === this.statusM.tosend && obj.creator_id === this.user.id) {
        result = true;
      }
      return result;
    },
    // 可审核
    canCheck(item = null) {
      const obj = item || this.editModel;
      let result = false;
      if (obj.status === this.statusM.pending && obj.order.creator_id === this.user.id) {
        result = true;
      }
      return result;
    },
    // 可收货
    canGot(item = null) {
      const obj = item || this.editModel;
      let result = false;
      const meta = this.user.meta ? this.user.meta : {};
      if ((obj.status === this.statusM.sended || obj.status === this.statusM.delay)
        && hasParty(meta, item.to_id)) {
        result = true;
      }
      return result;
    },
    // 选中一个订单
    selectedOrder(orderId) {
      this.$http.get(`dvorder/${orderId}`).then((resp) => {
        const order = resp.data;
        // 非采购，查询库存
        // if (!order.po_header_id) {
        //   order.items.forEach()
        // }
        this.$set(this.editModel, 'order', order);
        this.editModel.project_id = order.project_id;
        this.editModel.order_id = order.id;
        this.editModel.dept_id = order.dept_id;
        this.editModel.dept_name = order.dept_name;
        this.editModel.code = order.code;
        this.editModel.from_id = order.from_id;
        this.editModel.from_company = order.from_company;
        this.editModel.from_contact = order.from_contact;
        this.editModel.from_tel = order.from_tel;
        this.editModel.from_addr = order.from_addr;
        this.editModel.to_id = order.to_id;
        this.editModel.to_company = order.to_company;
        this.editModel.to_contact = order.to_contact;
        this.editModel.to_tel = order.to_tel;
        this.editModel.to_addr = order.to_addr;
        this.setItems(order.items);
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    setItems(list) {
      let poPromise = null;
      let lines = [];
      if (list.length > 0 && list[0].po_header_id) {
        const pohid = list[0].po_header_id;
        poPromise = this.$http.get(`po-num/${pohid}`).then((resp) => {
          lines = resp.data;
        });
      } else {
        poPromise = Promise.resolve();
      }
      poPromise.then(() => {
        list.forEach((item) => {
          let poLine = lines.find(t => t.po_header_id === item.po_header_id);
          let leftNum = null;
          if (poLine) {
            leftNum = this.$calc.Sub(poLine.pricing_quantity, poLine.itemNum);
          } else {
            poLine = {};
          }
          let num = item.quantity - item.real_amount;
          if (num < 0) {
            num = 0;
          }
          this.$set(item, 'itemNum', poLine.itemNum);
          this.$set(item, 'totalNum', poLine.pricing_quantity);
          this.$set(item, 'leftNum', leftNum);
          this.$set(item, 'num', parseFloat(num));
          this.$set(item, 'count', '');
          // const sum = this.$calc.Mul(item.num ? item.num : 0, item.unit_price ? item.unit_price : 0);
          // this.$set(item, 'sum', sum);
        });
        this.editModel.items = list;
      });
      // this.calcTotal(true);
    },
    // 打开编辑
    toEdit(editObj) {
      this.files = [];
      if (this.$refs.addFile) {
        this.$refs.addFile.reset();
      }
      this.$http.get(`dely-record/${editObj.id}`).then((resp) => {
        const record = resp.data;
        const detailMap = {};
        record.details.forEach((item) => {
          detailMap[item.index] = item;
        });
        record.items = record.order.items;
        record.items.forEach((item) => {
          item.num = detailMap[item.index] ? parseFloat(detailMap[item.index].amount) : 0;
          item.real_amount -= item.num;
          item.count = detailMap[item.index] ? detailMap[item.index].count : '';
          item.sum = this.$calc.Mul(item.num ? item.num : 0, item.unit_price ? item.unit_price : 0);
        });
        record.addFiles = [];
        this.editModel = record;
        // this.calcTotal();
        this.total = this.editModel.amount;
        this.editDialog = true;
        this.$nextTick(() => {
          if (this.$refs.editForm) {
            this.$refs.editForm.clearValidate();
          }
        });
      });
    },
    checkNum(item) {
      let result = '';
      if (item.totalNum) {
        const max = this.$calc.Mul(item.totalNum, 1.05);
        const maxLeftNum = this.$calc.Sub(max, item.itemNum);
        if (item.num > item.leftNum && item.num <= maxLeftNum) {
          result = 'warning';
        } else if (item.num > maxLeftNum) {
          result = 'danger';
        }
      }
      this.$set(item, 'numStatus', result);
      // item.numStatus = result;
      return result;
    },
    calcSum(item) {
      item.sum = this.$calc.Mul(item.num ? item.num : 0, item.unit_price ? item.unit_price : 0);
      this.calcTotal(true);
    },
    calcTotal(updateAmount = true) {
      let total = 0;
      this.editModel.items.forEach((item) => {
        total += item.sum ? item.sum : 0;
      });
      this.total = Math.round(total * 100) / 100;
      if (updateAmount) {
        this.editModel.amount = total;
      }
    },
    operateText(item) {
      let result = '';
      // if (item.status === this.statusM.pending && this.isDeliveryManager) {
      //   result = '确认信息';
      // }
      // if (item.status === this.statusM.tosend && this.user.meta && this.user.meta.party_id === item.from_id) {
      //   result = '待发货';
      // }
      if ((item.status === this.statusM.sended || item.status === this.statusM.delay)
        && this.user.meta && hasParty(this.user.meta, item.to_id)) {
        result = '待收货';
      }
      return result;
    },
    // 打开详情
    toDetail(editObj) {
      this.files = [];
      if (this.$refs.detailFile) {
        this.$refs.detailFile.reset();
      }
      this.$http.get(`dv-delivery/${editObj.id}`).then((resp) => {
        const record = resp.data;
        const detailMap = {};
        let totalSum = 0;
        record.details.forEach((item) => {
          detailMap[item.item_md5] = item;
          totalSum = this.$calc.Add(totalSum, item.sum);
        });
        record.items = record.order.items;
        record.items.forEach((item) => {
          item.num = detailMap[item.item_md5] ? detailMap[item.item_md5].quantity : 0;
          // item.sum = detailMap[item.item_md5] ? detailMap[item.item_md5].sum : 0;
          item.count = detailMap[item.item_md5] ? detailMap[item.item_md5].count : '';
        });
        this.total = totalSum;
        this.editModel = record;
        this.showDialog = true;
        this.$nextTick(() => {
          if (this.$refs.detailForm) {
            this.$refs.detailForm.clearValidate();
          }
        });
      });
    },
    // 选中上传文件时
    changeFile(files) {
      if (this.$refs.addFile.sizeWarning) {
        this.$utils.msg('文件过大', 'warning');
        return;
      }
      if (files.length > 0) {
        this.files = [];
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
      } else {
        this.files = [];
      }
    },
    // 详情页上传文件选择
    changeDetailFile(files) {
      if (this.$refs.detailFile.sizeWarning) {
        this.$utils.msg('文件过大', 'warning');
        return;
      }
      if (files.length > 0) {
        this.files = [];
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
      } else {
        this.files = [];
      }
    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const arr = this.editModel.items;
        let num = 0;
        arr.forEach((item) => {
          num += item.num;
        });
        if (!num) {
          this.$message.warning('发货数量不能都为0');
          return;
        }
        if (this.$refs.addFile.identifyBorwser() && this.files.length > 0) {
          const formData = new window.FormData(this.$refs.editForm.$el);
          // api中，上传头像参数用avatar,还有media、document、attachment
          formData.append('hint', 'delivery');
          this.files.forEach((ele) => {
            formData.append('file[]', ele);
          });
          this.waiting = true;
          this.$http.post('transfile', formData).then((resp) => {
            this.editModel.addFiles = resp.data;
            this.saveSubmit();
          }, (err) => {
            this.editModel.addFiles = [];
            this.waiting = false;
            this.$utils.resp(err);
          });
        } else {
          this.saveSubmit();
        }
      });
    },
    updateFile() {
      if (this.$refs.detailFile.identifyBorwser() && this.files.length > 0) {
        const formData = new window.FormData();
        // api中，上传头像参数用avatar,还有media、document、attachment
        formData.append('hint', 'delivery');
        this.files.forEach((ele) => {
          formData.append('file[]', ele);
        });
        this.waiting = true;
        this.$http.post('transfile', formData).then((resp) => {
          this.editModel.addFiles = resp.data;
          this.$http.put(`dely-record/${this.editModel.id}/update-attr`, this.editModel).then((resp) => {
            this.toDetail({ id: resp.data.id });
          });
        }, (err) => {
          this.editModel.addFiles = [];
          this.waiting = false;
          this.$utils.resp(err);
        });
      }
    },
    // submit
    saveSubmit() {
      const obj = { ...this.editModel };
      this.waiting = true;
      if (obj.id) {
        this.$http.put(`dv-delivery/${obj.id}`, obj).then(() => {
          this.editDialog = false;
          this.getList();
          this.$message({
            type: 'success',
            message: '更新成功',
            showClose: true,
            duration: 2000,
          });
          this.waiting = false;
        }, (err) => {
          this.waiting = false;
          this.$utils.resp(err);
        });
      } else {
        this.$http.post('dv-delivery', obj).then(() => {
          this.editDialog = false;
          this.waiting = false;
          this.$message({
            type: 'success',
            message: '保存成功',
            showClose: true,
            duration: 2000,
          });
          this.getList();
        }, (err) => {
          this.waiting = false;
          this.$utils.resp(err);
        });
      }
    },
    updateInfo() {
      this.waiting = true;
      this.$http.put(`dely-record/${this.editModel.id}/update-info`, this.editModel).then((resp) => {
        this.waiting = false;
        this.editModel.m_remark = resp.data.m_remark;
        this.editModel.amount = resp.data.amount;
        this.editModel.offset = resp.data.offset;
        this.$message.success('已更新');
      }, (err) => {
        this.waiting = false;
        this.$utils.resp(err);
      });
    },
    // 公司确认发货信息是否批准
    checkDelivery(record) {
      this.waiting = true;
      this.$http.put(`dv-delivery/${record.id}/change`, { status: this.statusM.tosend }).then(() => {
        this.getList();
        this.waiting = false;
        this.showDialog = false;
      }, (err) => {
        this.waiting = false;
        this.$utils.resp(err);
      });
    },
    // 发货方发货
    toDelivery(record) {
      this.waiting = true;
      this.$http.put(`dv-delivery/${record.id}/change`, { status: this.statusM.sended }).then(() => {
        this.getList();
        this.waiting = false;
        this.showDialog = false;
      }, (err) => {
        this.waiting = false;
        this.$utils.resp(err);
      });
    },
    // 收货方确认收货
    finishDelivery(record) {
      if (!this.editModel.receive_date) {
        this.$message.warning('请选择收货日期');
        return;
      }
      this.$confirm('是否确认收货？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.put(`dv-delivery/${record.id}/got`, {
          receive_date: this.editModel.receive_date,
          receive_at: this.editModel.receive_at,
        }).then(() => {
          this.waiting = false;
          this.getList();
          this.showDialog = false;
        }, (err) => {
          this.waiting = false;
          this.$utils.resp(err);
        });
      }, () => {});
    },
    // 收货方延迟收货
    ycDelivery(record) {
      this.$confirm('是否延迟收货？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.put(`dv-delivery/${record.id}/change`, { status: this.statusM.delay }).then(() => {
          this.getList();
          this.waiting = false;
          this.showDialog = false;
        });
      }, () => {});
    },
    // 删除
    delIt(item = null) {
      const id = item ? item.id : this.editModel.id;
      this.$confirm('删除后无法恢复，是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.delete(`dv-delivery/${id}`).then(() => {
          this.getList();
          this.editDialog = false;
          this.showDialog = false;
          this.waiting = false;
          this.$message({
            type: 'success',
            message: '删除成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    // 文件下载
    getfile(fileName) {
      const params = {
        hint: 'delivery',
        name: fileName,
      };
      return this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        return objurl;
      }, (err) => {
        this.$utils.resp(err);
        // todo
        return '404png';
      });
    },
    downloadFile(item, name = false) {
      const storeName = item.name;
      this.getfile(storeName).then((data) => {
        const tmp = item.orgName.split('.');
        const ext = tmp[tmp.length - 1];
        this.downloadFileName = name ? name + '.' + ext : item.orgName;
        this.downloadUrl = data;
        this.$nextTick(() => {
          this.$refs.downloadLink.click();
        });
      });
    },
    // 清空选择
    clearCheck() {
      this.checkedIds = [];
      this.list.forEach((item) => {
        item.checked = false;
      });
    },
    // 当前页全选和全不选操作
    checkAll(val) {
      this.list.forEach((item) => {
        item.checked = val;
        const index = this.checkedIds.indexOf(item.id);
        if (val && index === -1) {
          this.checkedIds.push(item.id);
        } else if (index !== -1 && !val) {
          this.checkedIds.splice(index, 1);
        }
      });
    },
    // 选择记录，打印用
    checkItem(item, val) {
      const index = this.checkedIds.indexOf(item.id);
      if (val && index === -1) {
        this.checkedIds.push(item.id);
      } else if (index !== -1 && !val) {
        this.checkedIds.splice(index, 1);
      }
      this.checkIsAll();
    },
    // 判断是否全选
    checkIsAll() {
      const len = this.list.length;
      let isAll = true;
      for (let i = 0; i < len; i += 1) {
        if (!this.list[i].checked) {
          isAll = false;
          break;
        }
      }
      this.allCheck = isAll;
    },
    // 详情
    // showDetail(id) {
    //   this.$http.get(`expense/${id}`).then((resp) => {
    //     const result = resp.data;
    //     result.rel_amount = this.getRelPay(result);
    //     this.showModel = result;
    //     if (this.showModel.attachments) {
    //       this.showModel.attachments.forEach((item) => {
    //         this.getfile(item.name).then((url) => {
    //           this.$set(item, 'url', url);
    //         });
    //       });
    //     }
    //     this.showDialog = true;
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
   .status-color-1 {
    color: #409eff;
    // color: #e6a23c;
  }
  .status-color-2 {
    color: #e6a23c;
  }
  .status-color-3 {
    color: #67c23a;
  }
  .status-color-4 {
    color: #909399;
  }
  .status-color-9 {
    color: #f56c6c;
  }
  .el-input.warning {
    border: 1px solid #ffcd04;
  }
  .el-input.danger {
    border: 1px solid #f56c6c;
  }
</style>
