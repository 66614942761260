<template>
  <el-card>
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="打样编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.date" clearable placeholder="申请日期" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.kh" clearable placeholder="款号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>
    <el-table :data="list" border :height="'calc(100vh - 230px)'" @sort-change="sort"
      v-loading="loading" :default-sort="{prop: 'data01', order: 'descending'}">
      <el-table-column fixed prop="data01" sortable="custom" label="打样编号" width="100"></el-table-column>
      <el-table-column fixed prop="data02" label="申请日期" width="100"></el-table-column>
      <el-table-column fixed prop="data03" label="款号" width="200"></el-table-column>
      <el-table-column prop="data04" label="品名" width="200"></el-table-column>
      <el-table-column prop="data05" label="类别" width="150"></el-table-column>
      <el-table-column prop="data06" label="规格" width="100"></el-table-column>
      <el-table-column prop="data07" label="数量" width="100"></el-table-column>
      <el-table-column prop="data08" label="业务部门" width="120"></el-table-column>
      <el-table-column prop="data09" label="客户" width="200"></el-table-column>
      <el-table-column prop="data10" label="面料到位日期" width="100"></el-table-column>
      <el-table-column prop="data11" label="辅料到位日期" width="100"></el-table-column>
      <el-table-column prop="data12" label="制版" width="100"></el-table-column>
      <el-table-column prop="data13" label="日期" width="100"></el-table-column>
      <el-table-column prop="data14" label="裁剪" width="100"></el-table-column>
      <el-table-column prop="data15" label="缝制" width="100"></el-table-column>
      <el-table-column prop="data16" label="后道" width="100"></el-table-column>
      <el-table-column prop="data17" label="样衣完成数量" width="100"></el-table-column>
      <el-table-column prop="data18" label="样衣完成日期" width="100"></el-table-column>
      <el-table-column prop="data19" label="申请人" width="100"></el-table-column>
      <el-table-column prop="data20" label="有无样衣" width="80"></el-table-column>
      <el-table-column prop="data21" label="备注" width="200"></el-table-column>
    </el-table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="sizes, total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-sizes="[15, 50, 100, 200, 500]"
        @size-change="sizeChange"
        :page-size="pageSize">
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      queryForm: {
        code: '',
        kh: '',
        date: '',
        sort: 'data01',
        order: 'desc',
      },
      params: {},
      list: [],
      pageSize: 15,
      currentPage: 1,
      totalItem: 0,
    };
  },
  created() {
    // this.changePage(1);
  },
  methods: {
    onSubmitQuery() {
      this.params.kh = this.queryForm.kh;
      this.params.code = this.queryForm.code;
      this.params.date = this.queryForm.date;
      this.changePage(1);
    },
    getList() {
      const params = { ...this.params, pageSize: this.pageSize, page: this.currentPage };
      this.loading = true;
      this.$http.get('dysummary', { params }).then((resp) => {
        this.loading = false;
        const list = resp.data.data;
        this.list = list;
        this.totalItem = resp.data.total;
      }, (err) => {
        this.loading = false;
        this.$utils.resp(err);
      });
    },
    sizeChange(size) {
      this.pageSize = size;
      this.changePage(1);
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    sort(obj) {
      if (obj.prop) {
        this.params.order = obj.order.split('ending')[0];
        this.params.sort = obj.prop;
      } else {
        this.params.order = '';
        this.params.sort = '';
      }
      this.changePage(1);
    },
  },
};
</script>

<style>

</style>
