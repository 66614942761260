<template>
  <div class="container">
    <main-header></main-header>
    <main-sidebar></main-sidebar>
    <div class="main-content pad-all">
      <div class="clearfix">
        <div class="pull-left" style="width: 100%;">
          <keep-alive :include="['wb-project', 'wb-order', 'dv-order']">
            <router-view></router-view>
          </keep-alive>
        </div>
      </div>
    </div>
    <pwd-edit></pwd-edit>
    <init-user></init-user>
    <show-big-img></show-big-img>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MainHeader from '@/components/widgets/Header';
import MainSidebar from '@/components/widgets/Sidebar';
import PwdEdit from '@/components/PwdEdit';
import ShowBigImg from '@/components/ShowBigImg';
import InitUser from '@/components/InitUser';

export default {
  components: {
    MainHeader,
    MainSidebar,
    PwdEdit,
    InitUser,
    ShowBigImg,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      loginData: state => state.auth.loginData,
    }),
  },
  beforeCreate() {
    if (!window.localStorage.getItem('pc_token')) {
      this.$router.push('/login');
    } else if (Object.keys(this.$store.state.auth.user).length === 0) {
      this.$store.dispatch('AUTH_GET_USER');
    }
  },
  mounted() {
    this.$root.$data.isToggle = false;
  },
  methods: {
  },
  watch: {
    loginData(val) {
      if (val) {
        this.$store.dispatch('AUTH_GET_USER');
      }
    },
  },
};
</script>
