<template>
  <li class="item"
    :class="[isMain ? 'item-container' : '', 'lel-'+level,
      (isMain && isMin) || isOpened ? openedClass : '']"
    v-if="item.children && item.children.length > 0">
    <div :style="{paddingLeft: indent*level + 'px'}"
      :class="[isMain ? 'first-title' : '','subm-title', titleClass, isCurrent ? currentClass : '']"
        @click="toggleOpen">
      <span :class="['fa', 'fa-lg', 'subm-icon', isOpened
        ? 'fa-angle-down' : 'fa-angle-right']"></span>
      <i :class="'icon fa fa-fw fa-' + item.icon"></i>
      <span class="title-text">
        &nbsp;{{item.title}}
      </span>
    </div>
    <slide-transition :toggle="!(isMain && isMin)">
    <div v-show="(isMain && isMin) || isOpened" :class="[subClass]">
      <ul :class="{ 'second-submenu': isMain }">
        <submenu :key="index" v-for="(subItem, index) in canChildren"
          :item="subItem"
          :routeName="routeName"
          :indexName="indexName"
          :isMain="false"
          :isMin="isMin"
          :level="level+1"
          :currentClass="currentClass"
          :titleClass="titleClass"
          :indent="indent">
        </submenu>
      </ul>
    </div>
    </slide-transition>
  </li>
  <li v-else-if="item[indexName] && item[indexName].indexOf('_') !== 0"
    :class="{'item-container': isMain}">
    <router-link ref="link" :to="processLink(item)"
      @click.native="commitFun(item)"
      event="preventDefault"
      :exact="isExact(item)"
      :style="{ paddingLeft: (indent*level) + 'px' }"
      :class="[titleClass, (isMain ? 'first-title' : '')]">
      <i :class="'icon fa fa-fw fa-' + item.icon"></i>
      <el-badge v-if="badges[item.name] !== undefined && badges[item.name] > 0"
        class="dotbadge badge-bg badge-warning" is-dot></el-badge>
      <span class="title-text">
      &nbsp;{{item.title}}
        <el-badge class="menu-item-badge badge-bg badge-warning"
          v-if="badges[item.name] !== undefined" :value="badges[item.name]" :max="99">
        </el-badge>
      </span>
    </router-link>
  </li>
  <li v-else :class="{'item-container': isMain}">
    <div :style="{ paddingLeft: (indent*level) + 'px' }"
      :class="[titleClass, (isMain ? 'first-title' : '')]" >
      <i :class="'icon fa fa-fw fa-' + item.icon"></i>
      <span class="title-text">
        &nbsp;{{item.title}}
      </span>
    </div>
  </li>
</template>

<script>
import SlideTransition from './slide-transition';

export default {
  name: 'submenu',
  beforeCreate() {
  },
  created() {
  },
  components: {
    SlideTransition,
  },
  mounted() {
    const { link } = this.$refs;
    if (link
        && link.$el.className.indexOf('router-link-active') !== -1
        && typeof this.$parent.setCurrent === 'function') {
      this.$parent.setCurrent();
    }
  },
  props: {
    // 菜单对应的标识(item[indexName]取值用，以'_'开头的没有children的只显示文字，不跳转，默认name)
    indexName: {
      type: String,
      default: 'name',
    },
    // 是否为第一级菜单
    isMain: {
      type: Boolean,
      default: true,
    },
    // 用来监测route变化的
    routeName: {
      default: '',
    },
    // 当前菜单对象
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    // 菜单层级
    level: {
      type: Number,
      default: 1,
    },
    // 当前是父节点的，展开状态的class
    openedClass: { // 展开状态菜单的class
      type: String,
      default: 'submenu-opened',
    },
    // 含有router-link选中的父类菜单class
    currentClass: {
      type: String,
      default: 'has-current',
    },
    // 菜单的标题 class
    titleClass: {
      type: String,
      default: 'sidebar-link',
    },
    subClass: { // 子菜单content class
      type: String,
      default: 'submenu-list',
    },
    indent: { // 缩进距离
      type: Number,
      default: 20,
    },
    isMin: { // 是否为缩小菜单模式
      type: Boolean,
      default: true,
    },
    // 标注对象el-badge用的内容
    badges: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isOpened: false,
      isCurrent: false,
    };
  },
  computed: {
    canChildren() {
      let result = [];
      if (this.item.children) {
        result = this.item.children.filter(a => a.can);
      }
      return result;
    },
  },
  watch: {
    routeName() {
      this.isCurrent = false;
      this.isOpened = false;
      const { link } = this.$refs;
      this.$nextTick(() => {
        if (link && link.$el.className.indexOf('router-link-active') !== -1
          && typeof this.$parent.setCurrent === 'function') {
          this.$parent.setCurrent();
        }
      });
    },
  },
  methods: {
    // 展开和收起菜单，一级菜单在最小图标模式下不变，会用css的:hover右侧显示
    toggleOpen() {
      if (!(this.isMain && this.isMin)) {
        this.isOpened = !this.isOpened;
      }
    },
    // 提供给子组件调用的方法，如果子菜单是当前页面菜单通过此方法设置当前组件的选中状态和展开状态，递归直到根目录
    setCurrent() {
      this.isCurrent = true;
      this.isOpened = true;
      if (typeof this.$parent.setCurrent === 'function' && !this.isMain) {
        this.$parent.setCurrent();
      }
    },
    // 获取route-link 的 :to属性对应的对象
    processLink(item) {
      return { name: item.name };
    },
    // 根据route.meta.exact 判断是否需要精确匹配url地址
    isExact(item) {
      const tRoute = this.$router.resolve({ name: item.name });
      return tRoute.route.meta.exact;
      // return false;
    },
    // meta设置了commitName的触发对应的commit, 用event放一个没有的事件屏蔽click跳转，用push
    commitFun(item) {
      // 查看vue-router是否有meta.commitName
      const tRoute = this.$router.resolve({ name: item.name });
      const { commitName } = tRoute.route.meta;
      // 点击的菜单如果是当前页应该不处理
      if (commitName && tRoute.route.name !== this.$route.name) {
        this.$store.commit(commitName);
      }
      this.$router.push(this.processLink(item));
    },
  },
};
</script>
