import Vue from 'vue';
import { Message } from 'element-ui';
// import * as _find from 'lodash/find';
import router from '@/router';

const USER_URL = 'auth/me';
const LOGIN_URL = 'auth/login';

const state = {
  user: {},
  userMeta: { branch: {} },
  curCompanyType: null, // 公司切换，如果是多公司的情况
  curDeptId: window.localStorage.getItem('cur_dept'), // 多公司时当前部门id
  token: window.localStorage.getItem('pc_token')
    ? window.localStorage.getItem('pc_token')
    : false,
  loginLoading: false,
  loginData: {},
  // breadcrumbList: [],
  msgList: [],
};
const getters = {
  userDeptId: (state) => {
    let id = state.user.department_id;
    if (String(state.curCompanyType) === '2') {
      id = state.user.meta ? state.user.meta.department_id2 * 1 : null;
    }
    return id;
  },
};
const actions = {
  AUTH_LOGIN({ commit }, params) {
    // Vue.axios.post(LOGIN_URL, params, { headers: { Authorization: '' } }).then((response) => {
    Vue.axios.post(LOGIN_URL, params).then((response) => {
      commit('AUTH_SET_TOKEN', response.data.token);
      commit('SET_LOGIN_DATA', { loginData: response.data.token });
      // 登录时清除之前的用户信息 replace时会判断后根据token重新请求用户信息
      commit('AUTH_SET_USER', {});
    }, (resp) => {
      if (resp.response.status === 401) {
        Message({
          showClose: true,
          message: '用户名密码错误',
          type: 'warning',
          duration: 5000,
        });
      } else {
        Vue.utils.resp(resp);
      }
    }).then(() => {
      commit('SET_LOGIN_LOADING', { loading: false });
    });
    // Vue.axios.post(LOGIN_URL, params, { headers: { Authorization: '' } }).then((response) => {
    //   window.localStorage.setItem('canRefresh', '');
    //   // inCrop用来标记用户是否已经选择了企业
    //   window.localStorage.setItem('inCrop', '');
    //   if (params.remember) {
    //     window.localStorage.setItem('canRefresh', 1);
    //   }
    //   commit('AUTH_SET_TOKEN', response.data.token);
    //   // 登录时清除之前的用户信息 replace时会判断后根据token重新请求用户信息
    //   commit('AUTH_SET_USER', {});
    //   commit('SET_LOGIN_LOADING', { loading: false });
    //   commit('SET_LOGIN_DATA', { loginData: response.data });
    // }, (response) => {
    //   commit('SET_LOGIN_LOADING', { loading: false });
    //   // 登录的401需要特别对待
    //   if (response.status === 401) {
    //     Message.error({
    //       message: '无法登录，请确认您的用户名密码输入',
    //       duration: 5000,
    //     });
    //   } else {
    //     Vue.mp.resp(response);
    //   }
    // });
  },
  AUTH_GET_USER({ state, commit, dispatch }) {
    dispatch('AUTH_GET_NOTIFICATIONS');
    return new Promise((resolve, reject) => {
      Vue.axios.get(USER_URL).then((response) => {
        dispatch('AUTH_GET_METAS', response.data.meta);
        commit('AUTH_SET_USER', response.data);
        const orgType = state.curCompanyType;
        commit('SET_USER_COMPANY', response.data);
        // 获取公司后如果需要重新请求的话
        if (orgType !== state.curCompanyType) {
          dispatch('UPDATE_COMPANY_TYPE', state.curCompanyType);
        }
        resolve(response.data);
      }, (resp) => {
        Vue.utils.resp(resp);
        reject(resp);
      });
    });
  },
  // 获取用户的部门信息
  AUTH_GET_NOTIFICATIONS({ commit }) {
    Vue.axios.get('sys/notification').then((resp) => {
      commit('AUTH_SET_NOTIFICATIONS', resp.data);
    });
  },
  READ_NOTIFYICATION({ dispatch }, notificationId) {
    Vue.axios.put(`sys/notification/${notificationId}`).then(() => {
      dispatch('AUTH_GET_NOTIFICATIONS');
    });
  },
  // 所属角色老的部门的所属分公司
  AUTH_GET_METAS({ commit }, meta) {
    if (meta && meta.branch_id) {
      Vue.axios.get('depts/' + meta.branch_id, { params: { with_ancestors: 1 } }).then((resp) => {
        let branch = resp.data;
        if (resp.data.ancestors.length >= 2) {
          branch = resp.data.ancestors[1];
        }
        commit('AUTH_SET_METAS', { type: 'branch', value: branch });
      }, (err) => {
        Vue.utils.resp(err);
      });
    }
  },
  UPDATE_COMPANY_TYPE({ commit, dispatch }, type) {
    commit('USER_COMPANY_CHANGE', type);
    dispatch('GET_ORGANIZATION');
  },
};
/* eslint-disable no-shadow, no-param-reassign */
const mutations = {
  AUTH_SET_TOKEN(state, token = false) {
    state.token = token;
    window.localStorage.setItem('pc_token', token);
  },
  AUTH_SET_USER(state, user) {
    state.user = user;
  },
  AUTH_LOGOUT(state) {
    window.localStorage.removeItem('pc_token');
    state.token = false;
    state.user = {};
    router.push('/login');
  },
  SET_BREADCRUMB_LIST(state, payload) {
    state.breadcrumbList = payload.list;
  },
  SET_LOGIN_LOADING(state, payload) {
    state.loginLoading = payload.loading;
  },
  SET_LOGIN_DATA(state, payload) {
    state.loginData = payload.loginData;
  },
  AUTH_SET_NOTIFICATIONS(state, payload) {
    state.msgList = payload || [];
  },
  AUTH_SET_METAS(state, payload) {
    state.userMeta[payload.type] = payload.value;
    state.user.meta[payload.type] = payload.value;
  },
  SET_USER_COMPANY(state, payload) {
    let { user } = state;
    if (payload) {
      user = payload;
    }
    if (user.meta && user.meta.department_id2) {
      if (String(user.meta.department_id2) === String(state.curDeptId)) {
        state.curCompanyType = '2';
      } else {
        state.curCompanyType = '1';
        localStorage.setItem('cur_dept', user.department_id);
      }
    } else {
      localStorage.setItem('cur_dept', null);
      state.curCompanyType = null;
    }
  },
  // 切换公司 1国华，2豪鼎
  USER_COMPANY_CHANGE(state, type) {
    const typeStr = String(type);
    let deptId = state.user.department_id;
    if (typeStr === '2') {
      deptId = state.user.meta.department_id2;
      state.curCompanyType = '2';
    } else if (state.user.meta && state.user.meta.department_id2) {
      state.curCompanyType = '1';
    } else {
      state.curCompanyType = null;
    }
    state.curDeptId = deptId;
    localStorage.setItem('cur_dept', deptId);
  },
  // ADD_URLLIST_ITEM(state, payload) {
  //   state.urlList.push(payload);
  // },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
