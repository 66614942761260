<template>
  <el-card class="components">
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane name="first" label="退货审核">
      <div class="mar-btm-10 pull-right">
        <div class="box-inline standard-search">
          <el-input v-model="queryForm.code" clearable placeholder="编号" @keydown.enter.native="onSubmitQuery"></el-input>
        </div>
        <div class="box-inline standard-search">
          <el-select v-model="queryForm.status" clearable placeholder="状态">
            <el-option v-for="(label, key) in statusMap" :key="key" :value="key" :label="label"></el-option>
          </el-select>
        </div>
        <div class="box-inline standard-search">
          <el-select v-model="queryForm.type" clearable placeholder="类型">
            <el-option v-for="(label, key) in typeMap" :key="key" :value="key" :label="label"></el-option>
          </el-select>
        </div>
        <el-button size="small" @click="onSubmitQuery">
          <i class="fa fa-fw fa-search"></i>
        </el-button>
      </div>

      <table class="table el-table">
        <thead>
          <tr>
            <th width="40">#</th>
            <th>款号</th>
            <th>类别</th>
            <th>颜色</th>
            <th>尺码</th>
            <th>SKU</th>
            <th>状态</th>
            <th>渠道</th>
            <th>数量</th>
            <th>退货入库日期</th>
            <th>退货编号</th>
            <th>备注</th>
            <th>审核人</th>
            <th>审核时间</th>
            <th width="1%">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="item.id">
            <td>{{index + 1}}</td>
            <td>{{ item.kh }}</td>
            <td>{{ item.category_text}}</td>
            <td>{{ item.color_text}}</td>
            <td>{{ item.size_text}}</td>
            <td>{{ item.code }}</td>
            <td>{{ statusMap[item.status]}}</td>
            <td>{{ typeMap[item.sub_type]}}</td>
            <td>{{ item.count }}</td>
            <td>{{ item.occur_date }}</td>
            <td>{{ item.order_num }}</td>
            <td>{{ item.check_remark ?  item.check_remark : item.remark}}</td>
            <td>{{ item.checker }}</td>
            <td>{{ item.check_at }}</td>
            <td>
              <div class="text-nowrap">
                <el-button v-if="item.status === 'unChecked' && isCw"
                  size="small" title="审核" @click="checkItem(item)"><i class="fa fa-fw fa-check"></i></el-button>
                <el-button title="修改" v-if="item.status === 'unChecked' && !isCw" icon="fa fa-fw fa-pencil"
                  @click="toEdit(item)"></el-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="clearfix mar-top">
        <el-pagination class="pull-right"
          @current-change="changePage"
          layout="total, prev, pager, next"
          :total="totalItem"
          :current-page="currentPage"
          :page-size="pageSize">
        </el-pagination>
      </div>

      <el-dialog :visible.sync="editDialog" title="编辑"
        :close-on-click-modal="false">
        <el-form :model="editModel" ref="editForm" label-width="100px">
          <el-form-item label="款号：">
            {{editModel.kh}}
          </el-form-item>
          <el-form-item label="退货收到日：">
            {{editModel.occur_date}}
          </el-form-item>
          <el-form-item label="渠道：">
            <el-select v-model="editModel.sub_type">
              <el-option v-for="(label, key) in typeMap" :key="key" :value="key" :label="label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="退货编号：">
            <el-input v-model="editModel.order_num"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="editModel.check_remark"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editDialog = false">
            <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
          <el-button type="primary" @click="updateModel">
            <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
        </div>
      </el-dialog>
    </el-tab-pane>
    <el-tab-pane name="second" label="旧款审核记录">
      <OrgReturn></OrgReturn>
    </el-tab-pane>
  </el-tabs>
  </el-card>
</template>

<script>
import { mapState } from 'vuex';
import OrgReturn from '../stock/ClothesReturn';

export default {
  components: {
    OrgReturn,
  },
  data() {
    return {
      activeName: 'first',
      queryForm: {
        code: '',
        status: 'unChecked',
        type: '',
      },
      statusMap: {
        unChecked: '待审核',
        checked: '已审核',
      },
      editDialog: false,
      editModel: {},
      typeMap: {
        '-1': '淘宝',
        '-4': '天猫',
        '-5': '抖音1',
        '-51': '抖音2',
        '-7': '京东',
        '-8': '小红书',
        '-3': '分销',
        '-52': '视频号',
      },
      list: [],
      queryParams: {
        code: '',
        status: '',
        type: '',
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      sizes: [],
      seasons: [],
      categories: [],
      colors: [],
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    isCw() {
      return this.user.allPerms.indexOf('clothes_cw') > -1;
    },
    canEdit() {
      return this.user.allPerms.indexOf('clothes_in_out') > -1;
    },
  },
  created() {
    this.init();
    this.onSubmitQuery();
  },
  watch: {
  },
  methods: {
    handleClick() {

    },
    init() {
      if (this.$store.state.common.clothesDicts.sizes) {
        this.sizes = this.$store.state.common.clothesDicts.sizes;
        this.seasons = this.$store.state.common.clothesDicts.seasons;
        this.categories = this.$store.state.common.clothesDicts.orgCategories;
        this.colors = this.$store.state.common.clothesDicts.orgColors;
      } else {
        this.$store.dispatch('GET_COLTHES_DICTS').then((resp) => {
          this.sizes = resp.data.sizes;
          this.seasons = resp.data.seasons;
          this.categories = resp.data.orgCategories;
          this.colors = resp.data.orgColors;
        });
      }
    },
    onSubmitQuery() {
      this.queryParams = {};
      if (this.queryForm.code && this.queryForm.code.trim()) {
        this.queryParams.code = this.queryForm.code;
      }
      if (this.queryForm.status && this.queryForm.status.trim()) {
        this.queryParams.status = this.queryForm.status;
      }
      if (this.queryForm.type && this.queryForm.type.trim()) {
        this.queryParams.type = this.queryForm.type;
      }
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    // 查询列表数据
    getList() {
      this.axios.get('e-clothes-return', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          list.forEach((item) => {
            if (item.code > 10) {
              // 旧版编号
              item.category_text = this.categories[item.code.substr(3, 2)];
              item.color_text = this.colors[item.code.substr(7, 2)];
              item.size_text = this.sizes[item.code.substr(9, 1)];
              item.kh = item.code.substr(2, 5);
            } else {
              // 新版
              item.category_text = item.clothes.sub_category;
              item.color_text = item.clothes.color;
              item.size_text = item.clothes.size;
              item.kh = item.code.substr(0, 5);
            }
          });
          this.list = list;
          this.totalItem = response.data.total;
        }, (err) => {
          this.$utils.resp(err);
        });
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toEdit(item) {
      this.editModel = { ...item };
      this.editDialog = true;
    },
    updateModel() {
      this.axios.put(`e-clothes-return/${this.editModel.id}`, this.editModel).then(() => {
        this.getList();
        this.editDialog = false;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    closeEditDialog() {
      // this.canChooseBranch = true;
    },
    checkItem(item) {
      this.$confirm('是否确认通过？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.axios.put(`e-clothes-return/${item.id}/check`).then(() => {
          this.getList();
        });
      }, () => {});
    },
  },
};
</script>
