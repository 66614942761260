<template>
  <el-card class="components">
    <!-- <el-button type="primary" size="small" v-if="managerUser"
      @click="test()"><i class="fa fa-fw fa-plus-circle"></i> test</el-button> -->
    <el-button type="primary" size="small" v-if="managerUser"
      @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search" style="margin-right: 5px;width: 300px">
        <el-select v-model="queryForm.company" placeholder="开票单位" :loading="queryPartiesLoading"
          clearable filterable remote :remote-method="queryParties">
          <el-option v-for="item in parties" :key="item.party_id"
            :value="item.party_name" :label="item.party_name"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-date-picker placeholder="对账日期" @change="submitQuery" value-format="yyyy-MM-dd" v-model="queryForm.confirmation_at"></el-date-picker>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status" clearable placeholder="状态选择" @change="submitQuery">
          <el-option label="全部" value=""></el-option>
          <el-option v-for="(val, key) in statuses" :label="val" :key="key" :value="key"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.checker" clearable placeholder="收件人" @change="submitQuery">
          <el-option v-for="(val, index) in receiptContacts" :label="val.name" :key="index" :value="val.name"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="submitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>
    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>开票单位</th>
          <th>对账日期</th>
          <th>业务部门</th>
          <th>收件人</th>
          <th>状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>
            {{ index + 1 }}
          </td>
          <td>{{ item.company }}</td>
          <td>{{ item.confirmation_at }}</td>
          <td>{{ item.department }}</td>
          <td>{{ item.checker }}</td>
          <td>
            <span :class="[`status-color-${item.status}`]">
              {{ item.status_text }}</span>
          </td>
          <td>
            <div class="text-nowrap">
              <el-button @click="toDetail(item)" icon="fa fa-fw fa-search"></el-button>
              <el-button v-if="managerUser" @click="toEdit(item)" type="warning" icon="fa fa-fw fa-pencil"></el-button>
              <el-button title="导出excel"
                @click="excel(item)" icon="fa fa-fw fa-download"></el-button>
              <!-- <el-button v-if="canModify(item)" type="default" style="margin-left: 3px;"
                icon="fa fa-fw fa-pencil" title="修改信息" @click="toEdit(item)" :disabled="waiting"></el-button>
              <el-button v-if="needConfirm(item)" title="订单确认" @click="toDetail(item)"
                icon="fa fa-fw fa-check" :disabled="waiting" type="warning" style="margin-left: 3px;"></el-button>
              <el-button v-if="canViewCheck(item)" title="订单审核" @click="toDetail(item)"
                icon="fa fa-fw fa-check" :disabled="waiting" type="warning" style="margin-left: 3px;"></el-button> -->
              <!-- <el-button v-if="isDeliveryManager" type="warning" style="margin-left: 3px;"
                icon="fa fa-fw fa-copy" title="拷贝" @click="copyIt(item)" :disabled="waiting"></el-button> -->
              <!-- <el-button v-if="!needConfirm(item)" size="small" type="primary"
                title="详情" @click="toDetail(item)" style="margin-left: 3px;"
                icon="fa fa-fw fa-search" :disabled="waiting"></el-button> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="itemDialog" title="选择入库记录"
      :close-on-click-modal="false" width="95%" top="2%">
      <div>
        <ItemSelect ref="itemChecks" :selectedList="toMatchList"
         @selected="addItems" :company="currentCompany"></ItemSelect>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="itemDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button @click="selectedItems" type="primary" icon="fa fa-fw fa-times"> 确 认</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'"
      :close-on-click-modal="false" width="99%" top="2%">
      <div>
        <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
          <el-form-item prop="company_id" label="开票单位：">
            <el-select v-if="!editModel.id" style="width:100%" v-model="editModel.company_id" placeholder="开票单位" :loading="queryPartiesLoading2"
              clearable filterable remote :remote-method="queryParties2">
              <el-option v-for="item in parties2" :key="item.party_id"
                :value="item.party_id" :label="item.party_name"></el-option>
            </el-select>
            <el-input readonly="" v-else :value="editModel.company"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="confirmation_at" label="对账日期：">
                <el-date-picker value-format="yyyy-MM-dd" v-model="editModel.confirmation_at"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="department_id" label="部门：">
                <el-input readonly v-if="!editModel.id && userDeptId && deptMap[userDeptId]"
                  :value="deptMap[userDeptId].name"></el-input>
                <el-input v-else readonly :value="editModel.department"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="业务员：">
                <el-input v-model="editModel.business_manager"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="contact_id" label="收件人：">
                <el-select v-model="editModel.contact_id" @change="changeContact">
                  <el-option v-for="item in contacts" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="checker_phone" label="电话：">
                <el-input v-model="editModel.checker_phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="checker_addr" label="地址：">
                <el-input v-model="editModel.checker_addr"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注说明">
            <el-input type="textarea" v-model="editModel.remark"></el-input>
          </el-form-item>
          <div style="overflow: auto; min-height: 300px;max-height: 600px;">
            <table style="min-width: 1300px;" class="table tight">
              <thead>
                <tr>
                  <th class="text-center" width="110">发票类型</th>
                  <th class="text-center" width="120">款号</th>
                  <th class="text-center" width="120">运编号</th>
                  <th class="text-center" width="120">项目号</th>
                  <th class="text-center" width="120">PO#</th>
                  <th class="text-center" width="120">品名</th>
                  <th class="text-center" width="70">对应库存量</th>
                  <th class="text-center" width="70">开票数量</th>
                  <th class="text-center" width="80">单位</th>
                  <th class="text-center" width="80">单价</th>
                  <th class="text-center" width="80">金额</th>
                  <th class="text-center" width="50">是否结账</th>
                  <th class="text-center">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in editModel.items" :key="index">
                  <td>
                    <el-select v-model="item.type" style="width:160px">
                      <el-option v-for="(type, idx) in types" :key="idx" :label="type" :value="type"></el-option>
                    </el-select>
                  </td>
                  <td><el-input v-model="item.kh"></el-input></td>
                  <td><el-input v-model="item.document_num"></el-input></td>
                  <td><el-input v-model="item.project_number"></el-input></td>
                  <td><el-input v-model="item.po_number"></el-input></td>
                  <td><el-input :readonly="item.delivery_detail_id" v-model="item.item_name"></el-input></td>
                  <td>
                    <span v-if="item.id && Boolean(item.delivery_detail_id)">{{item.quantity}}</span>
                    <el-input v-if="!item.id && Boolean(item.delivery_detail_id)" @change="(val) => {changeQuantity(val, item)}"
                      type="number" :max="item.max_use" v-model="item.quantity"></el-input></td>
                  <td><el-input @change="calcAmount(item)" type="number" v-model="item.receipt_quantity"></el-input></td>
                  <td><el-input v-model="item.uom"></el-input></td>
                  <td>
                    <el-input type="number" @change="calcAmount(item)" :readonly="Boolean(item.delivery_detail_id)"
                      v-model="item.unit_vat_price"></el-input>
                  </td>
                  <td><el-input :title="item.amount|currency" type="number" v-model="item.amount"></el-input></td>
                  <td>
                    <el-switch
                      v-model="item.is_settled"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      active-value="Y"
                      inactive-value="N">
                    </el-switch>
                  </td>
                  <td><el-button type="danger"
                    @click="removeItem(index)" icon="fa fa-fw fa-trash"></el-button></td>
                </tr>
                <tr>
                  <td colspan="9">
                    <span class="text-warning">
                      发票类型：外销成衣对应的面辅料采购属于“内销（面料）/内销（辅料）”
                    </span>
                  </td>
                  <td class="text-right">
                    <el-button @click="toSelectItems" type="primary" icon="fa fa-fw fa-plus" title="采购记录选择">
                    </el-button>
                  </td>
                  <td colspan="3">
                    <el-button style="margin-left: 10px" @click="addCustItem" type="warning" icon="fa fa-fw fa-plus" title="新增自定义记录">
                    </el-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button :disabled="waiting" @click="editDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button :disabled="waiting" type="primary" @click="save" icon="fa fa-fw fa-floppy-o"> 保 存</el-button>
        <el-button :disabled="waiting" v-if="editModel.id && managerUser && editModel.status === 'entered'"
          type="danger" @click="delIt(editModel)" class="pull-left" icon="fa fa-fw fa-trash"> 删 除</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="detailDialog" title="详情"
      :close-on-click-modal="false" width="90%" top="2%">
      <div>
        <el-form :model="showModel" ref="showForm" label-width="100px">
          <el-form-item label="开票单位：">
            <el-input readonly :value="showModel.company"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="confirmation_at" label="对账日期：">
                <el-input readonly :value="showModel.confirmation_at"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="department_id" label="部门：">
                <el-input readonly :value="showModel.department"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="业务员：">
                <el-input readonly :value="showModel.business_manager"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="contact_id" label="收件人：">
                <el-input readonly :value="showModel.checker"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="checker_phone" label="电话：">
                <el-input readonly :value="showModel.checker_phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="checker_addr" label="地址：">
                <el-input readonly :value="showModel.checker_addr"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注：">
            {{showModel.remark}}
          </el-form-item>
          <div style="overflow: auto; min-height: 300px;max-height: 600px;">
            <table style="min-width: 1300px;" class="table tight">
              <thead>
                <tr>
                  <th class="text-center" width="110">发票类型</th>
                  <th class="text-center" width="120">款号</th>
                  <th class="text-center" width="120">运编号</th>
                  <th class="text-center" width="120">项目号</th>
                  <th class="text-center" width="120">PO#</th>
                  <th class="text-center" width="120">品名</th>
                  <th class="text-center" width="70">对应库存量</th>
                  <th class="text-center" width="70">开票数量</th>
                  <th class="text-center" width="80">单位</th>
                  <th class="text-center" width="80">单价</th>
                  <th class="text-center" width="80">金额</th>
                  <th class="text-center" width="50">是否结账</th>
                  <th class="text-center" width="230">发票号</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in showModel.items" :key="index">
                  <td class="text-center"> {{item.type}} </td>
                  <td class="text-center"> {{item.kh}} </td>
                  <td class="text-center"> {{item.document_num}} </td>
                  <td class="text-center"> {{item.project_number}} </td>
                  <td class="text-center"> {{item.po_number}} </td>
                  <td class="text-center"> {{item.item_name}} </td>
                  <td class="text-center"> {{item.quantity ? item.quantity * 1 : ''}} </td>
                  <td class="text-center"> {{item.receipt_quantity ? item.receipt_quantity * 1 : ''}} </td>
                  <td class="text-center"> {{item.uom}} </td>
                  <td class="text-center"> {{item.unit_vat_price|currency}} </td>
                  <td class="text-center"> {{item.amount|currency}} </td>
                  <td class="text-center">
                    <el-button v-if="canSettled(item)" @click="settled(item)" type="info">结账</el-button>
                    <span v-if="item.is_settled === 'Y'">已结账</span>
                  </td>
                  <td class="text-center">
                    <template v-if="canInputNum(item)">
                      <el-input class="pd0" style="width:230px" v-model="item.receipt_num_input">
                        <el-button @click="updateReceiptNum(item)" slot="append" icon="el-icon-check"></el-button>
                      </el-input>
                    </template>
                    <span v-else>{{item.receipt_num}}</span>
                    <el-button style="margin-left: 10px" icon="fa fa-fw fa-pencil" v-if="canUpdateNum(item)"
                      @click="toUpdateNum(item)" title="修改"></el-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="detailDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button v-if="showModel.status === 'billed'" type="warning" @click="finish(showModel)" :disabled="waiting" icon="fa fa-fw fa-check"> 完 结</el-button>
        <!-- <el-button v-if="editModel.id"
          type="danger" @click="delIt" class="pull-left" :disabled="waiting" icon="fa fa-fw fa-trash"> 删 除</el-button> -->
      </div>
    </el-dialog>
    <a ref="downloadLink" :href="downloadUrl" :download="downloadFileName" v-show="false"></a>
  </el-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import addReceiptMixin from './mixins/addReceiptMixin';
import ItemSelect from './parts/ReceiptItemSelect';

export default {
  components: {
    ItemSelect,
  },
  mixins: [addReceiptMixin],
  data() {
    return {
      loading: false,
      waiting: false,
      // 查询条件
      queryForm: {
        // company_id: null,
        company: null,
        status: null,
        confirmation_at: null,
        checker: null,
      },
      queryPartiesLoading: false,
      parties: [],
      // page
      list: [],
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      // detail
      showModel: { items: [] },
      detailDialog: false,
      // edit
      editDialog: false,
      editModel: {
        items: [],
      },
      rules: {
        company_id: [{ required: true, message: '选择开票公司', trigger: ['change', 'blur'] }],
        confirmation_at: [{ required: true, message: '选择对账日期', trigger: ['change', 'blur'] }],
      },
      queryPartiesLoading2: false,
      parties2: [],
      // itemDialog
      itemDialog: false,
      // download
      downloadFileName: '',
      downloadUrl: '',
    };
  },
  beforeCreate() {
    if (this.$store.state.common.depts.length === 0) {
      this.$store.dispatch('GET_DEPTS');
    }
    if (!this.$store.state.common.receiptStatuses) {
      this.$store.dispatch('GET_RECEIPT_STATUSES');
    }
    if (!this.$store.state.common.receiptTypes) {
      this.$store.dispatch('GET_RECEIPT_TYPES');
    }
    if (!this.$store.state.common.receiptContacts) {
      this.$store.dispatch('GET_RECEIPT_CONTACTS');
    }
  },
  created() {

  },
  mounted() {
    this.submitQuery();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      curDeptId: state => state.auth.curDeptId,
      curCompanyType: state => state.auth.curCompanyType,
      receiptStatuses: state => state.common.receiptStatuses,
      receiptTypes: state => state.common.receiptTypes,
      receiptContacts: state => state.common.receiptContacts,
    }),
    ...mapGetters([
      'deptMap',
    ]),
    userDeptId() {
      let deptId = null;
      if (String(this.curCompanyType) === '2') {
        deptId = this.curDeptId;
      } else {
        deptId = this.user.department_id;
      }
      return deptId;
    },
    statuses() {
      const result = this.receiptStatuses
        ? this.receiptStatuses
        : {};
      return result;
    },
    contacts() {
      const result = this.receiptContacts
        ? this.receiptContacts
        : [];
      return result;
    },
    types() {
      const result = this.receiptTypes
        ? this.receiptTypes
        : [];
      return result;
    },
    currentCompany() {
      let company = {};
      if (this.editModel.company_id) {
        company = this.parties2.find(party => party.party_id === this.editModel.company_id);
        if (company) {
          company = {
            id: company.party_id,
            name: company.party_name,
          };
        } else {
          company = {
            id: this.editModel.company_id,
            name: this.editModel.company,
          };
        }
      }
      return company;
    },
    toMatchList() {
      const list = [];
      if (this.editModel.items) {
        this.editModel.items.forEach((item) => {
          if (!item.id && item.delivery_detail_id) {
            list.push({
              id: item.delivery_detail_id,
              quantity: item.quantity,
            });
          }
        });
      }
      return list;
    },
    managerUser() {
      let result = false;
      if (this.$utils.can('delivery_project_manager')) {
        result = true;
      }
      return result;
    },
  },
  watch: {
    curCompanyType() {
      this.submitQuery();
    },
  },
  methods: {
    test() {
      const params = {
        // party_id: 188836,
      };
      this.$http.put('erp-party/update', params).then(() => {

      });
    },
    excel(item) {
      const rid = item.id;
      this.$http.get(`receipt/${rid}/excel`, { responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.downloadFileName = item.company + item.confirmation_at;
        this.downloadUrl = objurl;
        this.$nextTick(() => {
          this.$refs.downloadLink.click();
        });
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    submitQuery() {
      const obj = {};
      if (this.queryForm.company_id) {
        obj.company_id = this.queryForm.company_id;
      }
      if (this.queryForm.confirmation_at) {
        obj.confirmation_at = this.queryForm.confirmation_at;
      }
      if (this.queryForm.company) {
        obj.company = this.queryForm.company;
      }
      if (this.queryForm.status) {
        obj.status = this.queryForm.status;
      }
      if (this.queryForm.checker) {
        obj.checker = this.queryForm.checker;
      }
      this.queryParams = obj;
      this.changePage(1);
    },
    getList() {
      const params = {
        ...this.queryParams,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$http.get('receipt', { params }).then((resp) => {
        this.list = resp.data.data;
        this.totalItem = resp.data.total;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 查询仓库
    // 查询企业
    queryParties(q) {
      const params = {
        limit: 20,
        q: {
          status_code: 'APPROVED',
        },
      };
      if (typeof q === 'string' && q.trim() !== '') {
        const name = q.trim();
        params.q.party_name = { $like: `%${name}%` };
      } else {
        return;
      }
      this.queryPartiesLoading = true;
      if (this.managerUser) {
        this.$http.get('erp-parties/', { params }).then((resp) => {
          const list = resp.data.items;
          this.queryPartiesLoading = false;
          this.parties = list;
        }, (err) => {
          this.queryPartiesLoading = false;
          this.$utils.resp(err);
        });
      } else {
        params.limit = 10;
        this.$http.get('erp-parties-all/', { params }).then((resp) => {
          const list = resp.data;
          this.queryPartiesLoading = false;
          this.parties = list;
        }, (err) => {
          this.queryPartiesLoading = false;
          this.$utils.resp(err);
        });
      }
    },
    // 编辑选择企业
    queryParties2(q) {
      const params = {
        limit: 20,
        q: {
          status_code: 'APPROVED',
        },
      };
      if (typeof q === 'string' && q.trim() !== '') {
        const name = q.trim();
        params.q.party_name = { $like: `%${name}%` };
      } else {
        return;
      }
      this.queryPartiesLoading2 = true;
      this.$http.get('erp-parties/', { params }).then((resp) => {
        const list = resp.data.items;
        this.queryPartiesLoading2 = false;
        this.parties2 = list;
      }, (err) => {
        this.queryPartiesLoading2 = false;
        this.$utils.resp(err);
      });
    },
    toDetail(item) {
      let tid = item;
      if (typeof tid === 'object') {
        tid = item.id;
      }
      this.$http.get(`receipt/${tid}`).then((resp) => {
        const obj = resp.data;
        obj.items.forEach((item) => {
          item.receipt_num_input = item.receipt_num;
          item.editingNum = false;
        });
        this.showModel = obj;
        this.detailDialog = true;
      });
    },
    toAdd() {
      this.editModel = {
        company_id: null,
        confirmation_at: this.$utils.formatDate(),
        company: '',
        contact_id: null,
        checker: null,
        checker_phone: null,
        checker_addr: null,
        department_id: this.userDeptId,
        department: '',
        business_manager: this.user.name,
        items: [],
      };
      this.editDialog = true;
      if (this.user && this.user.meta && this.user.meta.receiptChecker) {
        this.changeContact(this.user.meta.receiptChecker);
      }
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    toEdit(item) {
      let tid = item;
      if (typeof tid === 'object') {
        tid = item.id;
      }
      this.$http.get(`receipt/${tid}`).then((resp) => {
        const obj = resp.data;
        const contact = this.contacts.find(item => item.name === obj.checker);
        obj.contact_id = contact ? contact.id : obj.checker;
        this.editModel = resp.data;
        this.editDialog = true;
        this.$nextTick(() => {
          if (this.$refs.editForm) {
            this.$refs.editForm.clearValidate();
          }
        });
      });
    },
    addCustItem() {
      const item = {
        kh: '',
        project_number: '',
        type: null,
        document_num: '', // 运编号
        po_number: '',
        business_manager: '', // 业务员
        item_name: '',
        receipt_quantity: null,
        uom: '',
        unit_vat_price: null,
        amount: 0,
        is_settled: 'N', // 是否结账
        receipt_num: '',
      };
      this.editModel.items.push(item);
    },
    // 开始选择发货记录
    toSelectItems() {
      if (this.editModel.company_id) {
        this.itemDialog = true;
        this.$nextTick(() => {
          this.$refs.itemChecks.showQuery();
        });
      } else {
        this.$message({
          type: 'warning',
          message: '请选择开票单位后再添加记录',
        });
      }
    },
    // 确认选择的发货记录
    selectedItems() {
      this.$refs.itemChecks.addSelected();
    },
    // 添加选择的发货记录
    addItems(arr) {
      // const list = [];
      arr.forEach((item) => {
        const obj = {
          delivery_detail_id: item.id,
          delivery_at: item.delivery.delivery_date,
          delivery_code: item.delivery.code,
          type: null,
          document_num: '',
          business_manager: '',
          kh: item.attr6,
          project_number: item.order.project_number,
          po_line_id: item.po_line_id,
          po_number: '',
          item_name: item.item_name,
          uom: item.uom,
          unit_vat_price: item.unit_vat_price,
          amount: 0,
          quantity: item.use_quantity,
          receipt_quantity: item.use_quantity,
          max_use: item.max_use,
          stock_id: item.stock_id,
          is_settled: 'N',
          orgQuantity: item.quantity,
        };
        obj.amount = this.$calc.Mul(obj.unit_vat_price, obj.receipt_quantity);
        this.editModel.items.push(obj);
      });
      this.itemDialog = false;
    },
    removeItem(index) {
      const item = this.editModel.items[index];
      if (item.id) {
        this.$confirm('删除会立即生效，是否继续删除？', '确认信息', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$http.delete(`receipt-item/${item.id}`).then(() => {
            this.editModel.items.splice(index, 1);
          }, (err) => {
            this.$utils.resp(err);
          });
        }, () => {});
      } else {
        this.editModel.items.splice(index, 1);
      }
    },
    changeQuantity(val, item) {
      if (item.quantity > item.max_use) {
        item.quantity = item.max_use;
      }
    },
    // 计算金额
    calcAmount(item) {
      if (item.unit_vat_price && item.receipt_quantity) {
        item.amount = this.$calc.Mul(item.unit_vat_price, item.receipt_quantity);
      }
    },
    // 选择收件人
    changeContact(id) {
      const contact = this.contacts.find(item => item.id === id);
      if (contact) {
        this.editModel.contact_id = contact.id;
        this.editModel.checker = contact.name;
        this.editModel.checker_phone = contact.phone;
        this.editModel.checker_addr = contact.addr;
      }
    },
    save() {
      const obj = this.editModel;
      this.$refs.editForm.validate((val) => {
        if (val) {
          this.waiting = true;
          if (!obj.items || obj.items.length === 0) {
            this.$message({
              type: 'warning',
              message: '请添加记录',
            });
            return;
          }
          if (obj.id) {
            this.$http.put(`receipt/${obj.id}`, obj).then(() => {
              this.$store.commit('AUTH_SET_METAS', { type: 'receiptChecker', value: obj.contact_id });
              this.editDialog = false;
              this.getList();
              this.waiting = false;
            }, (err) => {
              this.waiting = false;
              this.$utils.resp(err);
            });
          } else {
            const company = this.parties2.find(item => item.party_id === this.editModel.company_id);
            this.editModel.company = company.party_name;
            this.editModel.department = this.deptMap[this.editModel.department_id].name;
            this.$http.post('receipt', obj).then(() => {
              this.$store.commit('AUTH_SET_METAS', { type: 'receiptChecker', value: obj.contact_id });
              this.editDialog = false;
              this.getList();
              this.waiting = false;
            }, (err) => {
              this.waiting = false;
              this.$utils.resp(err);
            });
          }
        }
      });
    },
    delIt(item) {
      this.$confirm('删除不可恢复，是否继续删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.delete(`receipt/${item.id}`).then(() => {
          this.waiting = false;
          this.editDialog = false;
          this.showDialog = false;
          this.getList();
          this.$message({
            message: '已经成功删除',
            type: 'success',
          });
        }, (err) => {
          this.waiting = false;
          this.$utils.resp(err);
        });
      }, () => {});
    },
    canSettled(item) {
      return item.is_settled !== 'Y';
    },
    // 结账
    settled(item) {
      this.$http.put(`receipt-item/${item.id}/settle`).then(() => {
        item.is_settled = 'Y';
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // 完结
    finish(obj) {
      this.waiting = true;
      this.$http.put(`receipt/${obj.id}/finish`).then(() => {
        this.waiting = false;
        this.detailDialog = false;
        this.getList();
      }, (err) => {
        this.waiting = false;
        this.$utils.resp(err);
      });
    },
    canInputNum(item) {
      if (item.editingNum) {
        return true;
      }
      return !item.receipt_num;
    },
    canUpdateNum(item) {
      if (item.editingNum) {
        return false;
      }
      return Boolean(item.receipt_num);
    },
    toUpdateNum(item) {
      item.editingNum = true;
      item.receipt_num_input = item.receipt_num;
    },
    updateReceiptNum(item) {
      this.$http.put(`receipt-item/${item.id}/receipt_num`, item).then((resp) => {
        item.receipt_num = item.receipt_num_input;
        item.editingNum = false;
        this.showModel.status = resp.data.status;
        const record = this.list.find(item => item.id === this.showModel.id);
        record.status = resp.data.status;
        record.status_text = this.statuses[record.status];
      }, (err) => {
        this.$utils.resp(err);
      });
    },
  },
};
</script>
