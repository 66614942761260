<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>
    <a ref="downloadLink" :href="downloadUrl" :download="downloadFileName" v-show="false">隐藏的下载文件链接</a>
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="打样编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>打样编号</th>
          <th>部门</th>
          <th>品名</th>
          <th>款号</th>
          <th>规格</th>
          <th>款式</th>
          <th>样版</th>
          <th>样品类别</th>
          <th>要求日期</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody v-loading="loading">
        <tr v-for="(item, index) in sampleList" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td><a href="#" @click.prevent="toDetail(item)">{{ item.code }}</a></td>
          <td>{{ item.dept }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.style }}</td>
          <td>{{ item.spec }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.yb }}</td>
          <td>{{ item.category }}</td>
          <td>{{ item.expect_date }}</td>
          <td>
            <div class="text-nowrap">
              <a href="#" @click.prevent="toQrPage(item)">二维码</a>
              <el-button size="small" title="修改信息" @click="toEdit(item)" icon="fa fa-fw fa-pencil"
                v-if="item.creator_id == user.id && item.status != '6'"></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="sampleForm.id ? '编辑打样' : '新增打样'" top="20px"
      :close-on-click-modal="false">
      <el-form :model="sampleForm" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="打样编号" prop="code">
          <el-input v-model="sampleForm.code"></el-input>
        </el-form-item>
        <!-- <el-form-item label="部门" prop="dept">
          <el-input v-model="sampleForm.dept"></el-input>
        </el-form-item> -->
        <el-form-item label="部门" prop="dept">
          <el-cascader style="width: 100%;" :show-all-levels="false"
            expand-trigger="hover"
            :options="erpTree"
            :props="{label: 'name', value: 'id'}"
            v-model="selectedDept"
            @change="chooseDept"></el-cascader>
        </el-form-item>
        <el-form-item label="品名" prop="name">
          <el-input v-model="sampleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="款号" prop="style">
          <el-input v-model="sampleForm.style"></el-input>
        </el-form-item>
        <el-form-item label="规格" prop="spec">
          <el-input v-model="sampleForm.spec"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="款式" prop="type">
              <el-select v-model="sampleForm.type" clearable style="width: 100%" allow-create filterable>
                <el-option v-for="item in typeList" :value="item" :key="item" :label="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="样板" prop="yb">
              <el-select v-model="sampleForm.yb" clearable style="width: 100%" allow-create filterable>
                <el-option v-for="item in ybList" :value="item" :key="item" :label="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="样品类别" prop="category">
              <el-select v-model="sampleForm.category" clearable style="width: 100%" allow-create filterable>
                <el-option v-for="item in categoryList" :value="item" :key="item" :label="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="要求日期" prop="expect_date">
              <el-date-picker v-model="sampleForm.expect_date" style="width: 100%" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人" prop="contact">
              <el-input v-model="sampleForm.contact"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式" prop="contact_phone">
              <el-input v-model="sampleForm.contact_phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="sampleForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="附件">
          <span v-if="files.length > 0">{{files[0].name}}</span>
          <span v-else>
            <span v-if="sampleForm.attachments">
              <a href="#" v-for="(item) in sampleForm.attachments" :key="item.name" @click.prevent="download(item)">{{item.orgName}}</a>
              <!-- {{sampleForm.attachments.length > 0 ? sampleForm.attachments[0].orgName : '' }} -->
            </span>
          </span>
          <file-uploader ref="addFile"
            accept="."
            @change="changeFile"
            :showPreview="false"
            :showFileHint="false">
          </file-uploader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="sampleForm.id && sampleForm.creator_id == user.id && sampleForm.status != '6'"
          :disabled="waiting" title="删除" type="danger" @click="delsample"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button @click="editDialog = false" :disabled="waiting">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="savesample" :disabled="waiting">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="detailDialog" title="打样详情" width="80%">
      <el-form :model="detail" label-width="100px" class="detail-form">
        <el-row>
          <el-col :span="24">
            <el-form-item label="二维码：">
              <a href="#" @click.prevent="toQrPage(detail)">显示</a>
              <el-button icon="fa fa-download fa-fw" @click="downloadQr(detail)"></el-button>
              <vue-qr ref="qrImg" v-show="false" :logoSrc="logoUrl" logoBackgroundColor="#fff" :logoMargin="5"
                :correctLevel="3" :text="detail.code|qrUrl" :margin="6" :dotScale="1"></vue-qr>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="打样编号：">{{detail.code}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门：">{{detail.dept}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="品名：">{{detail.name}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="款号：">{{detail.style}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格：">{{detail.spec}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="款式：">{{detail.type}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="样板：">{{detail.yb}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="样品类型：">{{detail.category}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人：">{{detail.contact}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话：">{{detail.contact_phone}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="要求日期：">{{detail.expect_date}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="归档日期：">{{detail.finished_date ? detail.finished_date : '未结束'}}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="附件：">
              <span v-if="detail.attachments">
                <a href="#" v-for="(item) in detail.attachments" :key="item.name" @click.prevent="download(item)">{{item.orgName}}</a>
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注说明：">{{detail.remark}}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="打样流程：">
              <table v-if="detailItems.length > 0" class="table">
                <thead>
                  <tr>
                    <th>工序</th>
                    <th>操作人</th>
                    <th>联系电话</th>
                    <th>备注</th>
                    <!-- <th>状态</th> -->
                    <th>处理时间</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in detailItems" :key="item.id">
                    <td>{{item.step}}</td>
                    <td>{{item.user}}</td>
                    <td>{{item.phone}}</td>
                    <td>{{item.remark}}</td>
                    <!-- <td>{{item.status == '1' ? '开始' : '完成'}}</td> -->
                    <td>{{item.status == '1' ? item.created_at : item.updated_at}}</td>
                  </tr>
                </tbody>
              </table>
              <span v-else>还未开始</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="canFinish(detail)"
          :disabled="waiting" title="完 结" type="warning" @click="finish"> 完 结</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
/* eslint-disable import/extensions */
import VueQr from 'vue-qr';
import { mapState, mapGetters } from 'vuex';
// eslint-disable-next-line import/no-unresolved
import FileUploader from '@/components/FileUpload';

export default {
  components: {
    VueQr,
    FileUploader,
  },
  beforeCreate() {
    if (this.$store.state.common.depts.length === 0) {
      this.$store.dispatch('GET_DEPTS');
    }
  },
  data() {
    return {
      logoUrl: window.baseUrl + 'favicon.ico',
      loading: false,
      waiting: false,
      // 查询条件form
      queryForm: {
        code: '',
      },
      selectedDept: [],
      queryParams: {}, // 分页查询参数
      sampleList: [], // 管理的列表对象
      sampleForm: {}, // 管理的操作时对象
      editDialog: false, // message dialog显示标志 true 显示
      detailDialog: false,
      detail: { items: [] },
      files: [],
      downloadFileName: '',
      downloadUrl: '',
      // 编辑form的条件
      rules: {
        code: [{ required: true, message: '填写打样编号', trigger: ['change', 'blur'] }],
        name: [{ required: true, message: '填写品名', trigger: ['change', 'blur'] }],
      },
      typeList: [], // 款式
      ybList: [], // 样板
      categoryList: [], // 样品类别
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapState({
      depts: state => state.common.depts,
      erpTree: state => state.common.erpTree,
    }),
    ...mapGetters([
      'deptMap',
    ]),
    detailItems() {
      let result = [];
      if (this.detail && Array.isArray(this.detail.items)) {
        result = this.detail.items;
      }
      return result;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    this.getDict();
    this.onSubmitQuery();
  },

  methods: {
    chooseDept(ids) {
      this.sampleForm.department_id = ids.length > 0 ? ids[ids.length - 1] : '';
      this.sampleForm.dept = this.deptMap[this.sampleForm.department_id].name;
    },
    getDict() {
      this.axios.get('dysample-dict').then((resp) => {
        this.typeList = resp.data.type;
        this.ybList = resp.data.yb;
        this.categoryList = resp.data.category;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    onSubmitQuery() {
      this.queryParams = {};
      if (this.queryForm.code && this.queryForm.code.trim()) {
        this.queryParams.code = this.queryForm.code;
      }
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.axios.get('dy-sample', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.loading = false;
          this.sampleList = response.data.data;
          this.totalItem = response.data.total;
        }, (response) => {
          this.loading = false;
          this.$utils.resp(response);
        });
    },
    toEdit(editObj) {
      this.sampleForm = { ...editObj };
      this.files = [];
      // 初始化部门选择框
      const selectedDept = [];
      if (editObj.department_id) {
        const id = editObj.department_id;
        let dept = this.deptMap[id];
        while (dept) {
          selectedDept.unshift(dept.id);
          dept = this.deptMap[dept.parent_id];
        }
      }
      this.selectedDept = selectedDept;
      if (this.$refs.addFile) {
        this.$refs.addFile.reset();
      }
      this.editDialog = true;
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toDetail(sample) {
      this.axios.get(`dy-sample/${sample.id}`)
        .then((response) => {
          this.detail = response.data;
          this.detailDialog = true;
        }, (response) => {
          this.$utils.resp(response);
        });
    },
    toAdd() {
      // 清空数据
      this.selectedDept = [];
      this.files = [];
      const sample = {
        code: '',
        dept: '',
        department_id: '',
        name: '',
        style: '',
        spec: '',
        type: '',
        yb: '',
        category: '',
        contact: '',
        contact_phone: '',
        expect_date: '',
        remark: '',
      };
      if (this.$refs.addFile) {
        this.$refs.addFile.reset();
      }
      this.sampleForm = sample;
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    // 修改新增的保存
    savesample() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          if (this.$refs.addFile.identifyBorwser() && this.files.length > 0) {
            const formData = new window.FormData(this.$refs.editForm.$el);
            formData.append('hint', 'dy');
            this.files.forEach((ele) => {
              formData.append('file[]', ele);
            });
            this.saveSubmit(formData);
            // this.waiting = true;
            // this.$http.post('transfile', formData).then((resp) => {
            //   this.editModel.addFiles = resp.data;
            //   this.saveSubmit();
            // }, (err) => {
            //   this.editModel.addFiles = [];
            //   this.waiting = false;
            //   this.$utils.resp(err);
            // });
          } else {
            this.saveSubmit();
          }
        }
      });
    },
    saveSubmit(formData = null) {
      const model = { ...this.sampleForm };
      let tempForm = model;
      if (formData) {
        this.$utils.formDataAdd(formData, tempForm);
        tempForm = formData;
      }
      this.waiting = true;
      if (this.sampleForm.id) {
        this.waiting = true;
        this.$http.post(`dy-sample/${this.sampleForm.id}`, tempForm).then(() => {
          this.editDialog = false;
          this.waiting = false;
          this.getList();
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      } else {
        this.waiting = true;
        this.$http.post('dy-sample', tempForm).then(() => {
          this.editDialog = false;
          this.waiting = false;
          this.changePage(1);
        }, (resp) => {
          this.$utils.resp(resp);
          this.waiting = false;
        });
      }
    },
    delsample() {
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.delete(`dy-sample/${this.sampleForm.id}`).then(() => {
          this.getList();
          this.waiting = false;
          this.editDialog = false;
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    canFinish(obj) {
      return Boolean(obj.creator_id === this.user.id && obj.status !== '6' && obj.items.length > 0);
    },
    finish(obj) {
      this.$confirm('是否完结归档？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.put(`dy-sample/${this.detail.id}`, { ...obj, status: '6' }).then(() => {
          this.getList();
          this.$message.success('已完结 ' + obj.code);
          this.waiting = false;
          this.detailDialog = false;
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    // 选中上传文件时
    changeFile(files) {
      if (this.$refs.addFile.sizeWarning) {
        this.$utils.msg('文件过大', 'warning');
        return;
      }
      if (files.length > 0) {
        this.files = [];
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
      }
    },
    download(file) {
      const params = {
        hint: 'dy',
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.downloadFileName = file.orgName;
        this.downloadUrl = objurl;
        this.$nextTick(() => {
          this.$refs.downloadLink.click();
        });
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    toQrPage(sample) {
      const route = this.$router.resolve({ name: 'DySampleQr', query: { code: sample.code } });
      window.open(route.href, '_blank');
    },
    downloadQr(sample) {
      this.downloadFileName = sample.code;
      this.downloadUrl = this.$refs.qrImg.$el.src;
      this.$nextTick(() => {
        this.$refs.downloadLink.click();
      });
    },
  },
  filters: {
    // 二维码
    qrUrl(code) {
      return `https://www.guotaigh.com.cn/wb-app/#/dysample?code=${code}`;
    },
  },
};
</script>

<style lang="scss" scope>
.detail-form ::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 10px;
}
</style>
