<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()" icon="fa fa-plus-circle"> 新 增</el-button>
    <el-button type="warning" size="small" @click="toExcel()"> excel导入</el-button>
    <div class="mar-btm-10 pull-right">
      <!-- <div class="box-inline standard-search">
        <el-date-picker v-model="queryForm.start" value-format="yyyy-MM-dd" placeholder="upload time start"></el-date-picker>
      </div> -
      <div class="box-inline standard-search">
        <el-date-picker v-model="queryForm.end" value-format="yyyy-MM-dd" placeholder="upload time end "></el-date-picker>
      </div> -->
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.kh" clearable placeholder="款号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.guige" clearable placeholder="规格" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.name" clearable placeholder="名称" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.category" placeholder="类型" clearable
            filterable>
            <el-option
              v-for="item in wlcategorys"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery" icon="fa fa-fw fa-search"></el-button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>名称</th>
          <th>类型</th>
          <th>款号</th>
          <th>图片</th>
          <th>颜色</th>
          <th>尺码</th>
          <th>规格</th>
          <th>库存</th>
          <th>单位</th>
          <th width="100">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.category}}</td>
          <td>{{ item.kh }}</td>
          <td>
            <el-popover v-if="item.clothes && item.clothes.imgs.length === 1"
              placement="right"
              trigger="hover">
              <img :src="showImgUrl">
              <img slot="reference" @click="showItemImg(item, true)"
                :src="'data:image/jpg/png/gif;base64,' + item.clothes.imgs[0].simg" alt="">
            </el-popover>
          </td>
          <td>{{ item.color_num ? colorMap[item.color_num] : '' }}</td>
          <td>{{ item.size }}</td>
          <td>{{ item.guige }}</td>
          <td>{{ item.stock_num }}</td>
          <td>{{ item.unit }}</td>
          <td>
            <div class="text-nowrap">
              <el-button  size="small" title="Edit" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button>
              <el-button type="warning" size="small" title="Copy" @click="copy(item)"><i class="fa fa-fw fa-copy"></i></el-button>
              <el-button  size="small" title="in" @click="toIn(item)">入库</el-button>
              <el-button  size="small" title="out" @click="toOut(item)">出库</el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>
    <!-- excel upload -->
    <el-dialog width="80%" :visible.sync="excelDailog" title="excel 批量导入" :close-on-click-modal="false">
      <file-upload ref="uploadFile" class="mar-lft box-inline" style="vertical-align: top;" name="excel" :showFileHint="true"
        :multiple="false" @change="changeFile" :show-preview="false" accept="xlsx" btn-name="excel导入"></file-upload>
      <a style="text-decoration: underline;color:#f16b25;padding: 20px;line-height: 30px" href="#" @click.stop="excel">模板下载</a>
      <div style="padding: 20px; font-size: 16px;">
        保存预览：<span class="text-warning"></span>
      </div>
      <table class="table bordered">
        <thead>
          <tr>
            <th>物料名称</th>
            <th>规格</th>
            <th>分类</th>
            <th>款号</th>
            <th>颜色</th>
            <th>尺寸</th>
            <th>数量</th>
            <th>单位</th>
            <th>备注</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(line, index) in addList" :key="index">
            <td>{{ line.name }}</td>
            <td>{{ line.guige }}</td>
            <td>{{ line.category}}</td>
            <td>{{ line.kh }}</td>
            <td>{{ line.color }}</td>
            <td>{{ line.size }}</td>
            <td>{{ line.num }}</td>
            <td>{{ line.unit }}</td>
            <td>{{ line.remark }}</td>
            <td>
              <el-button icon="el-icon-delete" @click="delAddItem(index)"></el-button>
            </td>
          </tr>
        </tbody>
      </table>
      <div slot="footer" class="dialog-footer">
        <el-button  @click="excelDailog = false" icon="fa fa-fw fa-times" aria-hidden="true">关闭</el-button>
        <el-button type="primary"  @click="saveList" icon="el-icon-upload" aria-hidden="true">上传</el-button>
      </div>
    </el-dialog>
    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'" :close-on-click-modal="false">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="物料名称" prop="name">
          <el-input v-model="editModel.name"></el-input>
        </el-form-item>
        <el-form-item label="款号" prop="kh">
          <el-select v-model="editModel.kh" filterable>
            <el-option v-for="item in khs" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类" prop="category">
          <el-select v-model="editModel.category"
            filterable clearable>
            <el-option v-for="item in wlcategorys"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="尺码" prop="size">
          <el-select v-model="editModel.size" placeholder="尺码" clearable>
            <el-option v-for="(item) in sizes" :key="item" :value="item" :label="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="颜色" prop="color_num">
          <el-select v-model="editModel.color_num" placeholder="颜色" clearable>
            <el-option v-for="(item) in colors" :key="item.id" :value="item.code" :label="item.name"></el-option>
          </el-select>{{ editModel.color }}
        </el-form-item>
        <el-form-item label="规格" prop="guige">
          <el-input v-model="editModel.guige"></el-input>
        </el-form-item>
        <el-form-item label="计量单位" prop="unit">
          <el-input v-model="editModel.unit"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="editModel.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times" aria-hidden="true">取消</el-button>
        <el-button type="primary" @click="save" icon="fa fa-fw fa-floppy-o"
          aria-hidden="true" :disabled="waiting"> 保存</el-button>
        <el-button v-if="editModel.id" type="danger" @click="delIt" class="pull-left" :disabled="waiting"
          icon="fa fa-fw fa-trash" aria-hidden="true"> 删除 </el-button>
      </div>
    </el-dialog>
    <!-- 出入库 -->
    <el-dialog :visible.sync="inDialog" :title="type=== 'in' ? '入库' : '出库'" :close-on-click-modal="false">
      <el-form :model="editModel" label-width="100px" :rules="rules">
        <el-form-item label="物料名称">
          {{editModel.name}}
        </el-form-item>
        <el-form-item label="款号">
          {{editModel.hk}}
        </el-form-item>
        <el-form-item label="分类">
          {{editModel.category}}
        </el-form-item>
        <el-form-item label="尺码">
          {{editModel.size}}
        </el-form-item>
        <el-form-item label="颜色">
          {{colorMap[editModel.color_num]}} {{editModel.color ? `(${editModel.color})` : ''}}
        </el-form-item>
        <el-form-item label="规格">
          {{editModel.guige}}
        </el-form-item>
        <el-form-item label="库存">
          {{editModel.stock_num}}{{editModel.unit}}
        </el-form-item>
        <el-form-item label="物料备注">
          {{editModel.remark}}
        </el-form-item>
        <el-form-item :label="type== 'in' ? '入库数量': '出库数量'" prop="num">
          <el-input v-model="editModel.num"></el-input>
        </el-form-item>
        <el-form-item label="客户" prop="customer">
          <el-input v-model="editModel.customer"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="log_remark">
          <el-input type="textarea" v-model="editModel.log_remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="inDialog = false" icon="fa fa-fw fa-times" aria-hidden="true">取消</el-button>
        <el-button type="primary" @click="inoutSave" icon="fa fa-fw fa-floppy-o"
          aria-hidden="true" :disabled="waiting"> {{type== 'in' ? '入库': '出库'}}</el-button>
      </div>
    </el-dialog>

    <!-- detail dialog -->
    <el-dialog :visible.sync="showDialog" title="详细信息" :close-on-click-modal="false">
      <el-form :model="showObj" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="物料编号: ">
              {{showObj.code}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="物料名称: ">
              {{showObj.name}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="分类: ">
              {{showObj.category}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="库房: ">
              {{showObj.kufang}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="品牌: ">
              {{showObj.brand}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格: ">
              {{showObj.guige}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="最低库存: ">
              {{showObj.minkucun}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最高库存: ">
              {{showObj.maxkucun}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          {{showObj.remark}}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false" icon="fa fa-fw fa-times" aria-hidden="true">关闭</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>

import { mapState } from 'vuex';
import * as xlsx from 'xlsx';
import FileUpload from '@/components/FileUpload';

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      today: this.$utils.formatDate(),
      waiting: false,
      sizes: [],
      khs: [],
      colors: [],
      wlcategorys: [],
      // 查询条件form
      queryForm: {
        kh: null,
        name: null,
        color: null,
        size: null,
        category: null,
      },
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
      },
      inDialog: false,
      type: null,
      // 详细
      showObj: {},
      showDialog: false,
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      // excel upload
      excelDailog: false,
      addList: [],
      showImgUrl: '',
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      dicts: (state) => state.common.dicts,
    }),
    colorMap() {
      const r = {};
      if (this.colors.length > 0) {
        this.colors.forEach((i) => {
          r[i.code] = i.name;
        });
      }
      return r;
    },
  },
  created() {
    this.init();
    this.onSubmitQuery();
  },
  methods: {
    init() {
      if (this.$store.state.common.clothesDicts.sizes) {
        this.sizes = this.$store.state.common.clothesDicts.sizes;
        this.colors = this.$store.state.common.clothesDicts.colors;
      } else {
        this.$store.dispatch('GET_COLTHES_DICTS').then((resp) => {
          this.sizes = resp.data.sizes;
          this.colors = resp.data.colors;
        });
      }
      this.axios.get('wuliao-dicts').then((r) => {
        this.khs = r.data.khs;
        this.wlcategorys = r.data.categories;
      });
    },
    toExcel() {
      this.resetFileUpload();
      this.addList = [];
      this.excelDailog = true;
    },
    // 显示图片
    showItemImg(item, isFullScrean = false) {
      this.showImg(item.clothes.imgs[0], isFullScrean);
    },
    showImg(file, isFullScrean) {
      const params = {
        hint: 'e_clothes',
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.showImgUrl = objurl;
        if (isFullScrean) {
          this.$store.commit('SET_BIG_IMG_URL', objurl);
          this.$store.commit('PICKER_SHOW_SHOW_BIG_IMG');
        }
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // 选中上传文件时
    changeFile(files) {
      if (this.$refs.uploadFile.sizeWarning) {
        this.$utils.msg('文件过大', 'warning');
        return;
      }
      let file = null;
      if (files.length > 0) {
        file = files[0];
      }
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const wb = xlsx.read(e.target.result, { type: 'binary' });
        const sheetNames = wb.SheetNames; // 获取表名
        const sheet = wb.Sheets[sheetNames[0]]; // 通过表名得到表对象
        const arr = xlsx.utils.sheet_to_json(sheet);
        const l = [];
        arr.forEach((item) => {
          l.push({
            name: item.名称,
            category: item.分类,
            guige: item.规格,
            kh: item.款号,
            color: item.颜色,
            size: item.尺码,
            num: item.初始库存,
            unit: item.单位,
            remark: item.备注,
          });
        });
        this.addList = l;
      };
      fileReader.readAsBinaryString(file);
    },
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.axios.get('wuliao', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.list = response.data.data;
          this.totalItem = response.data.total;
        }, (err) => {
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.resetFileUpload();
      this.editModel = {
        name: '',
        category: '',
        kh: '',
        guige: '',
        size: '',
        color: '',
        unit: '',
        num: '',
        remark: '',
      };
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 打开编辑
    toEdit(editObj) {
      this.resetFileUpload();
      this.axios.get(`wuliao/${editObj.id}`).then((resp) => {
        const obj = resp.data;
        this.editDialog = true;
        this.editModel = obj;
        this.$nextTick(() => {
          if (this.$refs.editForm) {
            this.$refs.editForm.clearValidate();
          }
        });
      });
    },
    copy(obj) {
      this.resetFileUpload();
      this.editDialog = true;
      this.editModel = { ...obj, id: null };
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    toIn(item) {
      this.type = 'in';
      this.editModel = { ...item, customer: null, num: 0, type: this.type, log_remark: '' };
      this.inDialog = true;
    },
    toOut(item) {
      this.type = 'out';
      this.editModel = { ...item, customer: null, num: 0, type: this.type, log_remark: '' };
      this.inDialog = true;
    },
    inoutSave() {
      if (this.editModel.num <= 0) {
        this.$message({
          type: 'warning',
          message: '必须填写正确数量',
          showClose: true,
          duration: 2000,
        });
        return;
      }
      this.axios.post('wuliaolog', this.editModel).then(() => {
        this.getList();
        this.inDialog = false;
      }, (e) => {
        this.$utils.resp(e);
      });
    },
    resetFileUpload() {
      if (this.$refs.uploadFile) {
        this.$nextTick(() => {
          this.$refs.uploadFile.reset();
        });
      }
      this.files = [];
      this.previewImgs = [];
    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.editModel };
          this.waiting = true;
          if (this.editModel.id) {
            this.$http.put(`wuliao/${this.editModel.id}`, obj).then(() => {
              this.editDialog = false;
              this.getList();
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          } else {
            this.$http.post('wuliao', obj).then(() => {
              this.editDialog = false;
              this.getList();
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
    delIt() {
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`wuliao/${this.editModel.id}`).then(() => {
          this.getList();
          this.editDialog = false;
          this.$message({
            type: 'success',
            message: '删除成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    toDetail(obj) {
      this.axios.get(`wuliao/${obj.id}`).then((resp) => {
        this.showObj = resp.data;
        this.showDialog = true;
      }, (e) => {
        this.$utils.resp(e);
      });
    },
    delAddItem(index) {
      this.addList.splice(index, 1);
    },
    saveList() {
      this.axios.post('wuliaos', { list: this.addList }).then(() => {
        this.excelDailog = false;
        this.getList();
      }, (e) => {
        this.$utils.resp(e);
      });
    },
    excel() {
      const list = [
        { name: '衬布', category: '面料', guige: '白色 80gms', kh: 'B2202', size: 'M', color: '天蓝色', num: '100', unit: '米', remark: '测试模板' },
      ];
      if (list.length > 0) {
        const l = [];
        list.forEach((i) => {
          l.push({
            名称: i.name,
            分类: i.category,
            规格: i.guige,
            款号: i.kh,
            颜色: i.color,
            尺码: i.size,
            初始库存: i.num,
            单位: i.unit,
            备注: i.remark,
          });
        });
        const sheet = xlsx.utils.json_to_sheet(l);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, sheet, 'sheet1');
        xlsx.writeFile(wb, (this.queryForm.month ? this.queryForm.month : '') + '库存初始化模板.xlsx');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
