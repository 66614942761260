<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新用户</el-button>
    <!-- <el-button type="danger" size="small" @click="test()">测试</el-button> -->
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.q" clearable placeholder="输入关键字检索" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select clearable v-model="queryForm.status" placeholder="状态" @change="onSubmitQuery">
          <el-option v-for="(val, key) in statusArr" :key="key" :value="key" :label="val"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>姓名</th>
          <th>登录名</th>
          <th>电话</th>
          <th>邮箱</th>
          <th>使用状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.login_name }}</td>
          <td>{{ item.mobile }}</td>
          <td>{{ item.email }}</td>
          <td>
            <el-switch v-if="item.id !== 1" v-model="item.status" @change="(val) => {switchStatus(item, val)}"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="1"
              inactive-value="2"
              inactive-text="">
            </el-switch>
          </td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" type="primary" title="修改信息" @click="toEdit(item)">
                <i class="fa fa-fw fa-pencil"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'" :close-on-click-modal="false">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="editModel.name"></el-input>
        </el-form-item>
        <el-form-item label="登录名" prop="login_name">
          <el-input v-model="editModel.login_name"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="!editModel.id" placeholder="初始密码不填保存时默认'123456'">
          <el-input v-model="editModel.password"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱" prop="email">
          <el-input v-model="editModel.email"></el-input>
        </el-form-item>
        <el-form-item label="用户类型" prop="type">
          <el-select v-model="editModel.type">
            <el-option v-for="(item, index) in userTypes" :key="index" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="国华部门" prop="department_id">
          <el-cascader style="width: 100%;" :options="departments" clearable
            :show-all-levels="false"
            expand-trigger="hover"
            :props="{label: 'name', value: 'id'}"
            change-on-select
            v-model="departmentArr"
            @change="changeDepartment">
          </el-cascader>
        </el-form-item>
        <el-form-item label="豪鼎部门">
          <el-cascader style="width: 100%;" :options="hdDepts" clearable
            :show-all-levels="false"
            expand-trigger="hover"
            :props="{label: 'name', value: 'id'}"
            change-on-select
            v-model="hdDepartmentArr">
          </el-cascader>
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="editModel.mobile"></el-input>
        </el-form-item>
        <el-form-item label="缅甸公司" prop="mmr_company_id" v-if="hasRole1">
          <el-select v-model="editModel.mmr_company_id">
            <el-option v-for="item in mmrCompanies" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属企业" prop="asset_company_id" v-if="hasRole2">
          <el-select v-model="editModel.asset_company_id">
            <el-option v-for="item in companies" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属部门" prop="branch_id" v-if="belongDept">
          <!-- <el-select v-model="editModel.branch_id">
            <el-option v-for="item in branches" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select> -->
          <el-cascader style="width: 100%;"
            expand-trigger="hover"
            :options="depts"
            :props="{label: 'name', value: 'id'}"
            change-on-select
            v-model="deptArr"
            @change="chooseDept">
          </el-cascader>
        </el-form-item>
        <el-form-item label="管理部门范围" prop="wb_depts" v-if="isViewUser">
          <el-input :value="editModel.wb_depts.join(',')" @focus="deptsDialog = true"></el-input>
        </el-form-item>
        <el-form-item label="自有工厂" prop="own_factory_ids" v-if="isOwnFactoryManager">
          <el-select v-model="editModel.own_factory_ids">
            <el-option v-for="item in ownFactories" :key="item.id" :value="item.id" :label="item.name + item.name1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发货企业" prop="shipping_company_id" v-if="belongShipCompany">
          <el-select v-model="editModel.shipping_company_id"  :loading="selectLoading"
            filterable remote :remote-method="queryShippingCompany">
            <el-option v-for="item in shipCompanies" :key="item.id" :value="item.id" :label="item.company_name"></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" v-for="(item) in allRoles" :key="item.id">
            <el-checkbox v-model="selectedBox[item.id]" :label="item.name"></el-checkbox>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="save">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true" :disabled="waiting"></i> 确 定</el-button>
        <el-button v-if="editModel.id" type="danger" @click="delIt" class="pull-left" :disabled="waiting">
          <i class="fa fa-fw fa-trash" aria-hidden="true"></i> 删 除</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="deptsDialog" title="选择部门">
      <el-tree v-if="deptsDialog" :data="depts" show-checkbox node-key="id" ref="deptTree"
        :default-checked-keys="checkedDepts"
        :default-expanded-keys="checkedDepts"
        :props="{children: 'children', label: 'name'}"></el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deptsDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="warning" @click="this.resetCheckedDepts" icon="fa fa-fw fa-times"> 清 空</el-button>
        <el-button type="primary" @click="selectViewDepts" icon="fa fa-fw fa-floppy-o"> 确 定</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import { mapState } from 'vuex';
import * as _find from 'lodash/find';
import * as _forOwn from 'lodash/forOwn';
// import * as _cloneDeep from 'lodash/cloneDeep';

export default {
  data() {
    return {
      loading: false,
      waiting: false,
      statusArr: {
        1: '在用',
        2: '已禁用',
      },
      userTypes: [
        // { value: 'admin', label: '管理员' },
        { value: '', label: '其他' },
        { value: 'dept_user', label: '国华业务' },
      ],
      // 查询条件form
      queryForm: {
        q: '',
      },
      selectLoading: false,
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
        login_name: [{ required: true, message: '填写登录名', trigger: ['change', 'blur'] }],
      },
      mmrCompanies: [], // 所属缅甸的公司
      companies: [], // 资产管理员所属企业
      branches: [], // 分公司
      depts: [], // 部门树
      departments: [], // erp 部门
      hdDepts: [], // erp 部门
      deptMap: {}, // 部门id和内容对应数组
      deptArr: [], // 选中部门
      departmentArr: [], // 编辑时国华部门级联选择结果数组
      hdDepartmentArr: [], // 编辑时豪鼎部门级联选择结果数组
      shipCompanies: [], // 货运
      ownFactories: [], // 自有工厂
      allRoles: [], // 所有角色
      selectedBox: {}, // 角色选中框
      deptsDialog: false, // 查看权限部门选择
      // viewDepts: [],
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapState({
      // depts: state => state.common.depts,
      erpTree: state => state.common.erpTree,
      hdErpTree: state => state.common.hdErpTree,
    }),
    // 缅甸企业录入角色是否选中
    hasRole1() {
      let result = false;
      const role = _find(this.allRoles, { code: 'mmr_input' });
      if (role && this.selectedBox[role.id]) {
        result = true;
      }
      return result;
    },
    // 资产管理录入角色是否选中
    hasRole2() {
      let result = false;
      const role = _find(this.allRoles, { code: 'asset_input' });
      if (role && this.selectedBox[role.id]) {
        result = true;
      }
      return result;
    },
    // 有属于部门的
    belongDept() {
      let result = false;
      const role = _find(this.allRoles, { code: 'wb_branch' });
      if (role && this.selectedBox[role.id]) {
        result = true;
      }
      return result;
    },
    isViewUser() {
      let result = false;
      const role = _find(this.allRoles, { code: 'wb_view' });
      if (role && this.selectedBox[role.id]) {
        result = true;
      }
      return result;
    },
    isOwnFactoryManager() {
      let result = false;
      const role = _find(this.allRoles, { code: 'factory_stock_manager' });
      if (role && this.selectedBox[role.id]) {
        result = true;
      }
      return result;
    },
    // 录入发货单记录
    belongShipCompany() {
      let result = false;
      const role = _find(this.allRoles, { code: 'wb_company' });
      if (role && this.selectedBox[role.id]) {
        result = true;
      }
      return result;
    },
    // wb_view查看权限当前选中的部门树
    checkedDepts() {
      return this.editModel.wb_depts ? this.editModel.wb_depts : [];
    },
  },
  created() {
    this.init();
    this.onSubmitQuery();
  },
  methods: {
    // test() {
    //   const obj = {
    //     codes: ['t1', 't2', 't3'],
    //   };
    //   this.$http.get('package-by-code', { params: { code: 't1' } }).then((resp) => {
    //     console.log(resp.data);
    //   });
    //   // this.$http.post('package', obj).then((resp) => {
    //   //   if (typeof resp.data === 'number') {
    //   //     console.log(resp.data);
    //   //   }
    //   // });
    // },
    init() {
      this.$http.get('role', { params: { with_list: 1 } }).then((resp) => {
        this.allRoles = resp.data;
      }, (err) => {
        this.$utils.resp(err);
      });
      this.$http.get('company', { params: { with_list: 1 } }).then((resp) => {
        this.companies = resp.data;
      }, (err) => {
        this.$utils.resp(err);
      });
      this.$http.get('mmrcompany', { params: { with_list: 1 } }).then((resp) => {
        this.mmrCompanies = resp.data;
      }, (err) => {
        this.$utils.resp(err);
      });
      this.$http.get('branches').then((resp) => {
        this.branches = resp.data;
      }, (err) => {
        this.$utils.resp(err);
      });
      this.$http.get('shippingcompany').then((resp) => {
        this.shipCompanies = resp.data.data;
      }, (err) => {
        this.$utils.resp(err);
      });
      this.$http.get('ownfactory', { params: { all: 1 } }).then((resp) => {
        this.ownFactories = resp.data;
      }, (err) => {
        this.$utils.resp(err);
      });
      // 部门获取
      this.deptMap = {};
      // erp部门
      this.$http.get('deptTree', { params: { id: 19177 } }).then((resp) => {
        const depts = resp.data;
        this.$utils.treeTailFun(depts, this.everyFun);
        // const viewDepts = _cloneDeep(depts);
        this.departments = depts;
        // this.viewDepts = viewDepts;
      }, (err) => {
        this.$utils.resp(err);
      });
      this.$http.get('deptTree', { params: { id: 437134 } }).then((resp) => {
        const depts = resp.data;
        this.$utils.treeTailFun(depts, this.everyFun);
        // const viewDepts = _cloneDeep(depts);
        this.hdDepts = depts;
        // this.viewDepts = viewDepts;
      }, (err) => {
        this.$utils.resp(err);
      });
      // 老数据部门
      this.$http.get('deptTree').then((resp) => {
        const depts = resp.data;
        this.$utils.treeTailFun(depts, this.everyFun);
        // const viewDepts = _cloneDeep(depts);
        this.depts = depts;
        // this.viewDepts = viewDepts;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // 循环树去掉children为空数组的children,顺便获取所有的节点组成map对象
    everyFun(item) {
      if (item.children && item.children.length === 0) {
        delete item.children;
      }
      this.deptMap[item.id] = item;
    },
    // 管理查看选择部门的清空选择
    resetCheckedDepts() {
      this.$refs.deptTree.setCheckedKeys([]);
    },
    selectViewDepts() {
      const checked = this.$refs.deptTree.getCheckedKeys();
      if (this.isEditUserDept) {
        this.editModel.depts = checked;
      } else {
        this.editModel.wb_depts = checked;
      }
      this.deptsDialog = false;
    },
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$http.get('user', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.list = response.data.data;
          this.totalItem = response.data.total;
          this.loading = false;
        }, (err) => {
          this.loading = false;
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.editModel = {
        name: '',
        login_name: '',
        password: '',
        email: '',
        mobile: '',
        type: '',
        department_id: '',
        asset_company_id: '',
        mmr_company_id: '',
        branch_id: '',
        wb_depts: [],
        shipping_company_id: '',
        own_factory_ids: null,
      };
      this.deptArr = [];
      this.departmentArr = [];
      this.hdDepartmentArr = [];
      this.initSelectedBox();
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 打开编辑
    toEdit(editObj) {
      this.axios.get(`user/${editObj.id}`).then((resp) => {
        const hasRoleIds = [];
        resp.data.roles.forEach((item) => {
          hasRoleIds.push(item.id);
        });
        this.initSelectedBox(hasRoleIds);
        this.editModel = {
          id: resp.data.id,
          name: resp.data.name,
          login_name: resp.data.login_name,
          email: resp.data.email,
          mobile: resp.data.mobile,
          department_id: resp.data.department_id,
        };
        // 部门级联初始化
        let selectedDept = [];
        if (resp.data.department_id) {
          const id = resp.data.department_id;
          let dept = this.deptMap[id];
          while (dept) {
            selectedDept.unshift(dept.id);
            dept = this.deptMap[dept.parent_id];
          }
        }
        this.departmentArr = selectedDept;
        // 豪鼎部门级联初始化
        selectedDept = [];
        if (resp.data.meta && resp.data.meta.department_id2) {
          const hdDeptartmentId = resp.data.meta.department_id2;
          let dept = this.deptMap[hdDeptartmentId];
          while (dept) {
            console.log(dept);
            selectedDept.unshift(dept.id);
            dept = this.deptMap[dept.parent_id];
          }
        }
        this.hdDepartmentArr = selectedDept;
        // 其他meta选项设置成editModel对象得直接属性
        if (!resp.data.meta) {
          resp.data.meta = {};
        }
        this.$set(this.editModel, 'asset_company_id', resp.data.meta.asset_company_id ? resp.data.meta.asset_company_id : null);
        this.$set(this.editModel, 'mmr_company_id', resp.data.meta.mmr_company_id ? resp.data.meta.mmr_company_id : null);
        this.$set(this.editModel, 'branch_id', resp.data.meta.branch_id ? resp.data.meta.branch_id : null);
        this.$set(this.editModel, 'wb_depts', resp.data.meta.wb_depts ? resp.data.meta.wb_depts : []);
        const shippingCompanyId = resp.data.meta.shipping_company_id ? resp.data.meta.shipping_company_id : null;
        // 初始化货运相关企业
        if (shippingCompanyId) {
          this.queryShippingCompany(shippingCompanyId);
        }
        if (this.editModel.branch_id) {
          const arr = [];
          let dept = this.deptMap[this.editModel.branch_id];
          if (dept) {
            arr.unshift(dept.id);
          }
          while (dept.parent) {
            dept = dept.parent;
            arr.unshift(dept.id);
          }
          this.deptArr = arr;
        } else {
          this.deptArr = [];
        }
        this.$set(this.editModel, 'shipping_company_id', shippingCompanyId);
        this.editDialog = true;
      });
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 初始化角色选择框
    initSelectedBox(selectedArr = false) {
      const obj = {};
      this.allRoles.forEach((item) => {
        if (selectedArr && selectedArr.indexOf(item.id) !== -1) {
          obj[item.id] = true;
        } else {
          obj[item.id] = false;
        }
      });
      this.selectedBox = obj;
    },
    // 货运企业筛选框
    queryShippingCompany(q) {
      if (typeof q === 'number') {
        this.$http.get('shippingcompany/' + q).then((resp) => {
          this.shipCompanies = [resp.data];
        });
      } else if (typeof q === 'string' && q !== '') {
        this.selectLoading = true;
        this.$http.get('shippingcompany', { params: { q } }).then((resp) => {
          this.shipCompanies = resp.data.data;
          this.selectLoading = false;
        }, (err) => {
          this.selectLoading = false;
          this.$utils.resp(err);
        });
      } else {
        this.options = [];
      }
    },
    // 国华用户部门选择
    changeDepartment(arr) {
      if (arr.length > 0) {
        this.editModel.department_id = arr[arr.length - 1];
      } else {
        this.editModel.department_id = '';
      }
    },
    // 部门选中
    chooseDept(depts) {
      if (depts.length > 0) {
        this.editModel.branch_id = depts[depts.length - 1];
      } else {
        this.editModel.branch_id = depts[depts.length - 1];
      }
    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.editModel };
          obj.roleIds = [];
          _forOwn(this.selectedBox, (val, key) => {
            if (val) {
              obj.roleIds.push(key);
            }
          });
          console.log(this.departmentArr);
          if (this.departmentArr.length > 0) {
            obj.department_id = this.departmentArr[this.departmentArr.length - 1];
            console.log(this.departmentArr[this.departmentArr.length - 1]);
          } else {
            obj.department_id = null;
          }
          if (this.hdDepartmentArr.length > 0) {
            obj.department_id2 = this.hdDepartmentArr[this.hdDepartmentArr.length - 1];
          } else {
            obj.department_id2 = null;
          }
          this.waiting = true;
          if (obj.id) {
            this.$http.put(`user/${obj.id}`, obj).then(() => {
              this.editDialog = false;
              this.getList();
              this.$message({
                type: 'success',
                message: '更新成功',
                showClose: true,
                duration: 2000,
              });
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          } else {
            this.$http.post('user', obj).then(() => {
              this.editDialog = false;
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
              this.getList();
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
    switchStatus(item, val) {
      this.$http.put(`disable_user/${item.id}`, { status: val }).then((resp) => {
        this.$message({
          type: 'success',
          message: resp.data === 1 ? '已启用' : '已禁用',
          duration: 2000,
          showClose: true,
        });
      }, (err) => {
        item.status = val === '1' ? '2' : '1';
        this.$utils.resp(err);
      });
    },
    delIt() {
      this.$confirm('删除后无法恢复，是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`user/${this.editModel.id}`).then(() => {
          this.getList();
          this.editDialog = false;
          this.$message({
            type: 'success',
            message: '删除成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.$utils.resp(resp);
        });
      }, () => {});
    },
  },
};
</script>
