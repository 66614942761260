<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>

    <div class="mar-btm-10 pull-right">
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>借用人</th>
          <th>日期</th>
          <th>备注</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.borrower }}</td>
          <td>{{ item.occur_date }}</td>
          <td>{{ item.remark }}</td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" title="详情" @click="toEdit(item)"><i class="fa fa-fw fa-search"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'" :close-on-click-modal="false">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="借用人" prop="borrower">
          <span v-if="editModel.id">{{editModel.borrower}}</span>
          <el-input v-else v-model="editModel.borrower"></el-input>
        </el-form-item>
        <el-form-item label="日期" prop="occur_date">
          <span v-if="editModel.id">{{editModel.occur_date}}</span>
          <el-date-picker v-else type="date" v-model="editModel.occur_date"
            value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <span v-if="editModel.id">{{editModel.remark}}</span>
          <el-input v-else type="textarea" v-model="editModel.remark"></el-input>
        </el-form-item>
        <el-form-item label="借用明细" prop="remark">
          <span>明细数量：{{editModel.items ? editModel.items.length : 0}}</span>
          <el-table
            :data="editModel.items"
            height="250"
            border
            style="width: 100%">
            <el-table-column
              prop="code"
              label="编号">
            </el-table-column>
            <el-table-column
              prop="name"
              label="名称">
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="save" v-if="!editModel.id">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true" :disabled="waiting"></i> 确 定</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      waiting: false,
      loading: false,
      // 查询条件form
      queryForm: {
        code: '',
        status: '',
        type: '',
      },
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      rules: {
        borrow: [{ required: true, message: '填写借用人', trigger: ['change', 'blur'] }],
      },
      scanCode: '',
      code: '',
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  created() {
    this.onSubmitQuery();
  },
  mounted() {
    window.document.addEventListener('keydown', this.keydown);
  },
  methods: {
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm, type: '1' };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$http.get('lend-log', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          this.loading = false;
          this.list = list;
          this.list = response.data.data;
          this.totalItem = response.data.total;
        }, (err) => {
          this.loading = false;
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.editModel = {
        borrower: '',
        occur_date: this.$utils.formatDate(),
        remark: '',
        items: [],
        type: '1',
        list: '',
      };
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    keydown(e) {
      const curTime = new Date().getTime();
      const keyCode = e.which;
      if (!this.lastKeyTime) {
        this.lastKeyTime = curTime;
      }
      if ((curTime - this.lastKeyTime) < 40) {
        if (keyCode === 13) {
          this.scanCode = this.code;
          const p1 = /^[A-Z,0-9]{7,20}$/;
          if (p1.test(this.scanCode)) {
            this.addCode(this.scanCode);
          }
        } else if (keyCode !== 16) {
          this.code += String.fromCharCode(keyCode);
        }
      } else {
        this.code = '';
        if (keyCode !== 16) {
          this.code = String.fromCharCode(keyCode);
        }
      }
      this.lastKeyTime = curTime;
    },
    addCode(code) {
      if (this.editDialog && !this.editModel.id) {
        const exist = this.editModel.items.find(item => item.code === code);
        if (!exist) {
          const params = {
            code,
          };
          this.axios.get('ykofcode', { params }).then((resp) => {
            this.editModel.items.push(resp.data);
          }, (e) => {
            this.$utils.resp(e);
          });
        }
      }
    },
    // 打开编辑
    toEdit(editObj) {
      this.axios.get(`lend-log/${editObj.id}`).then((resp) => {
        this.editModel = resp.data;
        this.editDialog = true;
        this.$nextTick(() => {
          if (this.$refs.editForm) {
            this.$refs.editForm.clearValidate();
          }
        });
      });
    },
    codeChange(code) {
      this.types.forEach((type) => {
        if (code.indexOf(type.value) === 0) {
          this.editModel.type = type.value;
        }
      });
    },
    typeChange() {

    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.editModel };
          obj.list = obj.items.map(item => item.code);
          this.waiting = true;
          if (obj.id) {
            this.$http.put(`lend-log/${obj.id}`, obj).then(() => {
              this.editDialog = false;
              this.getList();
              this.$message({
                type: 'success',
                message: '更新成功',
                showClose: true,
                duration: 2000,
              });
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          } else {
            this.$http.post('lend-log', obj).then(() => {
              this.editDialog = false;
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
              this.getList();
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
  },
  destroyed() {
    window.document.removeEventListener('keydown', this.keydown);
  },
};
</script>
