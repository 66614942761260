<template>
  <div class="components">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card>
          <el-button type="primary" @click="toAddMain">新增模板</el-button>
          <div>
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">名称</th>
                  <th class="text-center" width="100">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in leftList" :key="item.id">
                  <td @click="selectCurObj(item)">{{item.title}}</td>
                  <td>
                    <el-button type="warning" @click="toEdit(item)" icon="fa fa-pencil"></el-button>
                    <el-button type="danger" @click="deleteIt(item)" icon="fa fa-trash"></el-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card>
          <div>
            {{curObj.title}} <el-button v-if="curObj.id" type="primary" @click="toAddSub(curObj)"> 新增项目</el-button>
          </div>
          <div>
            <el-collapse>
              <el-collapse-item v-for="(item, index) in rightList" :key="index">
                <template slot="title">
                  {{item.title}}  <span v-if="item.type === 'sum'">(子项：{{item.subs.length}})</span>
                  <el-button v-if="item.type === 'sum'" type="primary" title="添加子项" @click.stop="toAddSub(item)" icon="fa fa-plus"></el-button>
                  <el-button type="warning" icon="fa fa-pencil" @click.stop="toEditSub(item)"></el-button>
                  <el-button type="danger" icon="fa fa-trash" @click.stop="delSub(item)"></el-button>
                </template>
                <table class="item-table" v-if="item.subs.length">
                  <tbody>
                    <tr v-for="sub in item.subs" :key="sub.id">
                      <td>{{sub.title}}</td>
                      <td>{{sub.type}}</td>
                      <td>
                        <el-button type="warning" @click.stop="toCopy(sub)">复制</el-button>
                        <el-button type="warning" icon="fa fa-pencil" @click.stop="toEditSub(sub)"></el-button>
                        <el-button type="danger" icon="fa fa-trash" @click.stop="delSub(sub)"></el-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editObj.id ? '编辑' : '新增'"
      :close-on-click-modal="false" :close="closeEditDialog">
      <el-form :model="editObj" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="名称" prop="title">
          <el-input v-model="editObj.title"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="editObj.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="save">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>

        <!-- 编辑子项dialog -->
    <el-dialog :visible.sync="subEditDialog" :title="subEditObj.id ? '编辑' : '新增'"
      :close-on-click-modal="false">
      <el-form :model="subEditObj" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="名称" prop="title">
          <el-input v-model="subEditObj.title"></el-input>
        </el-form-item>
        <el-form-item label="所属上级项" prop="parent_id">
          <el-select v-model="subEditObj.parent_id">
            <el-option v-for="item in pList" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评价类型" prop="type">
          <el-select v-model="subEditObj.type" @change="changeType">
            <el-option v-for="item in types" :key="item.id" :label="item.label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分型" prop="category" v-if="subEditObj.type!=='text'">
          <el-select v-model="subEditObj.category">
            <el-option v-for="item in categories" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否填人数" prop="is_person_number">
          <el-switch
            v-model="subEditObj.is_person_number"
            active-value="1"
            inactive-value="0"
            active-text="是"
            inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否评分" prop="is_score">
          <el-switch
            v-model="subEditObj.is_score"
            active-value="1"
            inactive-value="0"
            active-text="是"
            inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item v-if="subEditObj.type == 'sum'" label="是否填面积" prop="is_area">
          <el-switch
            v-model="subEditObj.is_area"
            active-value="1"
            inactive-value="0"
            active-text="是"
            inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="subEditObj.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="subEditDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="saveItem">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      wating: false,
      // 查询条件form
      queryForm: {
        q: '',
      },
      queryParams: {}, // 分页查询参数
      leftList: [], // 评价模板
      curObj: {},
      editObj: {}, // 管理的操作时对象
      subEditDialog: false,
      pList: [],
      types: [],
      categories: [],
      rightList: [],
      subEditObj: {},
      editDialog: false, // dialog显示标志 true 显示
      // 编辑form的条件
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
        asset_sn: [{ required: true, message: '填写资产编号', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  created() {
    this.init();
  },

  methods: {
    init() {
      this.getMainItem();
      this.types = [
        { id: 'sum', label: '统计项（统计子项的合计人数）' },
        { id: 'boolean', label: '是/否' },
        { id: 'number', label: '数量' },
        { id: 'text', label: '文本内容' },
        { id: 'array', label: '多条文本内容' },
      ];
      this.categories = [
        '工厂生产品类',
        '附加部门',
      ];
    },
    getMainItem() {
      this.axios.get('reportitemmain').then((resp) => {
        this.leftList = resp.data;
      }, (e) => {
        this.$utils.resp(e);
      });
    },
    toAddMain() {
      this.editObj = {
        isMain: true,
        title: '',
        remark: '',
      };
      this.editDialog = true;
      if (this.$refs.editForm) {
        this.$nextTick(() => {
          this.$refs.editForm.clearValidate();
        });
      }
    },
    toEdit(item) {
      this.editObj = { ...item };
      this.editDialog = true;
    },
    save() {
      if (this.editObj.id) {
        this.axios.put(`reportitem/${this.editObj.id}`, this.editObj).then(() => {
          this.wating = false;
          this.editDialog = false;
          this.getMainItem();
        }, (e) => {
          this.wating = false;
          this.$utils.resp(e);
        });
      } else {
        this.wating = true;
        this.axios.post('reportitem', this.editObj).then(() => {
          this.wating = false;
          this.editDialog = false;
          this.getMainItem();
        }, (e) => {
          this.wating = false;
          this.$utils.resp(e);
        });
      }
    },
    selectCurObj(obj) {
      this.curObj = obj;
      this.pList = [obj];
      const params = { pid: obj.id };
      this.axios.get('reportitem', { params }).then((resp) => {
        const list = resp.data;
        const parents = list.map((item) => {
          const { id, title } = item;
          return { id, title };
        });
        parents.unshift({ id: obj.id, title: obj.title });
        console.log(parents);
        this.pList = parents;
        this.rightList = resp.data;
      });
    },
    toAddSub(pItem) {
      this.subEditObj = {
        parent_id: pItem.id,
        title: '',
        type: '',
        is_score: 0,
        is_area: 0,
        is_person_number: 0,
      };
      this.subEditDialog = true;
    },
    toEditSub(item) {
      this.subEditObj = { ...item };
      this.subEditDialog = true;
    },
    toCopy(item) {
      this.$prompt('名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: '邮箱格式不正确'
      }).then(({ value }) => {
        if (value) {
          this.subEditObj = { ...item };
          this.subEditObj.id = null;
          this.subEditObj.title = value;
          this.saveItem();
        }
      }, () => {});
    },
    changeType(type) {
      console.log(type);
      // 统计之要填总人数和总面积
      if (type === 'sum') {
        this.subEditObj.is_area = '1';
        this.subEditObj.is_person_number = '1';
        this.subEditObj.is_score = '0';
      } else if (type === 'text' || type === 'array') {
        this.subEditObj.is_area = '0';
        this.subEditObj.is_person_number = '0';
        this.subEditObj.is_score = '0';
      } else if (type === 'number') {
        this.subEditObj.is_area = '0';
        this.subEditObj.is_person_number = '1';
        this.subEditObj.is_score = '1';
      } else if (type === 'boolean') {
        this.subEditObj.is_area = '0';
        this.subEditObj.is_person_number = '0';
        this.subEditObj.is_score = '1';
      }
    },
    saveItem() {
      if (this.subEditObj.id) {
        this.wating = true;
        this.axios.put(`reportitem/${this.subEditObj.id}`, this.subEditObj).then(() => {
          this.wating = false;
          this.subEditDialog = false;
          this.selectCurObj(this.curObj);
        }, (e) => {
          this.wating = false;
          this.$utils.resp(e);
        });
      } else {
        this.wating = true;
        this.axios.post('reportitem', this.subEditObj).then(() => {
          this.wating = false;
          this.subEditDialog = false;
          this.selectCurObj(this.curObj);
        }, (e) => {
          this.wating = false;
          this.$utils.resp(e);
        });
      }
    },
    // todel
    onSubmitQuery() {
      this.queryParams = {};
      if (this.queryForm.q && this.queryForm.q.trim()) {
        this.queryParams.q = this.queryForm.q;
      }
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    // 查询列表数据
    getList() {
      this.axios.get('company', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.firmList = response.data.data;
          this.totalItem = response.data.total;
        }, (response) => {
          this.$utils.resp(response);
        });
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toAdd() {
      const firm = {
        id: '',
        name: '',
        type: '',
        code: '',
        asset_sn: '',
        leader: '',
        leader_phone: '',
        contact_man: '',
        contact_phone: '',
        registered_capital: '',
        founded_at: '',
        addr: '',
      };
      this.firmForm = firm;
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    // 修改新增的保存
    saveFirm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const tempForm = { ...this.firmForm };
          if (tempForm.id) {
            this.$http.put(`company/${tempForm.id}`, tempForm).then(() => {
              this.editDialog = false;
              this.getList();
            }, (resp) => {
              this.$utils.resp(resp);
            });
          } else {
            this.$http.post('company', tempForm).then(() => {
              this.editDialog = false;
              this.changePage(1);
            }, (resp) => {
              this.$utils.resp(resp);
            });
          }
        }
      });
    },
    closeEditDialog() {
      // this.canChooseBranch = true;
    },
    deleteIt(item) {
      console.log(item);
      let theId = item;
      if (typeof item === 'number') {
        theId = item;
      } else {
        theId = item.id;
      }
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.wating = true;
        this.$http.delete(`reportitem/${theId}`).then(() => {
          this.wating = false;
          this.getMainItem();
          this.editDialog = false;
        }, (resp) => {
          this.wating = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
  },
  // filters: {
  //   substr4(value) {
  //     return value.substr(0, 4);
  //   },
  // },
};
</script>

<style scoped>
.item-table {
  width: 100%;
}
</style>
