<template>
  <div>
    <div class="mar-btm-10 text-right">
      <div class="pull-left">新旧合计{{totalInCount}}件</div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.kh" clearable placeholder="款号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.rela_style" clearable placeholder="关联款号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <!-- <div class="box-inline standard-search">
        <el-select v-model="queryForm.color" clearable placeholder="颜色选择" filterable>
          <el-option v-for="(label, key) in colors" :key="key" :value="key" :label="`[${key}]${label}`"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.size" clearable placeholder="尺码" filterable>
          <el-option v-for="(label, key) in sizes" :key="key" :value="key" :label="`${label}`"></el-option>
        </el-select>
      </div> -->
      <div class="box-inline standard-search">
        <el-date-picker v-model="queryForm.date"
          align="right"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div class="box-inline standard-search">
        <el-date-picker value-format="yyyy-MM-dd"
          v-model="queryForm.start_date"
          type="date" placeholder="开始日期">
        </el-date-picker>
      </div>
      <div class="box-inline standard-search">
        <el-date-picker value-format="yyyy-MM-dd"
          v-model="queryForm.end_date"
          type="date" placeholder="截至日期">
        </el-date-picker>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>
    <el-tabs v-model="subActionName" @tab-click="handleClick">
      <el-tab-pane v-if="inType == '2'" name="sub_first" label="老款">
        <table class="table bordered">
          <thead>
            <tr>
              <th>款号</th>
              <th>类别</th>
              <th>颜色</th>
              <th>尺码</th>
              <th>SKU</th>
              <th>关联款号</th>
              <th>操作人</th>
              <th>入库时间</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list1" :key="index">
              <td>{{item.code.substr(2, 5)}}</td>
              <td>{{categories[item.code.substr(3, 2)]}}</td>
              <td>{{colors[item.color]}}</td>
              <td>{{sizes[item.size]}}</td>
              <td>{{item.code}}</td>
              <td>{{item.rela_style}}</td>
              <td>{{item.in_creator}}</td>
              <td>{{item.in_at}}</td>
            </tr>
          </tbody>
        </table>
        <div class="clearfix mar-top">
          <el-pagination class="pull-right"
            @current-change="changePage1"
            layout="total, prev, pager, next"
            :total="totalItem1"
            :current-page="currentPage1"
            :page-size="pageSize1">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane name="sub_second" :label="inType == '2' ? '新款' : '返库'">
        <div>
          <table class="table bordered">
            <thead>
              <tr>
                <th>款号</th>
                <th>类别</th>
                <th>颜色</th>
                <th>尺码</th>
                <th>SKU</th>
                <th>入库数量</th>
                <th v-if="inType=='3'">返库类型</th>
                <th>操作人</th>
                <th>入库时间</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in list2" :key="idx">
                <td>{{item.code.substr(0, 5)}}</td>
                <td>{{item.clothes ? item.clothes.sub_category : categories[item.code.substr(3, 2)]}}</td>
                <td>{{item.clothes ? item.clothes.color : colors[item.code.substr(7, 2)]}}</td>
                <td>{{item.clothes ? item.code.substr(8) : sizes[item.code.substr(9, 1)]}}</td>
                <td>{{item.code}}</td>
                <td>{{item.count}} <el-button v-if="canCancel" @click="cancel(item.id)">撤销</el-button></td>
                <th v-if="inType=='3'">{{item.sub_type|subTypeName(subTypes)}}</th>
                <td>{{item.creator}}</td>
                <td>{{item.created_at}}</td>
              </tr>
            </tbody>
          </table>
          <div class="clearfix mar-top">
            <el-pagination class="pull-right"
              @current-change="changePage2"
              layout="slot, total, prev, pager, next"
              :total="totalItem2"
              :current-page="currentPage2"
              :page-size="pageSize2">
              <div style="font-size: 14px;">合计：{{total2}}件</div>
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  props: {
    inType: {
      type: String,
      default: '2',
    },
    subTypes: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      activeName: 'first',
      subActionName: this.inType === '2' ? 'sub_first' : 'sub_second',
      queryForm: {
        kh: '',
        rela_style: '',
        color: '',
        size: '',
        date: this.$utils.FormatDate(),
        start_date: '',
        end_date: '',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          },
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          },
        }],
      },
      queryParams: {},
      colors: [],
      sizes: [],
      seasons: [],
      outTypes: [],
      categories: [],
      relaStyles: [],
      khs: [],
      boxHeight: 100,
      list1: [], // 老款数据
      // 分页pagination数据
      totalItem1: 0,
      currentPage1: 1,
      pageSize1: 15,
      list2: [], // 新款数据
      totalItem2: 0,
      currentPage2: 1,
      pageSize2: 15,
      total2: 0,
      // img
      showImgUrl: '',
    };
  },
  created() {
    if (this.$store.state.common.clothesDicts.sizes) {
      this.sizes = this.$store.state.common.clothesDicts.sizes;
      this.seasons = this.$store.state.common.clothesDicts.seasons;
      this.categories = this.$store.state.common.clothesDicts.orgCategories;
      this.colors = this.$store.state.common.clothesDicts.orgColors;
      this.outTypes = this.$store.state.common.clothesDicts.sub_types;
      this.relaStyles = this.$store.state.common.clothesDicts.relaStyles;
      this.khs = this.$store.state.common.clothesDicts.khs;
    } else {
      this.$store.dispatch('GET_COLTHES_DICTS').then((resp) => {
        this.sizes = resp.data.sizes;
        this.seasons = resp.data.seasons;
        this.categories = resp.data.orgCategories;
        this.colors = resp.data.orgColors;
        this.outTypes = this.$store.state.common.clothesDicts.sub_types;
        this.relaStyles = this.$store.state.common.clothesDicts.relaStyles;
        this.khs = this.$store.state.common.clothesDicts.khs;
      });
    }
  },
  computed: {
    totalInCount() {
      let total = 0;
      total = this.totalItem1;
      if (this.total2) {
        total = 1 * total + 1 * this.total2;
      }
      return total;
    },
    canCancel() {
      let r = false;
      if (this.$store.state.auth.user && this.$store.state.auth.user.type === 'admin') {
        r = true;
      }
      return r;
    },
  },
  methods: {
    handleClick() {

    },
    onSubmitQuery() {
      this.queryParams = {};
      if (this.queryForm.rela_style && this.queryForm.rela_style.trim()) {
        this.queryParams.rela_style = this.queryForm.rela_style;
      }
      if (this.queryForm.kh && this.queryForm.kh.trim()) {
        this.queryParams.kh = this.queryForm.kh;
      }
      if (this.queryForm.date !== null) {
        this.queryParams.date = this.queryForm.date;
      }
      if (this.queryForm.start_date && this.queryForm.start_date.trim()) {
        this.queryParams.start_date = this.queryForm.start_date;
      }
      if (this.queryForm.end_date && this.queryForm.end_date.trim()) {
        this.queryParams.end_date = this.queryForm.end_date;
      }
      if (this.currentPage1 !== 1) {
        this.currentPage1 = 1;
      }
      if (this.currentPage2 !== 1) {
        this.currentPage2 = 1;
      }
      this.getListOld();
      this.getListNew();
    },
    changePage1(page) {
      this.currentPage1 = page;
      this.getListOld();
    },
    getListOld() {
      if (this.inType === '2') {
        this.axios.get('clothes-in-log', { params: { ...this.queryParams, page: this.currentPage1, pageSize: this.pageSize1 } })
          .then((response) => {
            const list = response.data.data;
            this.list1 = list;
            this.totalItem1 = response.data.total;
          }, (err) => {
            this.$utils.resp(err);
          });
      }
    },
    changePage2(page) {
      this.currentPage2 = page;
      this.getListNew();
    },
    getListNew() {
      const type = this.inType;
      this.axios.get('e-stock-log', { params: { ...this.queryParams, type, page: this.currentPage2, pageSize: this.pageSize2 } })
        .then((resp) => {
          this.list2 = resp.data.data;
          this.totalItem2 = resp.data.total;
          this.total2 = resp.data.totalcount;
        });
    },
    cancel(id) {
      this.axios.put(`e-clothes-back/${id}/cancel`).then(() => {
        this.getListNew();
      });
    },
  },
  filters: {
    subTypeName(subType, subTypes) {
      const type = subType.replace('-', '');
      return subTypes[type];
    },
  },
};
</script>

<style>
</style>
