<template>
  <div class="text-center">
    <div style="display: inline-block;padding-top: 50px;">
      <vue-qr :logoSrc="logoUrl" logoBackgroundColor="#fff" :logoMargin="5"
        :correctLevel="3" :text="qrStr" :margin="6" :dotScale="1"></vue-qr>
      <br/>
      <span>{{code}}</span>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr';

export default {
  data() {
    return {
      qrStr: '',
      code: '',
      id: '',
      logoUrl: window.baseUrl + 'favicon.ico',
    };
  },
  created() {
    const params = this.$route.query;
    if (params.code) {
      this.code = params.code;
    }
    if (params.id) {
      this.id = params.id;
    }
    if (this.id) {
      this.qrStr = `https://www.guotaigh.com.cn/wb-app/#/dysample?id=${params.id}`;
    } else {
      this.qrStr = `https://www.guotaigh.com.cn/wb-app/#/dysample?code=${params.code}`;
    }
  },
  components: {
    VueQr,
  },
  // filters: {
  //   qrUrl(code) {
  //     let url = '';
  //     if (this.code) {
  //       url = `https://www.guotaigh.com.cn/wb-app/#/dysample?code=${code}`;
  //     } else if (this.id) {
  //       url = `https://www.guotaigh.com.cn/wb-app/#/dysample?id=${code}`;
  //     }
  //     return url;
  //   },
  // },
};
</script>
