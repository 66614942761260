<template>
  <el-card>
    <div>
      <div class="mar-btm-10 pull-right">
        <div class="box-inline standard-search" v-if="isOwnStockManager">
          {{factories[0] ? (factories[0].name + factories[0].name1) : ''}}
        </div>
        <div class="box-inline standard-search" v-else>
          <el-select v-model="queryForm.own_factory_id" clearable placeholder="factory"  @change="onSubmitQuery">
            <el-option v-for="item in factories" :key="item.id" :value="item.id" :label="item.name + item.name1"></el-option>
          </el-select>
        </div>
        <div class="box-inline standard-search">
          <el-input v-model="queryForm.code" clearable placeholder="code" @keydown.enter.native="onSubmitQuery"></el-input>
        </div>
        <div class="box-inline standard-search">
          <el-select v-model="queryForm.type" clearable placeholder="出入库类型" @change="onSubmitQuery">
            <el-option v-for="(item, key) in types" :label="item" :value="key" :key="key"></el-option>
          </el-select>
        </div>
        <el-button size="small" @click="onSubmitQuery">
          <i class="fa fa-fw fa-search"></i>
        </el-button>
      </div>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>code出入库编号</th>
          <th>类型</th>
          <th>日期</th>
          <th>创建人</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.code }}</td>
          <td :class="['td-color-'+item.type]">{{item.type === 'in' ? 'IN' : ''}}{{item.type === 'out' ? 'OUT' : ''}}</td>
          <td>{{ item.occur_date }}</td>
          <td>{{ item.creator }}</td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" title="Edit" @click="toDetail(item)"><i class="fa fa-fw fa-search"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>
    <!-- detail -->
    <el-dialog :visible.sync="showDialog" title="detail"
      :close-on-click-modal="false">
      <el-form :model="record" ref="editForm" label-width="140px">
        <el-form-item label="Factory工厂：" prop="own_factory_id">
          {{factoryMap[record.id] ? factoryMap[record.id].name : ''}}
        </el-form-item>
        <el-form-item label="Code编号：" prop="code">
          {{record.code}}
        </el-form-item>
        <el-form-item label="Date：" prop="occur_date">
          {{record.occur_date}}
        </el-form-item>
        <el-form-item label="Remark 备注：" prop="remark">
          {{record.remark}}
        </el-form-item>
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th>物品</th>
              <th>style规格</th>
              <th>数量</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in record.items" :key="index">
              <td>{{index+1}}</td>
              <td>{{item.name}}{{item.name1}}</td>
              <td>{{item.spec}}</td>
              <td>{{record.type === 'out' ? '-' : ''}}{{Number(item.num)}}</td>
            </tr>
          </tbody>
        </table>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      queryForm: {
        code: null,
        type: null,
        own_factory_id: null,
      },
      queryParams: {},
      factories: [],
      types: {
        in: 'IN[入库]',
        out: 'OUT[出库]',
      },
      list: [],
      record: {},
      showDialog: false,
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    factoryMap() {
      const map = {};
      this.factories.forEach((item) => {
        map[item.id] = item;
      });
      return map;
    },
    isOwnStockManager() {
      let r = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('own_factory_stock') !== -1) {
        r = true;
      }
      return r;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.axios.get('ownfactorylist').then((r) => {
        this.factories = r.data;
        if (this.isOwnStockManager) {
          this.queryForm.own_factory_id = r.data[0].id;
        }
        this.onSubmitQuery();
      });
    },
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    getList() {
      this.axios.get('owfstockrecord', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.list = response.data.data;
          this.totalItem = response.data.total;
        }, (response) => {
          this.$utils.resp(response);
        });
    },
    toDetail(item) {
      this.record = item;
      this.showDialog = true;
    },
  },
};
</script>

<style>
  .td-color-in {
    color: #00cbe1;
  }
  .td-color-out {
    color: #d40e07;
  }
</style>
