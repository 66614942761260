<template>
  <el-card class="components">
    <el-button type="primary" v-if="isDeliveryManager" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 调拨 </el-button>
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="输入关键项目号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <!-- <div class="box-inline standard-search">
        <el-input v-model="queryForm.document_num" clearable placeholder="调拨单号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div> -->
      <!-- <div class="box-inline standard-search">
        <el-select style="width: 100%" v-model="queryForm.company_id" placeholder="选择加工单位" :loading="queryPartiesLoading"
          clearable filterable remote :remote-method="queryParties">
          <el-option v-for="item in parties" :key="item.party_id"
            :value="item.party_id" :label="item.party_name"></el-option>
        </el-select>
      </div> -->
      <div class="box-inline standard-search">
        <el-select style="width: 100%" v-model="queryForm.status" placeholder="状态选择" clearable>
          <el-option v-for="(val, key) in stockStatusMap" :key="key"
            :value="key" :label="val"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>部门</th>
          <th>编号</th>
          <!-- <th>调拨单号</th> -->
          <th>调拨日期</th>
          <th>申请人</th>
          <th>状态</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.owner_group_name }}</td>
          <td><a href="#" @click="toDetail(item)" style="text-decoration: none;">{{ item.code }}</a></td>
          <!-- <td>{{ item.document_num }}</td> -->
          <td>{{ item.transaction_date }}</td>
          <td>{{ item.owner_name }}</td>
          <td>{{ item.status_text }}</td>
          <td>
            <el-button type="info" :disabled="waiting" @click="apply(item)" v-if="item.status==='ENTERED'">提交</el-button>
            <el-button type="info" :disabled="waiting" @click="approve(item)"
              v-if="item.status==='PROCESSING' && !item.dv_order_id">审核通过</el-button>
            <el-button type="info" :disabled="waiting" @click="toDvOrder(item)"
              v-if="item.status==='PROCESSING' && item.dv_order_id">完结审核</el-button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'"
      :close-on-click-modal="false" width="90%" top="2%">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="业务部门">
              <!-- <el-input readonly :value="userDeptId"></el-input> -->
              <el-select :value="userDeptId" style="width: 100%">
                <el-option v-for="item in deptMap" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库组织" prop="organization_id_f">
              <el-select v-model="editModel.organization_id_f" style="width: 100%;" @change="getInventories">
                <el-option value="" label=""></el-option>
                <el-option v-for="(item) in organizations" :key="item.organization_id"
                  :value="item.organization_id" :label="item.organization_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="出库工厂" prop="company_id_f">
              <el-select style="width: 100%" v-model="editModel.company_id_f" placeholder="出库工厂" :loading="queryPartiesLoading"
                clearable filterable remote :remote-method="queryFromParties" @change="getInventories">
                <el-option v-for="item in fromParties" :key="item.party_id"
                  :value="item.party_id" :label="item.party_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库" prop="subinventory_code_f">
              <el-select v-model="editModel.subinventory_code_f" style="width: 100%;">
                <el-option v-for="(item, idx) in inventories" :key="idx"
                  :value="item.secondary_inventory_name" :label="item.description"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品源项目" prop="project_id_f">
              <el-select v-model="editModel.project_id_f" style="width:100%;" filterable
                remote :remote-method="getProjects" @change="chooseProjectFrom">
                <el-option label="" value=""></el-option>
                <el-option v-for="item in projects" :key="item.id"
                  :value="item.project_id" :label="item.project_number">{{`【${item.project_number}】 ${item.description}`}}</el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="调拨类型" prop="txn_type_code">
              <el-select v-model="editModel.txn_type_code" @change="changeType" style="width: 100%;">
                <el-option v-for="item in txnTypes" :key="item.typeCode"
                  :value="item.typeCode" :label="item.typeName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="调拨日期" prop="transaction_date">
              <el-date-picker v-model="editModel.transaction_date"
                style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开票日期" prop="inv_make_date">
              <el-date-picker v-model="editModel.inv_make_date"
                style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="editModel.txn_type_code !== 'INTRAPRO'">
          <el-col :span="12">
            <el-form-item label="目的项目" prop="project_id_t">
              <el-select v-model="editModel.project_id_t" style="width:100%;" filterable
                remote :remote-method="getToProjects" @change="chooseProjectTo">
                <el-option label="" value=""></el-option>
                <el-option v-for="item in toProjects" :key="item.id"
                  :value="item.project_id" :label="item.project_number">{{`【${item.project_number}】 ${item.description}`}}</el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="editModel.txn_type_code === 'INTERORA'">
          <el-col :span="12">
            <el-form-item label="目的部门">
              <!-- <el-input readonly :value="userDeptId"></el-input> -->
              <el-select :value="editModel.group_id_to" style="width: 100%">
                <el-option v-for="item in deptMap" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目的组织" prop="organization_id_t">
              <el-select v-model="editModel.organization_id_t" style="width: 100%;" @change="getToInventories">
                <el-option value="" label=""></el-option>
                <el-option v-for="(item) in organizations" :key="item.organization_id"
                  :value="item.organization_id" :label="item.organization_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="目的工厂" prop="company_id_t">
              <el-select style="width: 100%" v-model="editModel.company_id_t" placeholder="工厂" :loading="queryPartiesLoading"
                clearable filterable remote :remote-method="queryToParties" @change="getToInventories">
                <el-option v-for="item in toParties" :key="item.party_id"
                  :value="item.party_id" :label="item.party_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目的仓库" prop="subinventory_code_t">
              <el-select v-model="editModel.subinventory_code_t" style="width: 100%;">
                <el-option v-for="(item, idx) in toInventories" :key="idx"
                  :value="item.secondary_inventory_name" :label="item.description"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="合同号" prop="po_num">
              <el-input v-model="editModel.po_num"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手册号" prop="manual_num">
              <el-input v-model="editModel.manual_num"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="控货方" prop="attribute1">
              <el-input v-model="editModel.attribute1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="货权函正本" prop="attribute2">
              <el-switch
                v-model="editModel.attribute2"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="Y"
                inactive-value="N">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库单正本" prop="attribute3">
              <el-switch
                v-model="editModel.attribute3"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="Y"
                inactive-value="N">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注说明" prop="description">
          <el-input type="textarea" v-model="editModel.description"></el-input>
        </el-form-item>
        <el-row v-show="diffPlace">
          <el-col :span="12">
            <el-form-item label="创建发货" prop="needDelivery">
              <el-switch
                v-model="editModel.needDelivery"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
          </el-col>
        </el-row>

        <div v-show="diffPlace && editModel.needDelivery">
          <el-row>
            <el-col :span="12">
              <el-form-item label="发货联系人" prop="from_contact">
                <el-select v-model="editModel.from_contact" filterable allow-create clearable @change="changeFromContact">
                  <el-option v-for="item in fromContacts" :key="item.id" :label="item.name" :value="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收货联系人" prop="to_contact">
                <el-select v-model="editModel.to_contact" filterable allow-create clearable @change="changeToContact">
                  <el-option v-for="item in toContacts" :key="item.id" :label="item.name" :value="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="发货人电话" prop="from_tel">
                <el-input v-model="editModel.from_tel"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收货人电话" prop="to_tel">
                <el-input v-model="editModel.to_tel"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="发货地址" prop="from_addr">
                <el-input v-model="editModel.from_addr"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收获地址" prop="to_addr">
                <el-input v-model="editModel.to_addr"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-form-item>
          <template slot="label">
            明细
            <!-- <el-tooltip content="数量前面的参数相同会归为相同产品" placement="top" effect="light">
              <i class="el-icon-warning text-warning"></i>
            </el-tooltip> -->
          </template>
          <table class="items bordered table" style="width: 100%;">
            <thead>
              <tr>
                <th class="text-center">商品名称</th>
                <th class="text-center">成本批次号</th>
                <th class="text-center">款号</th>
                <th class="text-center">规格</th>
                <th class="text-center">英文款号</th>
                <th class="text-center">英文规格</th>
                <th class="text-center">颜色</th>
                <th class="text-center" width="110">库存</th>
                <th class="text-center" width="110">数量</th>
                <th class="text-center" width="70">单位</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in editModel.items" :key="index" :title="item.remark">
                <td class="text-center">{{item.item_name}}</td>
                <td class="text-center">{{item.code.replace(/_S\d+$/, '')}}</td>
                <!-- <td class="text-center">{{[item.attr1, item.attr6].join(' ')}}</td>
                <td class="text-center">{{[item.attr2, item.attr7].join(' ')}}</td> -->
                <td class="text-center">{{item.attr1}}</td>
                <td class="text-center">{{item.attr2}}</td>
                <td class="text-center">{{item.attr6}}</td>
                <td class="text-center">{{item.attr7}}</td>
                <td class="text-center">{{item.color}}</td>
                <td class="text-right">{{item.left_quantity}}</td>
                <td>
                  <el-input class="text-right" v-model="item.quantity" type="number"></el-input>
                </td>
                <td class="text-center">{{item.uom}}</td>
                <td>
                  <el-button @click="delItem(index)"
                    type="danger" title="删除" icon="el-icon-delete"></el-button>
                </td>
              </tr>
              <tr>
                <td colspan="10"></td>
                <td>
                  <el-button @click="toAddItems" type="primary" title="新增" icon="el-icon-plus"></el-button>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="save(false)" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 保 存</el-button>
        <!-- <el-button v-if="editModel.id"
          type="danger" @click="delIt" class="pull-left" :disabled="waiting" icon="fa fa-fw fa-trash"> 删 除</el-button> -->
      </div>
    </el-dialog>

    <!-- detail -->
    <el-dialog :visible.sync="detailDialog" title="详情"
      :close-on-click-modal="false" width="90%" top="2%">
      <el-form>
        <el-row>
          <el-col :span="12">
            <el-form-item label="记录编号："> {{showModel.code}}</el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="调拨单号：">{{showModel.receipt_num}}</el-form-item>
          </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="调拨类型："> {{showModel.type_text}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="调拨日期：">{{showModel.transaction_date}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="部门："> {{showModel.owner_group_name}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库组织："> {{organizationsMap[showModel.organization_id_f]}}</el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="editModel.txn_type_code === 'INTERORA'">
          <el-col :span="12">
            <el-form-item label="目的部门："> {{showModel.group_name_t}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目的仓库组织："> {{organizationsMap[showModel.organization_id_t]}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="源项目："> {{showModel.project_number_f}}</el-form-item>
          </el-col>
          <el-col :span="12" v-if="editModel.txn_type_code !== 'INTRAPRO'">
            <el-form-item label="目的项目："> {{showModel.project_number_t}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="源仓库："> {{showModel.subinventory_name_f}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目的仓库："> {{showModel.subinventory_name_t}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="创建人："> {{showModel.owner_name}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态：">{{showModel.status_text}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="审核人："> {{showModel.approved_by}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核日期：">{{showModel.approved_date}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="合同号：">{{showModel.po_num}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手册号：">{{showModel.manual_num}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="控货方：">{{showModel.attribute1}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="货权函正本：">
              <el-switch
                v-model="showModel.attribute2"
                disabled
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="Y"
                inactive-value="N">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库单正本：">
              <el-switch
                v-model="showModel.attribute3"
                disabled
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="Y"
                inactive-value="N">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注说明：">
          {{showModel.decoration}}
        </el-form-item>

        <el-form-item label="入库商品：">
          <table class="items bordered table" style="width: 100%;">
            <thead>
              <tr>
                <th class="text-center">库存编号</th>
                <th class="text-center">品名</th>
                <th class="text-center">款号</th>
                <th class="text-center">规格</th>
                <th class="text-center">英文款号</th>
                <th class="text-center">英文规格</th>
                <th class="text-center">颜色/其他</th>
                <th class="text-center" width="110">数量</th>
                <th class="text-center" width="70">单位</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in showModel.stocks" :key="index" :title="item.remark">
                <td> {{item.code}} </td>
                <td> {{item.item_name}} </td>
                <td> {{item.attr1}} </td>
                <td> {{item.attr2}} </td>
                <td> {{item.attr6}} </td>
                <td> {{item.attr7}} </td>
                <td> {{item.color}} </td>
                <td class="text-right"> {{item.quantity}} </td>
                <td> {{item.uom}} </td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="detailDialog = false" icon="fa fa-fw fa-times"> 关 闭 </el-button>
        <el-button :disabled="waiting" type="info" @click="apply(showModel)" v-if="showModel.status==='ENTERED'">提交</el-button>
        <el-button :disabled="waiting" type="info" @click="approve(showModel)" v-if="showModel.status==='PROCESSING'">审核通过</el-button>
        <el-button class="pull-left" type="danger" v-if="['ENTERED', 'PROCESSING'].indexOf(showModel.status) !== -1"
          @click="deleteIt(showModel)" icon="el-icon-delete"> 删除</el-button>
      </div>
    </el-dialog>
    <el-dialog  :visible.sync="stockDialog" title="项目库存"
      :close-on-click-modal="true" width="80%">
      <StockList :list="stocks">
      </StockList>
      <div slot="footer" class="dialog-footer">
        <el-button @click="stockDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="selStocks" icon="fa fa-fw fa-floppy-o"> 完成</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import StockList from './parts/StockList';

export default {
  components: {
    StockList,
  },
  data() {
    return {
      toProjects: [], // 目标项目选择list
      stockMap: {},
      stockDialog: false,

      waiting: false,
      loading: false,
      // 查询条件form
      queryForm: {
        party_id: '',
        company_id: '',
        q: '',
        code: '',
        document_num: '',
      },
      queryParams: {}, // 分页查询参数
      parties: [], // select party options array
      fromParties: [],
      toParties: [],
      queryPartiesLoading: false, // query-remote parties loading flag
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      projects: [], // 源项目选择list
      pos: [],
      inventories: [], // 仓库编码
      toInventories: [],
      showModel: {},
      detailDialog: false,
      rules: {
        project_id_f: [{ required: true, message: '选择项目', trigger: ['change', 'blur'] }],
        company_id_f: [{ required: true, message: '选择公司', trigger: ['change', 'blur'] }],
      },
      // dv
      selectFromLoading: false,
      fromContacts: [],
      toContacts: [],
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  beforeCreate() {
    if (this.$store.state.common.depts.length === 0) {
      this.$store.dispatch('GET_DEPTS');
    }
    if (this.$store.state.common.organizations.length === 0) {
      this.$store.dispatch('GET_ORGANIZATION');
    }
  },
  created() {
    this.onSubmitQuery();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      userMeta: state => state.auth.userMeta,
      curDeptId: state => state.auth.curDeptId,
      curCompanyType: state => state.auth.curCompanyType,
      organizations: state => state.common.organizations,
      stockStatusMap: state => state.common.stockStatusMap,
      txnTypes: state => state.common.txnTypes,
    }),
    ...mapGetters([
      'deptMap',
      'organizationsMap',
    ]),
    userDeptId() {
      let deptId = null;
      if (String(this.curCompanyType) === '2') {
        deptId = this.curDeptId;
      } else {
        deptId = this.user.department_id;
      }
      return deptId;
    },
    // 公司部门用户
    isDeliveryManager() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('delivery_order_manager') !== -1) {
        result = true;
      }
      return result;
    },
    isAdmin() {
      return this.user.type === 'admin';
    },
    stocks() {
      const result = [];
      const keys = Object.keys(this.stockMap);
      keys.forEach((key) => {
        result.push(this.stockMap[key]);
      });
      return result;
    },
    // 不同库
    diffPlace() {
      let result = false;
      if (this.editModel.company_id_f
          && this.editModel.company_id_t
          && this.editModel.company_id_f !== this.editModel.company_id_t) {
        result = true;
      }
      return result;
    },
    ckFrom() {
      return this.editModel.company_id_f;
    },
    ckTo() {
      return this.editModel.company_id_t;
    },
  },
  watch: {
    user() {
      this.onSubmitQuery();
    },
    curCompanyType() {
      this.onSubmitQuery();
    },
    ckFrom(val) {
      if (!val) {
        return;
      }
      let from = val;
      if (typeof val !== 'object') {
        const arr = this.fromParties.filter(item => item.party_id === val);
        if (arr.length > 0) {
          from = arr[0];
        }
      }
      this.editModel.from_id = from.party_id;
      this.editModel.from_company = from.party_name;
      this.editModel.from_addr = from.register_addr_tele;
      this.getContracts(from.party_id).then((contacts) => {
        if (contacts.length === 0) {
          this.editModel.from_contact = '';
          this.editModel.from_tel = '';
        }
        contacts.forEach((item) => {
          if (item.default) {
            this.editModel.from_contact = item.name;
            this.editModel.from_tel = item.phone;
          }
        });
        this.fromContacts = contacts;
      });
    },
    ckTo(val) {
      if (!val) {
        return;
      }
      let to = val;
      if (typeof val !== 'object') {
        const arr = this.toParties.filter(item => item.party_id === val);
        if (arr.length > 0) {
          to = arr[0];
        }
      }
      this.editModel.to_id = to.party_id;
      this.editModel.to_company = to.party_name;
      this.editModel.to_addr = to.register_addr_tele;
      this.getContracts(to.party_id).then((contacts) => {
        if (contacts.length === 0) {
          this.editModel.to_contact = '';
          this.editModel.to_tel = '';
        }
        contacts.forEach((item) => {
          if (item.default) {
            this.editModel.to_contact = item.name;
            this.editModel.to_tel = item.phone;
          }
        });
        this.toContacts = contacts;
      });
    },
  },
  methods: {
    // 查询企业
    queryParties(q) {
      const params = {
        limit: 20,
        q: {
          status_code: 'APPROVED',
        },
      };
      if (typeof q === 'string' && q.trim() !== '') {
        const name = q.trim();
        params.q.party_name = { $like: `%${name}%` };
      } else {
        return;
      }
      this.queryPartiesLoading = true;
      this.$http.get('erp-parties/', { params }).then((resp) => {
        const list = resp.data.items;
        this.queryPartiesLoading = false;
        this.parties = list;
      }, (err) => {
        this.queryPartiesLoading = false;
        this.$utils.resp(err);
      });
    },
    queryFromParties(q) {
      const params = {
        limit: 20,
        q: {
          status_code: 'APPROVED',
        },
      };
      if (typeof q === 'string' && q.trim() !== '') {
        const name = q.trim();
        params.q.party_name = { $like: `%${name}%` };
      } else {
        return;
      }
      this.queryPartiesLoading = true;
      this.$http.get('erp-parties/', { params }).then((resp) => {
        const list = resp.data.items;
        this.queryPartiesLoading = false;
        this.fromParties = list;
      }, (err) => {
        this.queryPartiesLoading = false;
        this.$utils.resp(err);
      });
    },
    queryToParties(q) {
      const params = {
        limit: 20,
        q: {
          status_code: 'APPROVED',
        },
      };
      if (typeof q === 'string' && q.trim() !== '') {
        const name = q.trim();
        params.q.party_name = { $like: `%${name}%` };
      } else {
        return;
      }
      this.queryPartiesLoading = true;
      this.$http.get('erp-parties/', { params }).then((resp) => {
        const list = resp.data.items;
        this.queryPartiesLoading = false;
        this.toParties = list;
      }, (err) => {
        this.queryPartiesLoading = false;
        this.$utils.resp(err);
      });
    },
    // 查询仓库
    getInventories() {
      if (!this.editModel.company_id_f) {
        return;
      }
      const params = {
        party_id: this.editModel.company_id_f,
        organization_id: this.editModel.organization_id_f,
      };
      this.inventories = [{
        secondary_inventory_name: 'GH',
        description: '国华仓库',
      }];
      this.$http.get('inventory', { params }).then((resp) => {
        const list = resp.data;
        list.push({
          secondary_inventory_name: 'GH',
          description: '国华仓库',
        });
        this.inventories = list;
        if (this.inventories.length > 0) {
          this.editModel.subinventory_code_f = this.inventories[0].secondary_inventory_name;
        } else {
          this.editModel.subinventory_code_f = '';
        }
      });
    },
    // 查询目的仓库
    getToInventories() {
      if (this.editModel.txn_type_code !== 'INTERORA') {
        this.editModel.organization_id_t = this.editModel.organization_id_f;
      }
      if (!this.editModel.company_id_t) {
        return;
      }
      const params = {
        party_id: this.editModel.company_id_t,
        organization_id: this.editModel.organization_id_t,
      };
      this.toInventories = [{
        secondary_inventory_name: 'GH',
        description: '国华仓库',
      }];
      this.$http.get('inventory', { params }).then((resp) => {
        const list = resp.data;
        list.push({
          secondary_inventory_name: 'GH',
          description: '国华仓库',
        });
        this.toInventories = list;
        if (this.toInventories.length > 0) {
          this.editModel.subinventory_code_t = this.toInventories[0].secondary_inventory_name;
        } else {
          this.editModel.subinventory_code_t = '';
        }
      });
    },
    // 查询
    onSubmitQuery() {
      if (!this.user.id) {
        return;
      }
      const params = {};
      if (this.queryForm.dept_id) {
        params.dept_id = this.queryForm.dept_id;
      }
      if (this.queryForm.status) {
        params.status = this.queryForm.status;
      }
      if (this.queryForm.code.trim()) {
        params.code = this.queryForm.code.trim();
      }
      if (this.queryForm.document_num.trim()) {
        params.document_num = this.queryForm.document_num.trim();
      }
      if (this.queryForm.q.trim()) {
        params.q = this.queryForm.q.trim();
      }
      this.queryParams = params;
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      const params = this.queryParams;
      params.pageSize = this.pageSize;
      params.page = this.currentPage;
      this.loading = true;
      this.$http.get('stocktrn', { params }).then((resp) => {
        this.loading = false;
        const list = resp.data.data;
        this.list = list;
        this.totalItem = resp.data.total;
      }, (err) => {
        this.loading = false;
        this.$utils.resp(err);
      });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 新增入库
    toAdd() {
      if (this.projects.length === 0) {
        this.getProjects('');
      }
      this.editModel = {
        // 货运相关
        needDelivery: true,
        from_company: '',
        to_company: '',
        from_contact: '',
        to_contact: '',
        from_tel: '',
        to_tel: '',
        from_addr: '',
        to_addr: '',
        // not dv
        txn_type_code: 'INTRAPRO', // 默认项目内调拨
        organization_id_f: '', // 仓库组织(源)
        company_id_f: '', // 仓库企业 源
        subinventory_code_f: '', // 仓库 源
        project_id_f: '',
        project_number_f: '', // 项目 源
        // 目的库相关
        group_id_to: '', // 目的部门
        subinventory_code_t: '',

        description: '',

        group_name_to: '',
        owner_group_name: '',

        attribute1: '',
        attribute2: 'Y',
        attribute3: 'Y',

        receipt_by: '',
        shipment_num: '',
        receipt_date: this.$utils.FormatDate(),
        transaction_date: this.$utils.FormatDate(),
        inv_make_date: '',
        gl_date: '',
        po_header_id: '',
        po_number: '',
        department_id: this.userDeptId,
        company_name: '',
        count: '',
        items: [],
        // left_quantity: '',
      };
      // 仓库组织初始化当前用户的组织
      if (this.deptMap && this.userDeptId) {
        const depts = this.deptMap[this.userDeptId].name.split('.');
        if (depts.length > 1) {
          const name = depts[1].substr(0, 2);
          const org = this.organizations.filter(item => item.organization_name.indexOf(name) > -1);
          if (org.length > 0) {
            this.editModel.organization_id_f = org[0].organization_id;
          }
        }
      }
      this.editDialog = true;
      if (this.$refs.editForm) {
        this.$nextTick(() => {
          this.$refs.editForm.clearValidate();
        });
      }
    },
    // 修改内容
    changeType() {
      // a
    },
    // 项目查询方法
    getProjects(qStr) {
      const params = {
        limit: 20,
        q: {
          // status_code: 'APPROVED',
          // project_number: { $like: `%${qStr}%` },
        },
      };
      if (typeof qStr === 'number') {
        params.q.project_id = qStr;
      } else if (qStr && qStr.trim() !== '') {
        params.q.project_number = { $like: `%${qStr}%` };
      } else {
        const dateStr = this.$utils.FormatDate(new Date(new Date().getTime() - 100 * 86400000), 'yyyy-MM-ddT00:00:00Z');
        params.q.creation_date = { $gt: { $date: dateStr } };
      }
      return this.$http.get('erp-project', { params }).then((resp) => {
        const list = resp.data.items;
        list.forEach((item) => {
          item.cur = false;
        });
        this.projects = list;
        return list;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // 项目查询方法
    getToProjects(qStr) {
      const params = {
        limit: 20,
        q: {
          // status_code: 'APPROVED',
          // project_number: { $like: `%${qStr}%` },
        },
      };
      if (typeof qStr === 'number') {
        params.q.project_id = qStr;
      } else if (qStr && qStr.trim() !== '') {
        params.q.project_number = { $like: `%${qStr}%` };
      } else {
        const dateStr = this.$utils.FormatDate(new Date(new Date().getTime() - 100 * 86400000), 'yyyy-MM-ddT00:00:00Z');
        params.q.creation_date = { $gt: { $date: dateStr } };
      }
      // 是否是部门间调拨 可获查询其他部门项目号
      if (this.editModel.txn_type_code === 'INTERORA') {
        this.$http.get('erp-projects', { params }).then((resp) => {
          const list = resp.data.items;
          list.forEach((item) => {
            item.cur = false;
          });
          this.toProjects = list;
          return list;
        }, (err) => {
          this.$utils.resp(err);
        });
      } else {
        this.$http.get('erp-project', { params }).then((resp) => {
          const list = resp.data.items;
          list.forEach((item) => {
            item.cur = false;
          });
          this.toProjects = list;
          return list;
        }, (err) => {
          this.$utils.resp(err);
        });
      }
    },
    // project from chooseed
    chooseProjectFrom(id) {
      const projects = this.projects.filter(item => item.project_id === id);
      if (projects.length === 1) {
        const project = projects[0];
        // const projectNum = project.project_number;
        this.editModel.fromPorject = project;
      }
    },
    // project to chooseed
    chooseProjectTo(id) {
      const projects = this.toProjects.filter(item => item.project_id === id);
      if (projects.length === 1) {
        const project = projects[0];
        // const projectNum = project.project_number;
        this.editModel.toProject = project;
        this.editModel.group_id_to = project.user_group_id;
        const depts = this.deptMap[project.user_group_id].name.split('.');
        if (depts.length > 1) {
          const name = depts[1].substr(0, 2);
          const org = this.organizations.filter(item => item.organization_name.indexOf(name) > -1);
          if (org.length > 0) {
            this.editModel.organization_id_t = org[0].organization_id;
            this.getToInventories();
          }
        }
      }
    },
    toAddItems() {
      this.getStocks();
    },
    // 获取项目库存
    getStocks() {
      if (!this.editModel.project_id_f) {
        this.$message({
          type: 'warning',
          message: '请先选择源项目',
          showClose: true,
          duration: 2000,
        });
        return;
      }
      if (!this.editModel.subinventory_code_f) {
        this.$message({
          type: 'warning',
          message: '请先选源仓库',
          showClose: true,
          duration: 2000,
        });
        return;
      }
      if (this.editModel.project_id_f && this.editModel.subinventory_code_f) {
        const params = {
          project_id: this.editModel.project_id_f,
          subinventory_code: this.editModel.subinventory_code_f,
        };
        this.$http.get('stocklist', { params }).then((resp) => {
          const list = resp.data;
          const map = {};
          list.forEach((item) => {
            if (map[item.item_md5]) {
              map[item.item_md5].left_quantity += item.left_quantity * 1;
            } else {
              map[item.item_md5] = item;
              map[item.item_md5].left_quantity = item.left_quantity * 1;
              map[item.item_md5].sel = false;
            }
          });
          this.stockMap = map;
          this.stockDialog = true;
        });
      }
    },
    // 选择库存
    selStocks() {
      this.editModel.items = [];
      const lines = this.stocks.filter(item => item.sel);
      const list = [];
      lines.forEach((item) => {
        const obj = { ...item, quantity: item.left_quantity };
        list.push(obj);
      });
      this.editModel.items = list;
      this.stockDialog = false;
    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.editModel };
          if (obj.items.length <= 0) {
            this.$message({
              type: 'warning',
              message: '至少需要有一条数据',
              showClose: true,
              duration: 2000,
            });
            return;
          }
          const subinf = this.$utils.filter(
            this.inventories,
            item => item.secondary_inventory_name === obj.subinventory_code_f
          );
          obj.subinventory_name_f = subinf.description;
          const subint = this.$utils.filter(
            this.toInventories,
            item => item.secondary_inventory_name === obj.subinventory_code_t
          );
          obj.subinventory_name_t = subint.description;
          let flag = true;
          obj.items.forEach((item) => {
            if (item.item_name.trim() === '' || item.quantity <= 0) {
              flag = false;
            }
          });
          if (!flag) {
            this.$message({
              type: 'warning',
              message: '产品数量也不能为0',
              showClose: true,
              duration: 2000,
            });
            return;
          }
          this.waiting = true;
          if (obj.id) {
            // to edit
          } else {
            const projects = this.projects.filter(item => item.project_id === obj.project_id_f);
            if (projects.length === 1) {
              const project = projects[0];
              const projectNum = project.project_number;
              obj.project_number_f = projectNum;
            } else {
              this.$message({
                type: 'warning',
                message: '没有对应项目',
                showClose: true,
                duration: 2000,
              });
              return;
            }
            // 其他项目
            if (obj.txn_type_code !== 'INTRAPRO') {
              const projects = this.toProjects.filter(item => item.project_id === obj.project_id_t);
              if (projects.length === 1) {
                const project = projects[0];
                const projectNum = project.project_number;
                obj.project_number_t = projectNum;
              } else {
                this.$message({
                  type: 'warning',
                  message: '没有目的项目',
                  showClose: true,
                  duration: 2000,
                });
                return;
              }
            }
            this.$http.post('stocktrn', obj).then(() => {
              this.editDialog = false;
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
              this.changePage(1);
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
    delItem(index) {
      this.editModel.items.splice(index, 1);
    },
    toDetail(item) {
      this.$http.get(`stocktrn/${item.id}`).then((resp) => {
        this.detailDialog = true;
        const obj = resp.data;
        obj.attribute2 = 'Y';
        this.showModel = obj;
      });
    },
    apply(item) {
      this.$confirm('确认提交？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.put(`stocktrn/${item.id}/submit`, {}).then(() => {
          this.getList();
          this.waiting = false;
          this.detailDialog = false;
          this.$message({
            type: 'success',
            message: '提交成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    approve(item) {
      this.$confirm('确认审批？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.put(`stocktrn/${item.id}/approve`, {}).then(() => {
          this.getList();
          this.waiting = false;
          this.detailDialog = false;
          this.$message({
            type: 'success',
            message: '审批成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    // dv联系人
    getContracts(id) {
      return this.$http.get(`erp-party/${id}/contracts`).then(resp => resp.data);
    },
    changeFromContact(name) {
      const checked = this.fromContacts.find(item => item.name === name);
      if (checked) {
        this.editModel.from_contact = checked.name;
        this.editModel.from_tel = checked.phone;
      }
    },
    changeToContact(name) {
      const checked = this.toContacts.find(item => item.name === name);
      if (checked) {
        this.editModel.to_contact = checked.name;
        this.editModel.to_tel = checked.phone;
      }
    },
    toDvOrder(item) {
      this.$router.push({ name: 'DvOrder', query: { code: item.code } });
    },
    deleteIt(item) {
      this.$http.delete(`stocktrn/${item.id}`).then(() => {
        this.detailDialog = false;
        this.getList();
      }, (err) => {
        this.$utils.resp(err);
      });
    },
  },
};
</script>
