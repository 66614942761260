<template>
  <el-card>
    <el-button type="primary" size="small" @click="toAdd()" v-if="canInput">
      <i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.waybill_no" clearable placeholder="成衣出口运编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.q" clearable placeholder="标题" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status" clearable placeholder="状态">
          <el-option v-for="(label, key) in invoiceStatusesMap" :key="key" :value="key" :label="label"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.type" clearable placeholder="类型">
          <el-option v-for="(item) in invoiceTypes" :key="item.id" :value="item.id" :label="item.label"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search" v-if="!isInputRole">
        <el-select v-model="queryForm.company_id" clearable placeholder="企业筛选">
          <el-option v-for="item in companies" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th class="text-center">#</th>
          <th class="text-center">日期</th>
          <th class="text-center">业务单位</th>
          <th class="text-center">发票号</th>
          <!-- <th class="text-center">成衣运编号</th> -->
          <th class="text-center">金额</th>
          <th class="text-center">兑付金额</th>
          <th class="text-center">状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in list" :key="item.id">
          <td class="text-center">{{ idx + 1 }}</td>
          <td class="text-center">{{ item.occ_at }}</td>
          <td class="text-center">{{ item.opposite_name }}</td>
          <td class="text-center"><a href="#" @click.prevent="showDetail(item.id)">{{ item.title }}</a></td>
          <!-- <td class="text-center">{{ item.waybill_no }}</td> -->
          <td class="text-center">{{ item.amount }}</td>
          <td class="text-center">{{ item.get > 0 ? item.get : '' }}</td>
          <td :class="{'text-center': true,'text-danger': item.status == '9', 'text-info': ['3','4'].indexOf(item.status) != -1}">
            {{invoiceStatusesMap[item.status] ? invoiceStatusesMap[item.status] : ''}}</td>
          <td class="text-center">
            <div class="text-nowrap">
              <el-button v-if="(item.status == '1' || item.status == '9') && canInput"
                style="margin-left: 3px;" type="info" title="修改信息" @click="toEdit(item)">
                <i class="fa fa-fw fa-pencil"></i></el-button>
              <el-button v-if="(item.status == '1' || item.status == '9') && isInputRole"
                style="margin-left: 3px;" type="warning" title="提交审核" @click="toVerify(item)">
                <i class="fa fa-fw fa-upload"></i></el-button>
              <el-button v-if="item.status == '2' && isInputRole"
                style="margin-left: 3px;" type="warning" title="撤回" @click="backVerify(item)">
                <i class="fa fa-fw fa-undo"></i></el-button>
              <el-button v-if="item.status == '2' && canVerify"
                style="margin-left: 3px;" type="warning" title="审核" @click="showDetail(item.id)">
                <i class="fa fa-fw fa-paint-brush"></i></el-button>
              <el-button v-if="['3', '4', '5'].indexOf(item.status) !== -1"
                style="margin-left: 3px;" type="info" title="详情" @click="showDetail(item.id)">
                <i class="fa fa-fw fa-search"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <a ref="downloadLink" :href="downloadUrl" :download="downloadFileName" v-show="false"></a>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="showDialog" title="开票详情" width="90%" :close-on-click-modal="true" :close="closeDetailDialog">
      <el-form label-width="140px" class="compact-mb5">
        <el-row>
          <el-col :span="24">
            <el-form-item label="开票号：">
              {{showModel.title}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="成衣出口运编号：">
              {{showModel.waybill_no}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期：">
              {{showModel.occ_at}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="往来企业：">
              {{showModel.opposite_name}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司名称：">
              {{showModel.company ? showModel.company.name : '-'}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品：">
              {{showModel.production}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量：">
              {{showModel.num}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="单价：">
              {{showModel.unit_price}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="金额(USD)：">
              {{showModel.amount}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="状态：">
              {{invoiceStatusesMap[showModel.status] ? invoiceStatusesMap[showModel.status] : ''}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="类型：">
              {{typesMap[showModel.type] ? typesMap[showModel.type] : ''}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="兑付金额：">
              {{ showModel.get ? showModel.get : 0}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
          </el-col>
        </el-row>
        <el-form-item label="说明备注：">
          {{showModel.remark}}
        </el-form-item>
        <el-form-item label="审批信息：" v-if="['1', '2', '9'].indexOf(showModel.status) !== -1">
          <el-input type="textarea" v-if="showModel.status === '2' && canVerify" v-model="showModel.verify_response"></el-input>
          <span v-else>{{showModel.verify_response}}</span>
        </el-form-item>
        <el-collapse>
          <el-collapse-item title="外发加工" name="1"
            v-if="showModel.expense_items && showModel.expense_items.length > 0">
            <div style="padding: 0 0 0 10px;">
              <table class="table" >
                <thead>
                  <tr>
                    <th>#</th>
                    <th class="required">往来企业</th>
                    <th>产品</th>
                    <th>数量</th>
                    <th>单价</th>
                    <th class="required">费用</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, idx) in showModel.expense_items" :key="idx">
                    <td>{{idx + 1}}</td>
                    <td>{{item.opposite_name}}</td>
                    <td>{{item.msg}}</td>
                    <td>{{item.num}}</td>
                    <td>{{item.unit_price}}</td>
                    <td>{{item.amount}}</td>
                  </tr>
                  <tr>
                    <td colspan="5" class="text-right">合计</td>
                    <td colspan="1">{{showModel.expense_amount}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-collapse-item>
          <el-collapse-item title="收款明细" name="2" v-if="['4', '5'].indexOf(showModel.status) !== -1">
            <table class="table">
              <thead>
                <tr>
                  <th>收款单号</th>
                  <th>金额</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="rev in showModel.revenues" :key="rev.id + 'r'">
                  <td>{{rev.org_no}}</td>
                  <td>{{rev.pivot ? rev.pivot.pay : ''}}</td>
                </tr>
              </tbody>
            </table>
          </el-collapse-item>
        </el-collapse>
        <el-form-item label="附件：" label-width="140px" v-if="showModel.attachments">
          <div style="padding-right: 50px;">
            <div v-for="(item, idx) in showModel.attachments" :key="idx" class="download-img-box">
              <i class="fa fa-download download-rt-ico" title="下载"
                @click="downloadImg(item, `${showModel.org_no}_${idx + 1}`)"></i>
              <img :src="item.url" alt="" style="max-width: 100%;">
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="showModel.id && showModel.status=='1' && canInput"
          title="删除" type="danger" @click="del(showModel.id)"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button v-if="showModel.id && showModel.status=='2' && canVerify"
          title="审核通过" type="primary" @click="verifyCheck(showModel.id)" icon="fa fa-check">审核通过</el-button>
        <el-button v-if="showModel.id && showModel.status=='2' && canVerify"
          title="驳回" type="warning" @click="reject(showModel.id)" icon="fa fa-reply">驳 回</el-button>
        <el-button @click="showDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'"
      :close-on-click-modal="false" width="90%">
      <el-form :model="editModel" ref="editForm" label-width="125px" :rules="rules">
        <!-- <el-form-item label="标题" prop="title">
          <el-input v-model="editModel.title"></el-input>
        </el-form-item> -->
        <el-form-item label="成衣出口运编号" prop="waybill_no">
          <el-input v-model="editModel.waybill_no"></el-input>
        </el-form-item>
        <el-form-item label="日期" prop="occ_at">
          <el-date-picker v-model="editModel.occ_at"
            style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="editModel.type" clearable placeholder="请选择" style="width: 100%;">
            <el-option v-for="item in invoiceTypes" :key="item.id" :value="item.id" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="往来企业" prop="opposite_name">
          <el-input v-model="editModel.opposite_name"></el-input>
        </el-form-item>
        <!-- <el-form-item label="开票号" prop="order_no">
          <el-input v-model="editModel.order_no"></el-input>
        </el-form-item> -->
        <el-form-item label="产品" prop="production">
          <el-input v-model="editModel.production"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="num">
          <el-input type="number" v-model="editModel.num"></el-input>
        </el-form-item>
        <el-form-item label="单价" prop="unit_price">
          <el-input type="number" v-model="editModel.unit_price"></el-input>
        </el-form-item>
        <el-form-item label="金额(USD)" prop="amount">
          <el-input type="number" @focus="calcAmount" v-model="editModel.amount"></el-input>
        </el-form-item>
        <el-form-item label="说明备注" prop="remark">
          <el-input type="textarea" v-model="editModel.remark"></el-input>
        </el-form-item>
        <el-form-item label="外发加工">
          <el-button type="primary" icon="fa fa-plus" @click="addItem">添加</el-button>
        </el-form-item>
        <div style="padding: 0 0 0 10px;">
          <table class="table" v-if="editModel.expense_items && editModel.expense_items.length > 0">
            <thead>
              <tr>
                <th>#</th>
                <th class="required">往来企业</th>
                <th>产品</th>
                <th>数量</th>
                <th>单价</th>
                <th class="required">费用</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in editModel.expense_items" :key="idx">
                <td>{{idx + 1}}</td>
                <td><el-input v-model="item.opposite_name"></el-input></td>
                <td><el-input v-model="item.msg"></el-input></td>
                <td><el-input type="number" v-model="item.num" @change="calcItemAmount(item)"></el-input></td>
                <td><el-input type="number" v-model="item.unit_price" @change="calcItemAmount(item)"></el-input></td>
                <td><el-input v-model="item.amount" @change="calcExpAmount" type="number"></el-input></td>
                <td><el-button @click="delItem(idx)" type="danger" icon="fa fa-trash-o"></el-button></td>
              </tr>
              <tr>
                <td colspan="5" class="text-right">合计</td>
                <td colspan="2">{{editModel.expense_amount}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <el-form-item label="附件">
          <div>
              <div v-if="editModel.attachments && editModel.attachments.length !== 0">
                <div class="text-semibold">已上传的文件</div>
                <div class="file-list" v-for="(file, index) in editModel.attachments" :key="index">
                  <span class="pull-right cur-pointer del-btn" @click="delFileOne(index)" style="width: 25px;" title="删除附件">
                  <i class="fa fa-times" aria-hidden="true"></i></span>
                  <span class="text-overflow" :title="file.name" style="margin-right: 25px;">
                    {{ file.orgName }}</span>
                </div>
              </div>
              <div v-if="files.length !== 0">
                <div class="text-semibold">已选择的文件</div>
                <div class="file-list" v-for="(item, index) in files" :key="index">
                  <span class="pull-right cur-pointer del-btn" @click="delUfile(index)" style="width: 25px;" title="删除附件">
                    <i class="fa fa-times" aria-hidden="true"></i></span>
                  <span class="text-overflow" :title="item.name" style="margin-right: 25px;">{{ item.name }}</span>
                </div>
              </div>
          </div>
          <file-uploader ref="addFile"
            accept="."
            @change="changeFile"
            :showPreview="false"
            :showFileHint="false">
          </file-uploader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="editModel.id && editModel.status=='1' && canInput"
          title="删除" type="danger" @click="del(editModel.id)"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="beforeSave">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import * as _findIndex from 'lodash/findIndex';
import { mapState, mapGetters } from 'vuex';
import FileUploader from '@/components/FileUpload';

export default {
  components: {
    FileUploader,
  },
  data() {
    return {
      loading: true,
      waiting: false,
      queryForm: {
        waybill_no: '',
        q: '',
        type: '',
      },
      queryParams: {},
      list: [],
      showModel: {},
      showDialog: false,
      editDialog: false,
      editModel: {},
      rules: {
        // title: [{ required: true, message: '请输入标题', trigger: ['change', 'blur'] }],
        occ_at: [{ required: true, message: '请选择日期', trigger: ['change', 'blur'] }],
        type: [{ required: true, message: '请选择类型', trigger: ['change', 'blur'] }],
        waybill_no: [{ required: true, message: '请填写成衣出口运编号', trigger: ['change', 'blur'] }],
        amount: [{ required: true, message: '请输入金额', trigger: ['change', 'blur'] }],
        remark: [{ required: true, message: '请输入事项说明', trigger: ['change', 'blur'] }],
      },
      files: [], // 待上传的文件
      // 所有缅甸企业
      companies: [],
      invoiceStatusesMap: {
        1: '待提交',
        2: '待审核',
        3: '未支付',
        4: '部分支付',
        5: '支付完成',
        9: '审批未通过',
      },
      // page
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      downloadFileName: '',
      downloadUrl: '',
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      invoiceTypes: state => state.dict.invoiceTypes,
    }),
    ...mapGetters({
      typesMap: 'invoiceTypesMap',
    }),
    isInputRole() {
      let result = false;
      if (this.user.id) {
        const companyId = this.user.meta ? this.user.meta.mmr_company_id : false;
        if (_findIndex(this.user.roles, { code: 'mmr_input' }) !== -1 && companyId) {
          result = true;
        }
      }
      return result;
    },
    canVerify() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('mmr_verify') !== -1) {
        result = true;
      }
      return result;
    },
    canInput() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('mmr_expense_input') !== -1) {
        result = true;
      }
      return result;
    },
  },
  beforeCreate() {
  },
  created() {
    this.init();
    this.getList();
  },
  filters: {
    statusName: status => (this.invoiceStatusesMap[status] ? this.invoiceStatusesMap[status] : ''),
  },
  methods: {
    init() {
      // 获取公司和部门
      this.$http.get('mmrcompanydepts').then((resp) => {
        this.companies = resp.data;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    getList() {
      this.loading = true;
      const params = { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize };
      this.$http.get('invoice', { params }).then((resp) => {
        this.list = resp.data.data;
        this.totalItem = resp.data.total;
        this.loading = false;
      }, (err) => {
        this.loading = false;
        this.$utils.resp(err);
      });
    },
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toAdd() {
      this.files = [];
      const model = {
        title: '',
        occ_at: this.$utils.FormatDate(),
        waybill_no: '',
        order_no: '',
        production: '',
        num: '',
        unit_price: '',
        amount: '',
        remark: '',
        opposite_name: '',
        expense_items: [],
        expense_amount: 0,
      };
      if (this.isInputRole) {
        model.company_id = this.user.meta.mmr_company_id;
      }
      this.editModel = model;
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    toEdit(item) {
      this.files = [];
      this.$http.get(`invoice/${item.id}`).then((resp) => {
        this.editModel = resp.data;
        this.$nextTick(() => {
          if (this.$refs.editForm) {
            this.$refs.editForm.clearValidate();
          }
        });
        this.editDialog = true;
      });
    },
    addItem() {
      const newObj = {
        opposite_name: '',
        msg: '',
        amount: 0,
      };
      this.editModel.expense_items.push(newObj);
    },
    delItem(index) {
      this.editModel.expense_items.splice(index, 1);
      this.calcExpAmount();
    },
    getImg(fileName) {
      const params = {
        hint: 'mmrinvoice',
        name: fileName,
      };
      return this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        return objurl;
      }, (err) => {
        this.$utils.resp(err);
        // todo
        return '404png';
      });
    },
    downloadImg(item, name = false) {
      const tmp = item.orgName.split('.');
      const ext = tmp[tmp.length - 1];
      this.downloadFileName = name ? name + '.' + ext : item.orgName;
      this.downloadUrl = item.url;
      this.$nextTick(() => {
        this.$refs.downloadLink.click();
      });
    },
    // 详情
    showDetail(id) {
      this.$http.get(`invoice/${id}`).then((resp) => {
        this.showModel = resp.data;
        this.showModel.attachments.forEach((item) => {
          this.getImg(item.name).then((url) => {
            this.$set(item, 'url', url);
          });
        });
        this.showDialog = true;
      });
    },
    closeDetailDialog() {
      // 清图片缓存;
      this.showModel.attachments.forEach((item) => {
        URL.revokeObjectURL(item.url);
      });
    },
    // 提交审核
    toVerify(invoice) {
      this.$confirm('确认提交审核？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = { status: '2' };
        this.$http.put(`invoice/${invoice.id}/status`, params).then(() => {
          this.$message.success('提交成功');
          this.getList();
        }, (err) => { this.$utils.resp(err); });
      }, () => {});
    },
    // 撤回提交审核
    backVerify(invoice) {
      const params = { status: '1' };
      this.$http.put(`invoice/${invoice.id}/status`, params).then(() => {
        this.$message.success('已撤回');
        this.getList();
      }, (err) => { this.$utils.resp(err); });
    },
    // 审批通过
    verifyCheck(id, isOk = true) {
      let message = '驳回';
      const params = {
        status: '9',
        verify_response: this.showModel.verify_response,
      };
      if (isOk) {
        message = '审核通过';
        params.status = '3';
      }
      this.$confirm(`确认${message}？`, '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.put(`invoice/${id}/status`, params).then(() => {
          this.$message.success('操作成功');
          this.showDialog = false;
          this.getList();
        }, (err) => { this.$utils.resp(err); });
      }, () => {});
    },
    reject(id) {
      this.verifyCheck(id, false);
    },
    del(id) {
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`invoice/${id}`).then(() => {
          this.$message.success('删除成功');
          this.showDialog = false;
          this.editDialog = false;
          this.getList();
        }, (err) => {
          this.$utils.resp(err);
        });
      }, () => {});
    },
    beforeSave() {
      let isItemOk = true;
      this.editModel.expense_items.forEach((item) => {
        if (!item.opposite_name.trim()) {
          isItemOk = false;
        }
        if (!item.amount) {
          isItemOk = false;
        }
      });
      if (!isItemOk) {
        this.$message.warning('外发明细必须填写完整');
        return;
      }
      this.$refs.editForm.validate((vaid) => {
        if (vaid) {
          if (this.$refs.addFile.identifyBorwser() && this.files.length > 0) {
            const formData = new window.FormData(this.$refs.editForm.$el);
            // api中，上传头像参数用avatar,还有media、document、attachment
            formData.append('hint', 'mmrinvoice');
            this.files.forEach((ele) => {
              formData.append('file[]', ele);
            });
            this.waiting = true;
            this.$http.post('transfile', formData).then((resp) => {
              this.editModel.addFiles = resp.data;
              this.saveSubmit();
            }, (err) => {
              this.editModel.addFiles = [];
              this.waiting = false;
              this.$utils.resp(err);
            });
          } else {
            this.saveSubmit();
          }
        }
      });
    },
    saveSubmit() {
      // 更新
      if (this.editModel.id) {
        this.$http.put(`invoice/${this.editModel.id}`, this.editModel).then((resp) => {
          const index = _findIndex(this.list, { id: this.editModel.id });
          this.list.splice(index, 1, resp.data);
          this.editDialog = false;
          this.waiting = false;
          this.$utils.msg('已更新');
        }, (err) => {
          this.$utils.resp(err);
          this.waiting = false;
        });
      } else {
      // 新增
        this.$http.post('invoice', this.editModel).then(() => {
          this.editDialog = false;
          this.getList();
          this.waiting = false;
          this.$utils.msg('保存成功');
        }, (err) => {
          this.$utils.resp(err);
          this.waiting = false;
        });
      }
    },
    // 选中上传文件时
    changeFile(files) {
      if (this.$refs.addFile.sizeWarning) {
        this.$utils.msg('文件过大', 'warning');
        return;
      }
      if (files.length > 0) {
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
      }
    },
    delFileOne(index) {
      this.editModel.attachments.splice(index, 1);
    },
    // 删除未上传的文件
    delUfile(index) {
      this.files.splice(index, 1);
    },
    calcAmount() {
      if (!this.editModel.amount && this.editModel.unit_price && this.editModel.num) {
        this.editModel.amount = this.$calc.FixRound(this.editModel.unit_price * this.editModel.num);
      }
    },
    calcItemAmount(item) {
      if (item.num && item.unit_price) {
        item.amount = this.$calc.Mul(item.num, item.unit_price);
        this.calcExpAmount();
      }
    },
    calcExpAmount() {
      let sum = 0;
      this.editModel.expense_items.forEach((item) => {
        sum = this.$calc.Add(sum, item.amount);
      });
      this.editModel.expense_amount = sum;
    },
  },
};
</script>
