<template>
  <el-row :gutter="10">
    <el-col :span="8">
      <el-card>
        <div>
          选项列表
        </div>
        <div>
          <el-input v-model="leftKey" style="width: 200px"><el-button @click="getRoots" slot="append" icon="el-icon-search"></el-button></el-input>
          <el-button class="pull-right" @click="addRoot" type="primary" icon="el-icon-plus"> 新选项类</el-button>
        </div>
        <div style="overflow:auto; height: calc(100vh - 200px)">
          <table class="table bordered">
            <thead>
              <tr>
                <th></th>
                <th>名称</th>
                <th>编号</th>
                <th>序号</th>
                <th width="40">操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in leftList" :key="item.id" :title="item.remark"
                @dblclick="selectLeft(item)">
                <td>{{index+1}}</td>
                <td>{{item.value}}</td>
                <td>{{item.code}}</td>
                <td>{{item.order}}</td>
                <td><el-button @click="editItem(item)" title="编辑" icon="fa fa-fw fa-pencil"></el-button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-card>
    </el-col>
    <el-col :span="16">
      <el-card>
        <div style="padding: 10px;">
          {{selectedRoot.value}}
          <el-button type="primary" v-if="selectedRoot.id" @click="addItem">新增</el-button>
          <el-button class="pull-right" @click="querySubs" icon="el-icon-search">查询</el-button>
          <el-input style="width: 200px" clearable @keydown.enter.native="querySubs" class="pull-right" v-model="q"></el-input>
        </div>
        <table class="table bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>名称</th>
              <th>键值</th>
              <th>编号</th>
              <th>排序</th>
              <th width="40">操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="index">
              <td>{{index+1}}</td>
              <td>{{item.value}}</td>
              <td>{{item.key}}</td>
              <td>{{item.code}}</td>
              <td>{{item.order}}</td>
              <td><el-button @click="editItem(item)" title="编辑" icon="fa fa-fw fa-pencil"></el-button></td>
            </tr>
          </tbody>
        </table>
        <div class="clearfix mar-top">
          <el-pagination class="pull-right"
            @current-change="changePage"
            layout="total, prev, pager, next"
            :total="totalItem"
            :current-page="currentPage"
            :page-size="pageSize">
          </el-pagination>
        </div>
      </el-card>
    </el-col>
    <el-dialog :visible.sync="editDialog" :title="dialogTitle"
      :close-on-click-modal="false">
      <el-form ref="form" :model="editModel" :rules="rules" label-width="100px">
        <el-form-item label="名称" prop="value">
          <el-input v-model="editModel.value"></el-input>
        </el-form-item>
        <el-form-item label="键值" prop="key">
          <el-input v-model="editModel.key"></el-input>
        </el-form-item>
        <el-form-item label="编号" prop="code">
          <el-input v-model="editModel.code"></el-input>
        </el-form-item>
        <el-form-item label="说明" prop="remark">
          <el-input v-model="editModel.remark"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="order">
          <el-input type="number" v-model="editModel.order"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="editDialog = false">取消</el-button>
        <el-button :disabled="waiting" type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      waiting: false,
      leftKey: '',
      mainDicts: [],
      q: '',
      selectedRoot: {},
      editDialog: false,
      dialogTitle: '编辑',
      editModel: {},
      rules: {
        value: [{ required: true, message: '请填写名称', trigger: ['change', 'blur'] }],
      },
      list: [],
      queryParams: {},
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  created() {
    this.getRoots();
  },
  computed: {
    leftList() {
      let r = this.mainDicts;
      const q = this.leftKey.trim();
      if (q) {
        r = this.mainDicts.filter((m) => {
          let f = false;
          if (m.code.indexOf(q) !== -1 || m.value.indexOf(q) !== -1) {
            f = true;
          }
          return f;
        });
      }
      return r;
    },
  },
  methods: {
    getRoots() {
      const params = { all: 1, pid: 0 };
      this.axios.get('dict', { params }).then((resp) => {
        this.mainDicts = resp.data;
      });
    },
    addRoot() {
      this.$store.dispatch('GET_DICT', 'staff_post');
      this.editModel = {
        value: '',
        code: '',
        remark: '',
        key: '',
        pid: 0,
        order: 0,
      };
      this.dialogTitle = '新选项类';
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    selectLeft(obj) {
      this.selectedRoot = obj;
      this.q = '';
      this.querySubs();
    },
    addItem() {
      this.editModel = {
        value: '',
        code: '',
        remark: '',
        key: '',
        pid: this.selectedRoot.id,
        order: 0,
      };
      this.dialogTitle = `新${this.selectedRoot.value}`;
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    editItem(item) {
      this.editModel = { ...item };
      this.dialogTitle = '编辑选项';
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    querySubs() {
      if (!this.selectedRoot.id) {
        return;
      }
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.queryParams = { value: this.q, pid: this.selectedRoot.id };
      this.changePage(1);
    },
    changePage(page) {
      this.currentPage = page;
      this.getItems();
    },
    getItems() {
      this.axios.get('dict', { params: { ...this.queryParams, pageSize: this.pageSize, page: this.currentPage } })
        .then((resp) => {
          this.list = resp.data.data;
          this.totalItem = resp.data.total;
        });
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const obj = this.editModel;
          if (obj.id) {
            this.waiting = true;
            this.axios.put(`dict/${obj.id}`, obj).then(() => {
              this.waiting = false;
              this.editDialog = false;
              if (obj.pid === 0) {
                this.getRoots();
              } else {
                this.getItems();
                this.$store.dispatch('GET_DICTS');
              }
            }, (err) => {
              this.waiting = false;
              this.$utils.resp(err);
            });
          } else {
            this.axios.post('dict', obj).then(() => {
              this.waiting = false;
              this.editDialog = false;
              if (obj.pid === 0) {
                this.getRoots();
              } else {
                this.getItems();
                this.$store.dispatch('GET_DICTS');
              }
            }, (err) => {
              this.waiting = false;
              this.$utils.resp(err);
            });
          }
        }
      });
    },
  },
};
</script>

<style>

</style>
