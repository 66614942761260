import { render, staticRenderFns } from "./DvDelivery.vue?vue&type=template&id=b375ce94&scoped=true"
import script from "./DvDelivery.vue?vue&type=script&lang=js"
export * from "./DvDelivery.vue?vue&type=script&lang=js"
import style0 from "./DvDelivery.vue?vue&type=style&index=0&id=b375ce94&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b375ce94",
  null
  
)

export default component.exports