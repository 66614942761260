<template>
  <el-card class="components">
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="输入编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>客户</th>
          <th>编号</th>
          <th>提交时间</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td :title="item.listStr">
            <div  class="text-overflow" style="max-width: 400px;">{{ item.listStr }}</div></td>
          <td>{{ item.created_at }}</td>
          <td>
            <div>
              <el-button size="small" title="详情" @click="toDetial(item)"><i class="fa fa-fw fa-search"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="showDialog" title="明细" :close-on-click-modal="false">
      <el-form :model="showModel" ref="editForm" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户：" prop="name">
              {{showModel.name}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期：">
              {{showModel.list_date}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="扫码明细" prop="remark">
          <span>明细数量：{{showModel.list ? showModel.list.length : 0}}</span>
          <div style="height: 200px;overflow:auto">
            <div v-for="(item, index) in showModel.list" :key="index">
              {{index + 1}}#{{item}}
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      waiting: false,
      loading: false,
      // 查询条件form
      queryForm: {
        code: '',
        status: '',
        type: '',
      },
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      showModel: {}, // 管理的操作时对象
      showDialog: false, // dialog显示标志 true 显示
      rules: {
        borrow: [{ required: true, message: '填写借用人', trigger: ['change', 'blur'] }],
      },
      scanCode: '',
      code: '',
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  created() {
    this.onSubmitQuery();
  },
  methods: {
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$http.get('yklist', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          this.loading = false;
          list.forEach((item) => {
            item.listStr = item.list ? item.list.join(',') : '';
          });
          this.list = list;
          this.list = response.data.data;
          this.totalItem = response.data.total;
        }, (err) => {
          this.loading = false;
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    keydown(e) {
      const curTime = new Date().getTime();
      const keyCode = e.which;
      if (!this.lastKeyTime) {
        this.lastKeyTime = curTime;
      }
      if ((curTime - this.lastKeyTime) < 40) {
        if (keyCode === 13) {
          this.scanCode = this.code;
          const p1 = /^[A-Z,0-9]{7,20}$/;
          if (p1.test(this.scanCode)) {
            this.addCode(this.scanCode);
          }
        } else if (keyCode !== 16) {
          this.code += String.fromCharCode(keyCode);
        }
      } else {
        this.code = '';
        if (keyCode !== 16) {
          this.code = String.fromCharCode(keyCode);
        }
      }
      this.lastKeyTime = curTime;
    },
    addCode(code) {
      if (this.editDialog) {
        const exist = this.editModel.items.find(item => item.code === code);
        if (!exist) {
          const params = {
            code,
          };
          this.axios.get('ykofcode', { params }).then((resp) => {
            this.editModel.items.push(resp.data);
          }, (e) => {
            this.$utils.resp(e);
          });
        }
      }
    },
    // 打开编辑
    toDetial(item) {
      this.showModel = { ...item };
      this.showDialog = true;
    },
  },
};
</script>
