<template>
  <div>
    <el-dialog append-to-body :visible.sync="showInitUser"  width="500px" title="初始化用户">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="公司名称" prop="name">
          <el-input readonly :value="form.name"></el-input>
        </el-form-item>
        <el-form-item label="登录名" prop="login_name">
          <el-input v-model="form.login_name"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input type="password" v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="repetPassword">
          <el-input type="password" v-model="form.repetPassword"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showInitUser = false" :disabled="waiting">关 闭</el-button>
        <el-button type="primary" @click="editPassword" :disabled="waiting">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    const validePsw = (rule, value, callback) => {
      const test1 = /^\S{5,}$/;
      if (value === '') {
        callback(new Error('新密码不能为空！'));
      } else if (!test1.test(value)) {
        callback(new Error('密码至少为5位！'));
      } else if (this.form.repetPassword) {
        this.$refs.form.validateField('repetPassword');
        callback();
      } else {
        callback();
      }
    };
    const valideRepetPsw = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('新密码不能为空！'));
      } else if (this.form.password !== this.form.repetPassword) {
        callback(new Error('两次密码不一致'));
      } else {
        callback();
      }
    };
    return {
      waiting: false,
      form: {
        password: '',
        repetPassword: '',
      },
      rules: {
        password: [{ required: true, validator: validePsw, trigger: 'blur' }],
        repetPassword: [{ required: true, validator: valideRepetPsw, trigger: ['change', 'blur'] }],
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    showInitUser: {
      get() {
        return this.$store.state.common.showInitUser;
      },
      set(val) {
        if (!val) {
          this.$store.commit('SET_SHOW_INIT_USER', false);
        }
      },
    },
  },
  watch: {
    showInitUser(val) {
      if (val) {
        this.form = {
          mobile: this.user.mobile,
          login_name: this.user.login_name,
          name: this.user.name,
          email: this.user.email,
          password: '',
          repetPassword: '',
        };
        if (this.$refs.form) {
          this.$nextTick(() => {
            this.$refs.form.clearValidate();
          });
        }
      }
    },
  },
  methods: {
    editPassword() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
          };
          this.waiting = true;
          this.$http.put('erp-party-user/', {}, { params }).then(() => {
            this.waiting = false;
            this.$message({
              message: '成功修改密码，之后请使用新密码登录系统',
              type: 'success',
            });
            this.showInitUser = false;
            this.$store.commit('AUTH_LOGOUT');
          }, (resp) => {
            this.waiting = false;
            this.$utils.resp(resp);
          });
        }
      });
    },
  },
};
</script>
