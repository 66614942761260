<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增模块</el-button>
    <!-- <a href="/asset-api/clothes-total" class="href">库存统计导出</a> -->
    <!-- <el-badge :hidden="excelList.length === 0" :value="excelList.length" class="item" type="primary">
      <el-button style="margin-left: 3px" type="warning" size="small" @click="managerExcel"><i class="fa fa-fw fa-download"></i> 模板下载</el-button>
    </el-badge> -->

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.name" clearable placeholder="名称" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <el-date-picker value-format="yyyy-MM-dd"
        v-model="queryForm.date" type="date" placeholder="选择日期" size="small">
      </el-date-picker>
      <!-- <div class="box-inline standard-search">
        <el-select v-model="queryForm.category" clearable placeholder="分类选择" filterable>
          <el-option v-for="(label, key) in categories" :key="key" :value="key" :label="`[${key}]${label}`"></el-option>
        </el-select>
      </div> -->
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table el-table">
      <thead>
        <tr>
          <th width="40">#</th>
          <th>模块名</th>
          <th>对应表</th>
          <th>流程模式</th>
          <th>适用人员</th>
          <th>状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id" :class="item.ismark ? 'bg-warn' : ''">
          <td>{{index + 1}}</td>
          <td>{{item.name}}</td>
          <td>{{item.mtable}}</td>
          <td>{{item.flow_mode_text}}</td>
          <td>{{item.for_names}}</td>
          <td>{{item.status_text}}</td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" title="修改信息" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="moduleForm.id ? '编辑' : '新增'"
      :close-on-click-modal="false">
      <el-form :model="moduleForm" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="模块名称" prop="name">
          <el-input v-model="moduleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="模块编号" prop="num">
          <el-input v-model="moduleForm.num"></el-input>
        </el-form-item>
        <el-form-item label="对应表" prop="mtable">
          <el-input v-model="moduleForm.mtable"></el-input>
        </el-form-item>
        <el-form-item label="对应人员" prop="for_ids">
          <dept-user v-model="moduleForm.for_ids" @selected="forUserSelected"></dept-user>
        </el-form-item>
        <el-form-item label="审批模式" prop="flow_mode">
          <el-select v-model="moduleForm.flow_mode">
            <el-option v-for="(label, val) in flowModes" :key="val" :value="val" :label="label">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times" aria-hidden="true"> 关 闭</el-button>
        <el-button type="primary" @click="saveModule" icon="fa fa-fw fa-floppy-o" aria-hidden="true"> 确 定</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import DeptUser from '@/components/DeptUser';

export default {
  components: {
    DeptUser,
  },
  data() {
    return {
      flowModes: {
        1: '顺序流程审批',
        2: '同一审批人跳过',
      },
      // 查询条件form
      queryForm: {
        q: '',
        status: '1',
      },
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      moduleForm: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      // 编辑form的条件
      rules: {
        // name: [{ pattern: /^[0-9]{2,4}$/, required: true, message: '填写年份', trigger: ['change', 'blur'] }],
        name: [{ required: true, message: '请输入名称', trigger: ['change', 'blur'] }],
        // category: [{ required: true, message: '选择类别', trigger: ['change', 'blur'] }],
        // color: [{ required: true, message: '选择颜色', trigger: ['change', 'blur'] }],
        // size: [{ required: true, message: '选择尺寸', trigger: ['change', 'blur'] }],
        // style_num: [{ pattern: /^[0-9]{1,2}$/, required: true, message: '请输入2位以内的数字序号', trigger: ['change', 'blur'] }],
        // max_seq: [{ type: 'integer', max: 999, message: '填写3位以内的数字', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
    // // 已选的ids
    // excelIds() {
    //   const list = [];
    //   this.excelList.forEach((item) => {
    //     list.push(item.id);
    //   });
    //   return list;
    // },
  },
  created() {
    this.init();
    this.onSubmitQuery();
  },
  watch: {
    successList(list) {
      // 打印成功序列到最后一个时候，更新最大序号
      if (list[list.length - 1] === this.finalCode) {
        this.updateMaxSeq(this.finalCode);
      }
    },
    // msgCode(code) {
    //   if (code === 1 || code === '1') {
    //     console.log(this.successList[this.successList.length - 1]);
    //   }
    // },
  },
  methods: {
    init() {
    },
    onSubmitQuery() {
      this.queryParams = {};
      if (this.queryForm.name && this.queryForm.name.trim()) {
        this.queryParams.name = this.queryForm.name;
      }
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    sortCode() {
      if (this.sortClass === '') {
        this.sortClass = 'ascending';
      } else if (this.sortClass === 'ascending') {
        this.sortClass = 'descending';
      } else if (this.sortClass === 'descending') {
        this.sortClass = '';
      }
      this.onSubmitQuery();
    },
    // 查询列表数据
    getList() {
      this.axios.get('flowset', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          this.list = list;
          this.totalItem = response.data.total;
        }, (response) => {
          this.$utils.resp(response);
        });
    },
    // 编辑窗口
    toEdit(editObj) {
      this.moduleForm = { ...editObj };
      this.files = [];
      if (this.$refs.addFile) {
        this.$refs.addFile.reset();
      }
      this.editDialog = true;
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 新增
    toAdd() {
      const Module = {
        name: '',
        num: '',
        table: '',
        for_ids: '',
        for_names: '',
        flow_mode: '1',
      };
      this.moduleForm = Module;
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    forUserSelected(list) {
      // eslint-disable-next-line arrow-parens
      const forNames = list.map(item => item.name);
      this.moduleForm.for_names = forNames.join(',');
    },
    // 修改新增的保存
    saveModule() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          let isSave = true;
          if (this.moduleForm.id) {
            isSave = false;
          }
          this.axios({
            method: isSave ? 'post' : 'put',
            url: isSave ? 'flowset' : `flowset/${this.moduleForm.id}`,
            data: this.moduleForm,
          }).then(() => {
            this.editDialog = false;
            this.getList();
            this.$message({
              type: 'success',
              message: '保存成功',
              showClose: true,
              duration: 2000,
            });
          }, (err) => { this.$utils.resp(err); });
        }
      });
    },
    // reInit(item) {
    //   this.$confirm('是否确认操作？', '确认信息', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning',
    //   }).then(() => {
    //     this.$http.put(`clothes-reinit/${item.code}`).then((resp) => {
    //       const out = resp.data.outCount;
    //       const store = resp.data.sCount;
    //       this.$message({
    //         type: 'success',
    //         message: `已经重置库存数据${store}条; 出库数据${out}条`,
    //       });
    //     });
    //   }, () => {});
    // },

  },
  // filters: {
  //   substr4(value) {
  //     return value.substr(0, 4);
  //   },
  // },
};
</script>

<style scoped>
.el-table tr.bg-warn {
  background: #ebe24e;
}
</style>
