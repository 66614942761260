<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>
    <!-- <el-button type="primary" size="small" @click="change()"><i class="fa fa-fw fa-plus-circle"></i> 转新款</el-button> -->
    <!-- <a href="/asset-api/clothes-total" class="href">库存统计导出</a> -->
    <!-- <el-badge :hidden="excelList.length === 0" :value="excelList.length" class="item" type="primary">
      <el-button style="margin-left: 3px" type="warning" size="small" @click="managerExcel"><i class="fa fa-fw fa-download"></i> 模板下载</el-button>
    </el-badge> -->

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.kh" clearable placeholder="款号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.rela_style" clearable placeholder="关联款号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.season" clearable placeholder="季节选择">
          <el-option v-for="(label, key) in seasons" :key="key" :value="key" :label="label"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.category" clearable placeholder="分类选择" filterable>
          <el-option v-for="(label, key) in categories" :key="key" :value="key" :label="`[${key}]${label}`"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.color" clearable placeholder="颜色选择" filterable>
          <el-option v-for="(label, key) in colors" :key="key" :value="key" :label="`[${key}]${label}`"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.size" clearable placeholder="尺码" filterable>
          <el-option v-for="(label, key) in sizes" :key="key" :value="key" :label="`${label}`"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status" clearable placeholder="所有状态">
          <el-option value="1" label="在售"></el-option>
          <el-option value="5" label="下架"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.orderBy" clearable placeholder="库存排序" filterable>
          <el-option value="stores_count|asc" label="库存升序"></el-option>
          <el-option value="stores_count|desc" label="库存降序"></el-option>
          <el-option value="lack_num|asc" label="库存缺少降序"></el-option>
          <el-option value="lack_num|desc" label="库存缺少升序"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.ismark" clearable placeholder="关注状态" filterable>
          <el-option value="1" label="关注款式"></el-option>
          <el-option value="" label=""></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table el-table">
      <thead>
        <tr>
          <th width="40">#</th>
          <th>年份季节</th>
          <th>款号</th>
          <th>类别</th>
          <th>图片</th>
          <th>颜色</th>
          <th>尺码</th>
          <th>关联款号</th>
          <th>当前序号</th>
          <th @click="sortCode()" :class="['is-sortable', sortClass]">
            <div class="cell">编码
              <span class="caret-wrapper">
                <i class="sort-caret ascending"></i>
                <i class="sort-caret descending"></i>
              </span>
            </div>
          </th>
          <th>价格</th>
          <th>预警库存</th>
          <th>是否补货</th>
          <th>当前库存</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id" :class="item.ismark ? 'bg-warn' : ''">
          <td>{{index + 1}}</td>
          <td>{{ '20' + item.year + item.season_text }}</td>
          <td>{{ `${item.season}${item.category}${item.style_num}` }}</td>
          <td>{{ item.category_text }}</td>
          <td>
            <el-popover v-if="item.imgs.length === 1"
              placement="right"
              trigger="hover">
              <img :src="showImgUrl">
              <img slot="reference" @mouseover="showItemImg(item, false)" @click="showItemImg(item, true)"
                :src="'data:image/jpg/png/gif;base64,' + item.imgs[0].simg" alt="">
            </el-popover>
            <!-- <i @click="showItemImg(item)" class="cur-pointer fa fa-fw fa-image"></i> -->
          </td>
          <td>{{ item.color_text }}</td>
          <td>{{ item.size_text }}</td>
          <td>{{ item.rela_style }}</td>
          <td>{{ item.max_seq }}</td>
          <td :title="item.remark">{{ item.code }}</td>
          <td>{{ item.price }}</td>
          <td>{{ item.warn_num }}</td>
          <td :class="{'text-warning': (item.stores_count - item.warn_num) < 0}">{{item.stores_count - item.warn_num}}</td>
          <td class="text-primary">{{ item.stores_count }}</td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" title="修改信息" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button>
              <el-button type="warning" size="small" title="打印" @click="toPrint(item)" icon="fa fa-print"></el-button>
              <el-button type="info" size="small" title="复制" @click="toCopy(item)" icon="fa fa-copy"></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="moduleForm.id ? '编辑' : '新增'"
      :close-on-click-modal="false" :close="closeEditDialog">
      <el-form :model="moduleForm" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="年分" prop="year">
          <el-input v-model="moduleForm.year"></el-input>
        </el-form-item>
        <el-form-item label="季节" prop="season">
          <el-select v-model="moduleForm.season" clearable placeholder="季节选择">
            <el-option v-for="(label, key) in seasons" :key="key" :value="key" :label="label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服装类别" prop="category">
          <el-select v-model="moduleForm.category" clearable placeholder="选择类别" filterable>
            <el-option v-for="(label, key) in categories" :key="key" :value="key" :label="`[${key}]${label}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联款式" prop="rela_style">
          <el-input v-model="moduleForm.rela_style"></el-input>
        </el-form-item>
        <el-form-item label="款式序号" prop="style_num">
          <el-input v-model="moduleForm.style_num"></el-input>
        </el-form-item>
        <el-form-item label="颜色" prop="color">
          <el-select v-model="moduleForm.color" clearable placeholder="选择颜色" filterable>
            <el-option v-for="(label, key) in colors" :key="key" :value="key" :label="`[${key}]${label}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input type="number" v-model="moduleForm.price"></el-input>
        </el-form-item>
        <el-form-item label="预警库存" prop="warn_num">
          <el-input type="number" v-model="moduleForm.warn_num"></el-input>
        </el-form-item>
        <el-form-item label="尺码" prop="size">
          <el-select v-model="moduleForm.size" clearable placeholder="选择尺码">
            <el-option v-for="(label, key) in sizes" :key="key" :value="key" :label="label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="已打印数量" prop="max_seq">
          <el-input v-model.number="moduleForm.max_seq" type="number"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model.number="moduleForm.remark" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="特别关注" prop="ismark">
          <el-switch
            v-model="moduleForm.ismark" active-value="1" :inactive-value="null"
            active-color="#13ce66"
            inactive-color="#ddd">
          </el-switch>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="moduleForm.status">
            <el-option value="1" label="在售"></el-option>
            <el-option value="5" label="下架"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="附件">
          <span v-if="files.length > 0">{{files[0].name}}</span>
          <span v-else>
            <span v-if="moduleForm.imgs">
              <a href="#" v-for="(item) in moduleForm.imgs" :key="item.name" @click.prevent="download(item)">{{item.orgName}}</a>
              <!-- {{moduleForm.imgs.length > 0 ? moduleForm.imgs[0].orgName : '' }} -->
            </span>
          </span>
          <file-uploader ref="addFile"
            accept="."
            @change="changeFile"
            :showPreview="false"
            :showFileHint="false">
          </file-uploader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button type="warning" @click="reInit(moduleForm)">库存预重置</el-button> -->
        <!-- <el-button class="pull-left" v-if="moduleForm.id"
          title="删除" type="danger" @click="delModule"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button> -->
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="saveModule">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>

    <!-- 加入打印  -->
    <el-dialog :visible.sync="toPrintDialog" title="打印数量设置">
      <el-form :model="toAddObj" label-width="100px">
        <el-form-item label="产品：">
          {{toAddObj.color_text}} {{toAddObj.size_text}} {{toAddObj.category_text}} {{ '20' + toAddObj.year + toAddObj.season_text }}
        </el-form-item>
        <el-form-item label="关联款号：">
          {{toAddObj.rela_style}}
        </el-form-item>
        <el-form-item label="编号：">
          {{toAddObj.code}}
        </el-form-item>
        <el-form-item label="补打标签：">
          <el-checkbox v-model="toAddObj.isRePrint">勾选补打，将不改动最大编号序列</el-checkbox>
        </el-form-item>
        <el-form-item label="开始序号：" prop="start_num">
          <el-input v-model="toAddObj.start_num"></el-input>
        </el-form-item>
        <el-form-item label="打印数量：" prop="count">
          <el-input v-model="toAddObj.count"></el-input>
        </el-form-item>
        <el-form-item label="工厂：" prop="supplier">
          <el-select v-model="toAddObj.supplier" clearable>
            <el-option v-for="(item) in suppliers" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="toPrintDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="printList(toAddObj)">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>
    <!-- 打印预览 取消了 -->
    <el-dialog :visible.sync="excelDialog" width="80%" title="导出预览设置">
      <table class="table border">
        <thead>
          <tr>
            <th width="25">#</th>
            <th>年份季节</th>
            <th>类别</th>
            <th>款式序号</th>
            <th>颜色</th>
            <th>尺码</th>
            <th>编号</th>
            <th>开始序号</th>
            <th>数量</th>
            <th width="1%">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in excelList" :key="item.id">
            <td>{{index + 1}}<el-checkbox v-model="item.checked"></el-checkbox></td>
            <td>{{ '20' + item.year + item.season_text }}</td>
            <td>{{ item.category_text }}</td>
            <td>{{ item.style_num }}</td>
            <td>{{ item.color_text }}</td>
            <td>{{ item.size_text }}</td>
            <td>{{ item.code }}</td>
            <td><el-input style="width:80px" type="number" v-model="item.start_num"></el-input></td>
            <td><el-input style="width:80px" type="number" v-model="item.count"></el-input></td>
            <td>
              <div class="text-nowrap">
                <el-button type="danger" size="small" title="删除" @click="removeExcel(item)" icon="el-icon-minus"></el-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="excelDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="createExcel">
          <i class="fa fa-fw fa-download" aria-hidden="true"></i> 导出excel</el-button>
      </div>
    </el-dialog>
    <el-form ref="tempForm" v-show="false"></el-form>
    <a ref="downloadLink" :href="downloadUrl" :download="downloadFileName" v-show="false"></a>

  </el-card>
</template>

<script>
import FileUploader from '@/components/FileUpload';

function getQuarter(date = null) {
  let d = date;
  if (!d) {
    d = new Date();
  }
  const mon = d.getMonth();
  let result = null;
  if (mon < 3) {
    result = 1;
  } else if (mon < 6) {
    result = 2;
  } else if (mon < 9) {
    result = 3;
  } else if (mon < 12) {
    result = 4;
  }
  return result;
}

export default {
  components: {
    FileUploader,
  },
  data() {
    return {
      // 打印机服务
      ws: null,
      isWsOpen: false,
      msgCode: null,
      successList: [],
      printerUrl: 'ws://localhost:8989',
      // 查询条件form
      queryForm: {
        q: '',
        status: '1',
      },
      colors: [],
      sizes: [],
      seasons: [],
      categories: [],
      suppliers: [],
      toAddObj: {}, // 标签打印对象
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      moduleForm: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      // 编辑form的条件
      rules: {
        year: [{ pattern: /^[0-9]{2,4}$/, required: true, message: '填写年份', trigger: ['change', 'blur'] }],
        season: [{ required: true, message: '选择季节', trigger: ['change', 'blur'] }],
        category: [{ required: true, message: '选择类别', trigger: ['change', 'blur'] }],
        color: [{ required: true, message: '选择颜色', trigger: ['change', 'blur'] }],
        size: [{ required: true, message: '选择尺寸', trigger: ['change', 'blur'] }],
        style_num: [{ pattern: /^[0-9]{1,2}$/, required: true, message: '请输入2位以内的数字序号', trigger: ['change', 'blur'] }],
        max_seq: [{ type: 'integer', max: 999, message: '填写3位以内的数字', trigger: ['change', 'blur'] }],
      },
      toPrintDialog: false,
      excelDialog: false,
      excelList: [],
      allCheck: false,
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      downloadFileName: '',
      downloadUrl: '',
      files: [],
      showImgUrl: '',
      sortClass: '',
    };
  },
  computed: {
    // 已选的ids
    excelIds() {
      const list = [];
      this.excelList.forEach((item) => {
        list.push(item.id);
      });
      return list;
    },
  },
  created() {
    this.init();
    this.onSubmitQuery();
  },
  watch: {
    successList(list) {
      // 打印成功序列到最后一个时候，更新最大序号
      if (list[list.length - 1] === this.finalCode) {
        this.updateMaxSeq(this.finalCode);
      }
    },
    // msgCode(code) {
    //   if (code === 1 || code === '1') {
    //     console.log(this.successList[this.successList.length - 1]);
    //   }
    // },
  },
  methods: {
    init() {
      // this.initPrint().then(() => {
      //   this.isWsOpen = true;
      // });
      if (this.$store.state.common.clothesDicts.sizes) {
        this.sizes = this.$store.state.common.clothesDicts.sizes;
        this.seasons = this.$store.state.common.clothesDicts.seasons;
        this.categories = this.$store.state.common.clothesDicts.orgCategories;
        this.colors = this.$store.state.common.clothesDicts.orgColors;
      } else {
        this.$store.dispatch('GET_COLTHES_DICTS').then((resp) => {
          this.sizes = resp.data.sizes;
          this.seasons = resp.data.seasons;
          this.categories = resp.data.orgCategories;
          this.colors = resp.data.orgColors;
        });
      }
      this.getSuppliers();
    },
    // 加工厂信息
    getSuppliers() {
      this.axios.get('clothes-supplier', { params: { all: 1 } }).then((r) => {
        this.suppliers = r.data;
      });
    },
    onSubmitQuery() {
      this.queryParams = {};
      if (this.queryForm.code && this.queryForm.code.trim()) {
        this.queryParams.code = this.queryForm.code;
      }
      if (this.queryForm.rela_style && this.queryForm.rela_style.trim()) {
        this.queryParams.rela_style = this.queryForm.rela_style;
      }
      if (this.queryForm.kh && this.queryForm.kh.trim()) {
        this.queryParams.kh = this.queryForm.kh;
      }
      if (this.queryForm.season && this.queryForm.season.trim()) {
        this.queryParams.season = this.queryForm.season;
      }
      if (this.queryForm.category && this.queryForm.category.trim()) {
        this.queryParams.category = this.queryForm.category;
      }
      if (this.queryForm.color && this.queryForm.color.trim()) {
        this.queryParams.color = this.queryForm.color;
      }
      if (this.queryForm.size !== null) {
        this.queryParams.size = this.queryForm.size;
      }
      if (this.queryForm.ismark) {
        this.queryParams.ismark = this.queryForm.ismark;
      }
      if (this.queryForm.status) {
        this.queryParams.status = this.queryForm.status;
      }
      if (this.sortClass) {
        this.queryParams.order_by = (this.sortClass === 'descending') ? 'code|desc' : 'code|asc';
      }
      if (this.queryForm.orderBy) {
        this.queryParams.order_by = this.queryForm.orderBy;
        this.sortClass = '';
      }
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    sortCode() {
      if (this.sortClass === '') {
        this.sortClass = 'ascending';
      } else if (this.sortClass === 'ascending') {
        this.sortClass = 'descending';
      } else if (this.sortClass === 'descending') {
        this.sortClass = '';
      }
      this.onSubmitQuery();
    },
    toPrint(item) {
      this.$http.get(`clothes/${item.id}`).then((resp) => {
        const obj = resp.data;
        obj.supplier = '';
        obj.start_num = obj.max_seq + 1;
        obj.count = 1;
        obj.isRePrint = false;
        this.toAddObj = obj;
        this.toAddObj.count = 1;
        this.toPrintDialog = true;
      });
    },
    // 查询列表数据
    getList() {
      this.axios.get('clothes', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          list.forEach((item) => {
            item.checked = this.excelIds.indexOf(item.id) !== -1;
          });
          this.list = list;
          this.totalItem = response.data.total;
        }, (response) => {
          this.$utils.resp(response);
        });
    },
    // 编辑窗口
    toEdit(editObj) {
      this.moduleForm = { ...editObj };
      this.files = [];
      if (this.$refs.addFile) {
        this.$refs.addFile.reset();
      }
      this.editDialog = true;
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 新增
    toAdd() {
      const Module = {
        year: String(new Date().getFullYear()),
        season: String(getQuarter()),
        category: null,
        style_num: null,
        color: null,
        size: null,
        rela_style: null,
        price: null,
        warn_num: null,
        ismark: '0',
        remark: '',
        max_seq: 0,
      };
      this.moduleForm = Module;
      this.editDialog = true;
      this.files = [];
      if (this.$refs.addFile) {
        this.$refs.addFile.reset();
      }
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    // 复制
    toCopy(org) {
      const Module = {
        year: org.year,
        season: org.season,
        category: org.category,
        style_num: org.style_num,
        color: null,
        size: null,
        rela_style: org.style_num,
        price: org.price,
        warn_num: null,
        ismark: '0',
        remark: '',
        max_seq: 0,
      };
      this.moduleForm = Module;
      this.editDialog = true;
      this.files = [];
      if (this.$refs.addFile) {
        this.$refs.addFile.reset();
      }
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    // 修改新增的保存
    saveModule() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          let formData = null;
          if (this.$refs.addFile.identifyBorwser() && this.files.length > 0) {
            formData = new window.FormData(this.$refs.tempForm.$el);
            formData.append('hint', 'clothes');
            this.files.forEach((ele) => {
              formData.append('file[]', ele);
            });
          }
          let tempForm = { ...this.moduleForm };
          tempForm.year = tempForm.year.substr(tempForm.year.length - 2);
          if (tempForm.style_num.length < 2) {
            tempForm.style_num = `0${tempForm.style_num}`;
          }
          if (formData) {
            this.$utils.formDataAdd(formData, tempForm);
            tempForm = formData;
          }
          if (this.moduleForm.id) {
            this.$http.post(`clothes-update/${this.moduleForm.id}`, tempForm).then(() => {
              this.editDialog = false;
              this.getList();
            }, (resp) => {
              this.$utils.resp(resp);
            });
          } else {
            this.$http.post('clothes', tempForm).then(() => {
              this.editDialog = false;
              this.changePage(1);
            }, (resp) => {
              this.$utils.resp(resp);
            });
          }
        }
      });
    },
    closeEditDialog() {
      // this.canChooseBranch = true;
    },
    // reInit(item) {
    //   this.$confirm('是否确认操作？', '确认信息', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning',
    //   }).then(() => {
    //     this.$http.put(`clothes-reinit/${item.code}`).then((resp) => {
    //       const out = resp.data.outCount;
    //       const store = resp.data.sCount;
    //       this.$message({
    //         type: 'success',
    //         message: `已经重置库存数据${store}条; 出库数据${out}条`,
    //       });
    //     });
    //   }, () => {});
    // },
    delModule() {
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`clothes/${this.moduleForm.id}`).then(() => {
          this.getList();
          this.editDialog = false;
        }, (resp) => {
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    // managerExcel() {
    //   this.excelDialog = true;
    // },
    createExcel() {
      const list = [];
      this.excelList.forEach((item) => {
        list.push({
          id: item.id,
          count: item.count,
          start_num: item.start_num,
        });
      });
      this.$http.get('clothes-excel', { params: { list }, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.downloadUrl = objurl;
        this.downloadFileName = this.$utils.formatDate('yyyy-mm-dd') + '.xlsx';
        this.$nextTick(() => {
          this.$refs.downloadLink.click();
        });
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // 显示图片
    showItemImg(item, isFullScrean = false) {
      this.showImg(item.imgs[0], isFullScrean);
    },
    showImg(file, isFullScrean) {
      const params = {
        hint: 'clothes',
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.showImgUrl = objurl;
        if (isFullScrean) {
          this.$store.commit('SET_BIG_IMG_URL', objurl);
          this.$store.commit('PICKER_SHOW_SHOW_BIG_IMG');
        }
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // 选中上传文件时
    changeFile(files) {
      if (this.$refs.addFile.sizeWarning) {
        this.$utils.msg('文件过大', 'warning');
        return;
      }
      if (files.length > 0) {
        this.files = [];
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
      }
    },
    // 下载图片
    download(file) {
      const params = {
        hint: 'clothes',
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.downloadFileName = file.orgName;
        this.downloadUrl = objurl;
        this.$nextTick(() => {
          this.$refs.downloadLink.click();
        });
        this.getList();
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    initPrint() {
      return new Promise((resolve, reject) => {
        if ('WebSocket' in window) {
          this.ws = new window.WebSocket(this.printerUrl);
        } else if ('MozWebSocket' in window) {
          this.ws = new window.MozWebSocket(this.printerUrl);
        } else {
          this.$notify.error({
            title: '错误',
            message: '当前浏览器不支持html5的WebSocket，因此无法连接打印机',
          });
        }
        this.ws.onopen = () => {
          resolve('connected');
        };
        this.ws.onerror = () => {
          this.ws = null;
          this.$notify.error({
            title: '错误',
            message: '打印服务链接异常',
          });
          reject(new Error('无法链接打印机'));
        };
        this.ws.onmessage = (res) => {
          if (res.data && res.data.length > 10) {
            this.successList.push(res.data);
          } else {
            this.msgCode = res.data;
          }
        };
      });
    },
    // 开始打印
    printList(item) {
      this.successList = [];
      item.leftNum = item.count * 1;
      let numStr = item.start_num * 1 + item.count * 1 - 1;
      numStr = '000' + numStr;
      numStr = numStr.substr(numStr.length - 3);
      this.finalCode = item.code + numStr;
      if (!this.isWsOpen) {
        this.initPrint().then(() => {
          this.isWsOpen = true;
          setTimeout(() => {
            this.print(item);
          }, 1000);
        });
      } else {
        this.print(item);
      }
    },
    print(item) {
      if (item.leftNum === 0) {
        return;
      }
      const { ws } = this;
      const sn = `${item.season}${item.category}${item.style_num}`;
      let numStr = item.start_num * 1 + item.count * 1 - item.leftNum * 1;
      numStr = '000' + numStr;
      numStr = numStr.substr(numStr.length - 3);
      item.printCode = item.code + numStr;
      // this.initPrint().then(() => {
      //   });
      const supplier = item.supplier ? item.supplier : '';
      // console.log(`${item.category_text}|${sn}|${item.size_text}|${item.color_text}|${item.printCode}|${item.price}|${supplier}`);
      // 执行标准
      let bz = item.zxbz;
      if (!bz) {
        bz = 'GB/T 22853-2019';
      }
      // 安全技术等级
      let dj = item.aqjsdj;
      if (!dj) {
        dj = 'GB18401-2010 B类';
      }
      // ws.send(`${item.sub_category}|${sn}|${item.size_text}|${item.color}|${item.printCode}|${item.price}||${bz}|${dj}`);
      ws.send(`${item.category_text}|${sn}|${item.size_text}|${item.color_text}|${item.printCode}|${item.price}|${supplier}|${bz}|${dj}`);
      setTimeout(() => {
        item.leftNum--;
        this.print(item);
      }, 10);
      // new Promise((resolve, reject) => {
      //   this.ws.onmessage = (resp) => {
      //     console.log(resp.data);
      //     if (item.printCode === resp.data) {
      //       this.finalCode = resp.data;
      //       resolve(resp.data);
      //     } else {
      //       reject(new Error('err'));
      //     }
      //   };
      // }).then((r) => {
      //   let re = null;
      //   if (r === item.printCode) {
      //     re = 'success';
      //   } else {
      //     console.log(r);
      //     re = Promise.reject();
      //   }
      //   return re;
      // }).then((rest) => {
      //   item.leftNum--;
      //   this.print(item);
      // }, () => {
      //   console.log('error');
      // });
    },
    // 更新最大序号
    updateMaxSeq(code) {
      // 补打卡不更新
      if (this.toAddObj.isRePrint) {
        this.toPrintDialog = false;
        return;
      }
      const ccode = code.substr(0, 10);
      const maxSeq = code.substr(10) * 1;
      this.$http.put('clothes-update-max', { code: ccode, max: maxSeq }).then(() => {
        this.toPrintDialog = false;
        this.getList();
      });
    },
    change() {
      this.axios.get('change').then(() => {
      });
    },
  },
  // filters: {
  //   substr4(value) {
  //     return value.substr(0, 4);
  //   },
  // },
};
</script>

<style scoped>
.el-table tr.bg-warn {
  background: #ebe24e;
}
</style>
