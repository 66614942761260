<template>
  <div>
    <div class="mar-btm-10 text-right">
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.kh" clearable filterable placeholder="款号">
          <el-option v-for="item in khs" :key="item" :value="item" :label="item"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search mar-lft-10">
        <el-select v-model="queryForm.rela_style" clearable filterable placeholder="关联款号">
          <el-option v-for="item in relaStyles" :key="item" :value="item" :label="item"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search mar-lft-10">
        <el-date-picker value-format="yyyy-MM-dd"
          v-model="queryForm.start_date"
          type="date" placeholder="开始日期">
        </el-date-picker>
      </div>
      <div class="box-inline standard-search">
        <el-date-picker value-format="yyyy-MM-dd"
          v-model="queryForm.end_date"
          type="date" placeholder="截至日期">
        </el-date-picker>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
      <!-- <el-button size="small" @click="excel">
        <i class="fa fa-fw fa-download"></i>
      </el-button> -->
    </div>
    <div>
      合计入库：<span class="text-primary">{{totalCount}}</span>
    </div>
    <div :style="{height: boxHeight+'px', overflow: 'auto'}">
      <table class="table bordered">
        <thead>
          <tr>
            <th>款号</th>
            <th>关联款号</th>
            <th>类别</th>
            <th>颜色</th>
            <th>图片</th>
            <th>F</th>
            <th>XS</th>
            <th>S</th>
            <th>S/M</th>
            <th>M</th>
            <th>M/L</th>
            <th>L</th>
            <th>XL</th>
            <th>XXL</th>
            <th class="text-center">合计入库</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(obj) in dataList">
            <tr v-for="(item, index) in obj.list" :key="`${obj.code}_${index}`">
              <td :rowspan="obj.list.length" v-if="index == 0">{{String(item.code).substr(0, 5)}}
                <br/>
                <span class="text-primary">{{obj.total}}</span>
              </td>
              <td :rowspan="obj.list.length" v-if="index == 0">{{item.rela_style}}</td>
              <td :rowspan="obj.list.length" v-if="index == 0">
                 {{item.category}}
              </td>
              <td>{{item.color}}</td>
              <td>
                <el-popover v-if="item.imgs"
                  placement="right"
                  trigger="hover">
                  <img :src="showImgUrl">
                  <img slot="reference" @mouseover="showItemImg(item, false)" @click="showItemImg(item, true)"
                    :src="'data:image/jpg/png/gif;base64,' + item.imgs.simg" alt="">
                </el-popover>
              </td>
              <td class="text-right">{{item.size0}}</td>
              <td class="text-right">{{item.size1}}</td>
              <td class="text-right">{{item.size2}}</td>
              <td class="text-right">{{item.size7}}</td>
              <td class="text-right">{{item.size3}}</td>
              <td class="text-right">{{item.size8}}</td>
              <td class="text-right">{{item.size4}}</td>
              <td class="text-right">{{item.size5}}</td>
              <td class="text-right">{{item.size6}}</td>
              <td class="text-right">{{item.total}}</td>
            </tr>

          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      khs: [],
      relaStyles: [],
      boxHeight: 100,
      queryForm: {},
      totalData: [],
      categories: [],
      sizes: [],
      showImgUrl: '',
    };
  },
  computed: {
    totalCount() {
      let result = 0;
      this.totalData.forEach((item) => {
        result += 1 * item.total;
      });
      return result;
    },
    // 排序后的数据
    dataList() {
      const list = this.totalData;
      const l = list.sort((a, b) => b.total - a.total);
      l.forEach((item) => {
        item.list = item.list.sort((a, b) => b.total - a.total);
      });
      return l;
    },
  },
  created() {
    this.boxHeight = document.documentElement.clientHeight - 260;
    if (this.$store.state.common.clothesDicts.sizes) {
      this.categories = this.$store.state.common.clothesDicts.orgCategories;
      this.colors = this.$store.state.common.clothesDicts.orgColors;
      this.relaStyles = this.$store.state.common.clothesDicts.relaStyles;
      this.khs = this.$store.state.common.clothesDicts.khs;
    } else {
      this.$store.dispatch('GET_COLTHES_DICTS').then((resp) => {
        this.categories = resp.data.orgCategories;
        this.colors = resp.data.orgColors;
        this.relaStyles = this.$store.state.common.clothesDicts.relaStyles;
        this.khs = this.$store.state.common.clothesDicts.khs;
      });
    }
  },
  methods: {
    onSubmitQuery() {
      this.getTj();
    },
    getTj() {
      this.axios.get('e-in-tj', { params: this.queryForm }).then((resp) => {
        const temps = resp.data;
        const keys = Object.keys(temps);
        const list = [];
        keys.forEach((key) => {
          const arr = temps[key];
          let total = 0;
          arr.forEach((item) => {
            if (item.total) {
              total += 1 * item.total;
            }
          });
          list.push({
            total,
            list: arr,
            code: key,
          });
        });
        this.totalData = list;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // 显示图片
    showItemImg(item, isFullScrean = false) {
      const p1 = /^[A-Z]/;
      let hint = 'clothes';
      if (p1.test(item.code)) {
        hint = 'e_clothes';
      }
      this.showImg(item.imgs, hint, isFullScrean);
    },
    showImg(file, hint = 'clothes', isFullScrean) {
      const params = {
        hint,
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.showImgUrl = objurl;
        if (isFullScrean) {
          this.$store.commit('SET_BIG_IMG_URL', objurl);
          this.$store.commit('PICKER_SHOW_SHOW_BIG_IMG');
        }
      }, (err) => {
        this.$utils.resp(err);
      });
    },
  },
};
</script>

<style>

</style>
