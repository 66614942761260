<template>
  <el-card>
    <el-date-picker></el-date-picker>
    <el-button @click="excel">excel</el-button>
  </el-card>
</template>

<script>
import * as xlsx from 'xlsx';

export default {
  data() {
    return {
      list: [],
    };
  },
  methods: {
    excel() {
      this.axios.get('checkresult').then((r) => {
        this.list = r.data;
        this.download();
      });
    },
    download() {
      if (this.list.length > 0) {
        const l = [];
        this.list.forEach((i) => {
          l.push({
            编号: i.sn,
            名称: i.name,
            型号: i.spec,
            品牌: i.brand,
            // 品牌: i.brand,
            备注: i.remark,
          });
        });
        const sheet = xlsx.utils.json_to_sheet(l);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, sheet, 'sheet1');
        xlsx.writeFile(wb, (this.$utils.formatDate(new Date(), 'yyyyMMdd') + '未扫到的.xlsx'));
      }
    },
  },
};
</script>

<style>
</style>
