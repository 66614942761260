<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>
    <el-button type="primary" size="small" @click="getYq()"> 逾期更新</el-button>
    <file-uploader ref="addFile"
      accept="."
      :multiple="false"
      @change="changeFile"
      btnName="导入数据"
      :showPreview="false"
      :showFileHint="false">
    </file-uploader>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="输入编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.type" clearable placeholder="类型">
          <el-option v-for="(item, index) in types" :key="index" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status" clearable placeholder="状态">
          <el-option v-for="(item, index) in statuses" :key="index" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>编号</th>
          <th>状态</th>
          <th>借用人</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.code }}</td>
          <td>{{ item.status_text }}</td>
          <td>{{ (item.status==2 || item.status==3) ? `${item.borrower}(${item.lend_date})` : ''}}</td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" title="修改信息" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'" :close-on-click-modal="false">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="编号" prop="code">
          <el-input v-model="editModel.code" @change="codeChange"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="editModel.type" @change="typeChange">
            <el-option v-for="(item, index) in types" :key="index" :value="item.value" :label="item.label">{{item.label}}【{{item.value}}】</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="editModel.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="save">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true" :disabled="waiting"></i> 确 定</el-button>
        <el-button v-if="editModel.id" type="danger" @click="delIt(editModel.id)" class="pull-left">
          <i class="fa fa-fw fa-trash" aria-hidden="true"></i> 删 除</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import { mapState } from 'vuex';
import FileUploader from '@/components/FileUpload';

export default {
  components: {
    FileUploader,
  },
  data() {
    return {
      waiting: false,
      loading: false,
      // 查询条件form
      queryForm: {
        code: '',
        status: '',
        type: '',
      },
      types: [
        { value: '315', label: '大货样衣' },
        { value: '15', label: '购买样衣' },
        { value: 'GH15F', label: '展会面料' },
        { value: '88', label: '科室开发样' },
      ],
      statuses: [
        { value: '1', label: '在库' },
        { value: '2', label: '借出' },
        { value: '3', label: '逾期未还' },
        { value: '4', label: '失效' },
      ],
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
        code: [{ required: true, message: '填写编号', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      files: [],
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  created() {
    this.onSubmitQuery();
  },
  methods: {
    changeFile(files) {
      if (this.$refs.addFile.sizeWarning) {
        this.$utils.msg('文件过大', 'warning');
        return;
      }
      if (files.length > 0) {
        this.files = [];
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
        this.$nextTick(() => {
          this.upload();
        });
      } else {
        this.files = [];
      }
    },
    upload() {
      if (this.$refs.addFile.identifyBorwser() && this.files.length > 0) {
        const tempEle = document.createElement('form');
        const formData = new window.FormData(tempEle);
        this.files.forEach((ele) => {
          formData.append('file[]', ele);
        });
        this.$http.post('ykimport', formData).then((resp) => {
          const successLen = resp.data.success.length;
          const errCodes = resp.data.errCodes.join(',');
          this.$message({
            type: 'success',
            message: `成功导入${successLen}条，已存在的记录：${errCodes}`,
            duration: 0,
            showClose: true,
          });
          this.$refs.addFile.reset();
        }, (err) => {
          this.$refs.addFile.reset();
          this.$utils.resp(err);
        });
      }
    },
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    getYq() {
      this.axios.get('yklendyq').then(() => {
        this.queryForm.status = '3';
        this.onSubmitQuery();
      });
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$http.get('yksample', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          this.loading = false;
          this.list = list;
          this.list = response.data.data;
          this.totalItem = response.data.total;
        }, (err) => {
          this.loading = false;
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.editModel = {
        name: '',
        code: '',
        remark: '',
        type: '',
      };
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 打开编辑
    toEdit(editObj) {
      this.editModel = { ...editObj };
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    codeChange(code) {
      this.types.forEach((type) => {
        if (code.indexOf(type.value) === 0) {
          this.editModel.type = type.value;
        }
      });
    },
    typeChange() {

    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.editModel };
          this.waiting = true;
          if (obj.id) {
            this.$http.put(`yksample/${obj.id}`, obj).then(() => {
              this.editDialog = false;
              this.getList();
              this.$message({
                type: 'success',
                message: '更新成功',
                showClose: true,
                duration: 2000,
              });
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          } else {
            this.$http.post('yksample', obj).then(() => {
              this.editDialog = false;
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
              this.getList();
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
    delIt(id) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.axios.delete(`yksample/${id}`).then(() => {
          this.getList();
          this.editDialog = false;
          this.waiting = false;
        }, (e) => {
          this.waiting = false;
          this.$utils.resp(e);
        });
      }, () => {});
    },
  },
};
</script>
