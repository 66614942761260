<template>
  <el-card class="components">
    <el-button type="primary" size="small" v-if="isDeliveryManager"
      @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="完工入库编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.project_number" clearable placeholder="项目号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search" v-if="!isParty">
        <el-select style="width: 100%" v-model="queryForm.party_id" v-if="!isParty" placeholder="选择加工单位" :loading="queryPartiesLoading"
          clearable filterable remote :remote-method="queryParties">
          <el-option v-for="item in parties" :key="item.party_id"
            :value="item.party_id" :label="item.party_name"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>加工合同号</th>
          <th>编号</th>
          <th>加工厂</th>
          <th>部门</th>
          <th>完工日期</th>
          <th>状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.mo_order_number }}</td>
          <td><a href="#" @click="toDetail(item)">{{ item.code }}</a></td>
          <td>{{ item.party_name }}</td>
          <td>{{ item.owner_group_name }}</td>
          <td>{{ item.transaction_date }}</td>
          <td>{{ item.status_text }}</td>
          <td>
            <div class="text-nowrap">
              <el-button type="info" :disabled="waiting" @click="apply(item)" v-if="item.status==='ENTERED'">提交</el-button>
              <el-button type="info" :disabled="waiting" @click="approve(item)" v-if="item.status==='PROCESSING'">审核通过</el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" width="90%" :title="editModel.id ? '编辑' : '新增'"
      :close-on-click-modal="false">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="入库日期" prop="transaction_date">
              <el-date-picker v-model="editModel.transaction_date"
                style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属项目" prop="to_project_id">
              <el-select v-model="editModel.to_project_id" style="width:100%;" filterable
                remote :remote-method="getProjects" @change="chooseProject">
                <el-option label="" value=""></el-option>
                <el-option v-for="item in projects" :key="item.id"
                  :value="item.project_id" :label="item.project_number">{{`【${item.project_number}】 ${item.description}`}}</el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="editModel.to_project_id">
            <el-form-item label="加工合同" prop="source_header_id">
              <el-select v-model="editModel.source_header_id" style="width:100%;" clearable
                filterable @change="chooseMo" :loading="queryMosLoading">
                <el-option label="" value=""></el-option>
                <el-option v-for="item in mos" :key="item.mo_header_id"
                  :value="item.mo_header_id" :label="`【${item.order_number}】 ${item.business_type_name}`"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="业务部门">
              <el-select :value="userDeptId" style="width: 100%">
                <el-option v-for="item in deptMap" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库组织" prop="organization_id">
              <el-select v-model="editModel.organization_id" style="width: 100%;" @change="getInventories">
                <el-option value="" label=""></el-option>
                <el-option v-for="(item) in organizations" :key="item.organization_id"
                  :value="item.organization_id" :label="item.organization_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="加工企业" prop="party_id">
              <el-select style="width: 100%" v-model="editModel.party_id" placeholder="加工企业" :loading="queryPartiesLoading"
                clearable filterable remote :remote-method="queryParties2" @change="getInventories">
                <el-option v-for="item in parties2" :key="item.party_id"
                  :value="item.party_id" :label="item.party_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库" prop="to_subinventory_code">
              <el-select v-model="editModel.to_subinventory_code" style="width: 100%;">
                <el-option v-for="(item, idx) in inventories" :key="idx"
                  :value="item.secondary_inventory_name" :label="item.description"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注说明">
          <el-input type="textarea" v-model="editModel.description"></el-input>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            投料
            <!-- <el-tooltip content="数量前面的参数相同会归为相同产品" placement="top" effect="light">
              <i class="el-icon-warning text-warning"></i>
            </el-tooltip> -->
          </template>
          <table class="items bordered table" style="width: 100%;">
            <thead>
              <tr>
                <th class="text-center">品名</th>
                <th class="text-center">款号</th>
                <th class="text-center">规格</th>
                <th class="text-center">颜色/其他</th>
                <th class="text-center" width="110">数量</th>
                <th class="text-center" width="70">单位</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in editModel.meterial" :key="index" :title="item.remark">
                <td>{{item.item_name}}</td>
                <td>
                  {{item.attr1}}
                </td>
                <td>
                  {{item.attr2}}
                </td>
                <td>
                  {{item.color}}
                </td>
                <td>
                  <el-input class="text-right" v-model="item.quantity" type="number"></el-input>
                </td>
                <td>{{item.uom}}</td>
                <td>
                  <el-button @click="delMeterial(index)"
                    type="danger" title="删除" icon="el-icon-delete"></el-button>
                </td>
              </tr>
              <tr>
                <td colspan="6" class="text-right">
                </td>
                <td>
                  <el-button @click="addMeterial" type="primary" title="新增" icon="el-icon-plus"></el-button>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
        <el-form-item label="加工成品">
          <table class="items bordered table" style="width: 100%;">
            <thead>
              <tr>
                <th class="text-center">品名</th>
                <th class="text-center">款号</th>
                <th class="text-center">规格</th>
                <th class="text-center">颜色/其他</th>
                <th class="text-center" width="110">数量</th>
                <th class="text-center" width="70">单位</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in editModel.product" :key="index" :title="item.remark">
                <td>
                  <el-input :readonly="item.item_id" v-model="item.item_name"></el-input>
                </td>
                <td>
                  <el-input :readonly="item.item_id" v-model="item.attr1"></el-input>
                </td>
                <td>
                  <el-input :readonly="item.item_id" v-model="item.attr2"></el-input>
                </td>
                <td>
                  <el-input v-model="item.color"></el-input>
                </td>
                <td>
                  <el-input class="text-right" v-model="item.quantity" type="number"></el-input>
                </td>
                <td>
                  <el-input :readonly="item.item_id" v-model="item.uom"></el-input>
                </td>
                <td>
                  <el-button @click="delProduct(index)"
                    type="danger" title="删除" icon="el-icon-delete"></el-button>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="save">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true" :disabled="waiting"></i> 确 定</el-button>
        <!-- <el-button v-if="editModel.id" type="danger" @click="delIt(editModel.id)" class="pull-left" :disabled="waiting">
          <i class="fa fa-fw fa-trash" aria-hidden="true"></i> 删 除</el-button> -->

      </div>
    </el-dialog>

    <el-dialog  :visible.sync="stockDialog" title="项目库存"
      :close-on-click-modal="true" width="80%">
      <StockList :list="stocks">
      </StockList>
      <div slot="footer" class="dialog-footer">
        <el-button @click="stockDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="selStocks" icon="fa fa-fw fa-floppy-o"> 完成</el-button>
      </div>
    </el-dialog>

    <!-- 详情dialog -->
    <el-dialog :visible.sync="showDialog" width="90%" title="完工入库详情"
      :close-on-click-modal="false">
      <el-form :model="showModel" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="8">
            <el-form-item label="入库编号：">{{showModel.code}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态：">{{showModel.status_text}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库日期：">{{showModel.transaction_date}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目号：">{{showModel.project_number}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="加工合同号：">{{showModel.mo_order_number}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="业务部门：">{{showModel.owner_group_name}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库组织：">{{showModel.organization_name}}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="加工厂：">{{showModel.party_name}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库：">{{showModel.subinventory_name}}</el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <template slot="label">
            投料
            <!-- <el-tooltip content="数量前面的参数相同会归为相同产品" placement="top" effect="light">
              <i class="el-icon-warning text-warning"></i>
            </el-tooltip> -->
          </template>
          <table class="items bordered table" style="width: 100%;">
            <thead>
              <tr>
                <th class="text-center">品名</th>
                <th class="text-center">款号</th>
                <th class="text-center">规格</th>
                <th class="text-center">颜色/其他</th>
                <th class="text-center" width="110">数量</th>
                <th class="text-center" width="70">单位</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in showModel.meterial" :key="index" :title="item.remark">
                <td>{{item.item_name}}</td>
                <td>
                  {{item.attr1}}
                </td>
                <td>
                  {{item.attr2}}
                </td>
                <td>
                  {{item.color}}
                </td>
                <td>
                  {{item.quantity}}
                </td>
                <td>{{item.uom}}</td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
        <el-form-item label="加工成品">
          <table class="items bordered table" style="width: 100%;">
            <thead>
              <tr>
                <th class="text-center">品名</th>
                <th class="text-center">款号</th>
                <th class="text-center">规格</th>
                <th class="text-center">颜色/其他</th>
                <th class="text-center" width="110">数量</th>
                <th class="text-center" width="70">单位</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in showModel.product" :key="index" :title="item.remark">
                <td>{{item.item_name}}</td>
                <td>
                  {{item.attr1}}
                </td>
                <td>
                  {{item.attr2}}
                </td>
                <td>
                  {{item.color}}
                </td>
                <td>
                  {{item.quantity}}
                </td>
                <td>{{item.uom}}</td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="info" :disabled="waiting" @click="apply(showModel)" v-if="showModel.status==='ENTERED'">提交</el-button>
        <el-button type="info" :disabled="waiting" @click="approve(showModel)" v-if="showModel.status==='PROCESSING'">审核通过</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import StockList from './parts/StockList';

export default {
  components: {
    StockList,
  },
  data() {
    return {
      waiting: false,
      loading: false,
      // 查询条件form
      queryForm: {
        party_id: '',
        q: '',
        code: '',
      },
      queryParams: {}, // 分页查询参数
      parties: [], // select party options array
      parties2: [], // edit时候的选项
      inventories: [], // 仓库options
      queryPartiesLoading: false, // query-remote parties loading flag
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      projects: [], // 新增下拉选择的options
      mos: [],
      queryMosLoading: false,
      showModel: {},
      showDialog: false,
      detailDialog: false,
      rules: {
        finish_at: [{ required: true, message: '选择项目', trigger: ['change', 'blur'] }],
        project_id: [{ required: true, message: '选择项目', trigger: ['change', 'blur'] }],
        party_id: [{ required: true, message: '选择公司', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      stockDialog: false,
      stockMap: {},
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      curCompanyType: state => state.auth.curCompanyType,
      curDeptId: state => state.auth.curDeptId,
      userMeta: state => state.auth.userMeta,
      organizations: state => state.common.organizations,
    }),
    ...mapGetters([
      'deptMap',
    ]),
    userDeptId() {
      let result = '';
      if (String(this.curCompanyType) === '2') {
        result = this.curDeptId;
      } else {
        result = this.user.department_id;
      }
      return result;
    },
    // 工厂库
    isParty() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('dv_party') !== -1) {
        result = true;
      }
      return result;
    },
    // 公司部门用户
    isDeliveryManager() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('delivery_order_manager') !== -1) {
        result = true;
      }
      return result;
    },
    isAdmin() {
      return this.user.type === 'admin';
    },
    stocks() {
      const result = [];
      const keys = Object.keys(this.stockMap);
      keys.forEach((key) => {
        result.push(this.stockMap[key]);
      });
      return result;
    },
  },
  beforeCreate() {
    if (this.$store.state.common.depts.length === 0) {
      this.$store.dispatch('GET_DEPTS');
    }
    if (this.$store.state.common.organizations.length === 0) {
      this.$store.dispatch('GET_ORGANIZATION');
    }
  },
  created() {
    this.onSubmitQuery();
  },
  methods: {
    // 查询企业
    queryParties(q) {
      const params = {
        limit: 20,
        q: {
          status_code: 'APPROVED',
        },
      };
      if (typeof q === 'string' && q.trim() !== '') {
        const name = q.trim();
        params.q.party_name = { $like: `%${name}%` };
      } else {
        return;
      }
      this.queryPartiesLoading = true;
      this.$http.get('erp-parties/', { params }).then((resp) => {
        const list = resp.data.items;
        this.queryPartiesLoading = false;
        this.parties = list;
      }, (err) => {
        this.queryPartiesLoading = false;
        this.$utils.resp(err);
      });
    },
    queryParties2(q) {
      if (typeof q === 'number') {
        this.queryPartiesLoading = true;
        this.$http.get('erp-party/' + q).then((resp) => {
          const partyObj = resp.data;
          this.editModel.party_id = partyObj.party_id;
          this.editModel.party_name = partyObj.party_name;
          this.queryPartiesLoading = false;
          this.parties2 = [partyObj];
        }, (err) => {
          this.queryPartiesLoading = false;
          this.$utils.resp(err);
        });
      } else {
        const params = {
          limit: 20,
          q: {
            status_code: 'APPROVED',
          },
        };
        if (typeof q === 'string' && q.trim() !== '') {
          const name = q.trim();
          params.q.party_name = { $like: `%${name}%` };
        } else {
          return;
        }
        this.queryPartiesLoading = true;
        this.$http.get('erp-parties/', { params }).then((resp) => {
          const list = resp.data.items;
          this.queryPartiesLoading = false;
          this.parties2 = list;
        }, (err) => {
          this.queryPartiesLoading = false;
          this.$utils.resp(err);
        });
      }
    },
    // 查询
    onSubmitQuery() {
      if (!this.user.id) {
        return;
      }
      const params = {};
      if (this.queryForm.party_id) {
        params.party_id = this.queryForm.party_id;
      }
      if (this.queryForm.dept_id) {
        params.dept_id = this.queryForm.dept_id;
      }
      if (this.queryForm.code.trim()) {
        params.code = this.queryForm.code.trim();
      }
      if (this.queryForm.q.trim()) {
        params.q = this.queryForm.q.trim();
      }
      this.queryParams = params;
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      const params = this.queryParams;
      params.pageSize = this.pageSize;
      params.page = this.currentPage;
      this.loading = true;
      this.$http.get('product', { params }).then((resp) => {
        this.loading = false;
        const list = resp.data.data;
        this.list = list;
        this.totalItem = resp.data.total;
      }, (err) => {
        this.loading = false;
        this.$utils.resp(err);
      });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toAdd() {
      if (this.projects.length === 0) {
        this.getProjects('');
      }
      this.editModel = {
        transaction_date: this.$utils.FormatDate(),
        to_project_id: '',
        project_number: '',
        source_header_id: '',
        mo_order_number: '',
        party_id: '',
        party_name: '',
        owner_group_id: this.userDeptId,
        owner_group_name: '',
        to_subinventory_code: '',
        description: '',
        product: [],
        meterial: [],
      };
      if (this.deptMap && this.userDeptId) {
        this.editModel.owner_group_name = this.deptMap[this.userDeptId].name;
        const depts = this.editModel.owner_group_name.split('.');
        if (depts.length > 1) {
          const name = depts[1].substr(0, 2);
          const org = this.organizations.filter(item => item.organization_name.indexOf(name) > -1);
          if (org.length > 0) {
            const temp = org[0];
            this.editModel.organization_id = temp.organization_id;
            this.editModel.organization_name = temp.organization_name;
          }
        }
      }
      this.editDialog = true;
      if (this.$refs.editForm) {
        this.$nextTick(() => {
          this.$refs.editForm.clearValidate();
        });
      }
    },
    // 项目查询方法
    getProjects(qStr) {
      const params = {
        limit: 20,
        q: {
          status_code: 'APPROVED',
          // project_number: { $like: `%${qStr}%` },
        },
      };
      if (typeof qStr === 'number') {
        params.q.project_id = qStr;
      } else if (qStr && qStr.trim() !== '') {
        params.q.project_number = { $like: `%${qStr}%` };
      } else {
        const dateStr = this.$utils.FormatDate(new Date(new Date().getTime() - 100 * 86400000), 'yyyy-MM-ddT00:00:00Z');
        params.q.creation_date = { $gt: { $date: dateStr } };
      }
      return this.$http.get('erp-project', { params }).then((resp) => {
        const list = resp.data.items;
        list.forEach((item) => {
          item.cur = false;
        });
        this.projects = list;
        return list;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // project choose
    chooseProject(id, isInit = false) {
      const projects = this.projects.filter(item => item.project_id === id);
      if (projects.length === 1) {
        const project = projects[0];
        const projectNum = project.project_number;
        this.editModel.project_number = projectNum;
        const params = {
          q: {
            // order_number: { $like: '%15GH%' },
            project_id: project.project_id,
            status_code: 'APPROVED',
            // project_number: projectNum,
          },
        };
        if (!isInit) {
          this.editModel.source_header_id = '';
          this.mos = [];
        }
        this.queryMosLoading = true;
        this.$http.get('erp-mo', { params }).then((resp) => {
          this.queryMosLoading = false;
          const list = resp.data.items;
          this.mos = list;
        }, (err) => {
          this.queryMosLoading = false;
          this.$utils.resp(err);
        });
      }
    },
    // 选中加工合同
    chooseMo(id) {
      if (!id) {
        this.editModel.product = [];
        return;
      }
      const arr = this.mos.filter(item => item.mo_header_id === id);
      if (arr.length === 1) {
        const moObj = arr[0];
        this.editModel.mo_order_number = moObj.mo_order_number;
        this.queryParties2(moObj.vendor_id);
        this.editModel.party_id = moObj.vendor_id;
        this.editModel.party_name = moObj.vendor_name;
        this.getInventories();
        // 查询合同明细
        const params = {
          // mo_number: moObj.order_number,
          q: {
            mo_header_id: id,
          },
        };
        this.$http.get(`erp-mo/${id}`, { params }).then((resp) => {
          const lines = resp.data;
          this.addMoLines(lines, moObj);
        });
      }
    },
    // 查询仓库
    getInventories() {
      if (!this.editModel.party_id) {
        return;
      }
      const params = {
        party_id: this.editModel.party_id,
        organization_id: this.editModel.organization_id,
      };
      this.$http.get('inventory', { params }).then((resp) => {
        this.inventories = resp.data;
        if (this.inventories.length > 0) {
          this.editModel.to_subinventory_code = this.inventories[0].secondary_inventory_name;
        } else {
          this.editModel.to_subinventory_code = '';
        }
      });
    },
    addMoLines(lines, moObj) {
      this.editModel.product = [];
      const arr = [];
      lines.forEach((item) => {
        const obj = {
          ...item,
          obj_id: item.po_line_id,
          mo_number: moObj.order_number,
          item_id: item.item_id,
          item_name: item.item_name,
          attr1: item.item_attribute1,
          attr2: item.item_attribute2,
          uom: item.uom_code,
          color: '',
        };
        arr.push(obj);
      });
      this.editModel.product = arr;
    },
    // addProduct() {
    //   const obj = {
    //     item_name: '',
    //     attr1: '',
    //     attr2: '',
    //     unit_price: '',
    //     unit_vat_price: '',
    //     uom: '',
    //     quantity: 0,
    //     color: '',
    //   };
    //   this.editModel.product.push(obj);
    // },
    addMeterial() {
      this.getStocks();
    },
    delMeterial(index) {
      this.editModel.meterial.splice(index, 1);
    },
    delProduct(index) {
      this.editModel.product.splice(index, 1);
    },
    getStocks() {
      if (!this.editModel.to_project_id) {
        this.$message({
          type: 'warning',
          message: '请先选择项目',
          showClose: true,
          duration: 2000,
        });
        return;
      }
      if (!this.editModel.to_subinventory_code) {
        this.$message({
          type: 'warning',
          message: '请选择仓库',
          showClose: true,
          duration: 2000,
        });
        return;
      }
      if (this.editModel.to_project_id && this.editModel.to_subinventory_code) {
        const params = {
          project_id: this.editModel.to_project_id,
          subinventory_code: this.editModel.to_subinventory_code,
        };
        this.$http.get('stocklist', { params }).then((resp) => {
          const list = resp.data;
          const map = {};
          list.forEach((item) => {
            if (map[item.item_md5]) {
              map[item.item_md5].left_quantity += item.left_quantity * 1;
            } else {
              map[item.item_md5] = item;
              map[item.item_md5].left_quantity = item.left_quantity * 1;
              map[item.item_md5].sel = false;
            }
          });
          this.stockMap = map;
          this.stockDialog = true;
        });
      }
    },
    selStocks() {
      this.editModel.meterial = [];
      const lines = this.stocks.filter(item => item.sel);
      const list = [];
      lines.forEach((item) => {
        const obj = { ...item, quantity: item.left_quantity };
        list.push(obj);
      });
      this.editModel.meterial = list;
      this.stockDialog = false;
    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.editModel };
          const projects = this.projects.filter(item => item.project_id === obj.to_project_id);
          if (projects.length === 1) {
            const project = projects[0];
            const projectNum = project.project_number;
            obj.project_number = projectNum;
          } else {
            this.$message({
              type: 'warning',
              message: '没有对应项目',
              showClose: true,
              duration: 2000,
            });
            return;
          }
          const company = this.parties2.filter(item => item.party_id === obj.party_id);
          if (company.length === 1) {
            obj.party_name = company[0].party_name;
          } else {
            this.$message({
              type: 'warning',
              message: '没有对应企业',
              showClose: true,
              duration: 2000,
            });
            return;
          }
          const subinventory = this.inventories.filter(item => item.secondary_inventory_name === obj.to_subinventory_code);
          if (subinventory.length === 1) {
            obj.subinventory_name = subinventory[0].description;
          } else {
            this.$message({
              type: 'warning',
              message: '没有对应的仓库',
              showClose: true,
              duration: 2000,
            });
            return;
          }
          const mo = this.mos.filter(item => item.mo_header_id === obj.source_header_id);
          if (mo.length === 1) {
            obj.mo_order_number = mo[0].order_number;
          }
          if (obj.product.length <= 0) {
            this.$message({
              type: 'warning',
              message: '至少需要有一条数据',
              showClose: true,
              duration: 2000,
            });
            return;
          }
          let flag = true;
          obj.product.forEach((item) => {
            if (item.item_name.trim() === '' || item.quantity <= 0) {
              flag = false;
            }
          });
          if (!flag) {
            this.$message({
              type: 'warning',
              message: '产品名称不能为空，数量也不能为0',
              showClose: true,
              duration: 2000,
            });
            return;
          }
          this.waiting = true;
          if (obj.id) {
            this.$http.put(`product/${obj.id}`, obj).then(() => {
              this.editDialog = false;
              this.changePage(1);
              this.$message({
                type: 'success',
                message: '更新成功',
                showClose: true,
                duration: 2000,
              });
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          } else {
            this.$http.post('product', obj).then(() => {
              this.editDialog = false;
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
              this.changePage(1);
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
    toDetail(item) {
      this.showModel = item;
      this.showDialog = true;
    },
    apply(item) {
      this.$confirm('确认提交？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.put(`product/${item.id}/submit`, {}).then(() => {
          this.getList();
          this.waiting = false;
          this.detailDialog = false;
          this.showDialog = false;
          this.$message({
            type: 'success',
            message: '提交成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    approve(item) {
      this.$confirm('确认审批？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.put(`product/${item.id}/approve`, {}).then(() => {
          this.getList();
          this.waiting = false;
          this.detailDialog = false;
          this.showDialog = false;
          this.$message({
            type: 'success',
            message: '审批成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
  },
};
</script>
