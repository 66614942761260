<template>
  <div style=" margin: 0 auto;padding: 20px 20px;font-family: 宋体;color: #333;">
    <div class="text-center" style="font-size: 28px;letter-spacing: .3em;">江苏国泰国华实业有限公司</div>
    <div class="text-center" style="font-size: 16px;">张家港市港城大道国泰金融广场A座 15-30楼</div>

    <div class="text-center" style="color: #222;padding: 20px 0 10px;font-size: 20px;">国内发货通知单</div>

    <div style="margin-right: 0;">
      <div class="text-right">
        <div class="bh-div text-left pos-rel" style="width: 240px;display: inline-block;">
          <span class="bbl" style="left: 45px; width: 170px;"></span>
          日期：
        </div>
      </div>
      <div class="text-right" style="margin-top: 10px;">
        <div class="bh-div text-left" style="width: 240px;display: inline-block;">编号：
          <span style="font-weight: 700;">{{obj.code}}</span>
        </div>
      </div>
    </div>
    <div style="font-size: 18px; margin-top: 20px;">
      兹通知发货方：{{obj.party_name}}
    </div>

    <div style="margin-top: 30px;">
      <table class="table bordered" style="border: 1px solid #333;">
        <thead>
          <tr>
            <th class="text-center" style="padding: 2px; border: 1px solid #333;">品种/规格</th>
            <th class="text-center" style="padding: 2px; border: 1px solid #333;">颜色</th>
            <th class="text-center" style="padding: 2px; border: 1px solid #333;">数量</th>
            <th class="text-center" width="100" style="padding: 2px; border: 1px solid #333;">
              实发数量
              <div style="font-size: 12px;font-weight: 300;line-height: 12px;">（发货方填写）</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in obj.items" :key="index">
            <td class="text-center" style="border: 1px solid #333;">{{item.item_name}}</td>
            <td class="text-center" style="border: 1px solid #333;">{{item.color}}</td>
            <td class="text-center" style="border: 1px solid #333;">{{item.quantity}}</td>
            <td class="text-center" style="border: 1px solid #333;"></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="font-size: 18px; padding-top: 50px;">
      收货单位或物流单位：{{obj.to_name}}
    </div>
    <div class="clearfix">
      <vue-qr class="pull-left qr-box" :logoSrc="logoUrl" logoBackgroundColor="#fff" :logoMargin="5"
        :correctLevel="3" :text="obj.code|qrStr" :margin="6" :dotScale="1"></vue-qr>
      <div style="font-size: 18px; margin-top: 30px;">
        <div style="padding-right: 0;" class="text-right">
          <span class="text-left pos-rel" style="display:inline-block; width: 180px;">
            <span class="bbl" style="left: 70px;"></span>
            业务员：
          </span>
          <span class="text-left pos-rel" style="display:inline-block; width: 220px;">
            <span class="bbl" style="left: 100px;"></span>
            分公司经理：
          </span>
        </div>
        <div style="margin-top: 20px;"  class="text-right">
          <span class="text-left pos-rel" style="display:inline-block; width: 400px;">
            <span class="bbl" style="left: 140px; width: 130px;"></span>
            分公司财务人员：
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr';

export default {
  components: {
    VueQr,
  },
  data() {
    return {
      logoUrl: window.baseUrl + 'favicon.ico',
      obj: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http.get(`dvbill/${this.$route.params.id}`).then((resp) => {
        this.obj = resp.data;
      });
    },
  },
  filters: {
    qrStr(code) {
      const baseUrl = window.location.root;
      return `${baseUrl}/#/dv-info/${code}`;
    },
  },
};
</script>

<style scoped lang="scss">
.qr-box {
  position: absolute;
  top: 50px;
  left: -20px;
  transform: scale(.6);
}
.bbl {
  position: absolute;
  bottom: 0px;
  width: 100px;
  border-bottom: 1px solid #222;
}
</style>
