import Vuex from 'vuex';
import Vue from 'vue';
import auth from './modules/auth';
import common from './modules/common';
import dict from './modules/dict';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  strict: debug,
  state: { test: true },
  modules: {
    auth,
    common,
    dict,
  },
});
