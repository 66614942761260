<template>
  <el-card>
    <el-form :model="model">
      <el-form-item label="设备">
        {{model.name}}
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      model: { name: 'test', status: 'temp' },
    };
  },
  created() {
    const { code } = this.$route.query;
    if (code) {
      this.$http.get('equipment/info', { params: { code } }).then((resp) => {
        this.model = resp.data;
      }, (resp) => {
        this.$utils.resp(resp);
      });
    }
  },
  methods: {
    getEquipmentTypes() {
      this.$http.get('equipment-type').then((resp) => {
        this.types = resp.data;
      }, (resp) => {
        this.$utils.resp(resp);
      });
    },
  },
};
</script>

<style>

</style>
