<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新建</el-button>
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.q" clearable placeholder="输入关键字检索" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status" clearable placeholder="选择" @change="onSubmitQuery">
          <el-option label="全部" value=""></el-option>
          <el-option v-for="(val, key) in statuses" :label="val" :key="key" :value="key"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>款号</th>
          <!-- <th>部门</th> -->
          <th width="200">说明</th>
          <th width="80">创建时间</th>
          <th>状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.OrderNo }}</td>
          <!-- <td>{{ item.DeptInfo }}</td> -->
          <td>{{ item.Remark }}</td>
          <td>{{ item.CreateDate }}</td>
          <td><span :class="[item.OrderStatus === 'A' ? 'text-primary' : '']">{{ statuses[item.OrderStatus] }}</span></td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" type="primary" title="修改信息" @click="toEdit(item)">
                <i class="fa fa-fw fa-pencil"></i></el-button>
              <el-button style="margin-left: 3px;" size="small" v-if="item.OrderStatus === 'A'" type="warning" title="完结项目"
                @click="finish(item)">
                <i class="fa fa-fw fa-check"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.Id ? '编辑' : '新增'" :close-on-click-modal="false">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="款号" prop="OrderNo">
          <el-input v-model="editModel.OrderNo" placeholder=""></el-input>
        </el-form-item>
        <!-- <el-form-item label="部门" prop="DeptInfo">
          <el-input v-model="editModel.DeptInfo" placeholder=""></el-input>
        </el-form-item> -->
        <el-form-item label="备注说明" prop="Remark">
          <el-input type="textarea" v-model="editModel.Remark"></el-input>
        </el-form-item>
        <el-form-item label="二维码" v-if="editModel.Id">
          <span style="cursor:pointer" @click="toQrPage(editModel.OrderNo)">显示二维码</span>
          <el-button @click="downloadQr(editModel)" type="primary" icon="fa fa-download"></el-button>
          <vue-qr  ref="qrImg" v-show="false" logoSrc="/asset/favicon.ico" logoBackgroundColor="#fff" :logoMargin="5"
            :correctLevel="3" :text="editModel.OrderNo|orderQr" :margin="6" :dotScale="1"></vue-qr>
        </el-form-item>
      </el-form>
      <div v-if="editModel.Id">
        <file-upload ref="addFile" accept="image/gif, image/jpeg, image/png"
          :multiple="false" @change="changeFile" :showPreview="false" :showFileHint="false">
        </file-upload>
        <div class="clearfix">
          <div class="pic-item" v-for="(item, index) in rootPics" :key="index">
            <img :src="`/gh-pic/${item.PicName}`" @click="showBigImg(item)" alt="">
            <i class="fa fa-trash" @click="delImg(item)"></i>
          </div>
        </div>
        <!-- <div v-if="files.length > 0">
          <el-form :model="fileData" ref="fileForm">
            <el-form-itme label="说明" prop="Remark">
              <el-input v-model="fileData.Remark"></el-input>
            </el-form-itme>
          </el-form>
        </div> -->
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="save" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 确 定</el-button>
        <!-- <el-button v-if="editModel.Id" type="danger" @click="delIt" class="pull-left" :disabled="waiting">
          <i class="fa fa-fw fa-trash" aria-hidden="true"></i> 删 除</el-button> -->

      </div>
    </el-dialog>

    <el-dialog fullscreen :visible.sync="showBigImgVisible" custom-class="show-big-img-dialog">
      <div class="show-big-img-h">
        <div class="to-show-pic" @click="closeRightMenu">
          <div class="show-img pos-rel">
            <img @contextmenu.prevent="(event) => { showRightMenu(event) }" :src="bigImgSrc" v-err-src="`${baseUrl}404.png`"/>
            <div class="point point-temp" :style="{left: tempPoint.x, top: tempPoint.y}" v-if="rightMenuShow && isAddSubImg">
              <div class="dot"></div>
            </div>
            <div class="point" v-for="(item) in subPics"
              @click="showSubImg(item)" @contextmenu.prevent="(event) => { showRightMenu(event, item) }"
              :key="item.Id" :style="{left: item.PXaxis + '%', top: item.PYaxis + '%'}">
              <div class="dot"></div>
            </div>
          </div>
          <!-- 表格右键菜单 -->
          <div v-show="rightMenuShow" :style="{'left':menuPositionLeft+'px','top':menuPositionTop+'px'}" class="trm-style">
            <file-upload ref="addSubFile" accept="image/gif, image/jpeg, image/png" v-if="isAddSubImg"
              :multiple="false" @change="changeSubFile" :showPreview="false" :showFileHint="false">
            </file-upload>
            <el-button v-else @click="delImg(subPic)" type="danger">删 除</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="subImgShow" width="90%">
      <div class="text-center" style="width: 100%">
        <img style="max-width: 900px;" @contextmenu.prevent="(event) => { showRightMenu(event) }" :src="`/gh-pic/${subPic.PicName}`"
          v-err-src="`${baseUrl}404.png`"/>
      </div>
    </el-dialog>

    <a ref="downloadLink" :href="downloadUrl" :download="downloadFileName" v-show="false"></a>
  </el-card>
</template>

<script>
import { mapState } from 'vuex';
import VueQr from 'vue-qr';
import FileUpload from '@/components/FileUpload';

export default {
  name: 'wb-project',
  components: {
    VueQr,
    FileUpload,
  },
  data() {
    return {
      // 二维码
      downloadLink: '',
      downloadUrl: '',
      downloadFileName: '',
      baseUrl: window.baseUrl,
      // 大图
      showBigImgVisible: false,
      bigImgSrc: '',
      curRootPic: {},
      tempPoint: { x: 0, y: 0 }, // 右键鼠标的临时点位
      isAddSubImg: false, // 右键是删除还是新增判断
      rightMenuShow: false, // 右键显示
      menuPositionLeft: 0, // 右键菜单坐标
      menuPositionTop: 0,
      subImgShow: false,
      subPic: {},
      loading: false,
      waiting: false,
      files: [],
      // 查询条件form
      queryForm: {
        q: '',
        status: 'A',
      },
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      rules: {
        OrderNo: [{ required: true, message: '请填写款号', trigger: ['change', 'blur'] }],
        DeptInfo: [{ required: true, message: '请填写部门', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      fileData: {
        remark: '',
      },
      allPics: [],
      // branches: [],
      statuses: { A: '进行中', B: '已完成' },
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    userLoginName() {
      return this.user.login_name ? this.user.login_name : '';
    },
    isDeliveryManager() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('delivery_manager') !== -1) {
        result = true;
      }
      return result;
    },
    rootPics() {
      let result = [];
      if (this.allPics && this.allPics.length > 0) {
        result = this.allPics.filter(item => !item.FId);
      }
      return result;
    },
    subPics() {
      let result = [];
      if (this.curRootPic && this.curRootPic.Id) {
        result = this.allPics.filter(item => item.FId === this.curRootPic.Id);
      }
      return result;
    },
  },
  mounted() {
    this.onSubmitQuery();
  },
  watch: {
    user(val) {
      if (val.id) {
        this.onSubmitQuery();
      }
    },
  },
  methods: {
    // 查询
    onSubmitQuery() {
      if (!this.userLoginName) {
        return;
      }
      const params = {
        orderno: '',
        orderstatus: '',
        username: this.userLoginName,
      };
      if (this.queryForm.q) {
        params.orderno = this.queryForm.q;
      }
      if (this.queryForm.status) {
        params.orderstatus = this.queryForm.status;
      }
      this.queryParams = params;
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$http.get('Home/GetOrderList', {
        params: { ...this.queryParams, pageindex: this.currentPage, pagesize: this.pageSize },
        baseURL: '/dimg-api',
      }).then((resp) => {
        const list = resp.data.result.dataList || [];
        list.forEach((item) => {
          item.CreateDate = this.$utils.formatDate(new Date(item.CreateDate.replace(/\D*/g, '') * 1));
        });
        this.loading = false;
        this.list = list;
        this.totalItem = resp.data.result.totalCount;
      }, (err) => {
        this.loading = false;
        this.$utils.resp(err);
      });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.editModel = {
        OrderNo: '',
        Detail: '',
        // CreateDate: this.$utils.FormatDate(),
        CreateName: '',
        DeptInfo: '',
        Remark: '',
      };
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 打开编辑
    toEdit(editObj) {
      const tempObj = { ...editObj };
      this.editModel = tempObj;
      this.fiels = [];
      this.editDialog = true;
      this.getPics(tempObj.Id);
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
        if (this.$refs.addFile) {
          this.$refs.addFile.reset();
        }
      });
    },
    getPics(OrderId) {
      const params = { OrderId };
      this.$http.get('Home/GetPicInfoList', { params, baseURL: '/dimg-api' }).then((res) => {
        const list = res.data.result;
        list.forEach((item) => {
          if (item.FId) {
            item.PXaxis /= 100;
            item.PYaxis /= 100;
          }
        });
        this.allPics = list;
      });
    },
    // 打开
    showBigImg(item) {
      this.curRootPic = item;
      this.bigImgSrc = `/gh-pic/${item.PicName}`;
      this.showBigImgVisible = true;
    },
    // 上传文件
    changeFile(files) {
      if (this.$refs.addFile.sizeWarning) {
        // this.$utils.msg('附件过大无法上传，允许上传最大附件为5M', 'warning');
        this.$message.warning('附件过大无法上传，允许上传最大附件为5M');
        return;
      }
      if (files.length > 0) {
        this.files = [];
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
        this.fileData.remark = '';
        if (this.$refs.addFile.identifyBorwser() && this.files.length === 1) {
          const file = this.files[0];
          const formData = new window.FormData();
          formData.append('file', file);
          formData.append('OrderId', this.editModel.Id);
          formData.append('FId', 0);
          formData.append('CreateName', this.userLoginName);
          formData.append('PXaxis', 0);
          formData.append('PYaxis', 0);
          this.$http.post('Home/SavePicInfo', formData, { baseURL: '/dimg-api' }).then(() => {
            this.$message.success('附件上传成功');
            this.$refs.addFile.reset();
            this.getPics(this.editModel.Id);
          }, (err) => {
            this.$mp.resp(err);
          });
        }
      } else {
        this.files = [];
      }
    },
    changeSubFile(files) {
      if (this.$refs.addSubFile.sizeWarning) {
        // this.$utils.msg('附件过大无法上传，允许上传最大附件为5M', 'warning');
        this.$message.warning('附件过大无法上传，允许上传最大附件为5M');
        return;
      }
      if (files.length > 0) {
        this.files = [];
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
        this.fileData.remark = '';
        if (this.$refs.addSubFile.identifyBorwser() && this.files.length === 1) {
          const file = this.files[0];
          const formData = new window.FormData();
          formData.append('file', file);
          formData.append('OrderId', this.editModel.Id);
          formData.append('FId', this.curRootPic.Id);
          formData.append('CreateName', this.userLoginName);
          formData.append('PXaxis', this.tempPoint.percentX);
          formData.append('PYaxis', this.tempPoint.percentY);
          this.$http.post('Home/SavePicInfo', formData, { baseURL: '/dimg-api' }).then(() => {
            this.$message.success('上传成功');
            this.resetSubFile();
            this.getPics(this.editModel.Id);
          }, (err) => {
            this.$mp.resp(err);
          });
        }
      } else {
        this.files = [];
      }
    },
    // 右键菜单
    showRightMenu(e, subImg = false) {
      this.isAddSubImg = !subImg;
      if (this.isAddSubImg) {
        const box = e.target.parentNode;
        const org = {
          x: box.offsetLeft,
          y: box.offsetTop - (box.clientHeight / 2),
        };
        const perx = Math.round((e.pageX - org.x) * 10000 / box.clientWidth);
        const pery = Math.round((e.pageY - org.y) * 10000 / box.clientHeight);
        const relPos = {
          x: (e.pageX - org.x) + 'px',
          y: (e.pageY - org.y) + 'px',
          percentX: perx,
          percentY: pery,
        };
        this.tempPoint = relPos;
      } else {
        this.subPic = subImg;
      }
      this.menuPositionLeft = e.pageX;
      this.menuPositionTop = e.pageY;
      this.rightMenuShow = true;
    },
    closeRightMenu() {
      this.rightMenuShow = false;
    },
    showSubImg(subPic) {
      this.subPic = subPic;
      this.subImgShow = true;
    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.editModel };
          this.waiting = true;
          if (obj.Id) {
            if (obj.CreateName !== this.userLoginName) {
              this.$message({
                type: 'warning',
                message: '不是创建人，无法操作',
                showClose: true,
                duration: 2000,
              });
              return;
            }
            this.$http.post('Home/EditOrderInfo', obj, { baseURL: '/dimg-api' }).then(() => {
              this.editDialog = false;
              this.getList();
              this.$message({
                type: 'success',
                message: '更新成功',
                showClose: true,
                duration: 2000,
              });
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          } else {
            obj.CreateName = this.userLoginName;
            this.$http.post('Home/CreateOrderInfo', obj, { baseURL: '/dimg-api' }).then(() => {
              this.editDialog = false;
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
              this.getList();
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
    finish(item) {
      this.$confirm('是否确认完成？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const obj = { ...item, OrderStatus: 'B' };
        if (obj.CreateName !== this.userLoginName) {
          this.$message({
            type: 'warning',
            message: '不是创建人，无法操作',
            showClose: true,
            duration: 2000,
          });
          return;
        }
        this.$http.post('Home/EditOrderInfo', obj, { baseURL: '/dimg-api' }).then(() => {
          this.editDialog = false;
          this.getList();
          this.$message({
            type: 'success',
            message: '更新成功',
            showClose: true,
            duration: 2000,
          });
        }, (err) => {
          this.$utils.resp(err);
        }).then(() => {
          item.OrderStatus = 'B';
          this.waiting = false;
        });
      }, () => {});
    },
    delImg(item) {
      this.$http.get('Home/DeletePic', { params: { picId: item.Id }, baseURL: '/dimg-api' }).then(() => {
        this.getPics(this.editModel.Id);
      });
    },
    delIt() {
      // this.$confirm('删除后无法恢复，是否确认删除？', '确认信息', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(() => {
      //   this.waiting = true;
      //   this.$http.delete(`dely-project/${this.editModel.Id}`).then(() => {
      //     this.getList();
      //     this.editDialog = false;
      //     this.waiting = false;
      //     this.$message({
      //       type: 'success',
      //       message: '删除成功',
      //       showClose: true,
      //       duration: 2000,
      //     });
      //   }, (resp) => {
      //     this.waiting = false;
      //     this.$utils.resp(resp);
      //   });
      // }, () => {});
    },
    resetSubFile() {
      if (this.$refs.addSubFile) {
        this.$refs.addSubFile.reset();
      }
    },
    downloadQr(item) {
      this.downloadFileName = item.OrderNo;
      this.downloadUrl = this.$refs.qrImg.$el.src;
      this.$nextTick(() => {
        this.$refs.downloadLink.click();
      });
    },
    toQrPage(code) {
      const route = this.$router.resolve({ name: 'DimgQr', query: { code } });
      window.open(route.href, '_blank');
    },
  },
  filters: {
    orderQr(code) {
      return `https://www.guotaigh.com.cn/asset/#/dimg-show?code=${code}`;
    },
  },
};
</script>

<style lang="scss">
  .pic-item {
    position: relative;
    float: left;
    margin-right: 10px;
    img {
      cursor: pointer;
      width: 80px;
      height: 100px;
    }
    i.fa {
      display: none;
      position: absolute;
      right: 2px;
      top: 2px;
      color: rgb(233, 182, 15);
    }
    &:hover i.fa {
      cursor: pointer;
      display: block;
      &:hover {
        color: #f00;
      }
    }
  }
  .el-dialog.show-big-img-dialog {
    background-color: rgba(0,0,0,0.9);
  }
  .el-dialog.show-big-img-dialog .el-dialog__header {
    padding: 0 10px;
    height: 0;
  }
  .el-dialog.show-big-img-dialog .el-dialog__body {
    padding: 0;
    height: 100%;
  }
  .el-dialog.show-big-img-dialog .el-dialog__body .show-big-img-h {
    height: 100%;
    background: rgba($color: #888, $alpha: .3);
    // background-image: url(~assets/imgs/loadingbg.gif);
    // background-position: center center;
    // background-repeat: no-repeat;
  }
  .el-dialog.show-big-img-dialog .el-dialog__body .show-big-img-h .to-show-pic {
    height: 100%;
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }
  .el-dialog.show-big-img-dialog .el-dialog__body .show-big-img-h .to-show-pic .show-img {
    padding: 0;
    margin: 0;
    max-width: 90%;
    max-height: 100%;
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
    img {
      display: block;
      max-height: 98vh;
      max-width: 96vw;
    }
  }
  // 右键菜单样式
  .trm-style{
    display: block;
    position: fixed;
    background-color: #fff;
    border: 1px solid #ebeef5;
    color: #606266;
    li{
      padding: 0 20px;
      line-height: 30px;
      font-size: 12px;
      cursor: pointer;
      &:hover{
        background-color: #228df1;
        color: #fff;
        border: 1px slid rgb(8, 8, 177);
      }
    }
  }
  .point {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    cursor: pointer;
    background: rgb(238, 57, 2);
    animation: pani 2s ease-out infinite;
    &.point-temp {
      background: blue;
    }
  }
  @keyframes pani {
    0% {transform: translate(-50%, -50%) scale(1); opacity: 1;}
    60% {transform: translate(-50%, -50%) scale(2); opacity: 2;}
    100% {transform: translate(-50%, -50%) scale(1); opacity: 1;}
  }
</style>
