<template>
  <div>
    <!-- <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button> -->
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-date-picker v-model="queryForm.date"
          align="right"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.kh" clearable placeholder="款号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.category" clearable placeholder="分类选择" filterable>
          <el-option v-for="(label, key) in categories" :key="key" :value="key" :label="`[${key}]${label}`"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.color" clearable placeholder="颜色选择" filterable>
          <el-option v-for="(label, key) in colors" :key="key" :value="key" :label="`[${key}]${label}`"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.size" clearable placeholder="尺码" filterable>
          <el-option v-for="(label, key) in sizes" :key="key" :value="key" :label="`${label}`"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.out_type" placeholder="出库类型" clearable>
          <el-option v-for="(label, key) in outTypes" :key="key" :value="key" :label="`${label}`"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table el-table">
      <thead>
        <tr>
          <th width="40">#</th>
          <th>款号</th>
          <th>类别</th>
          <th>颜色</th>
          <th>尺码</th>
          <th @click="sortCode()" :class="['is-sortable', sortClass]">
            <div class="cell">SKU
              <span class="caret-wrapper">
                <i class="sort-caret ascending"></i>
                <i class="sort-caret descending"></i>
              </span>
            </div>
          </th>
          <th>关联款号</th>
          <th>入库时间</th>
          <th>出库时间</th>
          <th>出库人</th>
          <th>出库类型</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{index + 1}}</td>
          <td>{{ `${item.season}${item.category}${item.style_num}` }}</td>
          <td>{{ item.category_text }}</td>
          <td>{{ item.color_text }}</td>
          <td>{{ item.size_text }}</td>
          <td>{{ item.code }}</td>
          <td>{{ item.rela_style }}</td>
          <td>{{ item.in_at }}</td>
          <td class="text-warning">{{ item.out_at }}</td>
          <td>{{ item.out_creator }}</td>
          <td>{{ item.out_type_text }}</td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" title="修改" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button>
              <!-- <el-button size="small" v-if="item.status != 5" title="出库" type="info" @click="out(item)" icon="fa fa-sign-out"></el-button>
              <el-button size="small" v-if="item.status != 0" title="删除" type="danger" @click="delItem(item)" icon="fa fa-trash"></el-button> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" title="编辑"
      :close-on-click-modal="false" :close="closeEditDialog">
      <el-form :model="moduleForm" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="年分" prop="year">
          {{ moduleForm.year }}
        </el-form-item>
        <el-form-item label="季节" prop="season">
          {{ moduleForm.season_text }}
        </el-form-item>
        <el-form-item label="服装类别" prop="category">
          {{ moduleForm.category_text }}
        </el-form-item>
        <el-form-item label="款式序号" prop="style_num">
          {{ moduleForm.style_num }}
        </el-form-item>
        <el-form-item label="颜色" prop="color">
          {{ moduleForm.color_text }}
        </el-form-item>
        <el-form-item label="尺码" prop="size">
          {{ moduleForm.size_text }}
        </el-form-item>
        <el-form-item label="出库类型" prop="size">
          <el-select v-model="moduleForm.out_type" placeholder="出库类型" clearable>
            <el-option v-for="(label, key) in outTypes" :key="key" :value="key" :label="`${label}`"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="saveModule">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
        <el-button type="warning" icon="fa fa-reply" @click="rollback(moduleForm)" class="pull-left">撤销出库</el-button>
        <!-- <el-button type="danger" icon="fa fa-trash" @click="delItem(moduleForm)" class="pull-left">删 除</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      // 查询条件form
      queryForm: {
        date: this.$utils.formatDate(),
        code: '',
        color: '',
        size: '',
        category: '',
        season: '',
        status: '2',
        out_type: '',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          },
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          },
        }],
      },
      colors: [],
      sizes: [],
      seasons: [],
      statuses: {
        2: '库存',
        5: '已出库',
        0: '已删除',
        3: '入库重置',
        6: '出库重置',
      },
      outTypes: [],
      categories: [],
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      moduleForm: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      // 编辑form的条件
      rules: {
        // year: [{ pattern: /^[0-9]{2,4}$/, required: true, message: '填写年份', trigger: ['change', 'blur'] }],
      },
      // out
      outDialog: false,
      outObj: {},
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      sortClass: '',
      // downloadFileName: '',
      // downloadUrl: '',
      // files: [],
      // showImgUrl: '',
    };
  },
  computed: {
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$store.state.common.clothesDicts.sizes) {
        this.sizes = this.$store.state.common.clothesDicts.sizes;
        this.seasons = this.$store.state.common.clothesDicts.seasons;
        this.categories = this.$store.state.common.clothesDicts.orgCategories;
        this.colors = this.$store.state.common.clothesDicts.orgColors;
        this.outTypes = this.$store.state.common.clothesDicts.sub_types;
      } else {
        this.$store.dispatch('GET_COLTHES_DICTS').then((resp) => {
          this.sizes = resp.data.sizes;
          this.seasons = resp.data.seasons;
          this.categories = resp.data.orgCategories;
          this.colors = resp.data.orgColors;
          this.outTypes = resp.data.sub_types;
        });
      }
    },
    showTab() {
      this.onSubmitQuery();
    },
    onSubmitQuery() {
      this.queryParams = {};
      if (this.queryForm.kh && this.queryForm.kh.trim()) {
        this.queryParams.kh = this.queryForm.kh;
      }
      if (this.queryForm.out_type && this.queryForm.out_type.trim()) {
        this.queryParams.out_type = this.queryForm.out_type;
      }
      if (this.queryForm.date && this.queryForm.date.trim()) {
        this.queryParams.date = this.queryForm.date;
      }
      if (this.queryForm.category && this.queryForm.category.trim()) {
        this.queryParams.category = this.queryForm.category;
      }
      if (this.queryForm.color && this.queryForm.color.trim()) {
        this.queryParams.color = this.queryForm.color;
      }
      if (this.queryForm.size !== null) {
        this.queryParams.size = this.queryForm.size;
      }
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    // 查询列表数据
    getList() {
      this.axios.get('clothes-out-index', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          this.list = list;
          this.totalItem = response.data.total;
        }, (response) => {
          this.$utils.resp(response);
        });
    },
    toEdit(editObj) {
      this.moduleForm = { ...editObj };
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 撤销出库
    rollback(obj) {
      this.$confirm('是否确认撤销出库？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.put(`clothes-out/${obj.id}/rollback`).then(() => {
          this.getList();
          this.editDialog = false;
        });
      }, () => {});
    },
    sortCode() {
      if (this.sortClass === '') {
        this.sortClass = 'ascending';
      } else if (this.sortClass === 'ascending') {
        this.sortClass = 'descending';
      } else if (this.sortClass === 'descending') {
        this.sortClass = '';
      }
      this.onSubmitQuery();
    },
    // 修改出库类型
    saveModule() {
      const obj = this.moduleForm;
      this.$http.put(`clothes-out/${obj.id}/type`, { out_type: obj.out_type }).then(() => {
        this.editDialog = false;
        this.getList();
      }, (err) => {
        obj.otype = obj.out_type;
        this.$utils.resp(err);
      });
    },
    closeEditDialog() {
      // this.canChooseBranch = true;
    },
    delModule() {
      this.$conModule('是否确认删除？', '确认信息', {
        conModuleButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`clothes/${this.moduleForm.id}`).then(() => {
          this.getList();
          this.editDialog = false;
        }, (resp) => {
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    // 显示图片
    showItemImg(item) {
      this.showImg(item.imgs[0]);
    },
    showImg(file) {
      const params = {
        hint: 'clothes',
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.showImgUrl = objurl;
        this.$store.commit('SET_BIG_IMG_URL', objurl);
        this.$store.commit('PICKER_SHOW_SHOW_BIG_IMG');
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    out(item) {
      this.outDialog = true;
      this.outObj = { ...item, out_type: '1' };
    },
    saveOut(item) {
      this.$http.put('clothes-out', { code: item.code, out_type: item.out_type }).then(() => {
        this.getList();
        this.outDialog = false;
      });
    },
    delItem(item) {
      const id = typeof item === 'object' ? item.id : item;
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`clothesstore/${id}`).then(() => {
          this.getList();
        });
      }, () => {});
    },
    // 下载图片
    download(file) {
      const params = {
        hint: 'clothes',
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.downloadFileName = file.orgName;
        this.downloadUrl = objurl;
        this.$nextTick(() => {
          this.$refs.downloadLink.click();
        });
        this.getList();
      }, (err) => {
        this.$utils.resp(err);
      });
    },
  },
  // filters: {
  //   substr4(value) {
  //     return value.substr(0, 4);
  //   },
  // },
};
</script>
