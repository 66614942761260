<template>
  <div class="text-center">
    <div style="display: inline-block;padding-top: 50px;">
      <vue-qr :logoSrc="logoUrl" logoBackgroundColor="#fff" :logoMargin="5"
        :correctLevel="3" :text="orderNo|orderQr" :margin="6" :dotScale="1"></vue-qr>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr';

export default {
  data() {
    return {
      orderNo: '',
      logoUrl: window.baseUrl + 'favicon.ico',
    };
  },
  created() {
    this.orderNo = this.$route.query.code;
  },
  components: {
    VueQr,
  },
  filters: {
    orderQr(code) {
      return `https://www.guotaigh.com.cn/asset/#/dimg-show?code=${code}`;
      // return 'https://www.guotaigh.com.cn/asset/#/dimg-show';
      // return `http://192.168.31.64:2356/#/dimg-show?code=${code}`;
    },
  },
};
</script>
