<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新填报</el-button>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.factory_id" filterable clearable>
          <el-option v-for="item in factories" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.template_id" filterable clearable>
          <el-option v-for="item in templates" :key="item.id" :value="item.id" :label="item.title"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>企业名称</th>
          <th>模板</th>
          <th>更新时间</th>
          <th>状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in firmList" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.factory.name }}</td>
          <td>{{ item.template.title }}</td>
          <td>{{ item.updated_at }}</td>
          <td>{{ item.status == 1 ? '未完成' : '已完成'}}</td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" title="修改信息" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="addDialog" title="开始评价"
      :close-on-click-modal="false">
      <el-form :model="addReport" ref="editForm" label-width="100px">
        <el-form-item label="工厂">
          <el-select v-model="addReport.factory" filterable>
            <el-option v-for="item in factories" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模板">
          <el-select v-model="addReport.template" filterable>
            <el-option v-for="item in templates" :key="item.id" :value="item.id" :label="item.title"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="startReport">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="firmForm.id ? '编辑企业' : '新增企业'"
      :close-on-click-modal="false" :close="closeEditDialog">
      <el-form :model="firmForm" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="名称" prop="name">
          <el-input v-model="firmForm.name"></el-input>
        </el-form-item>
        <el-form-item label="纳税人编号" prop="taxpayer_registration_num">
          <el-input v-model="firmForm.taxpayer_registration_num"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact_man">
          <el-input v-model="firmForm.contact_man"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contact_phone">
          <el-input v-model="firmForm.contact_phone"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="firmForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="firmForm.id"
          title="删除" type="danger" @click="delFirm"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="saveFirm">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
export default {
  data() {
    return {
      addDialog: false,
      factories: [],
      tempaltes: [],
      addReport: {
        factory: null,
        template: null,
      },
      // 查询条件form
      queryForm: {
        factory_id: '',
        template_id: '',
      },
      queryParams: {}, // 分页查询参数
      firmList: [], // 管理的列表对象
      firmForm: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      // 编辑form的条件
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  created() {
    this.init();
    this.onSubmitQuery();
  },
  methods: {
    init() {
      this.axios.get('reportitemmain').then((resp) => {
        this.templates = resp.data;
      }, (e) => {
        this.$utils.resp(e);
      });
      this.axios.get('factorynames').then((resp) => {
        this.factories = resp.data;
      }, (e) => { this.$utils.resp(e); });
    },
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    // 查询列表数据
    getList() {
      this.axios.get('factoryreport', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.firmList = response.data.data;
          this.totalItem = response.data.total;
        }, (response) => {
          this.$utils.resp(response);
        });
    },
    toEdit(editObj) {
      const { href } = this.$router.resolve({
        name: 'ReportPage',
        query: { report_id: editObj.id },
      });
      window.open(href, '_blank');
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toAdd() {
      this.addDialog = true;
      this.addReport = {
        factory: null,
        template: null,
      };
    },
    startReport() {
      const { href } = this.$router.resolve({
        name: 'ReportPage',
        query: this.addReport,
      });
      window.open(href, '_blank');
    },
    // 修改新增的保存
    saveFirm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const tempForm = { ...this.firmForm };
          if (tempForm.id) {
            this.$http.put(`factory/${tempForm.id}`, tempForm).then(() => {
              this.editDialog = false;
              this.getList();
            }, (resp) => {
              this.$utils.resp(resp);
            });
          } else {
            this.$http.post('factory', tempForm).then(() => {
              this.editDialog = false;
              this.changePage(1);
            }, (resp) => {
              this.$utils.resp(resp);
            });
          }
        }
      });
    },
    closeEditDialog() {
      // this.canChooseBranch = true;
    },
    delFirm() {
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`factory/${this.firmForm.id}`).then(() => {
          this.getList();
          this.editDialog = false;
        }, (resp) => {
          this.$utils.resp(resp);
        });
      }, () => {});
    },
  },
  // filters: {
  //   substr4(value) {
  //     return value.substr(0, 4);
  //   },
  // },
};
</script>
