<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.q" clearable placeholder="输入关键字检索" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>公司名称</th>
          <th>联系人</th>
          <th>联系电话</th>
          <th>地址</th>
          <th v-if="isDeliveryManager">关联</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td><a href="#" @click.prevent="toDetail(item.id)">{{ item.company_name }}</a></td>
          <td>{{ item.contact_man }}</td>
          <td>{{ item.contact_phone }}</td>
          <td :title="item.addr"><div style="width: 200px;" class="text-overflow">{{ item.addr }}</div></td>
          <td v-if="isDeliveryManager"><el-switch v-model="item.rela" @change="(q) => {switchRela(q, item)}"></el-switch></td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" title="修改信息" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'" :close-on-click-modal="false">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="名称" prop="company_name">
          <el-input v-model="editModel.company_name"></el-input>
        </el-form-item>
        <el-form-item label="企业类型" prop="type">
          <el-select v-model="editModel.type" multiple>
            <el-option v-for="(label, key) in companyTypes" :key="key" :value="key" :label="label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司代码" prop="sn">
          <el-input v-model="editModel.sn"></el-input>
        </el-form-item>
        <el-form-item label="登录名" prop="login_name" v-if="!editModel.id">
          <el-input v-model="editModel.login_name"></el-input>
        </el-form-item>
        <el-form-item label="用户额外角色" v-if="editModel.login_name && !editModel.id">
          <el-checkbox-group v-model="editModel.roles">
            <el-checkbox v-for="(item, index) in roles" :key="index" :label="item.value">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="联系人" prop="contact_man">
          <el-input v-model="editModel.contact_man"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contact_phone">
          <el-input v-model="editModel.contact_phone"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="addr">
          <el-input type="textarea" v-model="editModel.addr"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="save">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true" :disabled="waiting"></i> 确 定</el-button>
        <el-button v-if="editModel.id" type="danger" @click="delIt(editModel.id)" class="pull-left" :disabled="waiting">
          <i class="fa fa-fw fa-trash" aria-hidden="true"></i> 删 除</el-button>

      </div>
    </el-dialog>

    <!-- 详情dialog -->
    <el-dialog :visible.sync="detailDialog" title="详情" :close-on-click-modal="false">
      <el-form label-width="100px">
        <el-form-item label="名称：">
          {{showModel.company_name}}
        </el-form-item>
        <el-form-item label="企业类型：">
          {{getTypeText(showModel.type)}}
        </el-form-item>
        <el-form-item label="公司代码：">
          {{showModel.sn}}
        </el-form-item>
        <el-form-item label="登录名：">
          {{showModel.user ? showModel.user.login_name : ''}}
          <template v-if="!showModel.user">
            <el-button type="primary" @click="addCompanyUser" icon="fa fa-fw fa-plus"></el-button>
            <div v-if="addUserBox">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="登录名：">
                    <el-input v-model="addUser.login_name"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="昵称：">
                    <el-input v-model="addUser.name"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <div>
                <el-form-item label="额外角色：">
                  <el-checkbox-group class="" v-model="addUser.roles" inline>
                    <el-checkbox v-for="(item, index) in roles" :key="index" :label="item.value">{{item.label}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-button type="primary" @click="saveUser">保存用户</el-button>
              </div>
            </div>
          </template>
          <template v-else>
            <el-button type="warning" @click="addCompanyUser" icon="fa fa-fw fa-pencil"></el-button>
            <div v-if="addUserBox">
              <div>
                <el-form-item label="额外角色：">
                  <el-checkbox-group class="" v-model="addUser.roles" inline>
                    <el-checkbox v-for="(item, index) in roles" :key="index" :label="item.value">{{item.label}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-button type="primary" @click="updateUser">保存</el-button>
              </div>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="联系人：">
          {{showModel.contact_man}}
        </el-form-item>
        <el-form-item label="联系方式：">
          {{showModel.contact_phone}}
        </el-form-item>
        <el-form-item label="地址：">
          {{showModel.addr}}
        </el-form-item>
        <el-form-item label="联系人：">
        </el-form-item>
      </el-form>
      <table class="table">
        <thead>
          <tr>
            <th>联系人</th>
            <th>联系电话</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in contacts" :key="item.id">
            <td>
              <span>{{item.name}}</span>
            </td>
            <td>
              <span>{{item.phone}}</span>
            </td>
            <td>
              <el-button style="margin-left: 2px;" type="warning"
                icon="fa fa-fw fa-pencil" @click="editContact(item)"></el-button>
              <el-button style="margin-left: 2px;" type="danger" icon="fa fa-fw fa-trash" @click="delContact(idx)"></el-button>
              <el-button style="margin-left: 2px;" v-if="!item.default" type="primary"
                icon="fa fa-fw fa-check" @click="defaultContact(item)" title="设置默认"></el-button>
              <span style="margin-left: 2px;" v-else>默认联系人</span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><el-button icon="fa fa-fw fa-plus" type="primary" @click="addContact"></el-button></td>
          </tr>
        </tbody>
      </table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="detailDialog = false" icon="fa fa-fw fa-times"> 关 闭</el-button>
        <el-button v-if="isAdmin" type="danger" @click="delIt(showModel.id)" class="pull-left" :disabled="waiting"
          icon="fa fa-fw fa-trash"> 删 除</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="contactDialog" title="联系人" :close-on-click-modal="false">
      <el-form label-width="100px">
        <el-form-item label="联系人：">
          <el-input v-model="contactModel.name"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：">
          <el-input v-model="contactModel.phone"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="contactDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 取 消</el-button>
        <el-button type="primary" @click="saveContact">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true" :disabled="waiting"></i> 确 定</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      waiting: false,
      loading: false,
      branches: [],
      // 查询条件form
      queryForm: {
        q: '',
      },
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      showModel: {},
      detailDialog: false,
      rules: {
        company_name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
      },
      contacts: [], // 联系人列表
      contactDialog: false, // 联系人弹出框
      contactModel: {}, // 编辑联系人
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      roles: [
        { value: 'wb_use', label: '领用管理' },
      ],
      addUserBox: false,
      addUser: {
        login_name: '',
        name: '',
        roles: [],
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      companyTypes: state => state.dict.companyTypes,
    }),
    isDeliveryManager() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('delivery_order_manager') !== -1) {
        result = true;
      }
      return result;
    },
    isAdmin() {
      return this.user.type === 'admin';
    },
  },
  created() {
    if (!this.companyTypes.unloaded) {
      this.$store.dispatch('GET_COMPANY_TYPES');
    }
    // this.$http.get('branches').then((resp) => {
    //   this.branches = resp.data;
    // }, (err) => {
    //   this.$utils.resp(err);
    // });
    this.onSubmitQuery();
  },
  methods: {
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$http.get('shippingcompany', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          list.forEach((item) => {
            if (item.type) {
              item.type = item.type.split(',');
            } else {
              item.type = [];
            }
          });
          this.loading = false;
          this.list = list;
          this.list = response.data.data;
          this.totalItem = response.data.total;
        }, (err) => {
          this.loading = false;
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.editModel = {
        company_name: '',
        contact_man: '',
        contact_phone: '',
        type: [],
        addr: '',
        sn: '',
        login_name: '',
        roles: [],
      };
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    // 打开编辑
    toEdit(editObj) {
      this.editModel = { ...editObj };
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    toDetail(id) {
      this.addUserBox = false;
      this.$http.get(`shippingcompany/${id}`).then((resp) => {
        const obj = resp.data;
        const contacts = [...obj.contacts];
        this.contacts = contacts;
        this.showModel = obj;
        this.detailDialog = true;
      });
    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.editModel };
          obj.type = obj.type.join(',');
          this.waiting = true;
          if (obj.id) {
            this.$http.put(`shippingcompany/${obj.id}`, obj).then(() => {
              this.editDialog = false;
              this.getList();
              this.$message({
                type: 'success',
                message: '更新成功',
                showClose: true,
                duration: 2000,
              });
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          } else {
            this.$http.post('shippingcompany', obj).then(() => {
              this.editDialog = false;
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
              this.getList();
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
    delIt(id) {
      let delId = this.editModel.id;
      if (typeof id === 'number') {
        delId = id;
      }
      this.$confirm('删除后无法恢复，是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.delete(`shippingcompany/${delId}`).then(() => {
          this.getList();
          this.editDialog = false;
          this.$message({
            type: 'success',
            message: '删除成功',
            showClose: true,
            duration: 2000,
          });
          this.waiting = false;
          this.detailDialog = false;
          this.editDialog = false;
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    // 关联关系
    switchRela(value, item) {
      const obj = {
        type: value,
        companyId: item.id,
      };
      this.$http.put('rela-shippingcompany', obj).then((resp) => {
        if (resp.data !== 'success') {
          item.rela = !value;
        }
      }, (err) => {
        item.rela = !value;
        this.$utils.resp(err);
      });
    },
    getTypeText(type) {
      const types = type ? type.split(',') : [];
      const str = types.map(item => this.companyTypes[item]);
      return str.join(',');
    },
    // contact
    addContact() {
      this.contactModel = {
        name: '',
        phone: '',
        company_id: this.showModel.id,
      };
      this.contactDialog = true;
    },
    editContact(contact) {
      this.contactModel = { ...contact };
      this.contactDialog = true;
    },
    delContact(index) {
      const contact = this.contacts[index];
      this.$http.delete(`shippingcompany/contact/${contact.id}`).then(() => {
        this.toDetail(contact.company_id);
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    saveContact() {
      const contact = this.contactModel;
      if (contact.id) {
        this.$http.put(`shippingcompany/contact/${contact.id}`, contact).then(() => {
          this.toDetail(contact.company_id);
          this.contactDialog = false;
        }, (err) => {
          this.$utils.resp(err);
        });
      } else {
        this.$http.post(`shippingcompany/${contact.company_id}/contact`, contact).then(() => {
          this.toDetail(contact.company_id);
          this.contactDialog = false;
        }, (err) => {
          this.$utils.resp(err);
        });
      }
    },
    defaultContact(contact) {
      this.$http.put(`shippingcompany/contact/${contact.id}/default`).then(() => {
        this.toDetail(contact.company_id);
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // 添加企业用户
    addCompanyUser() {
      this.addUser = {
        name: '',
        login_name: '',
        roles: [],
      };
      if (this.showModel.user) {
        this.addUser.roles = this.showModel.user.roles.map(item => item.code);
      }
      this.addUserBox = true;
    },
    saveUser() {
      if (!this.addUser.login_name.trim()) {
        this.$message.warning('登录名不能为空');
        return;
      }
      this.$http.post(`shippingcompany/${this.showModel.id}/user`, this.addUser).then(() => {
        this.toDetail(this.showModel.id);
      });
    },
    updateUser() {
      this.$http.put(`shippingcompany/${this.showModel.id}/user/${this.showModel.user.id}`, this.addUser).then(() => {
        this.toDetail(this.showModel.id);
        this.$message.success('用户角色已更新');
      });
    },
  },
};
</script>
