<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增企业</el-button>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.q" clearable placeholder="关键字" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>企业名称</th>
          <th>纳税人编号</th>
          <th>联系人</th>
          <th>联系电话</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in firmList" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.taxpayer_registration_num }}</td>
          <td>{{ item.contact_man }}</td>
          <td>{{ item.contact_phone }}</td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" title="修改信息" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="firmForm.id ? '编辑企业' : '新增企业'"
      :close-on-click-modal="false" :close="closeEditDialog">
      <el-form :model="firmForm" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="名称" prop="name">
          <el-input v-model="firmForm.name"></el-input>
        </el-form-item>
        <el-form-item label="纳税人编号" prop="taxpayer_registration_num">
          <el-input v-model="firmForm.taxpayer_registration_num"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact_man">
          <el-input v-model="firmForm.contact_man"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contact_phone">
          <el-input v-model="firmForm.contact_phone"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="firmForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="firmForm.id"
          title="删除" type="danger" @click="delFirm"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="saveFirm">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
export default {
  data() {
    return {
      // 查询条件form
      queryForm: {
        q: '',
      },
      queryParams: {}, // 分页查询参数
      firmList: [], // 管理的列表对象
      firmForm: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      // 编辑form的条件
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  created() {
    this.onSubmitQuery();
  },

  methods: {
    onSubmitQuery() {
      this.queryParams = {};
      if (this.queryForm.q && this.queryForm.q.trim()) {
        this.queryParams.q = this.queryForm.q;
      }
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    // 查询列表数据
    getList() {
      this.axios.get('factory', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.firmList = response.data.data;
          this.totalItem = response.data.total;
        }, (response) => {
          this.$utils.resp(response);
        });
    },
    toEdit(editObj) {
      this.firmForm = { ...editObj };
      this.editDialog = true;
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toAdd() {
      const firm = {
        id: '',
        name: '',
        contact_man: '',
        contact_phone: '',
        taxpayer_registration_num: '',
        remark: '',
      };
      this.firmForm = firm;
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    // 修改新增的保存
    saveFirm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const tempForm = { ...this.firmForm };
          if (tempForm.id) {
            this.$http.put(`factory/${tempForm.id}`, tempForm).then(() => {
              this.editDialog = false;
              this.getList();
            }, (resp) => {
              this.$utils.resp(resp);
            });
          } else {
            this.$http.post('factory', tempForm).then(() => {
              this.editDialog = false;
              this.changePage(1);
            }, (resp) => {
              this.$utils.resp(resp);
            });
          }
        }
      });
    },
    closeEditDialog() {
      // this.canChooseBranch = true;
    },
    delFirm() {
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`factory/${this.firmForm.id}`).then(() => {
          this.getList();
          this.editDialog = false;
        }, (resp) => {
          this.$utils.resp(resp);
        });
      }, () => {});
    },
  },
  // filters: {
  //   substr4(value) {
  //     return value.substr(0, 4);
  //   },
  // },
};
</script>
