<template>
  <el-dialog fullscreen :visible.sync="showBigImgVisible" custom-class="show-big-img-dialog">
    <div class="show-big-img-h">
      <div class="to-show-pic">
        <img class="show-img" :src="imgSrc">
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      imgSrc: state => state.common.imgSrc,
    }),
    showBigImgVisible: {
      get() {
        return this.$store.state.common.showBigImgModel;
      },
      // 点x时可以关闭
      set(v) {
        if (!v) {
          this.closeBigImgDialog();
        }
      },
    },
  },
  methods: {
    // 关闭dialog
    closeBigImgDialog() {
      this.$store.commit('PICKER_HIDE_SHOW_BIG_IMG');
    },
  },
};
</script>

<style>
.el-dialog.show-big-img-dialog {
  background-color: rgba(0,0,0,0.9);
}
.el-dialog.show-big-img-dialog .el-dialog__header {
  padding: 0 10px;
  height: 0;
}
.el-dialog.show-big-img-dialog .el-dialog__body {
  padding: 0;
  height: 100%;
}
.el-dialog.show-big-img-dialog .el-dialog__body .show-big-img-h {
  height: 100%;
  /* background-image: url(~assets/imgs/loadingbg.gif); */
  background-position: center center;
  background-repeat: no-repeat;
}
.el-dialog.show-big-img-dialog .el-dialog__body .show-big-img-h .to-show-pic {
  height: 100%;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}
.el-dialog.show-big-img-dialog .el-dialog__body .show-big-img-h .to-show-pic .show-img {
  padding: 0;
  margin: 0;
  max-width: 90%;
  max-height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  vertical-align: middle;
}
</style>
