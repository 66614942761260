<template>
  <el-card class="component">
    <el-tabs v-model="activeName" @tab-click="tabClick">
      <el-tab-pane name="first" label="实际出库">
        <div class="mar-btm-10 text-right">
          <div class="box-inline standard-search">
            <el-select v-model="queryForm.kh" clearable filterable placeholder="款号">
              <el-option v-for="item in khs" :key="item" :value="item" :label="item"></el-option>
            </el-select>
          </div>
          <div class="box-inline standard-search mar-lft-10">
            <el-select v-model="queryForm.rela_style" clearable filterable placeholder="关联款号">
              <el-option v-for="item in relaStyles" :key="item" :value="item" :label="item"></el-option>
            </el-select>
          </div>
          <div class="box-inline standard-search mar-lft-10">
            <el-select v-model="queryForm.out_type" multiple clearable placeholder="出库类型" filterable>
              <el-option v-for="(label, key) in outTypes" :key="key" :value="key" :label="`${label}`"></el-option>
            </el-select>
          </div>
          <div class="box-inline standard-search mar-lft-10">
            <el-date-picker value-format="yyyy-MM-dd"
              v-model="queryForm.start_date"
              type="date" placeholder="开始日期">
            </el-date-picker>
          </div>
          <div class="box-inline standard-search">
            <el-date-picker value-format="yyyy-MM-dd"
              v-model="queryForm.end_date"
              type="date" placeholder="截至日期">
            </el-date-picker>
          </div>
          <div class="box-inline standard-search">
            <el-select v-model="orderType" clearable placeholder="排序" filterable>
              <el-option v-for="(label, key) in orderTypes" :key="key" :value="key" :label="`${label}`"></el-option>
            </el-select>
          </div>
          <el-button size="small" @click="onSubmitQuery">
            <i class="fa fa-fw fa-search"></i>
          </el-button>
          <!-- <el-button size="small" @click="excel">
            <i class="fa fa-fw fa-download"></i>
          </el-button> -->
        </div>
        <div>
          合计：<span class="text-primary">{{sumTj.realSum}}</span>（{{sumTj.outSum + ' - ' + sumTj.backSum}}）退货率：{{sumTj.rate}}
        </div>
        <div :style="{height: boxHeight+'px', overflow: 'auto'}">
          <table class="table bordered">
            <thead>
              <tr>
                <th>款号</th>
                <th>关联款号</th>
                <th>类别</th>
                <th>颜色</th>
                <th>图片</th>
                <th>F</th>
                <th>XS</th>
                <th>S</th>
                <th>S/M</th>
                <th>M</th>
                <th>M/L</th>
                <th>L</th>
                <th>XL</th>
                <th>XXL</th>
                <th class="text-center">合计出库</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(obj) in dataList">
                <tr v-for="(item, index) in obj.list" :key="`${obj.code}_${index}`">
                  <td :rowspan="obj.list.length" v-if="index == 0">{{String(item.code).substr(0, 5)}}
                    <br/>
                    <span class="text-primary">{{obj.outTotal - obj.returnTotal}}</span>
                    （{{obj.outTotal}}-{{obj.returnTotal}}）{{obj.returnRate}}</td>
                  <td :rowspan="obj.list.length" v-if="index == 0">{{item.rela_style}}</td>
                  <td :rowspan="obj.list.length" v-if="index == 0">
                    {{categories[String(item.code).substr(1, 2)] ? categories[String(item.code).substr(1, 2)] : item.category}}
                  </td>
                  <td>{{item.color}}</td>
                  <td>
                    <el-popover v-if="item.imgs"
                      placement="right"
                      trigger="hover">
                      <img :src="showImgUrl">
                      <img slot="reference" @mouseover="showItemImg(item, false)" @click="showItemImg(item, true)"
                        :src="'data:image/jpg/png/gif;base64,' + item.imgs.simg" alt="">
                    </el-popover>
                  </td>
                  <td class="text-right">
                    {{(item.size0[0] > 0 || item.size0[1] > 0) ? ( (item.size0[0]-item.size0[1])+' ('+item.size0[0]+'-'+item.size0[1]+')'): '-'}}
                  </td>
                  <td class="text-right">
                    {{(item.size1[0] > 0 || item.size1[1] > 0) ? ( (item.size1[0]-item.size1[1])+' ('+item.size1[0]+'-'+item.size1[1]+')'): '-'}}
                  </td>
                  <td class="text-right">
                    {{(item.size2[0] > 0 || item.size2[1] > 0) ? ( (item.size2[0]-item.size2[1])+' ('+item.size2[0]+'-'+item.size2[1]+')'): '-'}}
                  </td>
                  <td class="text-right">
                    {{(item.size7[0] > 0 || item.size7[1] > 0) ? ( (item.size7[0]-item.size7[1])+' ('+item.size7[0]+'-'+item.size7[1]+')'): '-'}}
                  </td>
                  <td class="text-right">
                    {{(item.size3[0] > 0 || item.size3[1] > 0) ? ( (item.size3[0]-item.size3[1])+' ('+item.size3[0]+'-'+item.size3[1]+')'): '-'}}
                  </td>
                  <td class="text-right">
                    {{(item.size8[0] > 0 || item.size8[1] > 0) ? ( (item.size8[0]-item.size8[1])+' ('+item.size8[0]+'-'+item.size8[1]+')'): '-'}}
                  </td>
                  <td class="text-right">
                    {{(item.size4[0] > 0 || item.size4[1] > 0) ? ( (item.size4[0]-item.size4[1])+' ('+item.size4[0]+'-'+item.size4[1]+')'): '-'}}
                  </td>
                  <td class="text-right">
                    {{(item.size5[0] > 0 || item.size5[1] > 0) ? ( (item.size5[0]-item.size5[1])+' ('+item.size5[0]+'-'+item.size5[1]+')'): '-'}}
                  </td>
                  <td class="text-right">
                    {{(item.size6[0] > 0 || item.size6[1] > 0) ? ( (item.size6[0]-item.size6[1])+' ('+item.size6[0]+'-'+item.size6[1]+')'): '-'}}
                  </td>
                  <td class="text-right">
                    {{(item.total[0] > 0 || item.total[1] > 0) ? ( (item.total[0]-item.total[1])+' ('+item.total[0]+'-'+item.total[1]+') '): '-'}}
                    {{item.returnRate}}
                  </td>
                </tr>

              </template>
            </tbody>
          </table>
        </div>
      </el-tab-pane>
      <el-tab-pane name="second" label="入库统计">
        <in-log></in-log>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import InLog from './part/InLog';

export default {
  components: {
    InLog,
  },
  data() {
    return {
      activeName: 'first',
      khs: [],
      relaStyles: [],
      outTypes: [],
      boxHeight: 100,
      queryForm: {},
      countMap: {},
      totalData: [],
      orderType: 'real_desc',
      orderTypes: {
        real_desc: '有效成交',
        out_desc: '出货量',
        re_desc: '退货量',
      },
      showImgUrl: '',
    };
  },
  created() {
    this.init();
  },
  computed: {
    dataList() {
      const order = this.orderType;
      const list = this.totalData;
      const l = list.sort((a, b) => {
        let res = b.realTotal - a.realTotal;
        if (order === 'real_desc') {
          res = b.realTotal - a.realTotal;
        } else if (order === 'out_desc') {
          res = b.outTotal - a.outTotal;
        } else if (order === 're_desc') {
          res = b.returnTotal - a.returnTotal;
        }
        return res;
      });
      l.forEach((item) => {
        item.list = item.list.sort((a, b) => {
          let res = b.realNum - a.realNum;
          if (order === 'real_desc') {
            res = b.realNum - a.realNum;
          } else if (order === 'out_desc') {
            res = b.outNum - a.outNum;
          } else if (order === 're_desc') {
            res = b.returnNum - a.returnNum;
          }
          return res;
        });
      });
      return l;
    },
    sumTj() {
      const res = {
        outSum: 0,
        realSum: 0,
        backSum: 0,
      };
      this.totalData.forEach((item) => {
        res.backSum += 1 * item.returnTotal;
        res.outSum += 1 * item.outTotal;
      });
      res.realSum = res.outSum - res.backSum;
      if (res.outSum > 0) {
        res.rate = Math.round(res.backSum * 10000 / res.outSum) / 100;
        res.rate += '%';
      } else {
        res.rate = '';
      }
      return res;
    },
  },
  methods: {
    init() {
      this.boxHeight = document.documentElement.clientHeight - 260;
      if (this.$store.state.common.clothesDicts.sizes) {
        this.categories = this.$store.state.common.clothesDicts.categories;
        this.colors = this.$store.state.common.clothesDicts.colors;
        this.outTypes = this.$store.state.common.clothesDicts.sub_types;
        this.relaStyles = this.$store.state.common.clothesDicts.relaStyles;
        this.khs = this.$store.state.common.clothesDicts.khs;
      } else {
        this.$store.dispatch('GET_COLTHES_DICTS').then((resp) => {
          this.categories = resp.data.categories;
          this.colors = resp.data.colors;
          this.outTypes = this.$store.state.common.clothesDicts.sub_types;
          this.relaStyles = this.$store.state.common.clothesDicts.relaStyles;
          this.khs = this.$store.state.common.clothesDicts.khs;
        });
      }
    },
    // 标签切换时触发
    tabClick() {

    },
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      // if (this.currentPage !== 1) {
      //   this.currentPage = 1;
      // }
      // this.getList();
      this.getTj();
    },
    getTj() {
      this.axios.get('e-clothes-tj', { params: this.queryForm }).then((resp) => {
        const temps = resp.data;
        const keys = Object.keys(temps);
        const list = [];
        keys.forEach((key) => {
          const arr = temps[key];
          let outTotal = 0;
          let returnTotal = 0;
          let realTotal = 0;
          arr.forEach((item) => {
            if (item.total && item.total.length === 2) {
              item.outNum = item.total[0];
              item.returnNum = item.total[1];
              item.realNum = item.outNum - item.returnNum;
              if (item.outNum !== 0) {
                item.returnRate = (Math.round(item.returnNum * 10000 / item.outNum) / 100) + '%';
              } else {
                item.returnRate = '';
              }
            } else {
              item.outNum = 0;
              item.returnNum = 0;
              item.realNum = 0;
              item.returnRate = '';
            }
            outTotal += item.outNum;
            returnTotal += item.returnNum;
            realTotal += item.realNum;
          });
          const arr2 = arr.sort((a, b) => b.realNum - a.realNum);
          let returnRate = '';
          if (outTotal !== 0) {
            returnRate = (Math.round(returnTotal * 10000 / outTotal) / 100) + '%';
          }
          list.push({
            outTotal,
            returnTotal,
            realTotal,
            returnRate,
            list: arr2,
            code: key,
          });
        });
        this.totalData = list;
      });
    },
    // 显示图片
    showItemImg(item, isFullScrean = false) {
      const p1 = /^[A-Z]/;
      let hint = 'clothes';
      if (p1.test(item.code)) {
        hint = 'e_clothes';
      }
      this.showImg(item.imgs, hint, isFullScrean);
    },
    showImg(file, hint = 'clothes', isFullScrean) {
      const params = {
        hint,
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.showImgUrl = objurl;
        if (isFullScrean) {
          this.$store.commit('SET_BIG_IMG_URL', objurl);
          this.$store.commit('PICKER_SHOW_SHOW_BIG_IMG');
        }
      }, (err) => {
        this.$utils.resp(err);
      });
    },
  },
};
</script>

<style>

</style>
