import Vue from 'vue';
import Router from 'vue-router';
// eslint-disable-next-line import/no-cycle
import store from './store/index';
import MainLayout from './views/layout/MainLayout';
// import Bridge from './views/layout/Bridge';
import Login from './views/Login';
import Home from './views/Home';

import Dict from './views/sys/Dict';

import Company from './views/sys/company/Manager';
import Asset from './views/asset/Manager';
import AssetCheck from './views/asset/AssetCheck';
import Equipment from './views/asset/EquipmentInfo';
import User from './views/sys/user/Manager';
import ErpUser from './views/sys/user/ErpUser';
import Permission from './views/sys/promise/Manager';
import Role from './views/sys/role/Manager';
import MmrCompany from './views/mmr/datas/Company';
import MmrDept from './views/mmr/datas/Dept';
import ExpenseType from './views/mmr/datas/ExpenseType';

import FlowSet from './views/flow/FlowSet';
import FlowCourse from './views/flow/FlowCourse';

import Dept from './views/datas/Dept/DeptManage';
import DelyCompany from './views/delivery/Company';
import DelyProject from './views/delivery/Project';
import DelyOrder from './views/delivery/Order';
import QrPage from './views/delivery/QrPage';
import DelyRecord from './views/delivery/Record';
import DelyUseRecord from './views/delivery/UseRecord';
// import OrderDetail from './views/delivery/OrderDetail';

import Party from './views/dv_stock/Party';
import DvOrder from './views/dv_stock/DvOrder';
import OrderShow from './views/dv_stock/OrderShow';
import DvDelivery from './views/dv_stock/DvDelivery';
import DvStock from './views/dv_stock/Stock';
import PoStock from './views/dv_stock/PoStock';
import StockTransfer from './views/dv_stock/StockTransfer';
import StockOut from './views/dv_stock/StockOut';
import DvProduct from './views/dv_stock/Product';
import Receipt from './views/dv_stock/Receipt';

import DvBill from './views/dv_stock/delivery_bill/DvBill';
import PrintDvBill from './views/dv_stock/delivery_bill/PrintBill';
import DvBillInfo from './views/dv_stock/delivery_bill/BillInfo';

import Expense from './views/mmr/Expense';
import Revenue from './views/mmr/Revenue';
import Invoice from './views/mmr/Invoice';

import DySample from './views/dy/DySample';
import DyStep from './views/dy/DyStep';
import DySampleQr from './views/dy/SampleQr';
import DySummary from './views/dy/DySummary';
import SfDySample from './views/dy/SfDySample';

import DImg from './views/dimg/Index';
import DImgShow from './views/dimg/ShowImg';
import DimgQr from './views/dimg/DimgQrPage';

import Temp from './views/Temp';

import Clothes from './views/stock/Clothes';
import ClothesStore from './views/stock/Store';
import ClothesIn from './views/stock/In';
import ClothesOut from './views/stock/Out';
import BillOut from './views/stock/BillOut';
import ClothesLog from './views/stock/Log';
import ClothesReturn from './views/stock/ClothesReturn';

import EClothesCheck from './views/e_clothes/Check';
import EClothes from './views/e_clothes/Clothes';
import EColor from './views/e_clothes/Color';
import ClothesSupplier from './views/e_clothes/Supplier';
import SupplierLog from './views/e_clothes/SupplierLog';
import EClothesIn from './views/e_clothes/In';
import EClothesOut from './views/e_clothes/Out';
import EClothesBack from './views/e_clothes/Back';
import EClothesLog from './views/e_clothes/Log';
import EStock from './views/e_clothes/Stock';
import EClothesWarn from './views/e_clothes/EClothesWarn';
import Wuliao from './views/e_clothes/Wuliao';
import WuliaoLog from './views/e_clothes/WuliaoLog';

import Dispatch from './views/dispatch/Dispatch';
import ToDispatch from './views/dispatch/ToDispatch';
import DispatchCost from './views/dispatch/DispatchCost';
import DispatchTj from './views/dispatch/DispatchTj';

import OrderStatistic from './views/statistic/OrderStatistic';
// 样卡管理
import YkSample from './views/ykgl/Sample';
import YkLend from './views/ykgl/Lend';
import YkReturn from './views/ykgl/Return';
import YkList from './views/ykgl/ScanList';

// 国华样卡管理
import GhykCode from './views/ghyk/LoginCode';

//
import Factory from './views/factory_reprot/Factory';
import ReportItem from './views/factory_reprot/ReportItem';
import Report from './views/factory_reprot/Report';
import ReportPage from './views/factory_reprot/ReportPage';

// 工厂仓库管理
import OwnFactory from './views/factory/OwnFactory';
import OwfStock from './views/factory/OwfStock';
import OwfLog from './views/factory/OwfLog';
import FactoryInventory from './views/factory/FactoryInventory';
import InoutRecord from './views/factory/InoutRecord';
// 物流公司
import Supplier from './views/ghsys/Supplier';

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/logout',
      beforeEnter: () => {
        store.commit('AUTH_LOGOUT');
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { noLogin: true },
    },
    {
      path: '/dimg-show',
      name: 'DImgShow',
      component: DImgShow,
      meta: { noLogin: true },
    },
    {
      path: '/order-qr',
      name: 'OrderQr',
      component: QrPage,
    },
    {
      path: '/dimg-qr',
      name: 'DimgQr',
      component: DimgQr,
    },
    {
      path: '/dy-sample-qr',
      name: 'DySampleQr',
      component: DySampleQr,
    },
    {
      path: '/equipment-info',
      name: 'Equipment',
      component: Equipment,
      meta: { noLogin: true },
    },
    {
      path: '/delivery-detail/:id',
      name: 'PrintDvBill',
      component: PrintDvBill,
      // meta: { noLogin: true },
    },
    {
      path: '/dv-info/:code',
      name: 'DvBillInfo',
      component: DvBillInfo,
      meta: { noLogin: true },
    },
    {
      path: '/dv-order-info/:id',
      name: 'dv-show',
      component: OrderShow,
      meta: { noLogin: true },
    },
    {
      path: '/doreport',
      name: 'ReportPage',
      component: ReportPage,
    },
    {
      path: '/',
      name: 'Layout',
      component: MainLayout,
      children: [
        {
          path: '',
          name: 'Index',
          component: Home,
        },
        {
          path: '/dict',
          name: 'Dict',
          component: Dict,
        },
        // 工厂报告
        {
          path: 'supplier',
          name: 'Supplier',
          component: Supplier,
        },
        // 工厂报告
        {
          path: 'factory',
          name: 'Factory',
          component: Factory,
        },
        {
          path: 'reportitem',
          name: 'ReportItem',
          component: ReportItem,
        },
        {
          path: 'report',
          name: 'Report',
          component: Report,
        },
        // 流程
        {
          path: 'flowset',
          name: 'FlowSet',
          component: FlowSet,
        },
        {
          path: 'flowcourse',
          name: 'FlowCourse',
          component: FlowCourse,
        },
        // 派车系统
        {
          path: 'todispatch',
          name: 'ToDispatch',
          component: ToDispatch,
        },
        {
          path: 'dispatch',
          name: 'Dispatch',
          component: Dispatch,
        },
        {
          path: 'dispatchcost',
          name: 'DispatchCost',
          component: DispatchCost,
        },
        {
          path: 'dispatchtj',
          name: 'DispatchTj',
          component: DispatchTj,
        },
        // stock
        {
          path: 'party',
          name: 'Party',
          component: Party,
        },
        {
          path: 'dv-bill',
          name: 'DvBill',
          component: DvBill,
        },
        {
          path: 'dv-order',
          name: 'DvOrder',
          component: DvOrder,
        },
        {
          path: 'dv-delivery',
          name: 'DvDelivery',
          component: DvDelivery,
        },
        {
          path: 'dv-stock',
          name: 'DvStock',
          component: DvStock,
        },
        {
          path: 'po-stock',
          name: 'PoStock',
          component: PoStock,
        },
        {
          path: 'stock-trn',
          name: 'StockTransfer',
          component: StockTransfer,
        },
        {
          path: 'stock-out',
          name: 'StockOut',
          component: StockOut,
        },
        {
          path: 'dv-product',
          name: 'DvProduct',
          component: DvProduct,
        },
        {
          path: 'dv-receipt',
          name: 'Receipt',
          component: Receipt,
        },
        {
          path: 'dimg',
          name: 'DImg',
          component: DImg,
        },
        {
          path: 'asset',
          name: 'Asset',
          component: Asset,
        },
        {
          path: 'assetcheck',
          name: 'AssetCheck',
          component: AssetCheck,
        },
        {
          path: 'company',
          name: 'Company',
          component: Company,
        },
        {
          path: 'user',
          name: 'User',
          component: User,
        },
        {
          path: 'erp-user',
          name: 'ErpUser',
          component: ErpUser,
        },
        {
          path: 'promise',
          name: 'Promise',
          component: Permission,
        },
        {
          path: 'role',
          name: 'Role',
          component: Role,
        },
        {
          path: 'expense',
          name: 'Expense',
          component: Expense,
        },
        {
          path: 'revenue',
          name: 'Revenue',
          component: Revenue,
        },
        {
          path: 'invoice',
          name: 'Invoice',
          component: Invoice,
        },
        {
          path: 'mmrcompany',
          name: 'MmrCompany',
          component: MmrCompany,
        },
        {
          path: 'mmrdept',
          name: 'MmrDept',
          component: MmrDept,
        },
        {
          path: 'expense_type',
          name: 'ExpenseType',
          component: ExpenseType,
        },
        {
          path: 'dept',
          name: 'Dept',
          component: Dept,
        },
        {
          path: 'delycompany',
          name: 'DelyCompany',
          component: DelyCompany,
        },
        {
          path: 'delyorder',
          name: 'DelyOrder',
          component: DelyOrder,
        },
        {
          path: 'dely-project',
          name: 'DelyProject',
          component: DelyProject,
        },
        {
          path: 'delyrecord',
          name: 'DelyRecord',
          component: DelyRecord,
        },
        {
          path: 'userecord',
          name: 'DelyUseRecord',
          component: DelyUseRecord,
        },
        {
          path: 'dygl',
          name: 'DySample',
          component: DySample,
        },
        {
          path: 'dy-steps',
          name: 'DyStep',
          component: DyStep,
        },
        {
          path: 'dy-summary',
          name: 'DySummary',
          component: DySummary,
        },
        {
          path: 'sfdy',
          name: 'SfDySample',
          component: SfDySample,
        },
        {
          path: 'statistic-order',
          name: 'OrderStatistic',
          component: OrderStatistic,
        },
        {
          path: 'temp',
          name: 'Temp',
          component: Temp,
        },
        {
          path: 'clothes',
          name: 'Clothes',
          component: Clothes,
        },
        {
          path: 'clothes-store',
          name: 'ClothesStore',
          component: ClothesStore,
        },
        {
          path: 'clothes-in',
          name: 'ClothesIn',
          component: ClothesIn,
        },
        {
          path: 'clothes-out',
          name: 'ClothesOut',
          component: ClothesOut,
        },
        {
          path: 'wuliao',
          name: 'Wuliao',
          component: Wuliao,
        },
        {
          path: 'wuliaolog',
          name: 'WuliaoLog',
          component: WuliaoLog,
        },
        {
          path: 'bill-out',
          name: 'BillOut',
          component: BillOut,
        },
        {
          path: 'clothes-log',
          name: 'ClothesLog',
          component: ClothesLog,
        },
        {
          path: 'clothes-return',
          name: 'ClothesReturn',
          component: ClothesReturn,
        },
        {
          path: 'e_clothes',
          name: 'EClothes',
          component: EClothes,
        },
        {
          path: 'e_clothes_warn',
          name: 'EClothesWarn',
          component: EClothesWarn,
        },
        {
          path: 'e_clothes_in',
          name: 'EClothesIn',
          component: EClothesIn,
        },
        {
          path: 'e_clothes_out',
          name: 'EClothesOut',
          component: EClothesOut,
        },
        {
          path: 'e_clothes_back',
          name: 'EClothesBack',
          component: EClothesBack,
        },
        {
          path: 'e_clothes_check',
          name: 'EClothesCheck',
          component: EClothesCheck,
        },
        {
          path: 'e_clothes_log',
          name: 'EClothesLog',
          component: EClothesLog,
        },
        {
          path: 'e_stock',
          name: 'EStock',
          component: EStock,
        },
        {
          path: 'e_color',
          name: 'EColor',
          component: EColor,
        },
        {
          path: 'clothes-supplier',
          name: 'ClothesSupplier',
          component: ClothesSupplier,
        },
        {
          path: 'supplier-log',
          name: 'SupplierLog',
          component: SupplierLog,
        },
        {
          path: 'yksample',
          name: 'YkSample',
          component: YkSample,
        },
        {
          path: 'ykout',
          name: 'YkLend',
          component: YkLend,
        },
        {
          path: 'ykin',
          name: 'YkReturn',
          component: YkReturn,
        },
        {
          path: 'yklist',
          name: 'YkList',
          component: YkList,
        },
        {
          path: 'ghyk-code',
          name: 'GhykCode',
          component: GhykCode,
        },
        {
          path: 'ownfactory',
          name: 'OwnFactory',
          component: OwnFactory,
        },
        {
          path: 'owfstock',
          name: 'OwfStock',
          component: OwfStock,
        },
        {
          path: 'owflog',
          name: 'OwfLog',
          component: OwfLog,
        },
        {
          path: 'factoryinventory',
          name: 'FactoryInventory',
          component: FactoryInventory,
        },
        {
          path: 'inoutrecord',
          name: 'InoutRecord',
          component: InoutRecord,
        },
      ],
    },
  ],
});

router.beforeEach((to, form, next) => {
  // 未登录
  let noLogin = false;
  if (to.meta && to.meta.noLogin) {
    noLogin = true;
  }
  if (!noLogin && !store.state.auth.token) {
    next('/login');
  } else {
    next();
  }
});

export default router;
