const uoms = {
  M3: '立方米',
  MBF: '千板尺',
  L: '升',
  EA: '个',
  THG: '万粒',
  SRI: '付',
  LI: '令',
  PCS: '件',
  FEN: '份',
  Y: '元',
  FU: '副',
  BAO: '包',
  PI: '匹',
  BOU: '十亿',
  QG: '千个',
  QJ: '千件',
  QZ: '千只',
  THP: '千张',
  KPC: '千支',
  KP: '千片',
  SIN: '单',
  JU: '卷',
  PAI: '双',
  ZHI: '只',
  UNI: '台',
  KUA: '块',
  SET: '套',
  DU: '对',
  kWh: '度',
  SHI: '式',
  PIE: '张',
  DOZ: '打',
  BAT: '批',
  EA1: '把',
  KUN: '捆',
  BR: '支',
  BK: '本',
  DUO: '朵',
  TIA: '条',
  MEI: '枚',
  GEN: '根',
  BAR: '桶',
  TAN: '樘',
  CI: '次',
  K: '款',
  'PC.': '片',
  BTL: '瓶',
  HEA: '百个',
  HS: '百套',
  BT: '百条',
  HUN: '百粒',
  HE: '盒',
  DIS: '盘',
  PIA: '票',
  BI: '笔',
  CON: '筒',
  GRS: '箩',
  CTN: '箱',
  GRA: '粒',
  ZU: '组',
  GAN: '缸',
  LUO: '罗',
  SOU: '艘',
  COL: '色',
  BAG: '袋',
  ZHO: '轴',
  LIA: '辆',
  X: '项',
  KG: '千克',
  G: '克',
  kg: '公斤',
  MT: '吨',
  LBS: '磅',
  M: '米',
  KM: '千米',
  HM: '百米',
  'YD.': '码',
  M2: '平方米',
  PFC: '平方尺',
  MM2: '平方毫米',
  QFT: '平方英尺',
};
const other = null;

export { uoms, other };
