<template>
  <el-card class="components">
    <div class="mar-btm-10 pull-right">
      <el-form :model="queryForm" inline>
        <el-form-item label="用车日期">
          <el-date-picker
            v-model="queryForm.usedate"
            type="date"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用车人">
          <el-input v-model="queryForm.applyname" clearable placeholder="用车人" @keydown.enter.native="onSubmitQuery"></el-input>
        </el-form-item>
        <el-form-item label="用车部门">
          <el-select v-model="queryForm.fydept" clearable>
            <el-option v-for="(item, key) in depts" :key="key" :value="item.name" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="驾驶员">
          <el-input v-model="queryForm.drivename" clearable placeholder="驾驶人" @keydown.enter.native="onSubmitQuery"></el-input>
        </el-form-item>
        <el-form-item label="车牌">
          <el-input v-model="queryForm.licenseplate" clearable placeholder="车牌" @keydown.enter.native="onSubmitQuery"></el-input>
        </el-form-item>
        <el-form-item><el-button size="small" icon="fa fa-fw fa-search" @click="onSubmitQuery"/></el-form-item>
      </el-form>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>出车日期</th>
          <th>用车部门</th>
          <th>申请人</th>
          <th>申请人</th>
          <th>目的地</th>
          <th>司机</th>
          <th>车牌</th>
          <th>里程</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in dataList" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.dispatch.starttime }}</td>
          <td>{{ item.dispatch.deptname }}</td>
          <td>{{ item.dispatch.applyname }}</td>
          <td>{{ item.dispatch.fydepts }}</td>
          <td>{{ item.dispatch.address }}</td>
          <td>{{ item.dispatch.drivename }}</td>
          <td>{{ item.dispatch.licenseplate }}</td>
          <td>{{ item.mileage }}</td>
          <td>
            <div class="text-nowrap">
              <!-- <el-button size="small" title="修改信息" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="stepForm.id ? '编辑' : '新增'"
      :close-on-click-modal="false">
      <el-form :model="stepForm" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="名称" prop="name">
          <el-input v-model="stepForm.name"></el-input>
        </el-form-item>
        <el-form-item label="优先级" prop="sort">
          <el-input type="number" v-model="stepForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="说明备注" prop="remark">
          <el-input v-model="stepForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="stepForm.id" :disabled="waiting"
          title="删除" type="danger" @click="delstep"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="savestep">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true" :disabled="waiting"></i> 确 定</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      waiting: false,
      queryDates: [],
      statuses: {
        1: '待审核',
        2: '待安排',
        3: '已分配',
        4: '已出车',
        // 5: '司机出车结束',
        6: '待分配里程',
        7: '已分配结束',
        0: '退回，取消用车',
      },
      // 查询条件form
      queryForm: {
        applyname: '',
        drivename: '',
        address: '',
      },
      depts: [],
      dataList: [], // 管理的列表对象
      stepForm: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      // 编辑form的条件
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
        sort: [{ required: true, message: '排序不能为空', trigger: ['change', 'blur'] }],
      },
      currentPage: 1,
      pageSize: 15,
      totalItem: 0,
    };
  },
  created() {
    this.getDepts();
    this.onSubmitQuery();
  },

  methods: {
    getDepts() {
      this.axios.get('oa/pc-dicts').then((resp) => {
        const root = resp.data.depts;
        this.depts = root.children;
      });
    },
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      if (this.queryDates && this.queryDates.length > 0) {
        this.queryParams.startdate = this.queryDates[0];
        this.queryParams.enddate = this.queryDates[1];
      }
      // if (this.queryForm.q && this.queryForm.q.trim()) {
      //   this.queryParams.q = this.queryForm.q;
      // }
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.axios.get('pcxtlc', { params: { ...this.queryParams, pageSize: this.pageSize, page: this.currentPage } })
        .then((response) => {
          this.loading = false;
          this.totalItem = response.data.total;
          this.dataList = response.data.data;
        }, (response) => {
          this.loading = false;
          this.$utils.resp(response);
        });
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toEdit(editObj) {
      this.stepForm = { ...editObj };
      this.editDialog = true;
    },
    toAdd() {
      const step = {
        name: '',
        type: '',
        code: '',
        sort: 10,
        remark: '',
      };
      this.stepForm = step;
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    // 修改新增的保存
    savestep() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const tempForm = { ...this.stepForm };
          if (tempForm.id) {
            this.waiting = true;
            this.$http.put(`dystep/${tempForm.id}`, tempForm).then(() => {
              this.waiting = false;
              this.editDialog = false;
              this.getList();
            }, (resp) => {
              this.waiting = false;
              this.$utils.resp(resp);
            });
          } else {
            this.waiting = true;
            this.$http.post('dystep', tempForm).then(() => {
              this.waiting = false;
              this.editDialog = false;
              this.getList();
            }, (resp) => {
              this.waiting = false;
              this.$utils.resp(resp);
            });
          }
        }
      });
    },
    delstep() {
      this.$constep('是否确认删除？', '确认信息', {
        constepButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.delete(`dystep/${this.stepForm.id}`).then(() => {
          this.waiting = false;
          this.getList();
          this.editDialog = false;
        }, (resp) => {
          this.$utils.resp(resp);
          this.waiting = false;
        });
      }, () => {
      });
    },
  },
};
</script>
