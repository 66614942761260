const FormatDate = (date = null, fmt = 'yyyy-MM-dd') => {
  let dateObj = date;
  if (!(date instanceof Date)) {
    dateObj = new Date();
  }
  const hours = dateObj.getHours();
  const o = {
    'M+': dateObj.getMonth() + 1,
    'd+': dateObj.getDate(),
    'h+': hours,
    'm+': dateObj.getMinutes(),
    's+': dateObj.getSeconds(),
    ca: hours < 12 ? '上午' : '下午',
    a: hours < 12 ? 'am' : 'pm',
    'q+': Math.floor((dateObj.getMonth() + 3) / 3), // 季度
    S: dateObj.getMilliseconds(),
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (dateObj.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  const ks = Object.keys(o);
  ks.forEach((k) => {
  // Object.(k, value, o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
    }
  });
  return fmt;
};

// 获取月份天数
const getMonthDays = (year, month, returnString = false) => {
  let nY = year;
  let nM = month++;
  if (nM > 12) {
    nY++;
    nM -= 12;
  }
  const nDate = new Date(nY, nM, 1);
  const newDate = new Date(nDate.getTime() - 1000 * 60 * 60 * 24);
  let days = newDate.getDate();
  if (returnString) {
    days = String(days);
  }
  return days;
};

// 根据日期获取最后一天字符串'2020-01-31';
const MonthLastDay = (day = false) => {
  let theDate = day;
  // 参数不是string 或者 date 类型的 设置当前时间为参数
  if (!day || !(typeof day === 'string' || day instanceof Date)) {
    theDate = new Date();
  }
  if (typeof theDate === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(theDate)) {
    theDate = theDate.substr(0, 8);
    theDate += getMonthDays(theDate.substr(0, 4), theDate.substr(5, 2), true);
  } else {
    theDate = FormatDate(theDate, 'yyyy-MM-01');
    theDate = theDate.substr(0, 8);
    theDate += getMonthDays(theDate.substr(0, 4), theDate.substr(5, 2), true);
  }
  return theDate;
};

// 根据日期获取第一天字符串'2020-01-01';
const MonthFirstDay = (day = false) => {
  let theDate = day;
  // 参数不是string 或者 date 类型的 设置当前时间为参数
  if (!day || !(typeof day === 'string' || day instanceof Date)) {
    theDate = new Date();
  }
  if (typeof theDate === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(theDate)) {
    theDate = theDate.substr(0, 8) + '01';
  } else {
    theDate = FormatDate(theDate, 'yyyy-MM-01');
  }
  return theDate;
};

const DiffInMon = (d1, d2) => {
  const m1 = d1.getMonth();
  const m2 = d2.getMonth();
  const y1 = d1.getFullYear();
  const y2 = d2.getFullYear();
  return (y1 - y2) * 12 + (m1 - m2);
};

// 浮点数加法运算
const Add = (arg1, arg2) => {
  let r1 = 0;
  let r2 = 0;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) { r1 = 0; }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) { r2 = 0; }
  const max = Math.max(r1, r2);
  // 268.34 * 100bug不能直接乘以 10**max
  return (String(arg1).replace('.', '') * 10 ** (max - r1)
    + String(arg2).replace('.', '') * 10 ** (max - r2)) / 10 ** max;
};

const Sub = (arg1, arg2) => Add(arg1, -arg2);

// 乘法
const Mul = (arg1, arg2) => {
  let m = 0;
  const s1 = arg1.toString();
  const s2 = arg2.toString();
  try { m += s1.split('.')[1].length; } catch (e) { m += 0; }
  try { m += s2.split('.')[1].length; } catch (e) { m += 0; }
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / 10 ** m;
};

// 除法
const Div = (arg1, arg2) => {
  const r1 = arg1.toString();
  const r2 = arg2.toString();
  let m = 0;
  m = (r2.split('.')[1] ? r2.split('.')[1].length : 0) - (r1.split('.')[1] ? r1.split('.')[1].length : 0);
  return Number(r1.replace('.', '')) / Number(r2.replace('.', '')) * (10 ** m);
};

// 不用toFixed 因为 1.3335.toFixed(3) = 1.333 bug
const FixRound = (arg, n = 2, reutrnStr = false) => {
  // 268.34 * 100 bug不能直接乘以,但实际无影响，因为最后还是会round
  // const s = Math.round(Mul(arg, 10 ** n)) / 10 ** n;
  const s = Math.round(arg * 10 ** n) / 10 ** n;
  if (reutrnStr) {
    return s.toFixed(n);
  }
  return s;
};

const Calc = {
  Add,
  Sub,
  Mul,
  Div,
  FixRound,
};

// 距离当前时间的中文表述
const CurrentDistance = (time) => {
  let theTime = time;
  if (typeof theTime === 'string' || typeof theTime === 'number') {
    theTime = new Date(time);
  }
  if (!(theTime instanceof Date)) {
    return '';
  }
  const targetTime = theTime.getTime();
  if (Number.isNaN(targetTime)) {
    return '';
  }
  let result = '';
  const now = new Date();
  const nowTime = now.getTime();
  const d = nowTime - targetTime;
  if (d < 0) {
    return '';
  }
  const dDays = parseInt(d / 86400000, 10);
  const dHours = parseInt(d / 3600000, 10);
  const dMinutes = parseInt(d / 60000, 10);
  if (dDays < 1) {
    // 肯定不是一天的
    if (now.getDate() !== theTime.getDate()) {
      result = '昨天';
    } else if (dHours > 0) {
      result = `${dHours}小时前`;
    } else if (dMinutes > 0) {
      result = `${dMinutes}分钟前`;
    } else {
      result = '刚刚';
    }
  } else { // 超过一天的按日期的天计算忽略时分秒
    const nowDaysTime = parseInt(nowTime / 86400000, 10);
    const targetDaysTime = parseInt(targetTime / 86400000, 10);
    const diffDays = nowDaysTime - targetDaysTime;
    if (diffDays <= 1) {
      result = '昨天';
    } else if (diffDays < 3) {
      result = `${diffDays}天前`;
    } else if (now.getFullYear() !== theTime.getFullYear()) {
      result = FormatDate(theTime, 'yyyy-MM-dd');
    } else {
      result = FormatDate(theTime, 'MM-dd');
    }
  }
  return result;
};

const treeTailFun = (rootList, subName = 'children', everyFun, overFun, item, parent, checked = false) => {
  if (!item) {
    item = rootList.length > 0 ? rootList[0] : false;
  }
  if (!item) {
    return;
  }
  if (typeof subName === 'function') {
    overFun = everyFun;
    everyFun = subName;
    subName = 'children';
  }
  let list = rootList;
  if (parent) {
    item.parent = parent;
    list = parent[subName];
  }
  let next = null;
  let nextChecked = false;
  let nextParent = parent;
  if (checked) {
    if (typeof overFun === 'function') {
      overFun(item);
    }
    const index = list.indexOf(item);
    if (index < list.length - 1) {
      next = list[index + 1];
    }
  } else {
    if (typeof everyFun === 'function') {
      everyFun(item);
    }
    if (item[subName] && item[subName].length > 0) {
      next = item[subName][0];
      nextParent = item;
    } else {
      const index = list.indexOf(item);
      if (index < list.length - 1) {
        next = list[index + 1];
      }
    }
  }
  if (!next) {
    if (parent) {
      next = parent;
      nextChecked = true;
      nextParent = parent.parent;
    } else {
      return;
    }
  }
  treeTailFun(rootList, subName, everyFun, overFun, next, nextParent, nextChecked);
};

export {
  MonthFirstDay,
  getMonthDays,
  DiffInMon,
  FormatDate,
  MonthLastDay,
  Calc,
  CurrentDistance,
  treeTailFun,
};

