<template>
  <div>
    <el-form :model="query" label-width="100px">
      <el-row>
        <el-col :span="11">
          <el-form-item label="供应商：">
            <el-input readonly :value="company.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="相关编号：">
            <el-input clearable @keydown.enter.native="submit" v-model="q"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <el-button class="pull-right" type="primary" @click="submit" icon="fa fa-fw fa-search"></el-button>
        </el-col>
      </el-row>
    </el-form>
    <div>
      <div v-for="item in list" :key="item.id">
        <div>
          <span class="order-t"><label>发货通知编号：</label>{{item.code}}</span>
          <span class="order-t"><label>收货方：</label>{{item.to_company}}</span>
          <span class="order-t"><label>发货日期：</label>{{item.send_date}}</span>
        </div>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th>编号</th>
                <th>发货日期</th>
                <th>商品</th>
                <th>款号</th>
                <th>规格</th>
                <th>单价</th>
                <th>数量</th>
                <th style="width: 150px">开票数量</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="delivery in item.deliveries">
                <tr v-show="detail.max_use > 0" v-for="detail in delivery.details" :key="detail.id">
                  <td>{{delivery.code}}</td>
                  <td>{{delivery.delivery_date}}</td>
                  <td>{{detail.item_name}}</td>
                  <td>{{detail.attr2}}</td>
                  <td>{{detail.attr1}}</td>
                  <td>{{detail.unit_vat_price}}</td>
                  <td>
                    <el-checkbox v-model="detail.selected">
                      {{detail.max_use}}（{{detail.quantity * 1}}）{{detail.uom}}
                    </el-checkbox>
                  </td>
                  <td>
                    <el-input style="width: 150px" v-if="detail.selected" type="number" :max="detail.max_use" v-model="detail.use_quantity"></el-input>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedList: {
      type: Array,
      default() {
        return [];
      },
    },
    company: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      all: false,
      query: {},
      q: '',
      queryParams: {},
      list: [],
      totalItem: 0,
      currentPage: 1,
      pageSize: 5,
    };
  },
  watch: {
    company(val, orgVal) {
      if (val && orgVal !== val) {
        this.submit();
      }
    },
  },
  methods: {
    submit() {
      this.queryParams = {
        company_id: this.company.id,
        code: this.q,
      };
      this.currentPage = 1;
      this.getList();
    },
    showQuery() {
      this.submit();
    },
    changePage(page) {
      this.currentPage = page;
      this.addSelected(false);
      this.getList();
    },
    getList() {
      const params = {
        ...this.queryParams,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$http.get('receipt-orders', { params }).then((resp) => {
        const list = resp.data.data;
        this.totalItem = resp.data.total;
        list.forEach((order) => {
          order.deliveries.forEach((delivery) => {
            delivery.details.forEach((item) => {
              item.selected = false;
              const seled = this.selectedList.find(seled => seled.id === item.id);
              item.max_use = this.$calc.Sub(item.quantity, item.kpsl);
              if (seled) {
                item.max_use = this.$calc.Sub(item.max_use, seled.quantity);
                item.use_quantity = item.max_use;
              } else {
                item.use_quantity = item.max_use;
              }
            });
          });
        });
        this.list = list;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    addSelected(warning = true) {
      const selectedList = [];
      this.list.forEach((order) => {
        order.deliveries.forEach((delivery) => {
          delivery.details.forEach((item) => {
            if (item.selected) {
              const obj = {
                ...item,
                delivery,
                order,
              };
              selectedList.push(obj);
            }
          });
        });
      });
      if (selectedList.length === 0) {
        if (warning) {
          this.$message({
            type: 'warning',
            message: '请选择至少一条记录',
          });
        }
        return;
      }
      this.$emit('selected', selectedList);
    },
    trClick(item) {
      item.sel = !item.sel;
      this.checklist();
    },
    checkAll() {
      this.$nextTick(() => {
        let f = false;
        this.list.forEach((item) => {
          if (!item.sel) {
            f = true;
          }
        });
        this.all = !f;
      });
    },
    selAll(val) {
      this.list.forEach((item) => {
        item.sel = val;
      });
    },
  },
};
</script>

<style scoped lang="scss">
  .order-t {
    margin-right: 20px;
    display: inline-block;
  }
</style>
