<template>
  <el-card class="components">
    <!-- <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新增</el-button> -->

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="SKU" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status" @change="onSubmitQuery" clearable placeholder="状态">
          <el-option value="1" label="未补库"></el-option>
          <el-option value="2" label="已处理"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>SKU</th>
          <th>图片</th>
          <th>在售状态</th>
          <th>零库存时间</th>
          <th>处理时间</th>
          <th>处理人</th>
          <th>当前库存</th>
          <th>状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.code }}</td>
          <td>
            <el-popover v-if="item.clothes && item.clothes.imgs.length === 1"
              placement="right"
              trigger="hover">
              <img :src="showImgUrl">
              <img slot="reference" @mouseover="showItemImg(item.clothes, false)" @click="showItemImg(item.clothes, true)"
                :src="'data:image/jpg/png/gif;base64,' + item.clothes.imgs[0].simg" alt="">
            </el-popover>
          </td>
          <td>{{ item.clothes.status_text }}</td>
          <td>{{ item.created_at }}</td>
          <td>{{ item.status === '2' ? item.updated_at : '' }}</td>
          <td>{{ item.close_user }}</td>
          <td>{{ item.clothes.stock_num }}</td>
          <td :class="[`color-${item.status}`]">{{ item.status === '2' ? '已处理' : '未处理'  }}</td>
          <td>
            <div class="text-nowrap">
              <el-button v-if="item.status==1" size="small" title="手动解除报警" @click="resolve(item)"><i class="fa fa-fw fa-check"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>


  </el-card>
</template>

<style scoped lang="scss">
.color-1 {
  color: #e65050;
}
.color-2 {
  color: #aaa;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      waiting: false,
      loading: false,
      // 查询条件form
      queryForm: {
        code: null,
        status: '1',
      },
      showImgUrl: null,
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  created() {
    this.onSubmitQuery();
  },
  methods: {
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$http.get('clotheswarn', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          this.loading = false;
          this.list = list;
          this.list = response.data.data;
          this.totalItem = response.data.total;
        }, (err) => {
          this.loading = false;
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    resolve(item) {
      if (this.user.name !== '赵明雨') {
        this.$notify({
          title: '提示',
          type: 'warning',
          message: '只有赵明雨可以手动处理报警',
          duration: 5000,
        });
        return;
      }
      this.$confirm('确认已处理?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.warning = true;
        this.axios.put(`clotheswarn/${item.id}/resolve`).then(() => {
          this.getList();
          this.waiting = false;
        }, (err) => {
          this.waiting = false;
          this.$utils.resp(err);
        });
      }, () => {});
    },
    // 显示图片
    showItemImg(item, isFullScrean = false) {
      this.showImg(item.imgs[0], isFullScrean);
    },
    showImg(file, isFullScrean) {
      const params = {
        hint: 'e_clothes',
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.showImgUrl = objurl;
        if (isFullScrean) {
          this.$store.commit('SET_BIG_IMG_URL', objurl);
          this.$store.commit('PICKER_SHOW_SHOW_BIG_IMG');
        }
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // 修改新增的保存
    // save() {
    //   this.$refs.editForm.validate((valid) => {
    //     if (valid) {
    //       const obj = { ...this.editModel };
    //       this.waiting = true;
    //       if (obj.id) {
    //         this.$http.put(`e-color/${obj.id}`, obj).then(() => {
    //           this.editDialog = false;
    //           this.getList();
    //           this.$message({
    //             type: 'success',
    //             message: '更新成功',
    //             showClose: true,
    //             duration: 2000,
    //           });
    //         }, (err) => {
    //           this.$utils.resp(err);
    //         }).then(() => {
    //           this.waiting = false;
    //         });
    //       } else {
    //         this.$http.post('e-color', obj).then(() => {
    //           this.editDialog = false;
    //           this.$message({
    //             type: 'success',
    //             message: '保存成功',
    //             showClose: true,
    //             duration: 2000,
    //           });
    //           this.getList();
    //         }, (err) => {
    //           this.$utils.resp(err);
    //         }).then(() => {
    //           this.waiting = false;
    //         });
    //       }
    //     }
    //   });
    // },
  },
};
</script>
