<template>
    <el-form :model="order" label-width="100px">
      <el-form-item label="项目号：">
        {{order.project_number}}
      </el-form-item>
      <el-form-item label="编号：">
        {{order.code}}
      </el-form-item>
      <el-form-item label="发货方：">
        {{order.from_company}}
      </el-form-item>
      <el-form-item label="收货方：">
        {{order.to_company}}
      </el-form-item>
      <el-form-item label="发货明细：">
      </el-form-item>
      <table class="items bordered table" style="width: 100%;">
        <thead>
          <tr>
            <th class="text-center">产品</th>
            <th class="text-center" width="100">数量</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in items">
            <tr :key="index">
              <td>批次：{{item.code.substr(item.code.length - 2)}} （{{item.delivery_date}}）</td>
              <td></td>
            </tr>
            <tr :key="'sub' + sub.id" v-for="sub in item.details">
              <td>{{sub.item_name}} {{sub.attr1}} {{sub.attr2}} {{color}}</td>
              <td>{{sub.quantity * 1}}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </el-form>
</template>

<script>
export default {
  data() {
    return {
      order: {},
    };
  },
  computed: {
    items() {
      let result = [];
      if (this.order.deliveries && this.order.deliveries.length > 0) {
        result = this.order.deliveries;
      }
      return result;
    },
  },
  created() {
    this.getOrder();
  },
  methods: {
    getOrder() {
      if (this.$route.params) {
        const orderId = this.$route.params.id;
        this.$http.get(`qrdvinfo/${orderId}`).then((resp) => {
          this.order = resp.data;
        });
      }
    },
  },
};
</script>

<style>

</style>
