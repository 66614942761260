<template>
  <div>

    <el-dialog :visible.sync="showEditSelf" title="修改密码">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="原密码" prop="orgPassword">
          <el-input type="password" v-model="form.orgPassword"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" v-model="form.newPassword"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="repetPassword">
          <el-input type="password" v-model="form.repetPassword"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditSelf = false">取 消</el-button>
        <el-button type="primary" @click="editPassword">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validePsw = (rule, value, callback) => {
      const test1 = /^\S{5,}$/;
      if (value === '') {
        callback(new Error('新密码不能为空！'));
      } else if (!test1.test(value)) {
        callback(new Error('密码至少为5位！'));
      } else if (this.form.repetPassword) {
        this.$refs.form.validateField('repetPassword');
        callback();
      } else {
        callback();
      }
    };
    const valideRepetPsw = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('新密码不能为空！'));
      } else if (this.form.newPassword !== this.form.repetPassword) {
        callback(new Error('两次密码不一致'));
      } else {
        callback();
      }
    };
    return {
      form: {
        orgPassword: '',
        newPassword: '',
        repetPassword: '',
      },
      rules: {
        orgPassword: [{ required: true, message: '必须填写旧密码', trigger: ['change', 'blur'] }],
        newPassword: [{ required: true, validator: validePsw, trigger: 'blur' }],
        repetPassword: [{ required: true, validator: valideRepetPsw, trigger: ['change', 'blur'] }],
      },
    };
  },
  computed: {
    showEditSelf: {
      get() {
        return this.$store.state.common.showEditSelf;
      },
      set(val) {
        if (!val) {
          this.$store.commit('SET_SHOW_EDITSELF', false);
        }
      },
    },
  },
  methods: {
    editPassword() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.axios.put('auth/resetPwd', this.form).then(() => {
            this.$message({
              message: '成功修改密码，之后请使用新密码登录系统',
              type: 'success',
            });
            this.showEditSelf = false;
            this.$router.push('logout');
          }, (resp) => {
            this.$utils.resp(resp);
          });
        }
      });
    },
  },
};
</script>
