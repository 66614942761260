<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> New</el-button>
    <el-button type="primary" size="small" @click="addRecord('in')"><i class="el-icon-plus"></i> 直接入库</el-button>
    <el-button type="primary" size="small" @click="addApply('in')">采购申请</el-button>
    <el-button type="primary" size="small" @click="addRecord('out')"><i class="el-icon-minus"></i> 出库</el-button>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search" v-if="isOwnStockManager">
        {{factories[0] ? (factories[0].name + factories[0].name1) : ''}}
      </div>
      <div class="box-inline standard-search" v-else>
        <el-select v-model="queryForm.own_factory_id" clearable placeholder="factory"  @change="onSubmitQuery">
          <el-option v-for="item in factories" :key="item.id" :value="item.id" :label="item.name + item.name1"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.category" clearable placeholder="类型"  @change="onSubmitQuery">
          <el-option v-for="(label, code) in categories" :key="code" :value="code" :label="label"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.name" clearable placeholder="name" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
      <!-- <el-button size="small" @click="download">
        <i class="fa fa-fw fa-download"></i>
      </el-button> -->
      <a ref="downloadLink" :href="downloadUrl" :download="downloadFileName" v-show="false"></a>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>Name(名称)</th>
          <th>Name</th>
          <th>Code编号</th>
          <th>Category分类</th>
          <th>Style规格</th>
          <th>Stock库存</th>
          <th width="1%"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in firmList" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.name1 }}</td>
          <td>{{ item.sn }}</td>
          <td>{{ item.category_name }}</td>
          <td>{{ item.spec }}</td>
          <td>{{ Number(item.cur_num) }}{{item.unit}}</td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" title="Edit" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="firmForm.id ? 'Edit' : 'New'"
      :close-on-click-modal="false" :close="closeEditDialog">
      <el-form :model="firmForm" ref="editForm" label-width="150px" :rules="rules">
        <el-form-item label="Factory" prop="own_factory_id">
          <el-select v-model="firmForm.own_factory_id" placeholder="factory"  @change="onSubmitQuery">
            <el-option v-for="item in factories" :key="item.id" :value="item.id" :label="item.name + item.name1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="firmForm.name"></el-input>
        </el-form-item>
        <el-form-item label="name" prop="name1">
          <el-input v-model="firmForm.name1"></el-input>
        </el-form-item>
        <el-form-item label="Code编号" prop="sn">
          <el-input v-model="firmForm.sn"></el-input>
        </el-form-item>
        <el-form-item label="Category类型" prop="category">
          <el-select v-model="firmForm.category">
            <el-option v-for="(label, code) in categories" :key="code" :value="code" :label="label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Style型号" prop="spec">
          <el-input v-model="firmForm.spec"></el-input>
        </el-form-item>
        <el-form-item label="Brand品牌" prop="brand">
          <el-input v-model="firmForm.brand"></el-input>
        </el-form-item>
        <el-form-item label="Unit 计量单位" prop="unit">
          <el-input v-model="firmForm.unit"></el-input>
        </el-form-item>
        <el-form-item v-if="!firmForm.id" label="Number 库存数量" prop="init_num">
          <el-input type="number" v-model="firmForm.init_num"></el-input>
        </el-form-item>
        <el-form-item label="Remark 备注" prop="remark">
          <el-input type="textarea" v-model="firmForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="firmForm.id"
          title="删除" type="danger" @click="delFirm"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button @click="editDialog = false" :disabled="waiting">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="saveFirm" :disabled="waiting">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 提 交</el-button>
      </div>
    </el-dialog>

    <!-- record dialog -->
    <el-dialog :visible.sync="recordDialog" :title="record.type|typeName"
      :close-on-click-modal="false">
      <el-form :model="record" ref="recordForm" label-width="100px" :rules="recordRules">
        <el-form-item label="Factory工厂" prop="own_factory_id">
          <el-select v-model="record.own_factory_id">
            <el-option v-for="item in factories" :key="item.id" :value="item.id" :label="item.name + item.name1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="record.type === 'out'" label="类型">
          <el-select v-model="categoryType" @change="categoryTypeChange">
            <el-option label="自采物品" value="S"></el-option>
            <el-option label="客供物品" value="N"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Code编号" prop="code">
          <el-input v-model="record.code"></el-input>
        </el-form-item>
        <el-form-item label="Date" prop="occur_date">
          <el-date-picker v-model="record.occur_date" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="Remark 备注" prop="remark">
          <el-input type="textarea" v-model="record.remark"></el-input>
        </el-form-item>
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th>物品</th>
              <th>style规格</th>
              <th>库存</th>
              <th>数量</th>
              <th>单位</th>
              <th v-if="record.type === 'in'">单价</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in addList" :key="index">
              <td>{{index+1}}</td>
              <td>{{item.name}}{{item.name1}}</td>
              <td>{{item.spec}}</td>
              <td>{{Number(item.cur_num)}}</td>
              <td>
                <el-input v-if="record.type === 'in'" type="number" :min="0" v-model="item.num"></el-input>
                <el-input v-if="record.type === 'out'" type="number" :min="0" :max="Number(item.cur_num)" v-model="item.num"></el-input>
              </td>
              <td>{{item.unit}}</td>
              <td v-if="record.type === 'in'">
                <el-input type="number" :min="0" v-model="item.price"></el-input>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <el-button type="primary" @click="toSelectGoods" icon="el-icon-plus">新增</el-button>
              </td>
            </tr>
          </tbody>
        </table>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="recordDialog = false" :disabled="waiting">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="saveRecord" :disabled="waiting">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 提 交</el-button>
      </div>
    </el-dialog>

    <!-- record dialog -->
    <el-dialog :visible.sync="applyDialog" title="采购申请"
      :close-on-click-modal="false">
      <el-form :model="record" ref="recordForm" label-width="100px" :rules="recordRules">
        <el-form-item label="Factory工厂" prop="own_factory_id">
          <el-select v-model="record.own_factory_id">
            <el-option v-for="item in factories" :key="item.id" :value="item.id" :label="item.name + item.name1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Code编号" prop="code">
          <el-input v-model="record.code"></el-input>
        </el-form-item>
        <el-form-item label="Date" prop="occur_date">
          <el-date-picker v-model="record.occur_date" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="总价" prop="amount">
          {{addAmount}}
        </el-form-item>
        <el-form-item label="Remark 备注" prop="remark">
          <el-input type="textarea" v-model="record.remark"></el-input>
        </el-form-item>
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th>物品</th>
              <th>style规格</th>
              <th>库存</th>
              <th>数量</th>
              <th>单位</th>
              <th v-if="record.type === 'in'">单价</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in addList" :key="index">
              <td>{{index+1}}</td>
              <td>{{item.name}}{{item.name1}}</td>
              <td>{{item.spec}}</td>
              <td>{{Number(item.cur_num)}}</td>
              <td>
                <el-input v-if="record.type === 'in'" type="number" :min="0" v-model="item.num"></el-input>
                <el-input v-if="record.type === 'out'" type="number" :min="0" :max="Number(item.cur_num)" v-model="item.num"></el-input>
              </td>
              <td>{{item.unit}}</td>
              <td v-if="record.type === 'in'">
                <el-input type="number" :min="0" v-model="item.price"></el-input>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <el-button type="primary" @click="toSelectGoods" icon="el-icon-plus">新增</el-button>
              </td>
            </tr>
          </tbody>
        </table>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="applyDialog = false" :disabled="waiting">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="saveRecord" :disabled="waiting">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 提 交</el-button>
      </div>
    </el-dialog>
    <GoodsSelect :goods_type="categoryType" :visible.sync="goodsDialog" :categories="selectCategories"
      :factory_id="record.own_factory_id" @selected="selectGoods"></GoodsSelect>
  </el-card>
</template>

<script>
import { mapState } from 'vuex';
import GoodsSelect from '@/views/factory/parts/InventoryGoodsSelect';

export default {
  components: {
    GoodsSelect,
  },
  data() {
    return {
      waiting: false,
      loading: false,
      // 查询条件form
      queryForm: {
        name: '',
        own_factory_id: null,
        category: null,
      },
      queryParams: {}, // 分页查询参数
      firmList: [], // 管理的列表对象
      firmForm: {}, // 管理的操作时对象
      factories: [],
      categories: [],
      editDialog: false, // dialog显示标志 true 显示
      // 编辑form的条件
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
        category: [{ required: true, message: '填写类型', trigger: ['change', 'blur'] }],
      },
      recordRules: {
        code: [{ required: true, message: '出入库单号必填', trigger: ['change', 'blur'] }],
      },
      addList: [],
      record: {},
      selectCategories: [],
      recordDialog: false,
      applyDialog: false,
      categoryType: 'N',
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      downloadFileName: '',
      downloadUrl: '',
      goodsDialog: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    addAmount() {
      let sum = 0;
      this.addList.forEach((i) => {
        const amount = this.$calc.Mul(i.price, i.num);
        sum = this.$calc.Add(sum, amount);
      });
      return sum;
    },
    factoryMap() {
      const map = {};
      this.factories.forEach((item) => {
        map[item.id] = item;
      });
      return map;
    },
    isOwnStockManager() {
      let r = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('own_factory_stock') !== -1) {
        r = true;
      }
      return r;
    },
    ncategoryList() {
      const r = [];
      const keys = Object.keys(this.categories);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key.indexOf('N') === 0) {
          r.push({
            value: key,
            label: this.categories[key],
          });
        }
      }
      return r;
    },
    categoryList() {
      const r = [];
      const keys = Object.keys(this.categories);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        r.push({
          value: key,
          label: this.categories[key],
        });
      }
      return r;
    },
    scategoryList() {
      const r = [];
      const keys = Object.keys(this.categories);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key.indexOf('S') === 0) {
          r.push({
            value: key,
            label: this.categories[key],
          });
        }
      }
      return r;
    },
  },
  created() {
    this.init();
    // this.onSubmitQuery();
  },
  filters: {
    typeName(type) {
      let r = '出库 OUT';
      if (type === 'in') {
        r = '入库 IN';
      }
      return r;
    },
  },
  methods: {
    init() {
      this.axios.get('factoryidict').then((r) => {
        this.categories = r.data.category;
      });
      this.axios.get('ownfactorylist').then((r) => {
        this.factories = r.data;
        if (this.isOwnStockManager) {
          this.queryForm.own_factory_id = r.data[0].id;
        }
        this.onSubmitQuery();
      });
    },
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    // 文件下载
    download() {
      const params = this.queryForm;
      this.$http.get('factoryinventory-excel', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const downloadUrl = URL.createObjectURL(blob);
        this.downloadUrl = downloadUrl;
        this.downloadFileName = '库存.xlsx';
        this.$nextTick(() => {
          this.$refs.downloadLink.click();
        });
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.axios.get('factoryinventory', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.loading = false;
          this.firmList = response.data.data;
          this.totalItem = response.data.total;
        }, (response) => {
          this.loading = false;
          this.$utils.resp(response);
        });
    },
    toEdit(editObj) {
      const params = { logs: 1 };
      this.axios.get(`factoryinventory/${editObj.id}`, { params }).then((r) => {
        this.firmForm = r.data;
        this.editDialog = true;
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toAdd() {
      const obj = {
        id: '',
        name: '',
        name1: '',
        category: null,
        own_factory_id: this.queryForm.own_factory_id,
        spec: '',
        attr1: '',
        brand: '',
        price: '',
        unit: '',
        init_um: '',
        remark: '',
      };
      this.firmForm = obj;
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    // 修改新增的保存
    saveFirm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const tempForm = { ...this.firmForm };
          this.waiting = true;
          if (tempForm.id) {
            this.$http.put(`factoryinventory/${tempForm.id}`, tempForm).then(() => {
              this.editDialog = false;
              this.waiting = false;
              this.getList();
            }, (resp) => {
              this.waiting = false;
              this.$utils.resp(resp);
            });
          } else {
            this.$http.post('factoryinventory', tempForm).then(() => {
              this.waiting = false;
              this.editDialog = false;
              this.changePage(1);
            }, (resp) => {
              this.waiting = false;
              this.$utils.resp(resp);
            });
          }
        }
      });
    },
    closeEditDialog() {
      // this.canChooseBranch = true;
    },
    delFirm() {
      // this.$confirm('是否确认删除？', '确认信息', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(() => {
      //   this.$http.delete(`factoryinventory/${this.firmForm.id}`).then(() => {
      //     this.getList();
      //     this.editDialog = false;
      //   }, (resp) => {
      //     this.$utils.resp(resp);
      //   });
      // }, () => {});
    },
    // 出入库
    addRecord(type = 'in') {
      if (type === 'in') {
        this.categoryType = 'N';
        this.selectCategories = this.ncategoryList;
      } else {
        this.categoryType = 'S';
        this.selectCategories = this.scategoryList;
      }
      this.addList = [];
      this.record = {
        type,
        code: '',
        remark: '',
        own_factory_id: this.queryForm.own_factory_id,
        occur_date: this.$utils.formatDate(),
      };
      this.recordDialog = true;
    },
    categoryTypeChange() {
      if (this.categoryType === 'N') {
        this.selectCategories = this.ncategoryList;
      } else {
        this.selectCategories = this.scategoryList;
      }
    },
    // 需要审批的采购
    addApply(type = 'in') {
      this.categoryType = 'S';
      this.selectCategories = this.scategoryList;
      this.addList = [];
      this.record = {
        type,
        code: '',
        remark: '',
        own_factory_id: this.queryForm.own_factory_id,
        occur_date: this.$utils.formatDate(),
      };
      this.applyDialog = true;
    },
    toSelectGoods() {
      if (!this.record.own_factory_id) {
        const h = this.$createElement;
        this.$notify({
          title: '提示',
          message: h('i', { style: 'color: teal' }, '选择一个工厂'),
        });
        return;
      }
      this.goodsDialog = true;
    },
    delAddItem(index) {
      this.addList.splice(index, 1);
    },
    selectGoods(list) {
      const map = {};
      this.addList.forEach((item) => {
        map[item.id] = 1;
      });
      const arr = [];
      list.forEach((item) => {
        if (!map[item.id]) {
          arr.push({ ...item, num: 0, price: 0 });
        }
      });
      this.addList.splice(this.addList.length, 0, ...arr);
    },
    saveRecord() {
      if (!this.record.code) {
        this.$notify({
          title: '提示',
          message: '必须填写出入库单号',
          type: 'warning',
        });
        return;
      }
      if (this.addList.length === 0) {
        this.$notify({
          title: '提示',
          message: '请选择出库物品',
          type: 'warning',
        });
        return;
      }
      const errTypeItems = this.addList.filter((i) => {
        let r = true;
        if (i.category.indexOf(this.categoryType) === 0) {
          r = false;
        }
        return r;
      });
      if (errTypeItems.length > 0) {
        this.$notify({
          title: '提示',
          message: '出入库物品类型不统一',
          type: 'warning',
        });
        return;
      }
      const obj = { ...this.record, items: this.addList, categoryType: this.categoryType };
      this.waiting = true;
      this.axios.post('factoryinventoryrecord', obj).then(() => {
        this.waiting = false;
        this.recordDialog = false;
        this.getList();
      }, (e) => {
        this.waiting = false;
        this.$utils.resp(e);
      });
    },
  },
  // filters: {
  //   substr4(value) {
  //     return value.substr(0, 4);
  //   },
  // },
};
</script>
