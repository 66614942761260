<template>
  <el-card>
    <el-button type="primary" size="small" @click="toAdd()"
      icon="fa fa-fw fa-plus-circle"> 新增</el-button>
    <el-button type="warning" size="small" @click="showExcel"
      icon="fa fa-fw fa-download"> 项目统计</el-button>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search" style="margin-right: 5px;">
        <el-select v-model="queryForm.project_id" placeholder="选择项目" clearable filterable>
          <el-option v-for="(item, index) in relaProjects" :key="index" :value="item.id" :label="`${item.title}（${item.code}）`"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>领用货物</th>
          <th>数量</th>
          <th>领用日期</th>
          <th>所属项目编号</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.goods}}</td>
          <td>{{ item.amount }}{{item.unit}}</td>
          <td>{{ item.take_date }}</td>
          <td>{{ item.code }}</td>
          <td>
            <div class="text-nowrap">
              <!-- <el-button style="margin-right: 3px;" v-if="item.wastage_rate === ''" type="warning" icon="fa fa-fw fa-pencil"
                title="调整信息" @click="toEdit(item)"></el-button> -->
              <el-button style="margin-right: 3px;" icon="fa fa-search fa-fw" @click="toDetail(item)"></el-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新记录'"
      :close-on-click-modal="false" width="600px" top="20px">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="所属项目" prop="project_id">
          <el-select v-model="editModel.project_id" style="width: 100%" placeholder="选择项目" @change="selectedProject">
            <el-option v-for="(item, index) in projects" :key="index" :value="item.id" :label="`${item.title}（${item.code}）`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="领用物品" prop="goods">
          <el-select v-model="editModel.goods" style="width: 100%" placeholder="选择物品">
            <el-option v-for="(item, index) in goods" :key="index"
              :value="item" :label="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="领用日期" prop="take_date">
          <el-date-picker value-format="yyyy-MM-dd" v-model="editModel.take_date" style="width: 100%"></el-date-picker>
        </el-form-item>
        <el-form-item label="领用数量" prop="amount">
          <el-input v-model="editModel.amount" type="number" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="数量单位" prop="unit">
          <el-input v-model="editModel.unit" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="包数件数" prop="count">
          <el-input v-model="editModel.count" style="width: 100%"></el-input>
        </el-form-item>
        <!-- <el-form-item label="损耗" prop="wastage">
          <el-input v-model="editModel.wastage" type="number" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="损耗率" prop="wastage_rate">
          <el-input v-model="editModel.wastage_rate" placeholder="填写后表示记录完结，不可修改" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="结余" prop="rest">
          <el-input v-model="editModel.rest" type="number" style="width: 100%"></el-input>
        </el-form-item> -->
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="editModel.remark" style="width: 100%"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times"> 关 闭</el-button>
        <el-button type="primary" @click="save" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 确 定</el-button>
        <el-button type="danger" icon="fa fa-fw fa-trash" v-if="editModel.id"
          @click="delIt(editModel)" class="pull-left" :disabled="waiting"> 删 除</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showDialog" title="详情"
      :close-on-click-modal="false" width="600px" top="20px">
      <el-form :model="editModel" label-width="100px">
        <el-form-item label="所属项目：" prop="project_id">
          {{editModel.project ? `${editModel.project.title}[${editModel.code}]` : ''}}
        </el-form-item>
        <el-form-item label="领用物品：" prop="goods">
          {{editModel.goods}}
        </el-form-item>
        <el-form-item label="领用日期：" prop="take_date">
          {{editModel.take_date}}
        </el-form-item>
        <el-form-item label="领用数量：" prop="amount">
          {{editModel.amount}}
        </el-form-item>
        <el-form-item label="数量单位：" prop="unit">
          {{editModel.unit}}
        </el-form-item>
        <el-form-item label="包数件数：" prop="count">
          {{editModel.count}}
        </el-form-item>
        <!-- <el-form-item label="损耗：" prop="wastage">
          {{editModel.wastage}}
        </el-form-item>
        <el-form-item label="损耗率：" prop="wastage_rate">
          {{editModel.wastage_rate}}
        </el-form-item>
        <el-form-item label="结余：" prop="rest">
          {{editModel.rest}}
        </el-form-item> -->
        <el-form-item label="备注：" prop="remark">
          {{editModel.remark}}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times"> 关 闭</el-button>
        <el-button type="primary" @click="save" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 确 定</el-button>
        <el-button type="danger" icon="fa fa-fw fa-trash" v-if="editModel.id"
          @click="delIt(editModel)" class="pull-left" :disabled="waiting"> 删 除</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="excelDialog" title="项目统计" :close-on-click-modal="false" width="90%" top="20px">
      <el-select v-model="projectId" placeholder="选择项目" filterable @change="getExcel">
        <el-option v-for="(item, index) in relaProjects" :key="index" :value="item.id" :label="`${item.title}（${item.code}）`"></el-option>
      </el-select>
      <el-button @click="getExcel">查询</el-button>
      <table class="table border mar-top" v-loading="loading">
        <thead>
          <tr>
            <th>产品</th>
            <th>入库日期</th>
            <th>入库数量</th>
            <th>入库量</th>
            <th>领用日期</th>
            <th>领用数量</th>
            <th>领用量</th>
            <!-- <th>损耗</th>
            <th>损耗率</th>
            <th>结余</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in excelList" :key="index">
            <td>{{item.goods}}</td>
            <td>{{item.receive_date}}</td>
            <td>{{item.amount}}</td>
            <td>{{item.count}}</td>
            <td>{{item.take_date}}</td>
            <td>{{item.amount2}}</td>
            <td>{{item.count2}}</td>
            <!-- <td>{{item.wastage}}</td>
            <td>{{item.wastage_rate}}</td>
            <td>{{item.rest}}</td> -->
          </tr>
        </tbody>
      </table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="excelDialog = false" icon="fa fa-fw fa-times"> 关 闭</el-button>
        <el-button type="primary" @click="excelDownload" :disabled="waiting" icon="fa fa-fw fa-download"> 下 载</el-button>
      </div>
    </el-dialog>
    <a ref="downloadLink" :href="excelUrl" :download="fileName" v-show="false"></a>
  </el-card>
</template>

<script>
import xlsx from 'xlsx';

export default {
  data() {
    return {
      loading: false,
      waiting: false,
      list: [],
      queryForm: {
        project_id: '',
      },
      // 项目统计
      projectId: '',
      excelList: [],
      relaProjects: [],
      projects: [],
      goods: [],
      editModel: {},
      rules: {
        project_id: [{ required: true, message: '请选择项目', trigger: 'change' }],
        goods: [{ required: true, message: '请选择产品', trigger: 'change' }],
        take_date: [{ required: true, message: '领用日期必须填写', trigger: ['change', 'blur'] }],
        amount: [{ required: true, message: '请填写领用数量', trigger: ['change', 'blur'] }],
      },
      editDialog: false,
      showDialog: false,
      excelDialog: false,
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      total: 0, // 合计费用
      excelUrl: '',
      fileName: '',
    };
  },
  computed: {
    projectIds() {
      return this.relaProjects.map(item => item.id);
    },
    excelProject() {
      let result = {};
      const arr = this.relaProjects.filter(item => item.id === this.projectId);
      result = arr[0];
      return result;
    },
  },
  created() {
    this.getProject();
    this.queryProjects();
  },
  methods: {
    getProject() {
      this.axios.get('com-use/projects').then((resp) => {
        this.projects = resp.data;
      });
    },
    queryProjects() {
      this.axios.get('com-use/rela-projects').then((resp) => {
        this.relaProjects = resp.data;
      });
    },
    selectedProject(id) {
      this.editModel.code = this.projects.filter(item => item.id === id)[0].code;
      this.editModel.goods = '';
      this.goods = [];
      this.axios.get(`com-use/project/${id}/goods`).then((resp) => {
        this.goods = resp.data;
      });
    },
    showExcel() {
      this.projectId = '';
      this.excelList = [];
      this.excelDialog = true;
    },
    getExcel() {
      this.loading = true;
      this.excelList = [];
      this.axios.get(`com-use/project/${this.projectId}/excel`).then((resp) => {
        this.loading = false;
        this.excelList = resp.data;
      }, (err) => {
        this.loading = false;
        this.$utils.resp(err);
      });
    },
    // excel 导出
    excelDownload() {
      const arr = [['产品', '入库日期', '入库数量', '入库量', '领用日期', '领用数量', '领用量', '损耗', '损耗率', '结余']];
      let i = 0;
      this.excelList.forEach((item) => {
        const line = [];
        line.push(item.goods);
        line.push(item.receive_date);
        line.push(item.amount);
        line.push(item.count);
        line.push(item.take_date);
        line.push(item.amount2);
        line.push(item.count2);
        const prowastage = this.excelProject.wastages;
        if (i === 0) {
          line.push('');
          line.push(prowastage.wastage_rate);
          line.push(prowastage.rest);
        }
        arr.push(line);
        i += 1;
      });
      // 单元格样式
      const styleBase = {
        border: { // 设置边框
          top: { style: 'thin', color: '#000000' },
          bottom: { style: 'thin', color: '#000000' },
          left: { style: 'thin', color: '#000000' },
          right: { style: 'thin', color: '#000000' },
        },
      };
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < 10; j += 1) {
          arr[i][j] = { v: (arr[i][j] ? arr[i][j] : ''), s: styleBase };
        }
      }
      const ws = xlsx.utils.aoa_to_sheet(arr);
      ws['!cols'] = [
        { wch: 30 },
        { wch: 12 },
        { wch: 10 },
        { wch: 10 },
        { wch: 12 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ];

      // const range = xlsx.utils.decode_range(ws['!ref']);
      /* create new workbook */
      const wb = xlsx.utils.book_new();

      /* convert table 'table1' to worksheet named "Sheet1" */
      xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');

      const fileBinary = xlsx.write(wb, { type: 'binary', bookType: 'xlsx', bookSST: false });
      const blob = new Blob([this.$utils.s2ab(fileBinary)], {
        // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const objurl = URL.createObjectURL(blob);
      this.excelUrl = objurl;
      this.fileName = `${this.excelProject.title}_${this.excelProject.code}.xlsx`;
      this.$nextTick(() => {
        this.$refs.downloadLink.click();
      });
    },
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$http.get('com-use/record', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          this.list = list;
          this.totalItem = response.data.total;
          this.loading = false;
        }, (err) => {
          this.loading = false;
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toAdd() {
      this.goods = [];
      this.editModel = {
        project_id: '',
        code: '',
        take_date: this.$utils.formatDate(),
        goods: '',
        amount: '',
        unit: '',
        count: '',
        wastage: '',
        wastage_rate: '',
        rest: '',
        remark: '',
      };
      this.editDialog = true;
      if (this.$refs.editForm) {
        this.$nextTick(() => {
          this.$refs.editForm.clearValidate();
        });
      }
    },
    toEdit(item) {
      this.editModel = { ...item };
      this.editDialog = true;
      if (this.$refs.editForm) {
        this.$nextTick(() => {
          this.$refs.editForm.clearValidate();
        });
      }
    },
    toDetail(item) {
      this.axios.get('com-use/record/' + item.id).then((resp) => {
        this.editModel = resp.data;
        this.showDialog = true;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    save() {
      this.waiting = true;
      const type = this.editModel.id ? 'put' : 'post';
      const idStr = this.editModel.id ? '/' + this.editModel.id : '';
      this.$http[type]('com-use/record' + idStr, this.editModel).then(() => {
        // 新项目记录
        if (this.projectIds.indexOf(this.editModel.project_id) === -1) {
          this.queryProjects();
        }
        this.waiting = false;
        this.editDialog = false;
        this.getList();
        this.$message.success('保存成功');
      }, (err) => {
        this.waiting = false;
        this.$utils.resp(err);
      });
    },
    delIt(item) {
      const { id } = item;
      this.$confirm('删除后无法恢复，是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.delete(`com-use/record/${id}`).then(() => {
          this.getList();
          this.editDialog = false;
          this.waiting = false;
          this.$message({
            type: 'success',
            message: '删除成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.waiting = false;
          this.$utils.resp(resp);
        });
      }, () => {});
    },
  },
};
</script>

<style>

</style>
