<template>
  <el-card class="components">
    <!-- <el-button type="primary" v-if="isDeliveryManager" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 特殊入库</el-button> -->
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.project_number" clearable placeholder="所属项目号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="输入编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search" v-if="!isParty">
        <el-select style="width: 100%" v-model="queryForm.company_id" v-if="!isParty" placeholder="选择工厂" :loading="queryPartiesLoading"
          clearable filterable remote :remote-method="queryParties">
          <el-option v-for="item in parties" :key="item.party_id"
            :value="item.party_id" :label="item.party_name"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search" v-if="!isParty">
        <el-select style="width: 100%" v-model="queryForm.status">
          <el-option v-for="(val, key) in stockStatusMap" :key="key"
            :value="key" :label="val"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>公司名称</th>
          <th>编号</th>
          <th>产品名称</th>
          <th>款号</th>
          <th>规格</th>
          <th>颜色/其他</th>
          <th>数量(剩余/总数)</th>
          <th>入库时间</th>
          <th>状态</th>
          <!-- <th>操作</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.company_name }}</td>
          <td :title="item.project_number">{{ item.code }}</td>
          <td>{{ item.item_name }}</td>
          <td>{{ item.attr1 }}</td>
          <td>{{ item.attr2 }}</td>
          <td>{{ item.color }}</td>
          <td>{{ 1*item.left_quantity }} / {{1*item.quantity}} ({{item.uom}})</td>
          <td>{{ item.transaction_date }}</td>
          <td>{{ item.status_text }}</td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '编辑' : '新增'"
      :close-on-click-modal="false" width="90%" top="2%">
      <el-form :model="editModel" ref="editForm" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属项目" prop="project_id">
              <el-select v-model="editModel.project_id" style="width:100%;" filterable
                remote :remote-method="getProjects" @change="chooseProject">
                <el-option label="" value=""></el-option>
                <el-option v-for="item in projects" :key="item.id"
                  :value="item.project_id" :label="item.project_number">{{`【${item.project_number}】 ${item.description}`}}</el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="editModel.project_id">
            <el-form-item label="采购合同" prop="po_header_id">
              <el-select v-model="editModel.po_header_id" style="width:100%;" clearable
                filterable @change="choosePo">
                <el-option label="" value=""></el-option>
                <el-option v-for="item in pos" :key="item.po_header_id"
                  :value="item.po_header_id" :label="`【${item.po_number}】 ${item.business_type_name}`"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="业务部门">
              <!-- <el-input readonly :value="userDeptId"></el-input> -->
              <el-select :value="userDeptId" style="width: 100%">
                <el-option v-for="item in deptMap" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库组织" prop="organization_id">
              <el-select v-model="editModel.organization_id" style="width: 100%;" @change="getInventories">
                <el-option value="" label=""></el-option>
                <el-option v-for="(item) in organizations" :key="item.organization_id"
                  :value="item.organization_id" :label="item.organization_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="仓库企业" prop="company_id">
              <el-select style="width: 100%" v-model="editModel.company_id" placeholder="仓库企业" :loading="queryPartiesLoading"
                clearable filterable remote :remote-method="queryParties2" @change="getInventories">
                <el-option v-for="item in parties2" :key="item.party_id"
                  :value="item.party_id" :label="item.party_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库" prop="secondary_inventory_name">
              <el-select v-model="editModel.secondary_inventory_name" style="width: 100%;">
                <el-option v-for="(item, idx) in inventories" :key="idx"
                  :value="item.secondary_inventory_name" :label="item.description"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="入库日期" prop="receipt_date">
              <el-date-picker v-model="editModel.receipt_date"
                style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注说明" prop="remark">
          <el-input type="textarea" v-model="editModel.remark"></el-input>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            明细
            <el-tooltip content="数量前面的参数相同会归为相同产品" placement="top" effect="light">
              <i class="el-icon-warning text-warning"></i>
            </el-tooltip>
          </template>
          <table class="items bordered table" style="width: 100%;">
            <thead>
              <tr>
                <!-- <th class="text-center">货号</th> -->
                <th class="text-center">品名</th>
                <th class="text-center">款号</th>
                <th class="text-center">规格</th>
                <th class="text-center">颜色/其他</th>
                <th class="text-center" width="110">数量</th>
                <th class="text-center" width="70">单位</th>
                <!-- <th class="text-center">单价</th> -->
                <!-- <th class="text-center">金额</th> -->
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in editModel.items" :key="index" :title="item.remark">
                <!-- <td><el-input v-model="item.sn"></el-input></td> -->
                <td>
                  <el-input :readonly="item.item_id" v-model="item.item_name"></el-input>
                </td>
                <td><el-input :readonly="item.item_id" v-model="item.attr1"></el-input></td>
                <td>
                  <el-input :readonly="item.item_id" v-model="item.attr2"></el-input>
                </td>
                <td>
                  <el-input v-model="item.color"></el-input>
                </td>
                <td>
                  <el-input class="text-right" v-model="item.quantity" type="number"></el-input>
                </td>
                <td><el-input v-model="item.uom" :readonly="item.item_id"></el-input></td>
                <!-- <td><el-input v-model="item.unit_price" @change="calcItem(item)" type="number"></el-input></td>
                <td><el-input v-model="item.sum" type="number"></el-input></td> -->
                <td>
                  <el-button @click="delItem(index)"
                    type="danger" title="删除" icon="el-icon-delete"></el-button>
                </td>
              </tr>
              <tr>
                <td colspan="6" class="text-right">
                </td>
                <!-- <td class="text-right text-info">{{total}}</td> -->
                <td>
                  <el-button @click="addItem" type="primary" title="新增" icon="el-icon-plus"></el-button>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="save(false)" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 保 存</el-button>
        <!-- <el-button v-if="editModel.id"
          type="danger" @click="delIt" class="pull-left" :disabled="waiting" icon="fa fa-fw fa-trash"> 删 除</el-button> -->
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      waiting: false,
      loading: false,
      // 查询条件form
      queryForm: {
        party_id: '',
        company_id: '',
        q: '',
        code: '',
        project_number: '',
        status: 'APPROVED',
      },
      queryParams: {}, // 分页查询参数
      parties: [], // select party options array
      parties2: [], // adit时候的选项
      queryPartiesLoading: false, // query-remote parties loading flag
      list: [], // 管理的列表对象
      editModel: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      projects: [], // 新增下拉选择的options
      pos: [],
      inventories: [], // 仓库编码
      showModel: {},
      detailDialog: false,
      rules: {
        project_id: [{ required: true, message: '选择项目', trigger: ['change', 'blur'] }],
        company_id: [{ required: true, message: '选择公司', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  beforeCreate() {
    if (this.$store.state.common.depts.length === 0) {
      this.$store.dispatch('GET_DEPTS');
    }
    if (this.$store.state.common.organizations.length === 0) {
      this.$store.dispatch('GET_ORGANIZATION');
    }
  },
  created() {
    this.onSubmitQuery();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      userMeta: state => state.auth.userMeta,
      cirCompanyType: state => state.auth.cirCompanyType,
      curDeptId: state => state.auth.curDeptId,
      organizations: state => state.common.organizations,
      curCompanyType: state => state.auth.curCompanyType,
      stockStatusMap: state => state.common.stockStatusMap,
    }),
    ...mapGetters([
      'deptMap',
    ]),
    userDeptId() {
      let deptId = null;
      if (String(this.curCompanyType) === '2') {
        deptId = this.curDeptId;
      } else {
        deptId = this.user.department_id;
      }
      return deptId;
    },
    // 工厂库
    isParty() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('dv_party') !== -1) {
        result = true;
      }
      return result;
    },
    // 公司部门用户
    isDeliveryManager() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('delivery_order_manager') !== -1) {
        result = true;
      }
      return result;
    },
    isAdmin() {
      return this.user.type === 'admin';
    },
  },
  watch: {
    user() {
      this.onSubmitQuery();
    },
    curCompanyType() {
      this.onSubmitQuery();
    },
  },
  methods: {
    // 查询企业
    queryParties(q) {
      const params = {
        limit: 20,
        q: {
          status_code: 'APPROVED',
        },
      };
      if (typeof q === 'string' && q.trim() !== '') {
        const name = q.trim();
        params.q.party_name = { $like: `%${name}%` };
      } else {
        return;
      }
      this.queryPartiesLoading = true;
      this.$http.get('erp-parties/', { params }).then((resp) => {
        const list = resp.data.items;
        this.queryPartiesLoading = false;
        this.parties = list;
      }, (err) => {
        this.queryPartiesLoading = false;
        this.$utils.resp(err);
      });
    },
    queryParties2(q) {
      const params = {
        limit: 20,
        q: {
          status_code: 'APPROVED',
        },
      };
      if (typeof q === 'string' && q.trim() !== '') {
        const name = q.trim();
        params.q.party_name = { $like: `%${name}%` };
      } else {
        return;
      }
      this.queryPartiesLoading = true;
      this.$http.get('erp-parties/', { params }).then((resp) => {
        const list = resp.data.items;
        this.queryPartiesLoading = false;
        this.parties2 = list;
      }, (err) => {
        this.queryPartiesLoading = false;
        this.$utils.resp(err);
      });
    },
    getInventories() {
      if (!this.editModel.company_id) {
        return;
      }
      const params = {
        party_id: this.editModel.company_id,
        organization_id: this.editModel.organization_id,
      };
      this.$http.get('inventory', { params }).then((resp) => {
        this.inventories = resp.data;
        if (this.inventories.length > 0) {
          this.editModel.secondary_inventory_name = this.inventories[0].secondary_inventory_name;
        } else {
          this.editModel.secondary_inventory_name = '';
        }
      });
    },
    // 查询
    onSubmitQuery() {
      if (!this.user.id) {
        return;
      }
      const params = {};
      params.status = this.queryForm.status;
      if (this.queryForm.company_id) {
        params.company_id = this.queryForm.company_id;
      }
      if (this.queryForm.dept_id) {
        params.dept_id = this.queryForm.dept_id;
      }
      if (this.queryForm.code.trim()) {
        params.code = this.queryForm.code.trim();
      }
      if (this.queryForm.project_number.trim()) {
        params.project_number = this.queryForm.project_number.trim();
      }
      if (this.queryForm.q.trim()) {
        params.q = this.queryForm.q.trim();
      }
      this.queryParams = params;
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      const params = this.queryParams;
      params.pageSize = this.pageSize;
      params.page = this.currentPage;
      this.loading = true;
      this.$http.get('stock', { params }).then((resp) => {
        this.loading = false;
        const list = resp.data.data;
        this.list = list;
        this.totalItem = resp.data.total;
      }, (err) => {
        this.loading = false;
        this.$utils.resp(err);
      });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 新增入库
    toAdd() {
      if (this.projects.length === 0) {
        this.getProjects('');
      }
      this.editModel = {
        type: 'input',
        organization_id: '',
        secondary_inventory_name: '',
        project_id: '',
        receipt_date: this.$utils.FormatDate(),
        po_header_id: '',
        po_number: '',
        department_id: this.userDeptId,
        company_id: '',
        company_name: '',
        // item_name: '',
        // attr1: '',
        // attr2: '',
        // color: '',
        // uom: '',
        // quantity: '',
        // unit_price: '',
        // unit_vat_price: '',
        remark: '',
        count: '',
        items: [],
        // left_quantity: '',
      };
      if (this.deptMap && this.userDeptId) {
        const depts = this.deptMap[this.userDeptId].name.split('.');
        if (depts.length > 1) {
          const name = depts[1].substr(0, 2);
          const org = this.organizations.filter(item => item.organization_name.indexOf(name) > -1);
          if (org.length > 0) {
            this.editModel.organization_id = org[0].organization_id;
          }
        }
      }
      this.editDialog = true;
      if (this.$refs.editForm) {
        this.$nextTick(() => {
          this.$refs.editForm.clearValidate();
        });
      }
    },
    // 项目查询方法
    getProjects(qStr) {
      const params = {
        limit: 20,
        q: {
          // status_code: 'APPROVED',
          // project_number: { $like: `%${qStr}%` },
        },
      };
      if (typeof qStr === 'number') {
        params.q.project_id = qStr;
      } else if (qStr && qStr.trim() !== '') {
        params.q.project_number = { $like: `%${qStr}%` };
      } else {
        const dateStr = this.$utils.FormatDate(new Date(new Date().getTime() - 100 * 86400000), 'yyyy-MM-ddT00:00:00Z');
        params.q.creation_date = { $gt: { $date: dateStr } };
      }
      return this.$http.get('erp-project', { params }).then((resp) => {
        const list = resp.data.items;
        list.forEach((item) => {
          item.cur = false;
        });
        this.projects = list;
        return list;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    // project choose
    chooseProject(id, isInit = false) {
      const projects = this.projects.filter(item => item.project_id === id);
      if (projects.length === 1) {
        const project = projects[0];
        const projectNum = project.project_number;
        const params = {
          q: {
            // status_code: 'APPROVED',
            project_number: projectNum,
            // group_id: this.groupId,
            // po_number: 'GHQ141211001P17',
          },
        };
        if (!isInit) {
          this.editModel.po_header_id = '';
        }
        this.$http.get('erp-po', { params }).then((resp) => {
          const list = resp.data.items;
          this.pos = list;
        });
      }
    },
    choosePo(id) {
      if (!id) {
        this.editModel.items = [];
        return;
      }
      const arr = this.pos.filter(item => item.po_header_id === id);
      if (arr.length === 1) {
        const poHeader = arr[0];
        // 查询合同明细
        const params = {
          po_number: poHeader.po_number,
          q: {
            po_header_id: id,
          },
        };
        this.$http.get(`erp-po/${id}`, { params }).then((resp) => {
          const lines = resp.data;
          this.addPoItems(lines, poHeader);
        });
      }
    },
    addPoItems(lines, po) {
      this.editModel.items = [];
      lines.forEach((item) => {
        const obj = {
          org: item,
          obj_id: item.po_line_id,
          po_number: po.po_number,
          item_id: item.item_id,
          item_name: item.item_name,
          attr1: item.item_attribute1,
          attr2: item.item_attribute2,
          unit_price: item.unit_price,
          unit_vat_price: item.unit_vat_price,
          uom: item.pricing_quantity_uom,
          quantity: item.pricing_quantity,
          color: '',
          real_quantity: 0,
          sum: 0,
        };
        this.editModel.items.push(obj);
      });
    },
    addItem() {
      const obj = {
        po_number: '',
        item_name: '',
        attr1: '',
        attr2: '',
        unit_price: '',
        unit_vat_price: '',
        uom: '',
        quantity: 0,
        color: '',
        real_quantity: 0,
        sum: 0,
      };
      this.editModel.items.push(obj);
    },
    // 修改新增的保存
    save() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.editModel };
          if (obj.items.length <= 0) {
            this.$message({
              type: 'warning',
              message: '至少需要有一条数据',
              showClose: true,
              duration: 2000,
            });
            return;
          }
          let flag = true;
          obj.items.forEach((item) => {
            if (item.item_name.trim() === '' || item.quantity <= 0) {
              flag = false;
            }
          });
          if (!flag) {
            this.$message({
              type: 'warning',
              message: '产品名称不能为空，数量也不能为0',
              showClose: true,
              duration: 2000,
            });
            return;
          }
          this.waiting = true;
          if (obj.id) {
            // this.$http.put(`shippingcompany/${obj.id}`, obj).then(() => {
            //   this.editDialog = false;
            //   this.getList();
            //   this.$message({
            //     type: 'success',
            //     message: '更新成功',
            //     showClose: true,
            //     duration: 2000,
            //   });
            // }, (err) => {
            //   this.$utils.resp(err);
            // }).then(() => {
            //   this.waiting = false;
            // });
          } else {
            const projects = this.projects.filter(item => item.project_id === obj.project_id);
            if (projects.length === 1) {
              const project = projects[0];
              const projectNum = project.project_number;
              obj.project_number = projectNum;
            } else {
              this.$message({
                type: 'warning',
                message: '没有对应项目',
                showClose: true,
                duration: 2000,
              });
              return;
            }
            const company = this.parties2.filter(item => item.party_id === obj.company_id);
            if (company.length === 1) {
              obj.company_name = company[0].party_name;
            } else {
              this.$message({
                type: 'warning',
                message: '没有对应企业',
                showClose: true,
                duration: 2000,
              });
              return;
            }
            this.$http.post('stock', obj).then(() => {
              this.editDialog = false;
              this.$message({
                type: 'success',
                message: '保存成功',
                showClose: true,
                duration: 2000,
              });
              this.changePage(1);
            }, (err) => {
              this.$utils.resp(err);
            }).then(() => {
              this.waiting = false;
            });
          }
        }
      });
    },
    delItem(index) {
      this.editModel.items.splice(index, 1);
    },
    toDetail(item) {
      this.addUserBox = false;
      this.addLogin = '';
      this.$http.get(`erp-party/${item.party_id}/contracts`).then((resp) => {
        const list = resp.data;
        this.contacts = list;
      });
      this.$http.get(`erp-party/${item.party_id}`).then((resp) => {
        this.showModel = resp.data;
        this.detailDialog = true;
      });
    },
  },
};
</script>
