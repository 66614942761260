<template>
  <el-card class="component">
    <el-row>
      <el-col :span="8">
        <el-form label-width="100px">
          <el-form-item  label="出库类型">
            <el-select v-model="outType" placeholder="出库类型">
              <el-option v-for="(label, key) in outTypes" :key="key" :value="key" :label="`${label}`"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="8">
        <el-form label-width="100px">
          <el-form-item  label="临时添加">
            <el-input v-model="custCode" @keydown.enter.native="scanFinish(custCode)"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="8" class="text-right">
        <el-button type="warning" @click="reset">清空</el-button>
        <el-button type="primary" @click="outStores" :disabled="!isFinish">出库</el-button>
      </el-col>
    </el-row>
    <table class="table border">
      <thead>
        <tr>
          <th>商品编号</th>
          <th>图片</th>
          <th>类型</th>
          <th>颜色</th>
          <th>尺码</th>
          <th>数量</th>
          <th>待拣货</th>
          <th>扫码记录</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in toList" :key="item.code">
          <td>{{item.code}}</td>
          <td>
            <el-popover v-if="item.imgs.length === 1"
              placement="right"
              trigger="hover">
              <img :src="'data:image/jpg/png/gif;base64,' + item.img">
              <img slot="reference"
                :src="'data:image/jpg/png/gif;base64,' + item.imgs[0].simg" alt="">
            </el-popover>
          </td>
          <td>{{item.category_text}}</td>
          <td>{{item.color_text}}</td>
          <td>{{item.size_text}}</td>
          <td style="width:150px;"><el-input v-model="item.count" type="number"></el-input></td>
          <td>
            {{item.count - item.outList.length > 0 ? item.count - item.outList.length : ''}}
            <span v-if="item.count == item.outList.length" class="el-icon-check text-primary"></span>
          </td>
          <td>
            <div v-for="subItem in item.outList" :key="subItem.code">{{subItem.code}}</div>
          </td>
        </tr>
      </tbody>
    </table>
    <el-dialog :visible.sync="showDialog" title="详情"
      :close-on-click-modal="false" :close="closeShowDialog">
      <el-row :gutter="10">
        <el-col :span="12">
          <div class="grid-content">
            <label>款号：</label>
            {{showObj.season}}{{showObj.category}}{{showObj.style_num}}【{{showObj.rela_style}}】
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <label>款式：</label>
            {{showObj.category_text}}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <label>颜色：</label>
            {{showObj.color_text}}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <label>尺码：</label>
            {{showObj.size_text}}
          </div>
        </el-col>
      </el-row>
      <div>
        <span class="text-primary" style="font-size: 20px;">{{scanCode}}</span>
      </div>
      <div>
        <img :src="'data:image/jpg/png/gif;base64,'+showObj.img" alt="">
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      scanCode: '',
      custCode: '',
      lastKeyTime: null,
      toList: [],
      outList: [],
      showDialog: false,
      showObj: {},
      outType: '4',
      outTypes: {
        4: '天猫',
        1: '淘宝',
      },
    };
  },
  created() {
    window.document.addEventListener('keydown', this.keydown);
    this.init();
  },
  computed: {
    leftMap() {
      const map = {};
      this.toList.forEach((item) => {
        map[item.code] = item;
      });
      return map;
    },
    isFinish() {
      let result = true;
      if (this.toList.length === 0) {
        result = false;
      }
      this.toList.forEach((item) => {
        if (item.outList.length < item.count) {
          result = false;
        }
      });
      return result;
    },
  },
  methods: {
    init() {
    },
    reset() {
      this.toList = [];
      this.outList = [];
      this.scanCode = '';
    },
    keydown(e) {
      const curTime = new Date().getTime();
      const keyCode = e.which;
      if (!this.lastKeyTime) {
        this.lastKeyTime = curTime;
      }
      if ((curTime - this.lastKeyTime) < 40) {
        if (keyCode === 13) {
          this.scanCode = this.code;
          this.scanFinish(this.code);
        } else {
          this.code += String.fromCharCode(keyCode);
        }
      } else {
        this.code = String.fromCharCode(keyCode);
      }
      this.lastKeyTime = curTime;
    },
    scanFinish(code) {
      this.scanCode = code;
      if (code.length === 10) {
        if (this.leftMap[code]) {
          this.$message({
            type: 'warning',
            message: '已经扫码，请修改数量或者重新扫码',
          });
          return;
        }
        const ecode = encodeURIComponent(code);
        this.$http.get(`clothesbycode/${ecode}`).then((resp) => {
          if (resp.data.clothes) {
            const toObj = resp.data.clothes;
            toObj.count = 1;
            toObj.outList = [];
            this.toList.push(toObj);
          } else {
            this.$message({
              type: 'warning',
              message: '未找到对应款式',
            });
          }
        });
      } else if (code.length === 13) {
        if (this.outList.indexOf(code) !== -1) {
          this.$message({
            type: 'warning',
            message: '该商品已经扫码',
          });
          return;
        }
        const num = code.substr(0, 10);
        const relaObj = this.leftMap[num];
        if (!relaObj) {
          this.$message({
            type: 'warning',
            message: '非出货商品，请检查出货单是否扫码完全',
          });
          return;
        }
        if (relaObj.outList.length === relaObj.count) {
          this.$message({
            type: 'warning',
            message: '同类商品已经达到出货数量',
          });
          return;
        }
        this.$http.get('clothes-out-check', { params: { code } }).then((resp) => {
        // this.$http.put('clothes-out', { code, out_type: this.outType }).then((resp) => {
          const obj = resp.data;
          obj.out_type = this.outType;
          this.outList.push(code);
          relaObj.outList.push(obj);
          // this.showDialog = true;
          this.showObj = relaObj;
        }, (err) => {
          this.$utils.resp(err);
        });
      } else {
        this.$message({
          type: 'error',
          message: `[${code}] 不是正确的条码编号`,
        });
      }
    },
    changeOutType(index) {
      const obj = this.addList[index];
      this.$http.put(`clothes-out/${obj.id}/type`, { out_type: obj.otype }).then((resp) => {
        obj.out_type = resp.data.out_type;
      }, (err) => {
        obj.otype = obj.out_type;
        this.$utils.resp(err);
      });
    },
    sort() {
      this.showMultiple();
      this.addList = this.addList.sort((a, b) => {
        let result = 0;
        if (a.code > b.code) {
          result = 1;
        } else {
          result = -1;
        }
        return result;
      });
    },
    showMultiple() {
      this.addList.forEach((item) => {
        if (this.addList.filter(x => x.code === item.code).length > 1) {
          this.$set(item, 'isMul', true);
        } else {
          this.$set(item, 'isMul', false);
        }
      });
    },
    outStores() {
      const list = this.outList;
      this.$http.put('taobao-clothes-outs', { list, out_type: this.outType }).then((resp) => {
        let duration = 3000;
        let errMessage = '。';
        const result = resp.data;
        let clearList = true;
        if (resp.data.err.length > 0) {
          clearList = false;
          duration = 0;
          errMessage = `；未成功出库的编号： ${result.err} `;
        }
        this.$message({
          type: duration > 0 ? 'success' : 'danger',
          message: `成功出库 ${result.success} 条${errMessage} `,
          duration,
          showClose: true,
        });
        if (clearList) {
          this.toList = [];
          this.outList = [];
          this.scanCode = '';
        }
      });
    },
    closeShowDialog() {},
  },
  destroyed() {
    window.document.removeEventListener('keydown', this.keydown);
  },
};
</script>

<style scoped>
</style>
