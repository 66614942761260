<template>
  <el-card class="components">
    <div class="mar-btm-10 pull-right">
      <el-form :model="queryForm" inline>
        <el-form-item label="用车时间段">
          <el-date-picker
            v-model="queryDates"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item><el-button size="small" icon="fa fa-fw fa-search" @click="onSubmitQuery"/></el-form-item>
      </el-form>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>费用部门</th>
          <th v-for="(item, index) in drivers" :key="index">{{item}}</th>
          <th>合计</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, dept, idx) in dataList" :key="dept">
          <td>{{idx+1}}</td>
          <td>{{ dept }}</td>
          <td v-for="(driver, index) in drivers" :key="index">{{item[driver] ? item[driver]: '0'}}</td>
          <td>{{item['小计']}}</td>
        </tr>
        <tr>
          <td>#</td>
          <td>合计</td>
          <td v-for="(driver, index) in drivers" :key="index">
            {{driverSum[driver] ? driverSum[driver] : ''}}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="stepForm.id ? '编辑' : '新增'"
      :close-on-click-modal="false">
      <el-form :model="stepForm" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="名称" prop="name">
          <el-input v-model="stepForm.name"></el-input>
        </el-form-item>
        <el-form-item label="优先级" prop="sort">
          <el-input type="number" v-model="stepForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="说明备注" prop="remark">
          <el-input v-model="stepForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="stepForm.id" :disabled="waiting"
          title="删除" type="danger" @click="delstep"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="savestep">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true" :disabled="waiting"></i> 确 定</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      waiting: false,
      queryDates: [],
      statuses: {
        1: '待审核',
        2: '待安排',
        3: '已分配',
        4: '已出车',
        // 5: '司机出车结束',
        6: '待分配里程',
        7: '已分配结束',
        0: '退回，取消用车',
      },
      // 查询条件form
      queryForm: {
        applyname: '',
        status: '>2',
        drivename: '',
        address: '',

      },
      depts: [],
      dataList: [], // 管理的列表对象
      drivers: [],
      driverSum: {},
      stepForm: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      // 编辑form的条件
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
        sort: [{ required: true, message: '排序不能为空', trigger: ['change', 'blur'] }],
      },
    };
  },
  created() {
    this.getDepts();
    this.onSubmitQuery();
  },

  methods: {
    getDepts() {
      this.axios.get('oa/pc-dicts').then((resp) => {
        const root = resp.data.depts;
        this.depts = root.children;
      });
    },
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      if (this.queryDates && this.queryDates.length > 0) {
        this.queryParams.startdate = this.queryDates[0];
        this.queryParams.enddate = this.queryDates[1];
      }
      // if (this.queryForm.q && this.queryForm.q.trim()) {
      //   this.queryParams.q = this.queryForm.q;
      // }
      this.getList();
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.axios.get('pcxttj', { params: { ...this.queryParams } })
        .then((response) => {
          this.loading = false;
          this.dataList = response.data.data;
          const driverSum = response.data.sum;
          this.driverSum = response.data.sum;
          this.drivers = Object.keys(driverSum);
        }, (response) => {
          this.loading = false;
          this.$utils.resp(response);
        });
    },
    toEdit(editObj) {
      this.stepForm = { ...editObj };
      this.editDialog = true;
    },
    toAdd() {
      const step = {
        name: '',
        type: '',
        code: '',
        sort: 10,
        remark: '',
      };
      this.stepForm = step;
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    // 修改新增的保存
    savestep() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const tempForm = { ...this.stepForm };
          if (tempForm.id) {
            this.waiting = true;
            this.$http.put(`dystep/${tempForm.id}`, tempForm).then(() => {
              this.waiting = false;
              this.editDialog = false;
              this.getList();
            }, (resp) => {
              this.waiting = false;
              this.$utils.resp(resp);
            });
          } else {
            this.waiting = true;
            this.$http.post('dystep', tempForm).then(() => {
              this.waiting = false;
              this.editDialog = false;
              this.getList();
            }, (resp) => {
              this.waiting = false;
              this.$utils.resp(resp);
            });
          }
        }
      });
    },
    delstep() {
      this.$constep('是否确认删除？', '确认信息', {
        constepButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.$http.delete(`dystep/${this.stepForm.id}`).then(() => {
          this.waiting = false;
          this.getList();
          this.editDialog = false;
        }, (resp) => {
          this.$utils.resp(resp);
          this.waiting = false;
        });
      }, () => {
      });
    },
  },
};
</script>
