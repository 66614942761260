<template>
<div class="full-screen">
  <div class="login-wrap login-width">
    <div class="login-title text-center">
      <div class="login-title-name">管理平台</div>
      <div class="login-title-description text-center pad-top mar-btm text-muted">资产管理平台</div>
    </div>
    <div class="login-content">
      <el-form :model="loginFormData" @keyup.enter.native="onSubmit">
        <el-form-item class="mar-btm">
          <el-input v-model="loginFormData.login_name" placeholder="登录用户名/手机"
            :disabled="loading" ref="login_name" autoComplete="on">
            <template slot="append"><i class="fa fa-user"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item class="mar-btm">
          <el-input type="password" v-model="loginFormData.password" placeholder="登录密码"
            :disabled="loading" ref="password">
            <template slot="append"><i class="fa fa-lock"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item class="mar-btm">
          <!-- <el-checkbox class="pull-left" v-model="loginFormData.remember">记住登录状态</el-checkbox> -->
        </el-form-item>
        <el-form-item class="mar-btm">
          <el-button class="login-button" type="primary" @click="onSubmit" :disabled="isValidated"
            v-loading="loading">登 录</el-button>
        </el-form-item>
      </el-form>

      <!-- <div class="login-addition pad-ver">
        <div class="text-center">
          <el-button type="text" @click="hintForgetPassword">忘记密码</el-button>
          <el-button type="text" @click="showRegister=true">注册新用户</el-button>
        </div>
      </div> -->
    </div>
  </div>

  <el-dialog v-model="showRegister" size="tiny" :show-close="false">
    <div class="text-center">
      <img src="/static/app-qr.png" />
      <div>请使用手机App注册用户</div>
    </div>
    <div slot="footer" class="dialog-footer text-center">
      <el-button type="primary" @click="showRegister=false">确定</el-button>
    </div>
  </el-dialog>

  <div class="text-center mar-top text-muted">
     &copy; <a href="https://beian.miit.gov.cn">苏ICP备18042792号</a>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  data() {
    return {
      loginFormData: {
        login_name: '',
        password: '',
        remember: true,
      },
      showRegister: false,
    };
  },
  computed: {
    ...mapState({
      loading: state => state.auth.loginLoading,
      loginData: state => state.auth.loginData,
    }),
    isValidated() {
      // disabled为false时，即为valid状态
      return !(this.loginFormData.login_name && this.loginFormData.password && !this.loading);
    },
  },
  mounted() {
    // 浏览器自动填充需要设置一下
    setTimeout(() => {
      const evt = window.document.createEvent('HTMLEvents');
      evt.initEvent('input', false, true);
      this.$refs.login_name.$refs.input.dispatchEvent(evt);
      this.$refs.password.$refs.input.dispatchEvent(evt);
    }, 500);
  },
  methods: {
    onSubmit() {
      if (!this.isValidated) {
        this.$nextTick(() => {
          this.$store.commit('SET_LOGIN_LOADING', { loading: true });
        });
        this.$store.dispatch('AUTH_LOGIN', {
          ...this.loginFormData,
          agent: `web || ${navigator.platform} || ${navigator.userAgent}`,
        });
      }
    },
    hintForgetPassword() {
      this.$alert('请使用手机App的找回密码功能');
    },
  },
  watch: {
    loginData(val) {
      if (val) {
        this.$store.dispatch('AUTH_GET_USER').then(() => {
          this.$router.push('/');
        });
      }
    },
  },
};

</script>

<style>
  .full-screen {
    /*height: 100vh;*/
  }
  .login-width {
    width: 360px;
    max-width: 100%;
  }
  .login-wrap {
    margin: 0 auto;
    margin-top: 10vh;
    box-shadow: 0 0 3px rgba(0,0,0,0.5);
  }
  .login-title {
    color: #fff;
    background: #fff;
    border-color: #3a3f51;
    padding: 20px 15px 10px;
  }
  .login-title-name {
    font-size: 24px;
    color: #00c0f1;
    line-height: 2;
    font-weight: bold;
  }
  .login-content {
    background: #fff;
    padding: 10px 20px 20px;
  }
  .login-description {
    font-size: 14px;
    color: #afb9c3;
    line-height: 1.42857143;
    margin-bottom: 10px;
  }
  .login-button {
    width: 100%;
    height: 32px;
    font-size: 14px;
  }
  .login-addition a {
    color: #647484;
    text-decoration: none;
  }
  .login-addition a:hover {
    text-decoration: underline;
  }
  .login-forgot-password {
    margin-right: 15px;
  }
  .login-new-account {
    margin-left: 15px;
  }
</style>
