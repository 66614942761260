<template>
  <el-card class="component">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="first" label="出库">
        <el-row>
          <el-col :span="8">
            <el-form label-width="100px" @submit.native.prevent>
              <el-form-item  label="手动添加">
                <el-input v-model="custCode" @keydown.enter.native="addCustCode(custCode)"></el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="8">
            <el-form label-width="100px">
              <el-form-item  label="默认出库类型">
                <el-select v-model="outType" placeholder="出库类型">
                  <el-option v-for="(label, key) in outTypes" :key="key" :value="key" :label="`${label}`"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="8" class="text-right">
            <el-button type="warning" @click="clearAll" :disabled="addList.length===0">清空</el-button>
            <el-button type="warning" @click="sort" :disabled="addList.length===0">排序</el-button>
            <el-button type="primary" @click="outStores" :disabled="addList.length===0">出库</el-button>
          </el-col>
        </el-row>
        <table class="table border">
          <thead>
            <tr>
              <th>#</th>
              <th>操作</th>
              <th>条码</th>
              <th>款号</th>
              <th>款式</th>
              <th>颜色</th>
              <th>尺码</th>
              <th>关联款号</th>
              <th>库存数量</th>
              <th>出库类型</th>
            </tr>
          </thead>
          <tbody>
            <tr :class="{'is-mul': item.isMul}" v-for="(item, idx) in addList" :key="idx">
              <td>{{idx + 1}}</td>
              <td><el-button title="撤销出库" type="warning" @click="rollback(idx)" icon="fa fa-reply"></el-button></td>
              <td>{{item.code}}</td>
              <td>{{item.season}}{{item.category}}{{item.style_num}}</td>
              <td>{{item.category_text}}</td>
              <td>{{item.color_text}}</td>
              <td>{{item.size_text}}</td>
              <td>{{item.rela_style}}</td>
              <td>{{item.stores}}</td>
              <td>
                <el-select v-model="item.out_type" placeholder="出库类型" clearable>
                  <el-option v-for="(label, key) in outTypes" :key="key" :value="key" :label="`${label}`"></el-option>
                </el-select>
              </td>
            </tr>
          </tbody>
        </table>
      </el-tab-pane>
      <el-tab-pane name="second" label="出库记录">
        <out-log ref="log"></out-log>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import OutLog from './part/OutList';

export default {
  components: {
    OutLog,
  },
  data() {
    return {
      activeName: 'first',
      custCode: '',
      code: '',
      outType: '1',
      outTypes: [],
      scanCode: '',
      lastKeyTime: null,
      addList: [],
    };
  },
  created() {
    window.document.addEventListener('keydown', this.keydown);
    this.init();
  },
  computed: {
    typeNum() {
      let count = 0;
      const map = {};
      this.addList.forEach((item) => {
        const mcode = item.code.substr(0, 10);
        if (!map[mcode]) {
          map[mcode] = 1;
          count++;
        }
      });
      return count;
    },
  },
  methods: {
    init() {
      if (this.$store.state.common.clothesDicts.sizes) {
        this.outTypes = this.$store.state.common.clothesDicts.sub_types;
      } else {
        this.$store.dispatch('GET_COLTHES_DICTS').then((resp) => {
          this.outTypes = resp.data.sub_types;
        });
      }
    },
    keydown(e) {
      const curTime = new Date().getTime();
      const keyCode = e.which;
      if (!this.lastKeyTime) {
        this.lastKeyTime = curTime;
      }
      if ((curTime - this.lastKeyTime) < 40) {
        if (keyCode === 13) {
          this.scanCode = this.code;
          this.addCode(this.code);
        } else {
          this.code += String.fromCharCode(keyCode);
        }
      } else {
        this.code = String.fromCharCode(keyCode);
      }
      this.lastKeyTime = curTime;
    },
    handleClick() {
      if (this.activeName === 'second') {
        this.$refs.log.showTab();
      }
    },
    addCustCode(code) {
      this.addCode(code);
    },
    addCode(code) {
      if (code.length === 13) {
        this.$http.get('clothes-out-check', { params: { code } }).then((resp) => {
        // this.$http.put('clothes-out', { code, out_type: this.outType }).then((resp) => {
          const obj = resp.data;
          obj.out_type = this.outType;
          obj.otype = obj.out_type;
          this.addList.push(obj);
        }, (err) => {
          this.$utils.resp(err);
        });
      } else {
        this.$message({
          type: 'error',
          message: `[${code}] 不是正确的13位条码编号`,
        });
      }
    },
    clearAll() {
      if (this.addList.length === 0) {
        return;
      }
      this.$confirm('确认清空？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.addList = [];
      }, () => {});
    },
    // 撤销出库
    rollback(index) {
      this.$confirm('是否确认撤销出库？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const obj = this.addList[index];
        this.$http.put(`clothes-out/${obj.id}/rollback`).then(() => {
          this.addList.splice(index, 1);
          this.showMultiple();
        });
      }, () => {});
    },
    changeOutType(index) {
      const obj = this.addList[index];
      this.$http.put(`clothes-out/${obj.id}/type`, { out_type: obj.otype }).then((resp) => {
        obj.out_type = resp.data.out_type;
      }, (err) => {
        obj.otype = obj.out_type;
        this.$utils.resp(err);
      });
    },
    sort() {
      this.showMultiple();
      this.addList = this.addList.sort((a, b) => {
        let result = 0;
        if (a.code > b.code) {
          result = 1;
        } else {
          result = -1;
        }
        return result;
      });
    },
    showMultiple() {
      this.addList.forEach((item) => {
        if (this.addList.filter(x => x.code === item.code).length > 1) {
          this.$set(item, 'isMul', true);
        } else {
          this.$set(item, 'isMul', false);
        }
      });
    },
    outStores() {
      const list = this.addList;
      this.$http.put('clothes-outs', { list }).then((resp) => {
        let duration = 3000;
        let errMessage = '。';
        const result = resp.data;
        let clearList = true;
        if (resp.data.err.length > 0) {
          clearList = false;
          duration = 0;
          errMessage = `；未成功出库的编号： ${result.err} `;
        }
        this.$message({
          type: duration > 0 ? 'success' : 'danger',
          message: `成功出库 ${result.success} 条${errMessage} `,
          duration,
          showClose: true,
        });
        if (clearList) {
          this.addList = [];
        }
      });
    },
  },
  destroyed() {
    window.document.removeEventListener('keydown', this.keydown);
  },
};
</script>

<style scoped>
.table tr.is-mul {
  background: #ffce54;
}
</style>
