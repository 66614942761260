<template>
  <el-card>
    <el-button type="primary" size="small" @click="toAdd()" v-if="canInput">
      <i class="fa fa-fw fa-plus-circle"></i> 新增</el-button>
    <div class="mar-btm-10 pull-right">
      <!-- <div class="box-inline standard-search">
        <el-input v-model="queryForm.org_no" clearable placeholder="单据编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div> -->
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.q" clearable placeholder="关键字" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status" clearable placeholder="状态">
          <el-option v-for="(label, key) in statusesMap" :key="key" :value="key" :label="label"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.type" clearable placeholder="支付类型">
          <el-option v-for="(text, value) in types" :key="value" :value="value" :label="text"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search" v-if="!isInputRole">
        <el-select v-model="queryForm.company_id" clearable placeholder="企业筛选">
          <el-option v-for="item in companies" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th class="text-center">#</th>
          <th class="text-center">日期</th>
          <th class="text-center">金额</th>
          <th class="text-center">类型</th>
          <th class="text-center">原始单据编号</th>
          <th class="text-center">业务公司</th>
          <th class="text-center">状态</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in list" :key="item.id" :title="item.desc">
          <td class="text-center">{{ idx + 1 }}</td>
          <td class="text-center">{{ item.occ_at }}</td>
          <td class="text-center">{{ item.amount }}</td>
          <td class="text-center">{{ item.type_text }}</td>
          <td class="text-center"><a href="#" @click.prevent="showDetail(item.id)">{{ item.org_no }}</a></td>
          <td class="text-center">{{ item.opposite_name }}</td>
          <td :class="{'text-center': true,'text-danger': item.status == '4', 'text-info': item.status == '3' }">
            {{statusesMap[item.status] ? statusesMap[item.status] : ''}}</td>
          <td class="text-center">
            <div class="text-nowrap">
              <el-button v-if="(['1', '4', '6', '7'].indexOf(item.status) !== -1) && canInput"
                style="margin-left: 3px;" type="info" title="修改信息" @click="toEdit(item)">
                <i class="fa fa-fw fa-pencil"></i></el-button>
              <el-button v-if="(['1', '4', '6', '7'].indexOf(item.status) !== -1) && canInput"
                style="margin-left: 3px;" type="warning"
                :disabled="['6', '4'].indexOf(item.status) !== -1 && item.ap_verify_id && item.type === 'unpaid'"
                title="提交审核" @click="toVerify(item)">
                <i class="fa fa-fw fa-upload"></i></el-button>
              <el-button v-if="(item.status == '2' || item.status == '5') && canInput"
                style="margin-left: 3px;" type="warning" title="撤回" @click="backVerify(item)">
                <i class="fa fa-fw fa-undo"></i></el-button>
              <el-button v-if="(item.status == '2' || item.status == '5') && canArch"
                style="margin-left: 3px;" type="warning" title="审核" @click="showDetail(item.id)">
                <i class="fa fa-fw fa-paint-brush"></i></el-button>
              <el-button v-if="['3', '4', '5', '6', '7'].indexOf(item.status) !== -1"
                style="margin-left: 3px;" type="info" title="详情" @click="showDetail(item.id)">
                <i class="fa fa-fw fa-search"></i></el-button>

             <!--  <el-button v-if="item.status == '1' && canArch" type="warning" title="审核" @click="showDetail(item.id)">
                <i class="fa fa-fw fa-archive"></i></el-button>
              <el-button v-if="item.status == '1' && canInput"
                style="margin-left: 3px;" type="info" title="修改信息" @click="toEdit(item)">
                <i class="fa fa-fw fa-pencil"></i></el-button> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <a ref="downloadLink" :href="downloadUrl" :download="downloadFileName" v-show="false"></a>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="showDialog" title="支出凭证详情" width="90%" :close-on-click-modal="true" :close="closeDetailDialog">
<!--       <table class="bordered table expense-table">
        <tr>
          <td>
            <span class="td-label">Date/日期</span><br/>
            {{showModel.occ_at}}
          </td>
          <td>
            <span class="td-label">Original documnet No./原始单据编号</span><br/>
            {{showModel.org_no}}</td>
          <td>
            <span class="td-label">Related department/涉及部门</span><br/>
            {{showModel.department}}</td>
        </tr>
        <tr>
          <td colspan="3">
            <span class="td-label">Description/事项说明</span><br/>
            {{showModel.desc}}
          </td>
        </tr>
        <tr>
          <td>
            <span class="td-label">USD amount/美元金额</span><br/>
            {{showModel.amount}}
          </td>
          <td>
            <span class="td-label">支付类型</span><br/>
            {{showModel.type}}/{{showModel.type_text}}</td>
          <td>
            <span class="td-label">Others/其他</span><br/>
            {{showModel.remark}}</td>
        </tr>
      </table> -->
      <el-form label-width="130px" class="compact-mb5">
        <el-row>
          <el-col :span="12">
            <el-form-item label="原始单据编号：">
              {{showModel.org_no}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期：">
              {{showModel.occ_at}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="公司名称：">
              {{showModel.company ? showModel.company.name : '-'}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门：">
              {{showModel.department}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="往来企业名称：">
              {{showModel.opposite_name}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="支付类型：">
              {{showModel.type_text}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="金额(USD)：">
              {{showModel.amount}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="showModel.cost_type == 28">
          <el-col :span="12">
            <el-form-item label="实际支付">
              {{getRelPay(showModel)}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="差额">
              {{-showModel.offset}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="状态：">
              {{showModel.status ? statusesMap[showModel.status] : ''}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="费用类型：">
              <div v-if="(showModel.status == '2' || showModel.status == '5') && canArch">
                <el-select v-model="showModel.cost_type" clearable filterable :disabled="showModel.invoice_id ? true : false">
                  <el-option v-for="(ctype, idx) in costTypes" :label="ctype.name" :value="ctype.id" :key="idx"></el-option>
                </el-select>
              </div>
              <span v-else>{{showModel.cost_type ? costTypesMap[showModel.cost_type] : '' }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="事项说明：">
          {{showModel.desc}}
        </el-form-item>
        <el-form-item label="审批信息：" v-if="['1', '2', '4', '5', '7'].indexOf(showModel.status) !== -1">
          <el-input type="textarea" v-if="['2', '5'].indexOf(showModel.status) !== -1 && canArch"
            v-model="showModel.verify_response"></el-input>
          <span v-else>{{showModel.verify_response}}</span>
        </el-form-item>
        <el-form-item label="其他说明：">
          {{showModel.remark}}
        </el-form-item>
        <el-form-item label="附件：" label-width="100px" v-if="showModel.attachments">
          <div style="padding-right: 50px;">
            <div v-for="(item, idx) in showModel.attachments" :key="idx" class="download-img-box">
              <i class="fa fa-download download-rt-ico" title="下载"
                @click="downloadImg(item, `${showModel.org_no}_${idx + 1}`)"></i>
              <img :src="item.url" alt="" style="max-width: 100%;">
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="showModel.id && showModel.status=='1' && canInput"
          title="删除" type="danger" @click="del(showModel.id)"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button v-if="['2', '5'].indexOf(showModel.status) !== -1 && canArch" icon="fa fa-check"
          style="margin-left: 5px;" :disabled="!showModel.cost_type" @click="verifyCheck(showModel.id)" type="primary">
            审核通过</el-button>
        <el-button v-if="['2', '5'].indexOf(showModel.status) !== -1 && canArch" icon="fa fa-reply"
          style="margin-left: 5px;" :disabled="!showModel.verify_response" @click="reject(showModel.id)" type="warning">
            驳 回</el-button>

        <!-- <el-button v-if="showModel.id && showModel.status=='2' && canArch"
          title="审核通过" type="primary" @click="verifyCheck(showModel.id)" icon="fa fa-check">审核通过</el-button>
        <el-button v-if="showModel.id && showModel.status=='2' && canArch"
          title="驳回" type="warning" @click="reject(showModel.id)" icon="fa fa-check">驳 回</el-button> -->

        <el-button @click="showDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" :title="editModel.id ? '支出记录编辑' : '新增支出记录'"
      :close-on-click-modal="false">
      <el-form :model="editModel" ref="editForm" label-width="110px" :rules="rules" v-if="!editModel.ap_verify_id">
        <el-form-item label="原始单据编号" prop="org_no" v-if="editModel.id">
          <el-input v-model="editModel.org_no" :disabled="editModel.cost_type === 28"
            :readonly="editModel.org_no|isAuto"></el-input>
        </el-form-item>
        <el-form-item label="企业" prop="company_id" v-if="!isInputRole">
          <el-select v-model="editModel.company_id" :disabled="editModel.id ? true : false">
            <el-option v-for="item in companies" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="往来企业" prop="opposite_name">
          <el-input v-model="editModel.opposite_name" :readonly="editModel.invoice_id"></el-input>
        </el-form-item>
        <el-form-item label="日期" prop="occ_at">
          <el-date-picker v-model="editModel.occ_at"
            style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="支付类型" prop="type">
          <el-select v-model="editModel.type" clearable placeholder="请选择" style="width: 100%;">
            <el-option v-for="(text, value) in types" :key="value" :value="value" :label="text"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额(USD)" prop="amount">
          <el-input type="number" v-model="editModel.amount"></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="dept_id">
          <el-select v-model="editModel.dept_id">
            <el-option v-for="item in depts" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事项说明" prop="desc">
          <el-input type="textarea" v-model="editModel.desc"></el-input>
        </el-form-item>
        <el-form-item v-if="editModel.verify_response" label="驳回信息">
          {{editModel.verify_response}}
        </el-form-item>
        <el-form-item label="其他说明" prop="remark">
          <el-input type="textarea" v-model="editModel.remark"></el-input>
        </el-form-item>
        <el-form-item label="附件">
          <div>
              <div v-if="editModel.attachments && editModel.attachments.length !== 0">
                <div class="text-semibold">已上传的文件</div>
                <div class="file-list" v-for="(file, index) in editModel.attachments" :key="index">
                  <span class="pull-right cur-pointer del-btn" @click="delFileOne(index)" style="width: 25px;" title="删除附件">
                  <i class="fa fa-times" aria-hidden="true"></i></span>
                  <span class="text-overflow" :title="file.name" style="margin-right: 25px;">
                    {{ file.orgName }}</span>
                </div>
              </div>
              <div v-if="files.length !== 0">
                <div class="text-semibold">已选择的文件</div>
                <div class="file-list" v-for="(item, index) in files" :key="index">
                  <span class="pull-right cur-pointer del-btn" @click="delUfile(index)" style="width: 25px;" title="删除附件">
                    <i class="fa fa-times" aria-hidden="true"></i></span>
                  <span class="text-overflow" :title="item.name" style="margin-right: 25px;">{{ item.name }}</span>
                </div>
              </div>
            <!-- <div v-for="(item, index) in files" :key="index">
              <span>{{item.name}}</span>
              <span style="cursor: pointer;" @click="delufile(index)"><i class="fa fa-fw fa-trash text-danger"></i></span>
            </div> -->
          </div>
          <file-uploader ref="addFile"
            accept="."
            @change="changeFile"
            :showPreview="false"
            :showFileHint="false">
          </file-uploader>
        </el-form-item>
      </el-form>
      <el-form :model="editModel" ref="editForm" label-width="110px" :rules="rules" v-if="editModel.ap_verify_id">
        <el-form-item label="原始单据编号" prop="org_no" v-if="editModel.id">
          {{editModel.org_no}}
        </el-form-item>
        <el-form-item label="企业" prop="company_id">
          <el-select v-model="editModel.company_id" disabled>
            <el-option v-for="item in companies" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="往来企业" prop="opposite_name">
          {{editModel.opposite_name}}
        </el-form-item>
        <el-form-item label="日期" prop="occ_at">
          <el-date-picker v-model="editModel.occ_at"
            style="width: 100%;" type="date" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="支付类型" prop="type">
          <el-select v-model="editModel.type" clearable placeholder="请选择" style="width: 100%;">
            <el-option v-for="(text, value) in types" :key="value" :value="value" :label="text"></el-option>
          </el-select>
        </el-form-item>
        <div>
          <el-form-item label="金额(USD)" prop="amount">
            <el-input readonly type="number" v-model="editModel.amount"></el-input>
          </el-form-item>
          <el-form-item label="实际支付" prop="amount">
            <el-input type="number" v-model="editModel.rel_amount"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="部门" prop="dept_id">
          <el-select v-model="editModel.dept_id" disabled>
            <el-option v-for="item in depts" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事项说明" prop="desc">
          {{editModel.desc}}
        </el-form-item>
        <el-form-item v-if="editModel.verify_response" label="驳回信息">
          {{editModel.verify_response}}
        </el-form-item>
        <el-form-item label="其他说明" prop="remark">
          <el-input type="textarea" v-model="editModel.remark"></el-input>
        </el-form-item>
        <el-form-item label="附件">
          <div>
              <div v-if="editModel.attachments && editModel.attachments.length !== 0">
                <div class="text-semibold">已上传的文件</div>
                <div class="file-list" v-for="(file, index) in editModel.attachments" :key="index">
                  <span class="pull-right cur-pointer del-btn" @click="delFileOne(index)" style="width: 25px;" title="删除附件">
                  <i class="fa fa-times" aria-hidden="true"></i></span>
                  <span class="text-overflow" :title="file.name" style="margin-right: 25px;">
                    {{ file.orgName }}</span>
                </div>
              </div>
              <div v-if="files.length !== 0">
                <div class="text-semibold">已选择的文件</div>
                <div class="file-list" v-for="(item, index) in files" :key="index">
                  <span class="pull-right cur-pointer del-btn" @click="delUfile(index)" style="width: 25px;" title="删除附件">
                    <i class="fa fa-times" aria-hidden="true"></i></span>
                  <span class="text-overflow" :title="item.name" style="margin-right: 25px;">{{ item.name }}</span>
                </div>
              </div>
            <!-- <div v-for="(item, index) in files" :key="index">
              <span>{{item.name}}</span>
              <span style="cursor: pointer;" @click="delufile(index)"><i class="fa fa-fw fa-trash text-danger"></i></span>
            </div> -->
          </div>
          <file-uploader ref="addFile"
            accept="."
            @change="changeFile"
            :showPreview="false"
            :showFileHint="false">
          </file-uploader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="editModel.id && editModel.status=='1' && canInput"
          title="删除" type="danger" @click="del(editModel.id)"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="beforeSave">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import * as _findIndex from 'lodash/findIndex';
import * as _find from 'lodash/find';
import { mapState, mapGetters } from 'vuex';
import FileUploader from '@/components/FileUpload';

export default {
  components: {
    FileUploader,
  },
  data() {
    return {
      loading: false,
      waiting: false,
      types: {
        cash: '现金',
        cheque: '支票',
        transfer: '转账',
        unpaid: '未支付',
      },
      queryForm: {
        org_no: '',
        q: '',
        type: '',
      },
      queryParams: {},
      list: [],
      showModel: {},
      showDialog: false,
      editDialog: false,
      editModel: {},
      relAmount: null,
      rules: {
        occ_at: [{ required: true, message: '请选择日期', trigger: ['change', 'blur'] }],
        type: [{ required: true, message: '请选择类型', trigger: ['change', 'blur'] }],
        org_no: [{ required: true, message: '请填写单据编号', trigger: ['change', 'blur'] }],
        amount: [{ required: true, message: '请输入金额', trigger: ['change', 'blur'] }],
        desc: [{ required: true, message: '请输入事项说明', trigger: ['change', 'blur'] }],
      },
      // 归档支出费用分类
      checkType: '',
      files: [], // 待上传的文件
      // 所有缅甸企业
      companies: [],
      statusesMap: {
        1: '待提交',
        5: '应付待审核',
        6: '未付款',
        7: '应付驳回',
        2: '待审核',
        3: '已审核',
        4: '已驳回',
      },
      // page
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      downloadFileName: '',
      downloadUrl: '',
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      costTypes: state => state.common.costTypes,
    }),
    ...mapGetters([
      'costTypesMap',
    ]),
    isInputRole() {
      let result = false;
      if (this.user.id) {
        const companyId = this.user.meta ? this.user.meta.mmr_company_id : false;
        if (_findIndex(this.user.roles, { code: 'mmr_input' }) !== -1 && companyId) {
          result = true;
        }
      }
      return result;
    },
    canArch() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('mmr_verify') !== -1) {
        result = true;
      }
      return result;
    },
    canInput() {
      let result = false;
      if (this.user && this.user.allPerms && this.user.allPerms.indexOf('mmr_expense_input') !== -1) {
        result = true;
      }
      return result;
    },
    depts() {
      let result = [];
      if (this.isInputRole && this.companies.length > 0) {
        const company = _find(this.companies, { id: this.user.meta.mmr_company_id });
        if (company) {
          result = company.depts;
        }
      } else if (this.companies.length > 0 && this.editModel.company_id) {
        const company = _find(this.companies, { id: this.editModel.company_id });
        if (company) {
          result = company.depts;
        }
      }
      return result;
    },
  },
  filters: {
    isAuto(value) {
      let result = false;
      if (value && /^(C)\d{13}$/.test(value)) {
        result = true;
      }
      return result;
    },
  },
  beforeCreate() {
    if (this.$store.state.common.costTypes.length === 0) {
      this.$store.dispatch('GET_COST_TYPE');
    }
  },
  created() {
    this.init();
    this.getList();
  },
  methods: {
    init() {
      // 获取公司和部门
      this.$http.get('mmrcompanydepts').then((resp) => {
        this.companies = resp.data;
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    getList() {
      this.loading = true;
      const params = { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize };
      this.$http.get('expense', { params }).then((resp) => {
        this.list = resp.data.data;
        this.totalItem = resp.data.total;
        this.loading = false;
      }, (err) => {
        this.loading = false;
        this.$utils.resp(err);
      });
    },
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    toAdd() {
      this.files = [];
      const model = {
        occ_at: this.$utils.FormatDate(),
        org_no: '',
        department: '',
        desc: '',
        remark: '',
        amount: '',
        type: '',
        company_id: '',
      };
      if (this.isInputRole) {
        model.company_id = this.user.meta.mmr_company_id;
      }
      this.editModel = model;
      this.editDialog = true;
      this.$nextTick(() => {
        if (this.$refs.editForm) {
          this.$refs.editForm.clearValidate();
        }
      });
    },
    toEdit(item) {
      this.files = [];
      this.$http.get(`expense/${item.id}`).then((resp) => {
        const result = resp.data;
        result.rel_amount = this.getRelPay(result);
        this.editModel = result;
        this.$nextTick(() => {
          if (this.$refs.editForm) {
            this.$refs.editForm.clearValidate();
          }
        });
        this.editDialog = true;
      });
    },
    getImg(fileName) {
      const params = {
        hint: 'mmrexpense',
        name: fileName,
      };
      return this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        return objurl;
      }, (err) => {
        this.$utils.resp(err);
        // todo
        return '404png';
      });
    },
    downloadImg(item, name = false) {
      const tmp = item.orgName.split('.');
      const ext = tmp[tmp.length - 1];
      this.downloadFileName = name ? name + '.' + ext : item.orgName;
      this.downloadUrl = item.url;
      this.$nextTick(() => {
        this.$refs.downloadLink.click();
      });
    },
    // 详情
    showDetail(id) {
      this.$http.get(`expense/${id}`).then((resp) => {
        const result = resp.data;
        result.rel_amount = this.getRelPay(result);
        this.showModel = result;
        if (this.showModel.attachments) {
          this.showModel.attachments.forEach((item) => {
            this.getImg(item.name).then((url) => {
              this.$set(item, 'url', url);
            });
          });
        }
        this.showDialog = true;
      });
    },
    closeDetailDialog() {
      // 清图片缓存;
      this.showModel.attachments.forEach((item) => {
        URL.revokeObjectURL(item.url);
      });
    },
    // 提交审核
    toVerify(expense) {
      // if (expense.type === 'unpaid') {
      //   this.$message.warning('未支付的无法提交');
      //   return;
      // }
      this.$confirm('确认提交审核？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        if (expense.type === 'unpaid' && expense.ap_verify_id) {
          this.$message.warning('请选择支付方式再提交');
          return;
        }
        const params = { status: '2' };
        if (expense.type === 'unpaid') {
          params.status = '5';
        }
        this.$http.put(`expense/${expense.id}/status`, params).then(() => {
          this.$message.success('提交成功');
          this.getList();
        }, (err) => { this.$utils.resp(err); });
      }, () => {});
    },
    // 撤回提交审核
    backVerify(expense) {
      const params = { status: '1' };
      if (expense.status === '2' && expense.ap_verify_id) {
        params.status = '6';
      }
      this.$http.put(`expense/${expense.id}/status`, params).then(() => {
        this.$message.success('已撤回');
        this.getList();
      }, (err) => { this.$utils.resp(err); });
    },
    // 应付审批
    verifyAp(id, isOk = true) {
      let message = '驳回';
      const params = {
        status: '7',
        cost_type: this.showModel.cost_type,
        verify_response: this.showModel.verify_response,
      };
      if (isOk) {
        message = '应付审核通过';
        params.status = '6';
      }
      this.$confirm(`确认${message}？`, '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.put(`expense/${id}/status`, params).then(() => {
          this.$message.success('操作成功');
          this.showDialog = false;
          this.getList();
        }, (err) => { this.$utils.resp(err); });
      }, () => {});
    },
    // 审批
    verifyCheck(id, isOk = true) {
      if (this.showModel.type === 'unpaid') {
        this.verifyAp(id, isOk);
      } else {
        let message = '驳回';
        const params = {
          status: '4',
          cost_type: this.showModel.cost_type,
          verify_response: this.showModel.verify_response,
        };
        if (isOk) {
          message = '审核通过';
          params.status = '3';
        }
        this.$confirm(`确认${message}？`, '确认信息', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$http.put(`expense/${id}/status`, params).then(() => {
            this.$message.success('操作成功');
            this.showDialog = false;
            this.getList();
          }, (err) => { this.$utils.resp(err); });
        }, () => {});
      }
    },
    reject(id) {
      this.verifyCheck(id, false);
    },
    // archiveIt() {
    //   const obj = { cost_type: this.checkType };
    //   this.$http.put(`arch-expense/${this.showModel.id}`, obj).then(() => {
    //     this.showDialog = false;
    //     this.getList();
    //     this.$utils.msg(`记录"${this.showModel.org_no}"已审核`, 'success', 5000);
    //   });
    // },
    del(id) {
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`expense/${id}`).then(() => {
          this.showDialog = false;
          this.editDialog = false;
          this.getList();
        }, (err) => {
          this.$utils.resp(err);
        });
      }, () => {});
    },
    beforeSave() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          if (this.$refs.addFile.identifyBorwser() && this.files.length > 0) {
            const formData = new window.FormData(this.$refs.editForm.$el);
            // api中，上传头像参数用avatar,还有media、document、attachment
            formData.append('hint', 'mmrexpense');
            this.files.forEach((ele) => {
              formData.append('file[]', ele);
            });
            this.waiting = true;
            this.$http.post('transfile', formData).then((resp) => {
              this.editModel.addFiles = resp.data;
              this.saveSubmit();
            }, (err) => {
              this.editModel.addFiles = [];
              this.waiting = false;
              this.$utils.resp(err);
            });
          } else {
            this.saveSubmit();
          }
        }
      });
    },
    saveSubmit() {
      // 部门名称也保存下，改部门后也可知道当时的名称
      if (this.editModel.dept_id) {
        this.editModel.department = _find(this.depts, { id: this.editModel.dept_id }).name;
      }
      if (this.editModel.rel_amount) {
        this.editModel.offset = this.$calc.FixRound(this.$calc.Add(-this.editModel.amount, this.editModel.rel_amount));
      }
      // 更新
      if (this.editModel.id) {
        this.$http.put(`expense/${this.editModel.id}`, this.editModel).then((resp) => {
          const index = _findIndex(this.list, { id: this.editModel.id });
          this.list.splice(index, 1, resp.data);
          this.editDialog = false;
          this.waiting = false;
          this.$utils.msg('已更新');
        }, (err) => {
          this.$utils.resp(err);
          this.waiting = false;
        });
      } else {
      // 新增
        this.$http.post('expense', this.editModel).then(() => {
          this.editDialog = false;
          this.getList();
          this.waiting = false;
          this.$utils.msg('保存成功');
        }, (err) => {
          this.$utils.resp(err);
          this.waiting = false;
        });
      }
    },
    // 选中上传文件时
    changeFile(files) {
      if (this.$refs.addFile.sizeWarning) {
        this.$utils.msg('文件过大', 'warning');
        return;
      }
      if (files.length > 0) {
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
      }
    },
    // 删除原图片
    delFileOne(index) {
      this.editModel.attachments.splice(index, 1);
    },
    // 删除未上传的文件
    delUfile(index) {
      this.files.splice(index, 1);
    },
    getRelPay(item) {
      const offset = item.offset ? item.offset : 0;
      return this.$calc.FixRound(this.$calc.Add(item.amount, offset));
    },
  },
};
</script>

<style scoped>
  .expense-table td {
    color: #999;
    vertical-align: top;
  }
  .expense-table td .td-label {
    color: #333;
    font-size: 16px;
    font-weight: 500;
  }
</style>
