<template>
  <el-card class="components">
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.kh" clearable placeholder="款号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.guige" clearable placeholder="规格" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.name" clearable placeholder="名称" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.category" placeholder="类型" clearable
            filterable>
            <el-option
              v-for="item in wlcategorys"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery" icon="fa fa-fw fa-search"></el-button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>名称</th>
          <th>类型</th>
          <th>款号</th>
          <th>颜色</th>
          <th>尺码</th>
          <th>规格</th>
          <th>变动</th>
          <th>单位</th>
          <th>对象</th>
          <th>时间</th>
          <th>操作人</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id" :title="item.remark">
          <td>{{ index + 1 }}</td>
          <td>{{ item.wuliao.name }}</td>
          <td>{{ item.wuliao.category}}</td>
          <td>{{ item.wuliao.kh }}</td>
          <td>{{ item.wuliao.color }}</td>
          <td>{{ item.wuliao.size }}</td>
          <td>{{ item.wuliao.guige }}</td>
          <td :class="{ 'text-danger': item.type == 'in', 'text-primary': item.type === 'out'}">
            {{item.type === 'in' ? '+' : ''}}{{ item.num }}
          </td>
          <td>{{ item.wuliao.unit }}</td>
          <td>{{ item.customer }}</td>
          <td>{{ item.created_at }}</td>
          <td>{{ item.creator }}</td>
          <td>
            <el-button  size="small" title="Edit" @click="toEdit(item)"><i class="fa fa-fw fa-pencil"></i></el-button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <!-- 编辑 -->
    <el-dialog :visible.sync="editDialog" :title="editModel.type=== 'in' ? '入库' : '出库'" :close-on-click-modal="false">
      <el-form :model="editModel" ref="editForm" label-width="100px">
        <el-form-item label="物料名称">
          {{editModel.wuliao.name}}
        </el-form-item>
        <el-form-item label="款号">
          {{editModel.wuliao.hk}}
        </el-form-item>
        <el-form-item label="分类">
          {{editModel.wuliao.category}}
        </el-form-item>
        <el-form-item label="尺码">
          {{editModel.wuliao.size}}
        </el-form-item>
        <el-form-item label="颜色">
          {{editModel.wuliao.color}}
        </el-form-item>
        <el-form-item label="规格">
          {{editModel.wuliao.guige}}
        </el-form-item>
        <el-form-item label="变动数量">
          {{editModel.num}}{{editModel.wuliao.unit}}
        </el-form-item>
        <el-form-item label="备注">
          {{editModel.remark}}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="delIt" class="text-danger pull-left" icon="fa fa-fw fa-trash" aria-hidden="true">删除操作</el-button>
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times" aria-hidden="true">取消</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>

import { mapState } from 'vuex';

export default {
  components: {
  },
  data() {
    return {
      today: this.$utils.formatDate(),
      waiting: false,
      sizes: [],
      wlcategorys: [],
      // 查询条件form
      queryForm: {
        kh: null,
        name: null,
        color: null,
        size: null,
        category: null,
      },
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      editModel: { wuliao: {} }, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,

    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      // dicts: state => state.common.dicts,
    }),
  },
  created() {
    this.init();
    this.onSubmitQuery();
  },
  methods: {
    init() {
      if (this.$store.state.common.clothesDicts.sizes) {
        this.sizes = this.$store.state.common.clothesDicts.sizes;
      } else {
        this.$store.dispatch('GET_COLTHES_DICTS').then((resp) => {
          this.sizes = resp.data.sizes;
        });
      }
      this.axios.get('wuliao-dicts').then((r) => {
        this.wlcategorys = r.data.categories;
      });
    },
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.$http.get('wuliaolog', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.list = response.data.data;
          this.totalItem = response.data.total;
        }, (err) => {
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开编辑
    toEdit(editObj) {
      this.axios.get(`wuliaolog/${editObj.id}`).then((resp) => {
        const obj = resp.data;
        this.editDialog = true;
        this.editModel = obj;
      });
    },
    delIt() {
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`wuliaolog/${this.editModel.id}`).then(() => {
          this.getList();
          this.editDialog = false;
          this.$message({
            type: 'success',
            message: '删除成功',
            showClose: true,
            duration: 2000,
          });
        }, (resp) => {
          this.$utils.resp(resp);
        });
      }, () => {});
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
