<template>
  <el-card>
    <div>
      <el-form>
        <el-form-item v-if="isEdit" label="登录口令：">
          <el-input v-model="code"></el-input>
          <el-button @click="save" icon="el-icon-check"></el-button>
        </el-form-item>
        <el-form-item v-else label="登录口令：">
          {{code}}
          <el-button style="margin-left: 30px;" @click="isEdit=true" icon="el-icon-edit"></el-button>
        </el-form-item>
      </el-form>

    </div>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      isEdit: false,
      code: '',
    };
  },
  created() {
    this.getCode();
  },
  methods: {
    getCode() {
      this.axios.get('ghyk-code').then((r) => {
        this.code = r.data;
      }, (e) => {
        this.$utils.resp(e);
      });
    },
    save() {
      const params = { code: this.code };
      this.axios.post('ghyk-code', params).then(() => {
        this.isEdit = false;
      }, (e) => {
        this.$utils.resp(e);
      });
    },
  },
};
</script>

<style>

</style>
