<template>
  <div class="text-center">
    <div style="display: inline-block;padding-top: 50px;">
      <vue-qr v-if="orderId" :logoSrc="logoUrl" logoBackgroundColor="#fff" :logoMargin="5"
        :correctLevel="3" :text="orderId|orderQr" :margin="6" :dotScale="1"></vue-qr>
      <vue-qr v-if="newOrderId" :logoSrc="logoUrl" logoBackgroundColor="#fff" :logoMargin="5"
        :correctLevel="3" :text="newOrderId|orderQr2" :margin="6" :dotScale="1"></vue-qr>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr';

export default {
  data() {
    return {
      orderId: '',
      newOrderId: '',
      logoUrl: window.baseUrl + 'favicon.ico',
    };
  },
  created() {
    this.orderId = this.$route.query.id;
    this.newOrderId = this.$route.query.order_id;
  },
  components: {
    VueQr,
  },
  filters: {
    orderQr(id) {
      return `https://www.guotaigh.com.cn/wb-app/#/query?id=${id}`;
    },
    orderQr2(id) {
      return `https://www.guotaigh.com.cn/wb-app/#/query?order_id=${id}`;
    },
  },
};
</script>
