<template>
  <el-card class="components">
    <el-button type="primary" size="small" @click="toAdd()"><i class="fa fa-fw fa-plus-circle"></i> 新录入</el-button>
    <el-button type="primary" size="small" @click="toAddPl()"><i class="fa fa-fw fa-plus-circle"></i> 编号导入</el-button>

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.name" clearable placeholder="名称" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.spec" clearable placeholder="规格" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.brand" clearable placeholder="品牌" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.q" clearable placeholder="编号" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.company_id" clearable placeholder="公司筛选">
          <el-option v-for="(item) in companies" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search">
        <el-select v-model="queryForm.status" clearable placeholder="状态">
          <el-option v-for="(item, index) in statusList" :key="index" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <!-- <th width="25">#</th> -->
          <th>名称</th>
          <th>规格</th>
          <th>品牌</th>
          <th>编号</th>
          <th>公司</th>
          <th>购入日期</th>
          <th class="text-right">购入价格</th>
          <th>已使用</th>
          <th width="1%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item) in firmList" :key="item.id">
          <!-- <td>{{ index + 1 }}</td> -->
          <td>{{ item.name }}</td>
          <td>{{ item.spec }}</td>
          <td>{{ item.brand }}</td>
          <td>{{ item.sn }}</td>
          <td>{{ item.company ? item.company.name : '' }}</td>
          <td>{{ item.bought_at }}</td>
          <td class="text-right">{{ item.price }}</td>
          <td>{{ item.use_mons }}个月</td>
          <td>
            <div class="text-nowrap">
              <el-button size="small" type="info" title="详情" @click="showDetail(item)">
                <i class="fa fa-fw fa-navicon"></i></el-button>
              <el-button size="small" type="warning" title="修改信息" @click="toEdit(item)">
                <i class="fa fa-fw fa-pencil"></i></el-button>
              <!-- <el-button size="small" type="text" title="标牌打印" @click="pplbPrint(item.id)">
                <i class="fa fa-fw fa-print"></i></el-button> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="showDialog" :title="showAsset.name" :close-on-click-modal="false" @close="closeShow">
      <el-form label-width="120px">
        <!-- <el-form-item label="二维码">
          <vue-qr  ref="qrImg" v-show="false" logoSrc="/asset/favicon.ico" logoBackgroundColor="#fff" :logoMargin="5"
                :correctLevel="3" :text="showAsset.id|orderQr" :margin="6" :dotScale="1"></vue-qr>
          <el-button size="small" type="text" title="标牌打印" @click="pplbPrint(item.id)">
                <i class="fa fa-fw fa-print"></i></el-button>
        </el-form-item> -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称">
              {{showAsset.name}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号">
              {{showAsset.spec}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="品牌">
              {{showAsset.brand}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="物资编号">
              {{showAsset.sn}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="所属公司">
          {{showAsset.company.name}}
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="购入价格">
              {{showAsset.price}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="购入日期">
              {{showAsset.bought_at}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="可使用年限">
              {{showAsset.using_limit}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="残值">
              {{showAsset.depreciation_rate}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="月折旧费">
              <span v-if="showAsset.mon_depr">{{showAsset.mon_depr}}</span>
              <span v-else class="text-muted">{{showAsset.mon_depr2}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年折旧费">
              <span v-if="showAsset.year_depr">{{showAsset.year_depr}}</span>
              <span v-else class="text-muted">{{showAsset.year_depr2}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="已使用月数">
              {{showAsset.use_mons}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="现有价值">
              <span v-if="showAsset.value_price">{{showAsset.value_price}}</span>
              <span v-else class="text-muted">{{showAsset.value_price2}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          {{showAsset.remark}}
        </el-form-item>
      </el-form>
      <table class="el-table">
        <thead>
          <tr>
            <th>操作</th>
            <th>内容</th>
            <th>时间</th>
            <th>备注</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in logs" :key="item.id">
            <td>{{logTypes[item.type]}}</td>
            <td>{{item.from}} - {{item.to}}</td>
            <td>{{item.occured_at}}</td>
            <td>{{item.remark}}</td>
          </tr>
        </tbody>
      </table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="newLog">
          <i class="fa fa-fw fa-plus" aria-hidden="true"></i> 操 作</el-button>
      </div>
    </el-dialog>

    <!-- 新纪录 -->
    <el-dialog :visible.sync="addLogDialog" title="新增记录">
      <el-form :model="log" ref="logForm" label-width="100px" :rules="logRules">
        <el-form-item label="类型" prop="type">
          <el-select v-model="log.type" @change="logTypeChange">
            <el-option v-for="(type, val) in logTypes" :disabled="val == 2 && !lastLog"
              :label="type" :value="val" :key="val"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发生日期" prop="occured_at">
          <el-date-picker v-model="log.occured_at" type="date" placeholder="选择日期"
            value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="出方" prop="from_id">
          <el-select v-model="log.from_id">
            <el-option label="其他" value=""></el-option>
            <el-option v-for="(item) in companies" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其他出方" prop="from" v-if="!log.from_id">
          <el-input v-model="log.from"></el-input>
        </el-form-item>
        <el-form-item label="收到方" prop="to_id" v-if="log.type != 4">
          <el-select v-model="log.to_id">
            <el-option label="其他" value=""></el-option>
            <el-option v-for="(item) in companies" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其他收方" prop="to" v-if="log.type != 4 && !log.to_id">
          <el-input v-model="log.to"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="log.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addLogDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="saveLog">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑dialog -->
    <el-dialog :visible.sync="editDialog" top="10px" :title="assetForm.id ? '编辑资产' : '新增资产'"
      :close-on-click-modal="false" :close="closeEditDialog">
      <el-form :model="assetForm" ref="editForm" label-width="100px" :rules="rules">
        <el-form-item label="名称" prop="name">
          <el-input v-model="assetForm.name"></el-input>
        </el-form-item>
        <el-form-item label="型号" prop="spec">
          <el-input v-model="assetForm.spec"></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="brand">
          <el-input v-model="assetForm.brand"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="num" v-if="!assetForm.id">
          <el-input type="number" v-model="assetForm.num"></el-input>
        </el-form-item>
        <el-form-item label="批量修改" prop="mass" v-else-if="assetForm.sn.indexOf('_') !== -1">
          <el-select v-model="assetForm.mass">
            <el-option label="否" :value="0"></el-option>
            <el-option label="修改应用到同批次" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属公司" prop="company_id">
          <el-select v-model="assetForm.company_id">
            <el-option v-for="(item) in companies" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="购入价格" prop="price">
          <el-input v-model="assetForm.price" @blur="calcRate(true)"></el-input>
        </el-form-item>
        <el-form-item label="购入日期" prop="bought_at">
          <el-date-picker v-model="assetForm.bought_at" type="date" placeholder="选择日期"
            value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="可使用年限" prop="using_limit">
          <el-input v-model="assetForm.using_limit"></el-input>
        </el-form-item>
        <el-form-item label="残值" prop="depreciation_rate">
          <el-input style="width: 100px; margin-right: 10px;" @blur="calcRate(true)"
            v-model="assetForm.depreciation_rate"></el-input>
          <el-button type="info" @click="calcRate(false)">计算折旧</el-button>
        </el-form-item>
        <el-form-item label="月折旧费" prop="mon_depr">
          <el-input v-model="assetForm.mon_depr"></el-input>
        </el-form-item>
        <el-form-item label="年折旧费" prop="year_depr">
          <el-input v-model="assetForm.year_depr"></el-input>
        </el-form-item>
        <el-form-item label="现价值" prop="value_price">
          <el-input v-model="assetForm.value_price" placeholder="不填会根据时间自己计算显示"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="assetForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="pull-left" v-if="assetForm.id"
          title="删除" type="danger" @click="delFirm"><i class="fa fa-fw fa-trash-o"></i> 删 除</el-button>
        <el-button @click="editDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="saveFirm">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>

    <!-- 批量编号录入，有二维码的数据 -->
    <el-dialog :visible.sync="plDialog" title="批量编号录入" :top="20"
      :close-on-click-modal="false">
      <el-form :model="plForm" ref="plForm" label-width="100px" :rules="plRules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="起始编号" prop="startCode">
              <el-input @change="checkPlCode" v-model="plForm.startCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="起始编号" prop="endCode">
              <el-input v-model="plForm.endCode"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="名称" prop="name">
          <el-input v-model="plForm.name"></el-input>
        </el-form-item>
        <el-form-item label="型号" prop="spec">
          <el-input v-model="plForm.spec"></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="brand">
          <el-input v-model="plForm.brand"></el-input>
        </el-form-item>
        <el-form-item label="所属公司" prop="company_id">
          <el-select v-model="plForm.company_id">
            <el-option v-for="(item) in companies" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="购入价格" prop="price">
          <el-input v-model="plForm.price"></el-input>
        </el-form-item>
        <el-form-item label="购入日期" prop="bought_at">
          <el-date-picker v-model="plForm.bought_at" type="date" placeholder="选择日期"
            value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="可使用年限" prop="using_limit">
          <el-input v-model="plForm.using_limit"></el-input>
        </el-form-item>
        <el-form-item label="残值" prop="depreciation_rate">
          <el-input style="width: 100px; margin-right: 10px;"
            v-model="plForm.depreciation_rate"></el-input>
          <!-- <el-button type="info" @click="calcRate(false)">计算折旧</el-button> -->
        </el-form-item>
        <el-form-item label="月折旧费" prop="mon_depr">
          <el-input v-model="plForm.mon_depr"></el-input>
        </el-form-item>
        <el-form-item label="年折旧费" prop="year_depr">
          <el-input v-model="plForm.year_depr"></el-input>
        </el-form-item>
        <el-form-item label="现价值" prop="value_price">
          <el-input v-model="plForm.value_price" placeholder="不填会根据时间自己计算显示"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="plForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="plDialog = false">
          <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
        <el-button type="primary" @click="plSave()">
          <i class="fa fa-fw fa-floppy-o" aria-hidden="true"></i> 确 定</el-button>
      </div>
    </el-dialog>

    <!--
    <img :src="fileUrl' + qrSrc" alt="">
     -->
  </el-card>
</template>

<script>
import VueQr from 'vue-qr';
import { printMix } from '../../components/mixins/printMixin';

export default {
  mixins: [printMix],
  components: {
    VueQr,
  },
  data() {
    return {
      // test: this.$calc.Sub(268.34, 0.83),
      companies: [],
      statusList: [
        // { label: '现有', value: 'all' },
        { label: '在用', value: 'use' },
        { label: '预留', value: 'empty' },
      ],
      // 查询条件form
      queryForm: {
        q: '',
        name: '',
        spec: '',
        brand: '',
        company_id: '',
        status: 'use',
      },
      queryParams: {}, // 分页查询参数
      firmList: [], // 管理的列表对象
      assetForm: {}, // 管理的操作时对象
      editDialog: false, // dialog显示标志 true 显示
      // 详情
      showAsset: { company: {} },
      logs: [],
      log: {},
      logRules: {
        type: [{ required: true, message: '请选择类型', trigger: ['change', 'blur'] }],
        occured_at: [{ required: true, message: '请选择日期', trigger: [] }],
      },
      logTypes: {
        1: '出借',
        2: '归还',
        3: '转卖',
        4: '报废',
        5: '其他',
      },
      addLogDialog: false, // 新添纪录
      showDialog: false,
      // 编辑form的条件
      rules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
        company_id: [{ required: true, message: '请选择企业', trigger: ['change', 'blur'] }],
        bought_at: [{ required: true, message: '请输入购入日期', trigger: ['change', 'blur'] }],
        price: [{ required: true, message: '请输入购入价', trigger: ['change', 'blur'] }],
      },
      // 批量录入
      plDialog: false,
      plForm: {},
      plRules: {
        name: [{ required: true, message: '填写名称', trigger: ['change', 'blur'] }],
        startCode: [{ required: true, message: '填写开始编号', trigger: ['change', 'blur'] }],
        endCode: [{ required: true, message: '填写结束编号', trigger: ['change', 'blur'] }],
        company_id: [{ required: true, message: '请选择企业', trigger: ['change', 'blur'] }],
        bought_at: [{ required: true, message: '请输入购入日期', trigger: ['change', 'blur'] }],
        price: [{ required: true, message: '请输入购入价', trigger: ['change', 'blur'] }],
      },
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
      qrSrc: '',
    };
  },
  computed: {
    lastLog() {
      let result = false;
      const size = this.logs.length;
      for (let i = size - 1; i >= 0; i--) {
        const log = this.logs[i];
        if (log.status === '1') {
          result = log;
          break;
        }
      }
      return result;
    },
  },
  created() {
    const obj = {
      remark: 'asdfa',
      codes: ['111', '222'],
    };
    this.axios.post('assetcheck', obj).then(() => {});
    this.axios.get('company', { params: { with_list: 1 } }).then((resp) => {
      this.companies = resp.data;
    }, (resp) => {
      this.$utils.resp(resp);
    });
    this.onSubmitQuery();
  },
  watch: {
    // 'assetForm.price': {
    //   handler() {
    //     this.calcRate(true);
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  methods: {
    // 计算折旧，empty = true 表示只有为空时才计算，默认强制都计算不管折旧费有没有
    calcRate(empty = false) {
      if (this.assetForm.price && this.assetForm.depreciation_rate) {
        let limitMons = 60;
        if (this.assetForm.using_limit) {
          limitMons = this.$calc.Mul(this.assetForm.using_limit, 12);
        }
        if (!empty || !this.assetForm.mon_depr) {
          const monDepr = this.$calc.Div(
            this.$calc.Mul(this.assetForm.price, this.$calc.Sub(1, this.assetForm.depreciation_rate)),
            limitMons
          );
          this.assetForm.mon_depr = this.$calc.FixRound(monDepr);
        }
        if (!empty || !this.assetForm.year_depr) {
          this.assetForm.year_depr = this.$calc.FixRound(this.$calc.Mul(this.assetForm.mon_depr, 12));
        }
      }
    },
    // 详情中计算折旧
    calcRate2(asset) {
      const obj = asset;
      if (obj.price && obj.depreciation_rate) {
        if (!obj.mon_depr) {
          let limitMons = 60;
          if (this.obj.using_limit) {
            limitMons = this.$calc.Mul(this.obj.using_limit, 12);
          }
          const monDepr = this.$calc.Div(
            this.$calc.Mul(obj.price, this.$calc.Sub(1, obj.depreciation_rate)),
            limitMons
          );
          obj.mon_depr2 = this.$calc.FixRound(monDepr);
        }
        if (!obj.year_depr) {
          obj.year_depr2 = this.$calc.FixRound(this.$calc.Mul(obj.mon_depr, 12));
        }
        // const d1 = new Date();
        // const d2 = new Date(obj.bought_at.replace(/-/g, '/'));
        // const m1 = d1.getMonth();
        // const m2 = d2.getMonth();
        // const y1 = d1.getFullYear();
        // const y2 = d2.getFullYear();
        // const mons = (y1 - y2) * 12 + (m1 - m2);
        // obj.mons = mons;
        if (!obj.price_value) {
          obj.value_price2 = this.$calc.Sub(obj.price, this.$calc.Mul(obj.mon_depr ? obj.mon_depr : obj.mon_depr2, obj.use_mons));
          if (obj.value_price2 < 0) {
            obj.value_price2 = 0;
          }
        }
      }
    },
    onSubmitQuery() {
      // this.queryParams = {};
      // if (this.queryForm.q && this.queryForm.q.trim()) {
      //   this.queryParams.q = this.queryForm.q;
      // }
      // if (this.queryForm.company_id) {
      //   this.queryParams.company_id = this.queryForm.company_id;
      // }
      // if (this.queryForm.status) {
      //   this.queryParams.status = this.queryForm.status;
      // }
      this.queryParams = { ...this.queryForm };
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
      this.getList();
    },
    // 查询列表数据
    getList() {
      this.axios.get('asset', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          this.firmList = response.data.data;
          this.totalItem = response.data.total;
        }, (response) => {
          this.$utils.resp(response);
        });
    },
    toEdit(item) {
      this.axios.get(`asset/${item.id}`).then((resp) => {
        resp.data.mass = 0;
        this.assetForm = resp.data;
        this.editDialog = true;
      }, (resp) => {
        this.$utils.resp(resp);
      });
    },
    showDetail(item) {
      this.axios.get(`asset/${item.id}`).then((resp) => {
        this.calcRate2(resp.data);
        this.showAsset = resp.data;
        this.logs = resp.data.logs;
        this.showDialog = true;
        this.qr(item.id);
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 新纪录
    toAdd() {
      // $table->string('spec', 200)->nullable(); // 型号
      // $table->string('sn', 100)->nullable(); // 编号
      // $table->integer('company_id')->nullable(); // 所属公司id;
      // $table->string('status', 2)->default(); // 状态 use正常，fix维修，scrap报废，selled转卖
      // $table->date('bought_at')->nullable(); // 购买日期
      // $table->decimal('price', 12, 2); // 购买价格
      // $table->decimal('using_limit', 5, 2)->nullable(); // 可使用年限
      // // $table->decimal('used_mon', 5); // 使用月数
      // $table->decimal('depreciation_rate', 5, 2)->nullable(); // 残值
      // $table->decimal('mon_depr', 12, 2)->nullable(); // 月折旧费
      // $table->decimal('year_depr', 12, 2)->nullable(); // 年折旧费
      // // $table->decimal('depreciation', 12, 2)->nullable(); // 累计折旧
      // $table->decimal('value_price', 12, 2)->nullable(); // 净值
      // $table->decimal('return_price', 12, 2)->nullable(); // 最后收回价值，转卖或报废回收价格
      // $table->text('remark')->nullable(); // 备注 -->
      const firm = {
        id: '',
        name: '',
        spec: '',
        company_id: '',
        status: '',
        bought_at: '',
        price: '',
        using_limit: '',
        depreciation_rate: '',
        mon_depr: '',
        year_depr: '',
        value_price: '',
        return_price: '',
        remark: '',
      };
      this.assetForm = firm;
      this.editDialog = true;
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    // 批量根据编号导入（需要提前生产二维码的记录）
    toAddPl() {
      const firm = {
        startCode: '',
        endCode: '',
        name: '',
        brand: '',
        spec: '',
        company_id: '',
        status: '',
        bought_at: '',
        price: '',
        using_limit: '',
        depreciation_rate: '',
        mon_depr: '',
        year_depr: '',
        value_price: '',
        return_price: '',
        remark: '',
      };
      this.plForm = firm;
      this.plDialog = true;
      this.$nextTick(() => {
        this.$refs.plForm.clearValidate();
      });
    },
    // 获取信息
    checkPlCode(sn) {
      this.$http.get('equipment/info', { params: { code: sn } }).then((resp) => {
        if (!resp.data) {
          this.$message.warn('没有对应的编号二维码记录');
        } else {
          this.plForm.company_id = resp.data.company_id;
        }
      });
    },
    // 批量录入提交
    plSave() {
      this.$refs.plForm.validate((valid) => {
        if (valid) {
          this.$http.put('equipments', this.plForm).then((resp) => {
            this.$message.success(`本次操作共录入了 ${resp.data} 条记录`, {
              duration: 8000,
            });
            this.plDialog = false;
          }, (err) => {
            this.$utils.resp(err);
          });
        }
      });
    },
    // 修改新增的保存
    saveFirm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const tempForm = { ...this.assetForm };
          if (tempForm.id) {
            this.axios.put(`asset/${tempForm.id}`, tempForm).then((resp) => {
              this.editDialog = false;
              this.$message({
                message: `${resp.data}条记录更新完成`,
                center: true,
                type: 'success',
                duration: 3000,
              });
              this.getList();
            }, (resp) => {
              this.$utils.resp(resp);
            });
          } else {
            this.axios.post('asset', tempForm).then(() => {
              this.editDialog = false;
              this.changePage(1);
            }, (resp) => {
              this.$utils.resp(resp);
            });
          }
        }
      });
    },
    closeEditDialog() {
      // this.canChooseBranch = true;
    },
    delFirm() {
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`asset/${this.assetForm.id}`).then(() => {
          this.getList();
          this.editDialog = false;
        }, (resp) => {
          this.$utils.resp(resp);
        });
      }, () => {});
    },
    qr(id) {
      // this.axios.get(`assetqr/${id}`).then((resp) => {
      //   this.qrSrc = resp.data;
      // });
      this.axios.get(`assetqr/${id}`, { responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        const fileName = resp.headers['content-disposition'].split('=')[1];
        if (fileName) {
          this.fileName = fileName;
        }
        this.qrSrc = objurl;
      });
    },
    closeShow() {
      if (this.qrSrc) {
        URL.revokeObjectURL(this.qrSrc);
        this.qrSrc = '';
      }
    },
    newLog() {
      this.log = {
        type: '1',
        from_id: '',
        from: '',
        to: '',
        to_id: '',
        remark: '',
        occured_at: '',
      };
      if (this.lastLog) {
        this.log.type = '2';
        this.logTypeChange('2');
      } else {
        this.logTypeChange('1');
      }
      this.addLogDialog = true;
      this.$nextTick(() => {
        this.$refs.logForm.clearValidate();
      });
    },
    logTypeChange(type) {
      // 1: '出借',
      // 2: '归还',
      // 3: '卖出',
      // 4: '报废',
      // 5: '其他',
      if (type === '2' || type === 2) {
        this.log.from = this.lastLog.to;
        this.log.from_id = this.lastLog.to_id;
        this.log.to = this.lastLog.from;
        this.log.to_id = this.lastLog.from_id;
      } else if ((type === 1 || type === '1') && this.lastLog) {
        this.log.from_id = this.lastLog.to_id;
      } else {
        this.log.from_id = this.showAsset.company_id;
      }
    },
    saveLog() {
      this.$refs.logForm.validate((valid) => {
        if (valid) {
          this.axios.post(`asset/${this.showAsset.id}/log`, this.log).then((resp) => {
            this.showAsset = resp.data;
            this.logs = this.showAsset.logs;
            this.addLogDialog = false;
          }, (err) => {
            this.$utils.resp(err);
          });
        }
      });
    },
    // 打印标签
    pplbPrint(id) {
      let asset = { name: '', sn: '', spec: '' };
      this.axios.get(`asset/${id}`).then((resp) => {
        asset = resp.data;
        if (asset.id) {
          if (!this.ws) {
            this.connectPrinter();
          }
          setTimeout(() => {
            this.printTag(asset);
            // this.getPrinterStatus();
          }, 1000);
        }
      }, (err) => {
        this.utils.resp(err);
      });
    },
  },

  // filters: {
  //   substr4(value) {
  //     return value.substr(0, 4);
  //   },
  // },
};
</script>
